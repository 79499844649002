import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';

export const Container = styled(RowContainer)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const MainContainer = styled(ColumnContainer)`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding-bottom: 250px;
`;

export const SideContainer = styled.div`
  flex: 0 0 350px;
  padding-right: 45px;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;
