import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'modules/ui/components';
import { NotFoundImage } from '../../assets';

import { Container, Title, Subtitle, Image } from './styled';

const NotFoundPage = () => {
  const history = useHistory();
  const handleBackHome = useCallback(() => {
    history.replace('/');
  }, [history]);
  return (
    <Container>
      <Image src={NotFoundImage} alt="not-found" />
      <Title>Whoops! Lost in space?</Title>
      <Subtitle>The page you are looking for isn’t found.</Subtitle>
      <Button clickHandler={handleBackHome} label="Back to GalaxyCon" />
    </Container>
  );
};

export default React.memo(NotFoundPage);
