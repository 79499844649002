import styled from 'styled-components';
import { Button } from 'modules/ui';

export const Tr = styled.tr`
  text-align: left;
  margin: 8px;
`;

export const Td = styled.th<{ width: number }>`
  word-wrap: break-word;
  width: ${(props) => props.width}px;
  border-bottom: 1px solid ${(props) => props.theme.ga.grayBackground};
  padding-bottom: 8px;
  padding-left: 8px;
`;

export const RowButton = styled(Button)`
  height: 44px;
`;
