import { RootState } from 'modules/main';
import {
  ParticipantState,
  CommonMeetingSelectors,
} from 'modules/common-meeting';

import { TechCheckStatus } from '../consts';

export const techCheckStatusByBpsId = (bpsId: number) => (
  state: RootState,
): TechCheckStatus => {
  const participant = CommonMeetingSelectors.getParticipantByBpsId(bpsId)(
    state,
  );
  const participantState = participant?.State;

  switch (participantState) {
    case ParticipantState.READY_TO_JOIN:
    case ParticipantState.IN_ANNOUNCEMENT:
    case ParticipantState.IN_A_CALL:
    case ParticipantState.IN_PHOTO_TIMER:
    case ParticipantState.CALL_PAUSED:
    case ParticipantState.FINISHED:
      return TechCheckStatus.READY;

    case ParticipantState.TECH_CHECK_FAILED:
      return TechCheckStatus.FAILED;

    default:
      return TechCheckStatus.UNKNOWN;
  }
};
