import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, H2, Button, TextRegular } from 'modules/ui';
import { InviteMemberForm, AccountActions } from 'modules/account';
import { InviteModalContainer, ButtonContainer } from './styled';
import { ModalVersion } from '../../consts';
import { NewAdminData } from '../../types';

type Props = {
  isOpen: boolean;
  version: ModalVersion;
  closeModal: () => void;
  handleRemove: () => void;
};

export const InviteModal: React.FC<Props> = React.memo(
  ({ isOpen, version, handleRemove, closeModal }) => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({
      success: false,
      message: '',
      hasReponse: false,
    });
    const onSubmit = useCallback(async (data: NewAdminData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await dispatch(
        AccountActions.inviteNewAdmin({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
        }),
      );
      const { success, message } = res;
      setResponse({ success, message, hasReponse: true });
      closeModal();
    }, []);

    return (
      <>
        <Modal
          isOpen={isOpen}
          fullWidth={false}
          closeModal={closeModal}
          onRequestClose={closeModal}
          isDark
          height={675}
          width={500}
        >
          <InviteModalContainer>
            {version === ModalVersion.INVITE_FORM && !response.success && (
              <>
                <H2>Invite new member</H2>
                <InviteMemberForm onSubmit={onSubmit} closeModal={closeModal} />
              </>
            )}
            {version === ModalVersion.INVITE_FORM && response.success && (
              <>
                <H2>Invite sent succesfully!</H2>
                <TextRegular className="text">
                  John will receive your invitation by email.
                </TextRegular>
                <ButtonContainer>
                  <Button label="Close" isDark clickHandler={closeModal} />
                </ButtonContainer>
              </>
            )}
            {version === ModalVersion.REMOVE_MEMBER && (
              <>
                <H2>Remove member</H2>
                <TextRegular className="text">
                  Are you sure you want to remove member?
                </TextRegular>
                <ButtonContainer>
                  <Button label="Cancel" isDark clickHandler={closeModal} />
                  <Button label="Yes, Remove" clickHandler={handleRemove} />
                </ButtonContainer>
              </>
            )}
          </InviteModalContainer>
        </Modal>
      </>
    );
  },
);
