import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';

export const Container = styled(RowContainer)`
  margin-top: 20px;
`;

export const FormWrapper = styled.div`
  margin-top: 30px;
`;

export const FormContainer = styled(ColumnContainer)`
  width: 650px;
  margin-right: auto;
  padding-left: 15%;

  div {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 60px;
  }
`;

export const SideBySideFormContaienr = styled(RowContainer)`
  justify-content: space-between;
  margin-bottom: 0 !important;

  & > div {
    flex-grow: 1;
  }

  & :first-child {
    margin-right: 20px;
  }
`;

export const AccountNavBar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: auto;
  margin-top: 20px;

  a {
    text-decoration: none;
    color: black;
    border-left: 5px solid transparent;
    margin-bottom: 8px;
    padding: 20px 0 20px 50px;

    &.active {
      color: ${(props) => props.theme.ga.magenta};
      border-left: 5px solid ${(props) => props.theme.ga.magenta};
    }
  }
`;

export const SubmitWrapper = styled(ColumnContainer)`
  align-items: flex-end;
  padding-top: 50px;

  button {
    padding: 12px 30px;
  }

  .forgot-password {
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
  }
`;

export const CenterAlign = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginButton = styled.button`
  color: ${(props) => props.theme.ga.black};
  background: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 20px;
  border: 0;
  margin-top: 5px;
`;
