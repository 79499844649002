import React, { useEffect, useMemo } from 'react';
import { SideNavigation } from 'modules/account';
import { Container } from '../../styled';
import { AccountRoutes, AdminAccountRoutes, TechRoutes } from '../consts';

type Props = {
  isAdmin?: boolean;
  isTech?: boolean;
};

export const AccountLayout: React.FC<Props> = React.memo(
  ({ isAdmin, isTech, children }) => {
    useEffect(() => {
      document.title = `GalaxyCon Account`;
    });

    const routes = useMemo(() => {
      if (isTech) {
        return TechRoutes;
      }

      if (isAdmin) {
        return AdminAccountRoutes;
      }

      return AccountRoutes;
    }, [isAdmin, isTech]);

    return (
      <Container>
        <SideNavigation routes={routes} />
        {children}
      </Container>
    );
  },
);
