import styled from 'styled-components';

export const Countdown = styled.div`
  position: relative;
  margin-right: 16px;
  height: 44px;
  width: 44px;
  text-align: center;
`;

export const CountdownNumber = styled.div<{
  time: number;
  lastSeconds: boolean;
}>`
  color: ${({ lastSeconds, theme }) =>
    !lastSeconds ? theme.ga.green : theme.ga.magenta};
  display: inline-block;
  font-weight: 800;
  line-height: 32px;
  height: 100%;
`;
