import { get, create, Response } from 'modules/api';
import {
  MeetingInfo,
  SendToCallWithTalentRequestData,
  FinishParticipantRequestData,
  SendToQueueRequestData,
  PauseTalentCallRequestData,
  IncrementCallSecondsLeftData,
  StartScreenshotTimerRequestData,
  PrepareWaitingRoomBody,
  EnterWaitingRoomBody,
  EnterWaitingRoomResponseData,
  UpdateTalentNameBody,
  ReorderFansBody,
  SetOffParticipantHeartbeatRequestData,
} from '../types';
import { URL } from '../consts';

// todo: add types to responses
export const enterWaitingRoomRequest = (
  body: EnterWaitingRoomBody,
): Promise<Response<EnterWaitingRoomResponseData>> => {
  return create(URL.ENTER_WAITING_ROOM, body);
};

export const prepareWaitingRoomRequest = (
  body: PrepareWaitingRoomBody,
): Promise<Response<unknown>> => {
  return create(URL.PREPARE_WAITING_ROOM, body);
};

export type CreateTechCheckRequestData = {
  bookedParticipantSlotId: number;
  techCheckUserId: number;
};

type GetShopifyRedirectResponseData = {
  url: string;
};
export const getShopifyRedirectRequest = (): Promise<
  Response<GetShopifyRedirectResponseData>
> => get(URL.GET_SHOPIFY_REDIRECTS);

export const getTechCheckJoiningInfoRequest = (
  bookedParticipantSlotId: number,
): Promise<Response<MeetingInfo>> =>
  get(`${URL.GET_TECH_CHECK_JOINING_INFO}?BpsId=${bookedParticipantSlotId}`);

export const sendToCallWithTalentRequest = (
  body: SendToCallWithTalentRequestData,
): Promise<Response<unknown>> => create(URL.SEND_TO_CALL_WITH_TALENT, body);

export const finishParticipantRequest = (
  body: FinishParticipantRequestData,
): Promise<Response<unknown>> => create(URL.FINISH_PARTICIPANT, body);

export const sendToQueueRequest = (
  body: SendToQueueRequestData,
): Promise<Response<unknown>> => create(URL.SEND_TO_QUEUE, body);

export const pauseCallWithTalentRequest = (
  body: PauseTalentCallRequestData,
): Promise<Response<unknown>> => create(URL.PAUSE_TALENT_CALL, body);

export const incrementCallSecondsLeftRequest = (
  body: IncrementCallSecondsLeftData,
): Promise<Response<unknown>> => create(URL.INCREMENT_CALL_SECONDS_LEFT, body);

export const startScreenshotTimerRequest = (
  body: StartScreenshotTimerRequestData,
): Promise<Response<unknown>> => create(URL.START_PHOTO_TIMER, body);

// todo: add types to responses
export const updateTalentNameRequest = (
  body: UpdateTalentNameBody,
): Promise<Response<unknown>> => {
  return create(URL.UPDATE_TALENT_NAME, body);
};

export const reorderFansRequest = (
  body: ReorderFansBody,
): Promise<Response<unknown>> => {
  return create(URL.REORDER_FANS, body);
};

export const setOffParticipantHeartbeatRequest = (
  body: SetOffParticipantHeartbeatRequestData,
): Promise<Response<unknown>> =>
  create(URL.SET_OFF_PARTICIPANT_HEARTBEAT, body);
