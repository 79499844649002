import styled from 'styled-components';
import { device } from 'modules/utils';

export const EventContainer = styled.div`
  width: 49%;
  max-width: 552px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.ga.white};
  margin-bottom: 22px;
  position: relative;
  img {
    width: 100%;
  }
  h4 {
    font-weight: bold;
    margin: 0;
    width: 92%;
  }
  button {
    margin-top: 19px;
  }
  span {
    line-height: 26px;
  }

  @media ${device.tablet} {
    width: 100%;
    max-width: none;
  }

  @media ${device.mobile} {
    height: auto;
    width: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const InfoBox = styled.div`
  svg {
    display: none;
  }

  padding: 15px 24px 84px 24px;

  @media ${device.mobile} {
    padding: 8px 16px 10px 16px;
    position: relative;

    svg {
      display: inline-block;
      position: absolute;
      bottom: 12px;
      right: 20px;
    }
  }
`;

export const TextRegular = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;

  @media ${device.mobile} {
    display: none;
  }
`;

export const BottomButton = styled.div`
  position: absolute;
  bottom: 16px;
  @media ${device.mobile} {
    display: none;
  }
`;
