import styled from 'styled-components';
import { Arrow } from '../../assets';

export const CalendarContainer = styled.div`
  .Calendar {
    box-shadow: none;
    .Calendar__day.-selected {
      background: ${(props) => props.theme.ga.blue};
    }

    .Calendar__header {
      display: flex;
      flex-direction: row;

      button:first-child {
        order: 2;
      }

      button:last-child {
        order: 3;
      }

      .Calendar__monthYearContainer {
        .Calendar__monthYear > * {
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .Calendar__monthYear {
        left: 29%;
      }

      .Calendar__monthArrowWrapper {
        .Calendar__monthArrow {
          background-image: url(${Arrow});
        }

        &.-left {
          transform: rotate(180deg);
        }
        &.-right {
          transform: rotate(0deg);
        }
      }
    }
  }
`;
