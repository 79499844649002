import React from 'react';
import {
  AddIcon,
  Button,
  DeleteIcon,
  Label,
  TextItalic,
  TextRegular,
} from 'modules/ui';
import { useModal } from 'modules/main';
import { GalaxyProductModel } from '../../types';
import { EventDictionary } from '../../dictionary';
import { ProductsModal } from '../ProductsModal';
import { ProductsContainer, Product } from './styled';

type Props = {
  selectedProducts: GalaxyProductModel[];
  eventProducts?: GalaxyProductModel[];
  deselectProduct: (product: GalaxyProductModel) => void;
  selectProduct: (product: GalaxyProductModel) => void;
};

export const ProductsPicker: React.FC<Props> = React.memo(
  ({ eventProducts, selectedProducts, deselectProduct, selectProduct }) => {
    const [isProductsOpen, openProducts, closeProducts] = useModal(false);

    return (
      <>
        <ProductsContainer>
          <Label>{EventDictionary.CONNECT_PRODUCT}</Label>
          <TextItalic>{EventDictionary.CONNECT_SHOPIFY_PRODUCTS}</TextItalic>
          {selectedProducts.map((product: GalaxyProductModel) => (
            <Product key={product.id}>
              <TextRegular>{product.title}</TextRegular>
              <DeleteIcon onClick={() => deselectProduct(product)} />
            </Product>
          ))}
          <Button
            clickHandler={openProducts}
            isTertiary
            label={EventDictionary.CONNECT_PRODUCTS}
          >
            <AddIcon />
          </Button>
        </ProductsContainer>
        {isProductsOpen && (
          <ProductsModal
            isOpen={isProductsOpen}
            eventProducts={eventProducts}
            selectProduct={selectProduct}
            selectedProducts={selectedProducts}
            onRequestClose={closeProducts}
          />
        )}
      </>
    );
  },
);
