/* eslint-disable no-shadow */

export enum Dictionary {
  AUTH_SIDE_HEADING = 'Join Celebrities in Virtual Experiences Online',
  CREATE_SESSION = 'Create Session',
  CREATE_NEW_SESSION = 'Create new session',
  CONNECT_PRODUCT = 'CONNECT PRODUCT',
  COPY_LINK = 'Copy Link',
  EMPTY_SESSION = 'Currently, there are no created sessions',
  EXPERIENCE = 'Experience live sessions with your favorite celebrities and see the vide recordings here!',
  GALAXYCON = 'GalaxyCon',
  JOIN_SESSION = 'Join the Session',
  LOGIN_HEADING = 'Log in to your account',
  LOG_IN_WITH = 'log in with',
  LOG_IN = 'Log in',
  NEW_SESSION = 'Create new session',
  FORGOT_PASSWORD = 'Forgot password?',
  NO_SESSIONS = 'Currently, there are no created sessions for the selected date',
  NO_SESSIONS_SCHEDULE = 'Currently, there are no sessions in your schedule.',
  NO_VIDEOS = 'Looks like there are no videos for the selected date',
  NO_ORDERS = 'Looks like there are no orders for the selected date',
  DONT_HAVE_ACCOUNT = 'Don’t have an account?',
  SETUP_YOUR_ACCOUNT = 'Setup your account',
  SIGN_UP = 'Sign up',
  INVITE_MEMBER = 'Invite new member',
}
