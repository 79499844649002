/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import { LobbyActions } from 'modules/lobby';
import { Response } from 'modules/utils';
import { TalentActions } from 'modules/talent-meeting';
import { setTokens } from 'modules/api';
import { Roles } from '../consts';
import {
  // setMediaRegion,
  logIn,
  logOut,
  setVerifyEmailResponse,
  setUserProfile,
} from './actions';
import { Profile, Tokens } from '../types';

export const profile = createReducer({} as Profile, (builder) => {
  builder
    .addCase(logIn, (_state, action) => ({
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
    }))
    .addCase(TalentActions.setTalentName, (state, action) => ({
      ...state,
      firstName: action.payload,
    }))
    .addCase(setUserProfile, (_state, action) => ({
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
    }))
    .addCase(logOut, () => ({} as Profile));
});

export const tokens = createReducer({} as Tokens, (builder) => {
  builder.addCase(logIn, (_state, action) => action.payload.tokens);
  builder
    .addCase(setTokens, (_state, action) => ({
      access: action.payload.accessToken,
      refresh: action.payload.refreshToken,
    }))
    .addCase(TalentActions.authorizeTalent.fulfilled, (draft, action) => {
      draft.access = action.payload.result.accessToken;
    })
    .addCase(logOut, () => ({} as Tokens));
});

export const isLoggedIn = createReducer(false, (builder) => {
  builder.addCase(logIn, () => true);
  builder.addCase(TalentActions.authorizeTalent.fulfilled, () => true);
  builder.addCase(logOut, () => false);
});

export const role = createReducer(Roles.NONE, (builder) => {
  builder
    .addCase(logIn, (_state, action) => action.payload.role)
    .addCase(TalentActions.setTalentName, () => Roles.TALENT)
    .addCase(logOut, () => Roles.NONE);
});

export const id = createReducer(null as number | null, (builder) => {
  builder
    .addCase(logIn, (_state, action) => action.payload.id)
    .addCase(logOut, () => null);
});

export const externalId = createReducer(null as null | string, (builder) => {
  builder
    .addCase(logIn, (_state, action) => action.payload.id.toString())
    .addCase(
      TalentActions.authorizeTalent.pending,
      (state, action) => action.meta.arg.attendeeGuid,
    )
    .addCase(logOut, () => null);
});

export const authResponse = createReducer(
  {} as Response<unknown>,
  (builder) => {
    builder.addCase(setVerifyEmailResponse, (_state, action) => action.payload);
  },
);

const reducer = combineReducers({
  profile,
  tokens,
  isLoggedIn,
  role,
  id,
  externalId,
  authResponse,
});
export type User = ReturnType<typeof reducer>;

const persistConfig: PersistConfig<User> = {
  key: 'user',
  storage,
};

export const userReducer = persistReducer(persistConfig, reducer);
