import styled from 'styled-components';
import { PersonApproved, Theme } from 'modules/ui';
import { TechCheckStatus } from '../../consts';

interface ColorResolverProps {
  status: TechCheckStatus;
  theme: Theme;
}

const resolveTechCheckStatusColor = ({ status, theme }: ColorResolverProps) => {
  switch (status) {
    case TechCheckStatus.READY:
      return theme.ga.green;
    case TechCheckStatus.FAILED:
      return theme.ga.magenta;
    default:
      return theme.ga.gray;
  }
};

export const StyledPersonApproved = styled(PersonApproved)<{
  // eslint-disable-next-line no-restricted-globals
  status: TechCheckStatus;
}>`
  margin-left: auto;
  color: ${resolveTechCheckStatusColor};
`;
