import styled from 'styled-components';
import { device } from 'modules/utils';

export const HeroContainer = styled.div`
  background-color: ${({ theme }) => theme.ga.darkBlue};
  color: white;
  justify-content: flex-start;
  align-items: center;
  max-width: 1136px;
  margin: 0 auto;
  padding: 80px 72px 56px 72px;
  h1 {
    margin: 0;
  }
  h3 {
    display: none;
  }

  @media ${device.laptop} {
    padding: 80px 20px 56px 20px;
    max-width: none;
  }

  @media ${device.tablet} {
    padding: 80px 0 56px 0;
  }

  @media ${device.mobile} {
    padding: 0 22px 0px 22px;
    h1 {
      display: none;
    }
    h3 {
      display: inline-block;
      margin: 0;
    }
  }
`;

export const CurrentEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  @media ${device.mobile} {
    margin-top: 8px;
  }
`;
