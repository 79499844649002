import { isMobileSafari } from 'react-device-detect';
import {
  ColumnContainer,
  H1,
  H2Regular,
  H3,
  H5,
  SmallCaption,
  TalentBreakInfoCard,
} from 'modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { LobbyActions, LobbySelectors } from 'modules/lobby';
import {
  CommonMeetingSelectors,
  DevicePermissionsModal,
  useConnectToReadinessCall,
} from 'modules/common-meeting';
import { TwilioChat } from 'modules/chat/components/Chat/TwilioChat';
import { PreviewVideo } from '../PreviewVideo';
import { CallAnnouncement } from '../CallAnnouncement';
import {
  Container,
  InfoContainer,
  PlayerContainer,
  PositionInfo,
  PositionInfoMobile,
  SessionStartInfo,
  VideosContainer,
  InfoCardMobileContainer,
  ChatWrap,
} from './styled';

export const WaitingRoom: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { uid } = useParams<Record<string, string | undefined>>();
  const loggedInFanBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const lobby = useSelector(LobbySelectors.getLobbyInfo);
  // todo
  const callStart = false;
  const fan = useSelector(CommonMeetingSelectors.getLoggedInParticipant);
  const isOnBreak = useSelector(CommonMeetingSelectors.isTalentOnBreak);

  const showPosition = fan?.Position;
  const isFirst = fan?.Position === 1;

  const chatId = useMemo(() => `${uid}-${loggedInFanBpsId}`, [
    uid,
    loggedInFanBpsId,
  ]);

  useEffect(() => {
    if (uid) {
      dispatch(LobbyActions.getWaitingRoomInfo(uid));
    }
  }, [dispatch, uid]);

  useConnectToReadinessCall();

  return (
    <Container isMobileSafari={isMobileSafari}>
      <InfoContainer>
        <H5>WAITING ROOM</H5>
        <H3>{lobby.name}</H3>
        <PositionInfo>
          <H5>You are</H5>
          {showPosition && <H1>{`${fan?.Position} in line`}</H1>}
          {isFirst && (
            <SessionStartInfo>
              <H5>Session starts in</H5>
              <H1>2-5 minutes</H1>
            </SessionStartInfo>
          )}
        </PositionInfo>
        {isOnBreak && <TalentBreakInfoCard />}
      </InfoContainer>
      <VideosContainer>
        {!isMobileSafari && <PreviewVideo />}
        <PositionInfoMobile>
          {showPosition && (
            <ColumnContainer>
              <H5>You are</H5>
              <H2Regular>{`${fan?.Position} in line`}</H2Regular>
            </ColumnContainer>
          )}
          {isFirst && (
            <SessionStartInfo>
              <H5>Session starts in</H5>
              <H2Regular>2-5 minutes</H2Regular>
            </SessionStartInfo>
          )}
        </PositionInfoMobile>
        <InfoCardMobileContainer>
          {isOnBreak && <TalentBreakInfoCard />}
        </InfoCardMobileContainer>
        <SmallCaption>We’ve prepared the clips for you to enjoy</SmallCaption>
        <PlayerContainer>
          <ReactPlayer width="100%" height="100%" url={lobby.youTubeLink} />
        </PlayerContainer>
        <ChatWrap>
          <TwilioChat chatId={chatId} isFan />
        </ChatWrap>
      </VideosContainer>
      {callStart && <CallAnnouncement isOpen={callStart} />}
      <DevicePermissionsModal />
    </Container>
  );
});
