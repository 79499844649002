import styled from 'styled-components';
import { RowContainer } from 'modules/ui';

export const SessionItem = styled(RowContainer)`
  padding: 14px 12px;
  border-top: 1px solid ${({ theme }) => theme.ga.grayBackground};
  justify-content: space-between;
  cursor: pointer;

  span {
    max-width: 80%;
  }
`;

export const Duration = styled(RowContainer)`
  padding-right: 20px;
  svg {
    padding-right: 9px;
  }
`;
