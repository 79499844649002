// eslint-disable-next-line no-shadow
export enum Paths {
  Root = '/',
  Admin = '/admin/',
  AdminAccountDetails = '/admin/account/details',
  AdminChangePassword = '/admin/account/change-password',
  AdminMembers = '/admin/account/members',
  Sessions = '/admin/home/sessions',
  Media = '/admin/home/media',
  Orders = '/admin/home/orders',
  ClientVideos = '/videos',
  Schedule = '/admin/schedule',
  CreateEvent = '/admin/home/sessions/create-session',
  EventEdit = '/admin/home/sessions/:id/edit',
  AdminSession = '/admin/session/:uid',
  UserSignup = '/signup',
  UserLogin = '/login',
  VerifyEmail = '/verify-email',
  VerifyAdmin = '/verify-admin',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  CheckMail = '/check-mail',
  Meeting = '/session/:uid',
  Account = '/account',
  AccountDetails = '/account/details',
  ChangePassword = '/account/change-password',
  MyOrders = '/account/my-orders',
  WaitingRoom = '/waiting-room/:waitingRoomId/joined',
  TalentSession = '/talent/session/:attendeeGuid/:uid',
  FanSession = '/session/:uid',
  TechCheckMeeting = '/tech-check/:uid/:id',
  UserSchedule = '/user-schedule',
  NextSessions = '/user-schedule/next-sessions',
  PastSessions = '/user-schedule/past-sessions',
  FanVideos = '/videos',
  Events = '/events',
  Recommended = '/recommended',
  PrepareSession = '/waiting-room/:waitingRoomId',
}
