import styled from 'styled-components';
import { device } from 'modules/utils';
import { H4 } from 'modules/ui';

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.ga.white};

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  ${H4} {
    margin: 0 0 8px 0;
    font-weight: bold;
  }
`;

export const InfoBox = styled.div`
  padding: 24px;

  @media ${device.mobile} {
    padding: 16px;
  }
`;
