import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import { CommonMeetingSelectors, CommonMeetingActions } from '../redux';

const CONNECTION_CHECK_INTERVAL = 10000;

export const useIsDisconnected = (): void => {
  const dispatch = useDispatch();

  const lastMessageTimestampSelector = useSelector(
    CommonMeetingSelectors.lastMessageReceivedTimestampSelector,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const millisecondsElapsedSinceLastMqMessage = differenceInMilliseconds(
        Date.now(),
        lastMessageTimestampSelector,
      );
      if (millisecondsElapsedSinceLastMqMessage > CONNECTION_CHECK_INTERVAL) {
        dispatch(CommonMeetingActions.setIsDisconnected(true));
      }
    }, CONNECTION_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [lastMessageTimestampSelector, dispatch]);
};
