import React from 'react';
import { useSelector } from 'react-redux';
import { Attendee } from 'modules/utils';
import { ChatIcon, ChatNewMessageIcon } from 'modules/ui';
import { ChatHelpers, ChatSelectors } from 'modules/chat';

type Props = {
  attendee: Attendee;
  onIconClick: () => void;
};

export const AttendeeChatIcon: React.FC<Props> = React.memo(
  ({ attendee, onIconClick }) => {
    const twilioChats = useSelector(ChatSelectors.getTwilioChats);

    const hasNewMessages = (currentAttendee: Attendee) => {
      const chatId = ChatHelpers.getFanChatIdFromBpsId(
        currentAttendee.waitingRoomGuid,
        currentAttendee.bookedParticipantSlotId.toString(),
      );

      const chat = twilioChats[chatId];

      return chat && chat.hasNew;
    };

    return (
      <>
        {hasNewMessages(attendee) ? (
          <ChatNewMessageIcon onClick={onIconClick} />
        ) : (
          <ChatIcon color="#98A7B8" onClick={onIconClick} />
        )}
      </>
    );
  },
);
