import styled from 'styled-components';
import { ColumnContainer } from 'modules/ui';

export const LoginRedirectContainer = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
  width: 550px;
  margin-left: auto;
  margin-right: auto;
  button {
    margin: 20px 0;
    padding: 12px 30px;
  }
`;
