import styled from 'styled-components';
import { device } from 'modules/utils';

export const FormContainer = styled.div`
  flex-direction: column;
  width: 650px;
  padding-right: 100px;
  padding-left: 15%;

  div {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 60px;
  }

  @media ${device.tablet} {
    width: 85%;
    margin: 0 auto;
    padding: 0;
  }
`;
