import React from 'react';
import { Button, TextInput } from 'modules/ui';
import { Field, Form } from 'react-final-form';
import { UpdateProfile } from 'modules/auth';
import { Validators } from 'modules/utils';
import {
  SideBySideFormContainer,
  SubmitWrapper,
  FormWrapper,
} from '../../styled';

type Props = {
  onSubmit: (data: UpdateProfile) => void;
  firstName: string;
  lastName: string;
  email: string;
};

export const ProfileForm: React.FC<Props> = React.memo(
  ({ onSubmit, firstName, lastName, email }) => {
    return (
      <FormWrapper>
        <Form
          onSubmit={onSubmit}
          validateOnBlur
          initialValues={{
            firstName,
            lastName,
            email,
          }}
          render={({ handleSubmit, invalid }): React.ReactNode => (
            <>
              <SideBySideFormContainer>
                <Field
                  type="text"
                  name="firstName"
                  label="FIRST NAME"
                  placeholder="First name"
                  component={TextInput}
                  validate={Validators.required}
                  isSecondary
                />
                <Field
                  type="text"
                  name="lastName"
                  label="LAST NAME"
                  placeholder="Last name"
                  validate={Validators.required}
                  component={TextInput}
                  isSecondary
                />
              </SideBySideFormContainer>

              <Field
                type="text"
                name="email"
                label="EMAIL"
                placeholder="Email"
                component={TextInput}
                isSecondary
                disabled
              />
              <SubmitWrapper>
                <Button
                  label="Save changes"
                  clickHandler={handleSubmit}
                  disabled={invalid}
                />
              </SubmitWrapper>
            </>
          )}
        />
      </FormWrapper>
    );
  },
);
