import { ColumnContainer, RowContainer } from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  height: 485px;
  width: 100%;
  color: ${({ theme }) => theme.ga.white};
`;

export const Header = styled(RowContainer)`
  box-sizing: border-box;
  padding: 18px 24px;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  & > svg path {
    fill: white;
  }
`;

export const TabCaption = styled(RowContainer)`
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 12px;
  }
`;

export const ContentContainer = styled.div`
  .react-tabs__tab-panel {
    padding: 0px 40px;

    & > div {
      margin-bottom: 24px;
    }
  }
  .react-tabs__tab-list {
    padding-left: 40px;
    border-color: rgba(239, 242, 245, 0.1);

    .react-tabs__tab {
      bottom: -1px;
      padding: 12px 0px;
      margin-right: 45px;
    }

    .react-tabs__tab--selected {
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.ga.pink};
      border-bottom: 1px solid;
    }
  }
`;
