import { create, get } from 'modules/api';
import { Response } from 'modules/utils';
import { URL } from '../consts';
import {
  AuthorizeTalentBody,
  AuthorizeTalentResponseData,
  ToggleTalentBreakRequest,
  GetFanQuestionnaireAnswersResponseData,
} from '../types';

export const authorizeTalentRequest = (
  body: AuthorizeTalentBody,
): Promise<Response<AuthorizeTalentResponseData>> => {
  return create(URL.AUTHORIZE_TALENT, body);
};

export const toggleTalentBreakRequest = (
  body: ToggleTalentBreakRequest,
): Promise<Response<unknown>> => create(URL.SET_TALENT_BREAK, body);

export const getFanQuestionnaireAnswersRequest = (
  bpsId: number,
): Promise<Response<GetFanQuestionnaireAnswersResponseData>> =>
  get(`${URL.GET_FAN_QUESTIONNAIRE_ANSWERS}?bpsId=${bpsId}`);
