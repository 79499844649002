import styled, { css } from 'styled-components';
import { device } from 'modules/utils';

export const PastSession = styled.div<{ disabled?: boolean }>`
  width: 100%;
  max-width: 1000px;
  height: 153px;
  background: 'white';
  border: 1px solid ${({ theme }) => theme.ga.gray};
  margin-bottom: 16px;
  padding: 27px 27px;
  box-sizing: border-box;
  border-radius: 4px;

  .title {
    font-size: 18px;
  }

  ${({ disabled }) => css`
    .title {
      color: ${disabled
        ? ({ theme }) => theme.ga.darkGrayHalfOpacity
        : ({ theme }) => theme.ga.darkGray};
    }
  `}

  @media ${device.laptop} {
    padding: 18px 18px;
    .title {
      font-size: 17px;
    }
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
    height: auto;
    .title {
      font-size: 18px;
      margin-bottom: 22px;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 11px;
  }

  @media ${device.mobile} {
    span {
      font-size: 16px;
    }
  }
`;

export const IconContainer = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .play {
    margin-right: 16px;
  }
`;
