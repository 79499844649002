import React from 'react';
import { useSelector } from 'react-redux';
import { TechCheckSelectors } from '../../redux';
import { StyledPersonApproved } from './styled';

interface Props {
  fanBpsId: number;
}

export const TechCheckStatusIcon: React.FC<Props> = ({ fanBpsId }) => {
  const techCheckStatus = useSelector(
    TechCheckSelectors.techCheckStatusByBpsId(fanBpsId),
  );
  return <StyledPersonApproved status={techCheckStatus} />;
};
