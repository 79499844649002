import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TextItalic } from 'modules/ui';

const Terms = styled(TextItalic)`
  font-style: italic !important;
`;

const LinkText = styled.span`
  color: ${({ theme }) => theme.ga.magenta};
  font-style: italic !important;
`;

const AgreementTextWithLinks = () => {
  const onTosClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      window.open('https://galaxycon.com/policies/terms-of-service', '_blank');
    },
    [],
  );
  const onPolicyClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      window.open('https://galaxycon.com/policies/privacy-policy', '_blank');
    },
    [],
  );
  return (
    <Terms>
      {'I agree to '}
      <LinkText onClick={onTosClick}>GalaxyCon Terms</LinkText>
      {' of Service and '}
      <LinkText onClick={onPolicyClick}>Privacy Policy</LinkText>
    </Terms>
  );
};

export default React.memo(AgreementTextWithLinks);
