import { createReducer } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initAdmin } from './actions';

const adminReducer = createReducer(false, {
  [initAdmin.type]: (_state, action) => action.payload,
});

const persistConfig: PersistConfig<ReturnType<typeof adminReducer>> = {
  key: 'admin',
  storage,
  whitelist: ['adminReducer'],
};

export default persistReducer(persistConfig, adminReducer);
