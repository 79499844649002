import { createSelector } from '@reduxjs/toolkit';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { RootState } from 'modules/main';

export const shouldShowChatOptions = createSelector(
  [
    CommonMeetingSelectors.getFanParticipantInLiveCall,
    CommonMeetingSelectors.getFanParticipantInPhotoTimer,
  ],
  (fanParticipantInLiveCall, fanParticipantInPhotoTimer) =>
    !fanParticipantInLiveCall && !fanParticipantInPhotoTimer,
);

export const fanQuestionnaireAnswersSelector = (
  state: RootState,
): RootState['talent']['questionnaireAnswers'] =>
  state.talent.questionnaireAnswers;

export const whereFromFanQuestionnaireAnswerSelector = createSelector(
  fanQuestionnaireAnswersSelector,
  (questionnaireAnswers) =>
    questionnaireAnswers.answers?.find((answer) => answer.key === 'WhereFrom')
      ?.value,
);

export const favouriteMovieFanQuestionnaireAnswerSelector = createSelector(
  fanQuestionnaireAnswersSelector,
  (questionnaireAnswers) =>
    questionnaireAnswers.answers?.find(
      (answer) => answer.key === 'FavouriteMovie',
    )?.value,
);

export const talkAboutFanQuestionnaireAnswerSelector = createSelector(
  fanQuestionnaireAnswersSelector,
  (questionnaireAnswers) =>
    questionnaireAnswers.answers?.find((answer) => answer.key === 'TalkAbout')
      ?.value,
);
