import { createAction } from '@reduxjs/toolkit';
import ky, { BeforeRetryHook } from 'ky';
import { Response } from './types';
import { URL } from './urls';
import { store } from '../../index';

type RefreshTokensResponse = {
  accessToken: string;
  refreshToken: string;
};

export const setTokens = createAction<RefreshTokensResponse>('SET_TOKENS');

const BASE_API_URL = process.env.REACT_APP_API_URL;

const UnprotectedRoutes = [
  `${BASE_API_URL}${URL.AUTH}`,
  `${BASE_API_URL}${URL.REGISTER}`,
  `${BASE_API_URL}${URL.FORGOT_PASSWORD}`,
  `${BASE_API_URL}${URL.RESET_PASSWORD}`,
  `${BASE_API_URL}${URL.RESEND_VERIFICATION_EMAIL}`,
  `${BASE_API_URL}${URL.UPDATE_TALENT_NAME}`,
  `${BASE_API_URL}${URL.AUTHORIZE_TALENT}`,
];

const setAuthorizationHeader = (request: Request): Request => {
  const { user } = store.getState();
  const { url } = request;
  const isUnprotectedRoute = UnprotectedRoutes.some(
    (route: string) => route === url,
  );

  if (isUnprotectedRoute) {
    return request;
  }
  request.headers.set('Authorization', `Bearer ${user.tokens.access}`);
  return request;
};

const refreshAndSetNewTokens: BeforeRetryHook = async (beforeRetryData) => {
  const { getState, dispatch } = store;
  const { user } = getState();
  const { request, retryCount } = beforeRetryData;

  if (retryCount === 2) {
    const res: Response<RefreshTokensResponse> = await ky
      .post(`${BASE_API_URL}${URL.REFRESH_TOKEN}`, {
        json: { token: user.tokens.refresh },
      })
      .json();

    if (res.success) {
      dispatch(setTokens(res.result));
    }
  }
  request.headers.set('Authorization', `Bearer ${user.tokens.access}`);
};

export const api = ky.extend({
  prefixUrl: BASE_API_URL,
  timeout: false,
  headers: {
    'content-type': 'application/json',
  },
  retry: {
    limit: 10,
    methods: ['get', 'post', 'put', 'delete'],
    statusCodes: [401],
  },
  hooks: {
    beforeRequest: [setAuthorizationHeader],
    beforeRetry: [refreshAndSetNewTokens],
  },
});
