import styled, { css } from 'styled-components';
import { TextError } from '../../theme/typography';
import { ColumnContainer } from '../Styled';

export const Container = styled(ColumnContainer)`
  ${TextError} {
    color: ${({ theme }) => theme.ga.magenta};
  }
`;

export const Select = styled.select<{ invalid?: boolean; isDark: boolean }>`
  padding: 12px 20px;
  border-radius: 4px;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  outline: none;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  background-color: ${({ theme }) => theme.ga.white};

  ${({ isDark }) =>
    isDark &&
    css`
      background: #1d2430; //TODO: FIX!
      border-color: rgba(239, 242, 245, 0.1);
      color: white;
    `}

  &:invalid {
    color: ${(props) => props.theme.ga.gray};
  }

  ${({ invalid, theme }) =>
    invalid &&
    css`
      & {
        border: 1px solid ${theme.ga.magenta};
      }
    `}
`;
