import styled from 'styled-components';

export const Container = styled.div`
  height: 300px;
  width: 300px;
  top: 0;
  right: 0;
  position: fixed;
  padding: 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.ga.white};
  background: ${({ theme }) => theme.ga.darkGrayHalfOpacity};
  overflow-y: auto;

  li {
    font-size: 11px;
  }

  p {
    font-size: 12px;
  }
`;
