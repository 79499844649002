import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import { StyledAlert } from './styled';

type Props = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
};

export const Snackbar: React.FC<Props> = React.memo(
  ({ title, isOpen, handleClose }) => {
    return (
      <MuiSnackbar
        open={isOpen}
        autoHideDuration={60000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="warning"
        >
          {title}
        </StyledAlert>
      </MuiSnackbar>
    );
  },
);
