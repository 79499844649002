import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommonMeeting } from 'modules/common-meeting';
import { AdminMeeting } from 'modules/admin-meeting';
import {
  CreateEventPage,
  EditEventPage,
  Sessions,
} from 'modules/galaxy-events';
import { AdminLayout } from 'modules/admin';
import {
  AccountLayout,
  AccountDetails,
  ChangePassword,
  Members,
} from 'modules/account';
import { Schedule } from 'modules/schedule';
import { Videos } from 'modules/videos';
import { Orders } from 'modules/orders';
import { NotFoundPage } from 'modules/ui';
import { AuthSelectors, Roles } from 'modules/auth';
import { Paths } from './paths';

export const AdminRoutes: React.FC = (): JSX.Element => {
  const user = useSelector(AuthSelectors.getUser);
  const isTech = user.role === Roles.TECH;

  return (
    <Switch>
      <Route path={Paths.AdminSession}>
        <CommonMeeting renderReadyState={() => <AdminMeeting />} />
      </Route>

      {/* todo: don't render this if live session is active */}
      <Route path={Paths.Admin}>
        <Switch>
          <Route path={Paths.CreateEvent} component={CreateEventPage} />
          <Route path={Paths.EventEdit} component={EditEventPage} />
          <Route
            path={[
              Paths.Sessions,
              Paths.Schedule,
              Paths.Media,
              Paths.Orders,
              Paths.AdminAccountDetails,
              Paths.AdminChangePassword,
              Paths.AdminMembers,
            ]}
          >
            <AdminLayout>
              <Switch>
                <Route
                  path={[
                    Paths.AdminAccountDetails,
                    Paths.AdminChangePassword,
                    Paths.AdminMembers,
                  ]}
                >
                  <AccountLayout isAdmin isTech={isTech}>
                    <Switch>
                      <Route
                        path={Paths.AdminAccountDetails}
                        component={AccountDetails}
                      />
                      <Route
                        path={Paths.AdminChangePassword}
                        component={ChangePassword}
                      />
                      <Route path={Paths.AdminMembers} component={Members} />
                    </Switch>
                  </AccountLayout>
                </Route>
                <Route path={Paths.Sessions} component={Sessions} />
                <Route path={Paths.Schedule} component={Schedule} />
                <Route path={Paths.Media} component={Videos} />
                <Route path={Paths.Orders} component={Orders} />
              </Switch>
            </AdminLayout>
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </Route>
      <Redirect to={Paths.Sessions} />
    </Switch>
  );
};
