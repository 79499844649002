import React from 'react';
import { TextItalic } from 'modules/ui';
import { Soda } from '../../assets';
import { Container, LeftContainer, RightContainer } from './styled';

export const TalentBreakInfoCard: React.FC = () => (
  <Container>
    <LeftContainer>
      <img src={Soda} alt="soda" />
    </LeftContainer>
    <RightContainer>
      <TextItalic>
        Talent took a short break. They will be back soon.
      </TextItalic>
    </RightContainer>
  </Container>
);
