import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSelectors, AccountActions } from 'modules/account';
import {
  CheckmarkIcon,
  DropdownMenu,
  DropdownOpen,
  H4Bold,
  TextBold,
  TextRegular,
} from 'modules/ui';
import { MemberType } from 'modules/account/types';
import { Roles } from 'modules/auth';
import { ModalVersion, SelectedUser } from '../../consts';
import {
  DropdownContainer,
  MemberContainer,
  MemberItem,
  TextContainer,
} from './styled';

type MemberProps = {
  member: MemberType;
  selectUser: (B: SelectedUser) => void;
  openModal: () => void;
  setModalVersion: (B: ModalVersion) => void;
};

export const Member: React.FC<MemberProps> = React.memo(
  ({ member, selectUser, openModal, setModalVersion }) => {
    const {
      userId: id,
      role,
      firstName,
      lastName,
      email,
      emailConfirmed,
    } = member;

    const dispatch = useDispatch();
    const ownerResult = useSelector(AccountSelectors.isOwner);

    const isAdmin = useMemo(() => role === Roles.ADMIN, [role]);
    const isTech = useMemo(() => role === Roles.TECH, [role]);

    const isOwner = ownerResult.isOwnerAdmin;

    const handleRemoveMember = () => {
      if (isOwner) {
        selectUser({ userId: id, role, isOwner });
        setModalVersion(ModalVersion.REMOVE_MEMBER);
        openModal();
      }
    };

    const handleSendInvitationAgain = async () => {
      await dispatch(
        AccountActions.resendAdminInvite({
          firstName,
          lastName,
          email,
          role: 1,
        }),
      );
    };

    const handleUpdateUser = (newRole: number) => async () => {
      if (newRole === member.role || !isOwner) {
        return;
      }

      await dispatch(
        AccountActions.updateAdmin({
          ...member,
          role: newRole,
        }),
      );

      dispatch(AccountActions.getAdminMembers());
    };

    const dropdownItems = useMemo(() => {
      const items: React.ReactNode[] = [];

      if (isOwner) {
        items.concat([
          <MemberItem onClick={handleUpdateUser(Roles.ADMIN)}>
            <TextRegular>Admin</TextRegular>
            {isAdmin && <CheckmarkIcon />}
          </MemberItem>,
          <MemberItem onClick={handleUpdateUser(Roles.TECH)}>
            <TextRegular>Handler</TextRegular>
            {isTech && <CheckmarkIcon />}
          </MemberItem>,
        ]);
      }

      items.push(
        <MemberItem className="remove" onClick={handleRemoveMember}>
          <TextRegular>Remove Member</TextRegular>
        </MemberItem>,
      );

      if (!emailConfirmed) {
        items.push(
          <MemberItem onClick={handleSendInvitationAgain}>
            <TextRegular>Send invitation again</TextRegular>
          </MemberItem>,
        );
      }

      return items;
    }, []);

    return (
      <MemberContainer isEditable={emailConfirmed}>
        <TextContainer>
          <H4Bold>{`${firstName} ${lastName}`}</H4Bold>
          <TextRegular>{email}</TextRegular>
        </TextContainer>
        <DropdownContainer>
          <TextBold className="text">
            {isAdmin && emailConfirmed && 'Admin'}
            {isTech && emailConfirmed && 'Handler'}
            {!emailConfirmed && 'Invitation Sent'}
          </TextBold>
          <DropdownMenu icon={<DropdownOpen />}>{dropdownItems}</DropdownMenu>
        </DropdownContainer>
      </MemberContainer>
    );
  },
);
