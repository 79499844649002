// eslint-disable-next-line no-shadow
export enum URL {
  AUTH = 'authenticate',
  REGISTER = 'register',
  REFRESH_TOKEN = 'refresh-token',
  UPDATE = 'update',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',
  CREATE_SESSION = 'create-session',
  CREATE_EVENT = 'create-slot',
  MODIFY_EVENT = 'modify-slot',
  GET_NEAREST_MEDIA_REGION = `https://nearest-media-region.l.chime.aws`,
  GET_MESSAGE = 'receive-control-message',
  SEND_MESSAGE = 'send-control-message',
  SEND_CONTROL_MESSAGE = 'send-control-message',
  GET_PRODUCTS = 'get-products',
  GET_NEXT_EVENT = 'get-next-slot',
  GET_EVENTS = 'get-slots',
  GET_EVENT_PRODUCTS = 'get-products-for-session',
  GET_EVENT_BY_ID = 'get-slot-by-id',
  GET_DIGITAL_ASSETS_FOR_USER = 'get-digital-asset-for-user',
  GET_IMPORTED_ORDERS_WITH_STATUS = 'get-imported-orders-with-status',
  GET_USER_ORDER_INFOS = 'get-user-order-infos',
  DELETE_GALAXY_EVENT = 'delete-booking-slot',
  RESEND_VERIFICATION_EMAIL = 'resend-verification-email',
  UPDATE_TALENT_NAME = 'v2/waitingroom/update-talent-name',
  AUTHORIZE_TALENT = 'authenticate-talent',
}
