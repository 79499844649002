import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Paths } from 'paths';
import { Button, TextInput, SubmitContainer } from 'modules/ui';
import { Validators } from 'modules/utils';
import { AuthDictionary } from '../../dictionary';
import { NewPasswordData } from '../../types';

type Props = {
  onSubmit: (data: NewPasswordData) => void;
  email?: string;
};

export const NewPasswordForm: React.FC<Props> = React.memo(
  ({ onSubmit, email }) => {
    const [pass1, setPass1] = useState('');

    const changeHandler = (value: string) => {
      setPass1(value);
    };

    return (
      <Form
        onSubmit={onSubmit}
        validateOnBlur
        initialValues={{
          email,
          newPassword: '',
          confirm: '',
        }}
        render={({ handleSubmit, invalid }): React.ReactNode => (
          <>
            <Field
              type="email"
              name="email"
              label="Email"
              component={TextInput}
              disabled
            />
            <Field
              type="password"
              name="newPassword"
              label="Set new password"
              validate={Validators.composeValidators(
                Validators.required,
                Validators.mustHaveNumber,
                Validators.minLength(6),
                Validators.mustHaveCharacter,
              )}
              component={TextInput}
            />
            <OnChange name="newPassword">{changeHandler}</OnChange>
            <Field
              type="password"
              name="confirm"
              label="Confirm new password"
              validate={Validators.composeValidators(
                Validators.required,
                Validators.matchPassword(pass1),
              )}
              component={TextInput}
            />
            <SubmitContainer>
              <Button
                label="Save new password"
                clickHandler={handleSubmit}
                disabled={invalid}
              />
              <span className="terms">
                {AuthDictionary.ALREADY_HAVE_AN_ACCOUNT}
                <Link to={Paths.UserLogin}>Log in</Link>
              </span>
            </SubmitContainer>
          </>
        )}
      />
    );
  },
);
