import { device } from 'modules/utils';
import styled from 'styled-components';
import { RowContainer } from '../Styled';

export const Container = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  svg {
    margin-left: 10px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;
