import styled from 'styled-components';
import { device } from 'modules/utils';
import { ColumnContainer } from '../Styled';
import { H3, TextRegular } from '../../theme/typography';

export const Container = styled(ColumnContainer)`
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 30%;
    max-height: 360px;
  }

  .videos {
    ${H3} {
      margin-bottom: 0px;
    }

    ${TextRegular} {
      margin-bottom: 69px;
    }
  }

  ${H3}, ${TextRegular} {
    color: ${({ theme }) => theme.ga.gray};
  }

  ${H3} {
    margin-bottom: 69px;
  }

  .rotate {
    transform: scaleX(-1);
  }

  @media ${device.mobile} {
    img,
    svg {
      max-width: 80%;
    }
  }
`;
