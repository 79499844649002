import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
  ConsentStatus,
} from 'modules/common-meeting';
import { getDeviceConstraints } from '../utils';

type Returned = {
  stream: MediaStream | undefined;
  toggleAudio: () => void;
  toggleVideo: () => void;
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
};

export const usePreviewStream = (): Returned => {
  const dispatch = useDispatch();
  const [stream, setStream] = useState<MediaStream>();
  const [audioStream, setAudioStream] = useState<MediaStreamTrack>();
  const [videoStream, setVideoStream] = useState<MediaStreamTrack>();
  const [isAudioEnabled, setAudio] = useState(false);
  const [isVideoEnabled, setVideo] = useState(false);
  const permissionsStatus = useSelector(
    CommonMeetingSelectors.permissionsStatus,
  );

  const toggleAudio = useCallback(() => {
    if (audioStream) {
      audioStream.enabled = !audioStream.enabled;
      setAudio(audioStream.enabled);
    }
  }, [audioStream, setAudio]);

  const toggleVideo = () => {
    if (videoStream) {
      videoStream.enabled = !videoStream.enabled;
      setVideo(videoStream.enabled);
    } else {
      dispatch(CommonMeetingActions.setShouldHidePermissionsModal(false));
    }
  };

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const startStream = async () => {
    stopStream();

    let mediaStream = null;
    try {
      mediaStream = await navigator.mediaDevices.getUserMedia(
        getDeviceConstraints(),
      );
    } catch (e) {
      if (permissionsStatus !== ConsentStatus.Given) {
        dispatch(CommonMeetingActions.setShouldHidePermissionsModal(false));
      }
    }

    if (mediaStream) {
      setStream(mediaStream);
      const tracks = mediaStream.getTracks();

      tracks.forEach((track: MediaStreamTrack) => {
        if (track.kind === 'audio') {
          setAudioStream(track);
          setAudio(track.enabled);
        }

        if (track.kind === 'video') {
          setVideoStream(track);
          setVideo(track.enabled);
        }
      });
    }
  };

  useEffect(() => {
    startStream();
  }, []);

  useEffect(() => {
    return () => {
      stopStream();
    };
  }, [stream]);

  return {
    stream,
    toggleAudio,
    toggleVideo,
    isAudioEnabled,
    isVideoEnabled,
  };
};
