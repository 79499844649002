import React, { SyntheticEvent, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { format } from 'date-fns';
import { Slider } from '@material-ui/core';
// import { useSelector } from 'react-redux';
// import { URL } from 'modules/digital-assets';
import {
  FitContain,
  HorizontalAlign,
  VerticalAlign,
  Modal,
  CloseIcon,
  PlayIcon,
  PauseIcon,
  Spinner,
} from 'modules/ui';
// import { getTokens } from 'modules/auth/redux/selectors';
import {
  OuterContainer,
  PlayerContainer,
  Player,
  TitleContainer,
  CameraIcon,
  CloseButton,
  Title,
  PlayPauseButton,
  VideoControlsContainer,
  Timestamp,
  LoaderContainer,
} from './styled';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  title: string;
  startTime: string;
  url: string;
  bookedParticipantSlotId: number;
}

const doNothing = (event: SyntheticEvent) => {
  event.stopPropagation();
};

export const VideoPlayer: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  title,
  startTime,
  url,
}) => {
  const player = useRef<ReactPlayer>(null);
  // const { access } = useSelector(getTokens);
  const [playing, setPlaying] = useState(true);
  const [buffering, showLoader] = useState(true);
  const [{ played }, setProgress] = useState({ played: 0 });

  const handleSkip = (_event: unknown, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }

    player.current?.seekTo(newValue, 'fraction');
  };

  const renderTimestamp = (seconds: number | undefined) => (
    <Timestamp>
      {format(new Date(0, 0, 0, 0, 0, seconds ?? 0), 'm:ss')}
    </Timestamp>
  );

  return (
    <Modal fullWidth isOpen={isOpen} closeModal={onRequestClose}>
      <OuterContainer onClick={onRequestClose}>
        <FitContain
          horizontalAlign={HorizontalAlign.Center}
          verticalAlign={VerticalAlign.Center}
          ratio={16 / 9}
        >
          <PlayerContainer onClick={doNothing}>
            <TitleContainer>
              <CameraIcon />
              <Title>
                {title}
                {format(new Date(startTime), ' PPPPp')}
              </Title>
              <CloseButton onClick={onRequestClose}>
                <CloseIcon />
              </CloseButton>
            </TitleContainer>
            {buffering && (
              <LoaderContainer>
                <Spinner />
              </LoaderContainer>
            )}
            <Player
              ref={player}
              playing={playing}
              url={url}
              // url={`${process.env.REACT_APP_API_URL}${URL.DOWNLOAD_VIDEO}?BookedParticipantSlotId=${bookedParticipantSlotId}`}
              width="100%"
              height="100%"
              onPlay={() => {
                setPlaying(true);
                showLoader(false);
              }}
              onPause={() => setPlaying(false)}
              onEnded={() => setPlaying(false)}
              onBuffer={() => {
                showLoader(true);
              }}
              onBufferEnd={() => {
                showLoader(false);
              }}
              onProgress={setProgress}
            />
            <VideoControlsContainer>
              <PlayPauseButton
                onClick={() => {
                  setPlaying((wasPlaying) => !wasPlaying);
                }}
              >
                {playing ? <PauseIcon /> : <PlayIcon />}
              </PlayPauseButton>
              {renderTimestamp(player.current?.getCurrentTime())}
              <Slider
                max={1}
                value={played}
                onChange={handleSkip}
                step={0.001}
              />
              {renderTimestamp(player.current?.getDuration())}
            </VideoControlsContainer>
          </PlayerContainer>
        </FitContain>
      </OuterContainer>
    </Modal>
  );
};
