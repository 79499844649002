import { ColumnContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  flex: 0 0 320px;
  padding: 16px;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.ga.darkBlue};
  box-sizing: border-box;

  h3 {
    color: ${(props) => props.theme.ga.white};
    margin: 0px;
  }

  button {
    margin: 16px 0px;
  }

  @media ${device.mobile}, ${device.tablet} {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
  }
`;

export const Customers = styled.ul`
  flex: auto auto 280px;
  color: white;
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow-y: visible;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const TooltipText = styled.span`
  position: absolute;
  left: 300px;
  top: 7px;
  z-index: 99;
  width: auto;
  white-space: nowrap;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  visibility: hidden;
`;

export const Customer = styled.li<{ disabled?: boolean }>`
  padding: 12px;
  background-color: ${(props) => props.theme.ga.grayForms};
  margin-bottom: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    border: 1px solid ${({ theme }) => theme.ga.fluorescentGreen};

    ${TooltipText} {
      visibility: visible;
    }
  }

  .info {
    margin: 0px auto 0px 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }

  .menu {
    margin-right: 12px;
  }
`;

// .tooltip {
//   position: relative;
//   display: inline-block;
//   border-bottom: 1px dotted black;
// }

// .tooltip .tooltiptext {
//   visibility: hidden;
//   width: 120px;
//   background-color: black;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 0;

//   /* Position the tooltip */
//   position: absolute;
//   z-index: 1;
//   top: -5px;
//   left: 105%;
// }

// .tooltip:hover .tooltiptext {
//   visibility: visible;
// }
