import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDevicePermissionStatus,
  DevicePermissionStatus,
} from 'amazon-chime-sdk-component-library-react';
import { RecordingConsentModal } from '../RecordingConsentModal';
import { CommonMeetingActions, CommonMeetingSelectors } from '../../redux';
import { ConsentStatus } from '../../consts';

export const DevicePermissionsModal: React.FC = React.memo(() => {
  const devicePermissionStatus = useDevicePermissionStatus();
  const dispatch = useDispatch();
  const shouldHideConsentModal = useSelector(
    CommonMeetingSelectors.shouldHidePermissionsModal,
  );
  const recordingConsentStatus = useSelector(
    CommonMeetingSelectors.permissionsStatus,
  );

  useEffect(() => {
    async function getUserMedia() {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      dispatch(CommonMeetingActions.setPermissionsStatus(ConsentStatus.Given));
    }

    getUserMedia();
  }, []);

  useEffect(() => {
    switch (devicePermissionStatus) {
      case DevicePermissionStatus.GRANTED:
        dispatch(
          CommonMeetingActions.setPermissionsStatus(ConsentStatus.Given),
        );
        break;
      case DevicePermissionStatus.DENIED:
        dispatch(
          CommonMeetingActions.setPermissionsStatus(ConsentStatus.NotGiven),
        );
        break;
      default:
        break;
    }
  }, [devicePermissionStatus]);

  if (shouldHideConsentModal) {
    return null;
  }

  switch (recordingConsentStatus) {
    case ConsentStatus.NotAnswered:
      return (
        <RecordingConsentModal
          title="Allow GalaxyCon Live to use your camera and microphone"
          text="GalaxyCon Live needs access to your camera and microphone so that
              other participants can see and hear you. GalaxyCon Live will ask you
              to confirm this decision on each browser and computer you use."
          onAfterClose={() =>
            dispatch(CommonMeetingActions.setShouldHidePermissionsModal(true))
          }
        />
      );
    case ConsentStatus.NotGiven:
      return (
        <RecordingConsentModal
          title="Camera and microphone are blocked"
          text="GalaxyCon Live needs access to your camera and microphone. Click the camera blocked icon in your browser's address bar, press allow access and reload the page."
          onAfterClose={() =>
            dispatch(CommonMeetingActions.setShouldHidePermissionsModal(true))
          }
        />
      );
    default:
      return null;
  }
});
