import {
  ColumnContainer,
  RowContainer,
  TextRegular,
  H4Bold,
  TextSmall,
} from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(RowContainer)`
  height: 100%;
  width: 100%;
  & > ${TextRegular} {
    font-weight: bold;
    margin: 18px;
  }
`;

export const SideContainer = styled.div`
  flex: 0 0 350px;
`;

export const MainContainer = styled(ColumnContainer)`
  padding-bottom: 250px;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  background: ${(props) => props.theme.ga.white};
  border: 1px solid ${(props) => props.theme.ga.grayBackground};
  border-radius: 4px;
`;

export const VideosContainer = styled.ul`
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
`;

export const SessionContainer = styled.div`
  width: 100%;
  & > ${H4Bold} {
    font-weight: bold;
    margin: 16px;
    margin-bottom: 8px;
  }
`;

export const Video = styled.li`
  display: flex;
  flex-direction: row;
  padding: 7px 16px;
  justify-content: space-evenly;
`;

export const Attendee = styled(RowContainer)`
  align-items: center;
  margin-right: auto;

  & > ${TextRegular} {
    display: inline-block;
    width: 150px;
    text-overflow: ellipsis;
  }
`;

export const AttendeeContainer = styled(ColumnContainer)`
  margin-left: 4px;

  & > ${TextSmall} {
    display: inline-block;
    width: 150px;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.ga.darkGray};
  }
`;

export const Actions = styled(RowContainer)`
  align-items: center;
  margin-left: auto;
  svg {
    margin-right: 16px;
  }
`;

export const StatTime = styled(RowContainer)`
  align-items: center;
  margin-left: 0 auto;

  & > ${TextRegular} {
    display: inline-block;
    width: 170px;
    text-overflow: ellipsis;
  }
`;

export const VideoLength = styled(RowContainer)`
  align-items: center;
  margin-left: 32px;
`;

export const ButtonWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  button {
    height: 40px;
    width: 100px;
    span {
      color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.ga.secondaryButton : theme.ga.magenta} !important;
    }
  }
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;
