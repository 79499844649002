import { ColumnContainer, RowContainer } from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  height: 100vh;
`;

export const Footer = styled(RowContainer)`
  position: sticky;
  bottom: 0px;
  min-height: 64px;
  margin: auto 0 0 0;
  border-top: 1px solid ${({ theme }) => theme.ga.grayBackground};
  background-color: ${({ theme }) => theme.ga.white};
`;
