import React, { useCallback } from 'react';
import { ShoppingIcon } from 'modules/ui';
import { Duration, SessionItem } from './styled';

type Props = {
  title: string;
  duration: number;
  orderId: number;
};

export const SessionSlot: React.FC<Props> = React.memo(
  ({ title, duration, orderId }) => {
    const handleOrderClick = useCallback(() => {
      window.open(
        `${process.env.REACT_APP_SHOPIFY_BASE_URL}/admin/orders/${orderId}`,
        '_blank',
      );
    }, [orderId]);

    return (
      <SessionItem onClick={handleOrderClick}>
        <span>{title}</span>
        <Duration>
          <ShoppingIcon />
          <span>{duration}</span>
          <span> min</span>
        </Duration>
      </SessionItem>
    );
  },
);
