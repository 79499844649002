import { Urls } from 'modules/main';
import { get } from 'modules/api';
import { Response } from 'modules/utils';
import { SessionType } from '../types';

export const getNextSessionsRequest = (): Promise<Response<SessionType[]>> => {
  return get(`${Urls.GET_NEXT_SESSIONS}?skip=0&take=1000`);
};

export const getPastSessionsRequest = (): Promise<Response<SessionType[]>> => {
  return get(`${Urls.GET_PAST_SESSIONS}?skip=0&take=1000`);
};
