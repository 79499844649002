import React, { ChangeEvent } from 'react';
import { Label, TextError } from 'modules/ui';
import { FieldRenderProps } from 'react-final-form';
import { Container, Select } from './styled';

type FormProps = {
  options: Array<{ value: string | number; label: string }>;
  label: string;
  isDark?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & FieldRenderProps<any, HTMLElement>;

export const FormSelectInput: React.FC<FormProps> = React.memo(
  ({ options, label, isDark = false, input, meta, ...rest }) => {
    const isInvalid = meta.touched && !!meta.error;

    return (
      <Container>
        <Label>{label}</Label>
        <Select
          isDark={isDark}
          {...input}
          {...rest}
          required
          invalid={!!isInvalid}
        >
          <option value="">Please Choose...</option>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        {isInvalid && <TextError>{meta.error}</TextError>}
      </Container>
    );
  },
);

type Props = {
  options: Array<{ value: string | number; label: string }>;
  label: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  isDark?: boolean;
  selectedValue?: string;
};

export const SelectInput: React.FC<Props> = React.memo(
  ({ options, label, onChange, isDark = false, selectedValue }) => {
    return (
      <Container>
        <Label>{label}</Label>
        <Select isDark={isDark} value={selectedValue} onChange={onChange}>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Container>
    );
  },
);
