import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { QuestionnaireFormData } from 'modules/common-meeting';
import { getUpcomingEventsRequest } from '../api';
import { LiveProductType } from '../types';

export const getProducts = createAction<LiveProductType[]>('GET_PRODUCTS');

export const getUpcomingEvents = createAsyncThunk(
  'GET_UPCOMING_EVENTS',
  getUpcomingEventsRequest,
);

export const saveQuestionnaireAnswers = createAction<QuestionnaireFormData>(
  'SAVE_QUESTIONNAIRE_ANSWERS',
);
