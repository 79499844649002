import React, { useRef, useState } from 'react';

import { MenuContainer, Menu, Item, Toggler } from './styled';
import { useOutsideClick } from './useOutsideClick';

type Props = {
  icon?: React.ReactNode;
  children: React.ReactNode | React.ReactNodeArray;
};

export const DropdownMenuItem = Item;

export const DropdownMenu: React.FC<Props> = React.memo(
  ({ icon, children }) => {
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOutsideClick = () => {
      setIsOpen((open) => !open);
    };

    useOutsideClick(menuRef, isOpen, handleOutsideClick);

    return (
      <MenuContainer>
        <Toggler onClick={() => setIsOpen((open) => !open)}>{icon}</Toggler>
        <Menu isOpen={isOpen} ref={menuRef} onClick={() => setIsOpen(false)}>
          {children}
        </Menu>
      </MenuContainer>
    );
  },
);
