import styled from 'styled-components';

export const DownloadButton = styled.button<{ disabled: boolean }>`
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 5px;
  svg {
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.ga.gray : theme.ga.pink};
    }
  }
`;

export const SpinnerContainer = styled.div`
  text-align: center;
  margin-right: 16px;
  padding: 2px;
`;

export const SpinnerRing = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 4px;

  &:after {
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.ga.magenta};
    border-color: ${({ theme }) => theme.ga.magenta} transparent
      ${({ theme }) => theme.ga.magenta} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
