import React from 'react';
import { H4Bold } from 'modules/ui';
import { Tr, Th } from './styled';

export const OrdersHeader: React.FC = React.memo(() => {
  return (
    <Tr>
      <Th width={200}>
        <H4Bold>Shopify Email</H4Bold>
      </Th>
      <Th width={100}>
        <H4Bold>Shopify Id</H4Bold>
      </Th>
      <Th width={250}>
        <H4Bold>Item Title</H4Bold>
      </Th>
      <Th width={50}>
        <H4Bold>Qty.</H4Bold>
      </Th>
      <Th width={120}>
        <H4Bold>Order Time</H4Bold>
      </Th>
      <Th width={50}>
        <H4Bold>Conf.</H4Bold>
      </Th>
      <Th width={80} />
    </Tr>
  );
});
