import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';

export const Container = styled(ColumnContainer)`
  flex: 1;
  overflow-y: scroll;
  height: 500px;
  width: 800px;
`;

export const Header = styled(ColumnContainer)`
  padding: 22px 24px 0 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.ga.grayBackground};
  background: white;
  padding: 16px 26px 16px 0;
  box-sizing: border-box;

  button {
    margin-left: 8px;
  }
`;

export const ItalicText = styled.span`
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ theme }) => theme.ga.gray};
`;

export const SessionsContainer = styled.div`
  margin-top: 16px;
  overflow-y: scroll;
  height: 400px;
  padding: 0 24px;
`;

export const SessionList = styled.div`
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const FanHeader = styled(RowContainer)`
  padding: 14px 12px;
  span {
    padding-left: 10px;
  }
`;

export const SessionItem = styled(RowContainer)`
  padding: 14px 12px;
  border-top: 1px solid ${({ theme }) => theme.ga.grayBackground};
  justify-content: space-between;
`;

export const Duration = styled(RowContainer)`
  padding-right: 20px;
  svg {
    padding-right: 9px;
  }
`;

export const OpenOrder = styled.div`
  margin-left: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.ga.pink};
`;
