export type AuthorizeTalentBody = {
  attendeeGuid: string;
};

export type AuthorizeTalentResponseData = {
  accessToken: string;
};

export enum TalentMeetingActionType {
  AUTHORIZE_TALENT = 'AUTHORIZE_TALENT',
  TOGGLE_TALENT_BREAK = 'TOGGLE_TALENT_BREAK',
  GET_FAN_QUESTIONNAIRE_ANSWERS = 'GET_FAN_QUESTIONNAIRE_ANSWERS',
}

export type ToggleTalentBreakRequest = {
  waitingRoomGuid: string;
  onABrake: boolean;
};

export type FanQuestionnaireAnswerKeys =
  | 'WhereFrom'
  | 'FavouriteMovie'
  | 'TalkAbout';

export type FanQuestionnaireAnswerType = {
  key: FanQuestionnaireAnswerKeys;
  value: string;
};

export type GetFanQuestionnaireAnswersResponseData = {
  bpsId: number;
  answers: Array<FanQuestionnaireAnswerType>;
};
