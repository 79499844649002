import { ThunkAction } from 'modules/main';
import { getProductsRequest } from '../api';
import { getProducts } from './actions';

export const loadProducts = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    const { result } = await getProductsRequest();
    dispatch(getProducts(result));
  } catch (error) {
    throw new Error(error);
  }
};
