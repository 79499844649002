import { device } from 'modules/utils';
import styled from 'styled-components';

export type ButtonLinkProps = {
  background?: 'white' | 'grayButton';
};

export const ButtonLink = styled.a<ButtonLinkProps>`
  display: inline-block;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 4px;

  font-family: Open Sans;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  color: ${({ theme }) => theme.ga.darkGray};
  background: ${({ theme, background }) => background || theme.ga.grayButton};

  &:hover {
    text-decoration: none;
  }

  @media ${device.mobile} {
    padding: 12px;
  }
`;
