import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EmptySessions, CalendarPicker, DayValue } from 'modules/ui';
import { GalaxyEvent } from '..';
import { EventsSelectors, EventsActions } from '../../redux';
import { GalaxyEventModel } from '../../types';
import {
  Container,
  MainContainer,
  ScrollableContainer,
  SideContainer,
} from './styled';

export const Sessions: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  const today = new Date();
  const todayDayValue: DayValue = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };
  const [selectedDate, setSelectedDate] = useState<DayValue>(todayDayValue);
  const sessionsNumber = useSelector(EventsSelectors.getNumberOfEvents);
  const events = useSelector(EventsSelectors.getEvents);

  useEffect(() => {
    if (!selectedDate) {
      return;
    }

    const date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day,
    );
    dispatch(EventsActions.getEventsForDate(date));
  }, [dispatch, selectedDate]);

  useEffect(() => {
    document.title = `GalaxyCon Session`;
  });

  return (
    <Container>
      <SideContainer>
        <CalendarPicker value={selectedDate} onChange={setSelectedDate} />
      </SideContainer>
      {sessionsNumber === 0 && (
        <MainContainer>
          <EmptySessions isAdmin />
        </MainContainer>
      )}
      {sessionsNumber > 0 && (
        <ScrollableContainer>
          <MainContainer>
            {events.map((event: GalaxyEventModel) => (
              <GalaxyEvent key={event.id.toString()} galaxyEvent={event} />
            ))}
          </MainContainer>
        </ScrollableContainer>
      )}
    </Container>
  );
});
