/* eslint-disable no-shadow */
export enum URL {
  GET_DIGITAL_ASSETS_GROUPED_BY_BOOKING_SLOT = 'get-videos-groupedby-bookingslot',
  TOGGLE_SENDING_EMAIL_WITH_VIDEO_URL = 'toggle-sending-email-with-video-url',
  DOWNLOAD_VIDEO = 'download-video',
}

export enum AssetType {
  Unknown = 0,
  Photo = 1,
  Video = 2,
}
