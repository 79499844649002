import styled from 'styled-components';
import { TextRegular, RowContainer, ColumnContainer } from 'modules/ui';

export const VariantsContainer = styled(ColumnContainer)`
  ${TextRegular} {
    max-width: 70%;
  }
`;
export const EventTime = styled(RowContainer)`
  margin-left: auto;
  align-items: center;
`;
export const Attendees = styled(RowContainer)`
  margin-left: auto;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
export const Variant = styled(RowContainer)`
  padding: 13px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.ga.grayBackground};

  &:last-child {
    border-bottom: none;
  }
`;
