import { useEffect } from 'react';
import { Howl } from 'howler';
import techCheckSound from 'modules/ui/assets/sounds/call-announcement.mp3';

export const useTechCheckAudioAnnouncment = (shouldPlay: boolean): void => {
  useEffect(() => {
    if (!shouldPlay) {
      return;
    }

    const sound = new Howl({
      src: techCheckSound,
    });
    sound.play();
  }, []);
};
