import React from 'react';
import { EmptyEventsPlaceholder } from '../EmptyEventsPlaceholder';
import { Container, ScheduleContainer } from './styled';

export const ScheduleLayout: React.FC = React.memo(({ children }) => {
  return (
    <Container>
      <EmptyEventsPlaceholder />
      <ScheduleContainer>{children}</ScheduleContainer>
    </Container>
  );
});
