import React from 'react';
import { Form, Field } from 'react-final-form';
import { Validators } from 'modules/utils';

import {
  TextInput,
  Button,
  H6,
  DateTimePicker,
  FormSelectInput,
} from 'modules/ui';
import { EventDictionary } from '../../dictionary';
import {} from '../../hooks';

import {
  Container,
  FormContainer,
  DateContainer,
  ButtonsContainer,
} from './styled';
import { EventFormData, GalaxyEventModel } from '../../types';
import { generateHours } from '../../utils';

type Props = {
  onSubmit: (data: EventFormData) => void;
  event?: GalaxyEventModel;
  onClose: () => void;
  disabled: boolean;
  title: string;
};

export const EventForm: React.FC<Props> = React.memo(
  ({ onSubmit, onClose, event, disabled, title }) => {
    const initialValues = event
      ? {
          name: event.title,
          date: {
            day: event.day.short,
            month: event.month.short,
            year: new Date(event.start).getFullYear(),
          },
          startTime: event.startH,
          endTime: event.endH,
          sessionDuration: event.duration,
          youTubeLink: event.youTubeLink,
        }
      : ({} as EventFormData);
    return (
      <Container>
        <FormContainer>
          <H6>{title}</H6>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({
              handleSubmit,
              submitting,
              pristine,
              invalid,
            }): React.ReactNode => (
              <form onSubmit={handleSubmit}>
                <Field
                  name={EventDictionary.NAME}
                  label={EventDictionary.SESSION_NAME}
                  placeholder={EventDictionary.ENTER_SESSION_NAME}
                  isSecondary
                  validate={Validators.required}
                  component={TextInput}
                />
                <Field
                  name={EventDictionary.YOUTUBELINK}
                  label={EventDictionary.YOUTUBE_LINK}
                  placeholder={EventDictionary.ENTER_LINK}
                  isSecondary
                  component={TextInput}
                  validate={Validators.required}
                />
                <DateContainer>
                  <Field
                    name={EventDictionary.DATE}
                    label={EventDictionary.SESSION_DATE}
                    placeholder={EventDictionary.ENTER_DATE}
                    component={DateTimePicker}
                    validate={Validators.isDateInPast}
                  />
                  <Field
                    name={EventDictionary.SESSIONDURATION}
                    label={EventDictionary.SESSION_DURATION}
                    isSecondary
                    placeholder={EventDictionary.ENTER_SECONDS}
                    component={TextInput}
                    type={EventDictionary.NUMBER}
                    validate={Validators.composeValidators(
                      Validators.isPositiveNumber,
                      Validators.required,
                    )}
                  />
                </DateContainer>
                <DateContainer>
                  <Field
                    name={EventDictionary.STARTTIME}
                    label={EventDictionary.SESSION_START_TIME}
                    placeholder={EventDictionary.SELECT_TIME}
                    component={FormSelectInput}
                    options={generateHours()}
                    validate={Validators.composeValidators(
                      Validators.isStartBeforeEnd,
                      Validators.required,
                      Validators.isTimeInPast,
                    )}
                  />
                  <Field
                    name={EventDictionary.ENDTIME}
                    label={EventDictionary.SESSION_END_TIME}
                    placeholder={EventDictionary.SELECT_TIME}
                    component={FormSelectInput}
                    options={generateHours()}
                    validate={Validators.composeValidators(
                      Validators.required,
                      Validators.isEndAfterStart,
                      Validators.isTimeInPast,
                    )}
                  />
                </DateContainer>

                <ButtonsContainer>
                  <Button
                    isDark
                    label={EventDictionary.CLOSE}
                    clickHandler={onClose}
                  />
                  <Button
                    label={EventDictionary.SAVE_AND_CREATE}
                    disabled={submitting || pristine || invalid || disabled}
                    clickHandler={handleSubmit}
                  />
                </ButtonsContainer>
              </form>
            )}
          />
        </FormContainer>
      </Container>
    );
  },
);
