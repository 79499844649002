import styled, { css } from 'styled-components';
import { Label, TextError } from '../../theme/typography';
import { ColumnContainer } from '../Styled';

export const InputContainer = styled(ColumnContainer)<{
  invalid?: boolean;
  isRevealingPassword?: boolean;
  password?: boolean;
  isDarkBackground?: boolean;
  disabled?: boolean;
}>`
  ${Label} {
    color: ${({ theme }) => theme.ga.gray};
  }

  ${({ isDarkBackground }) =>
    isDarkBackground &&
    css`
      ${Label} {
        color: ${({ theme }) => theme.ga.white};
      }
    `}

  input {
    margin-bottom: 26px;
    padding: 12px 20px;
    border-radius: 4px;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

    outline: none;
    border: 1px solid ${({ theme }) => theme.ga.grayBackground};
    background-color: ${({ theme, isDarkBackground }) =>
      isDarkBackground ? theme.ga.grayForms : theme.ga.white};

    ${({ disabled, theme }) =>
      disabled &&
      css`
        background-color: ${theme.ga.grayBackground};
      `}

    color: ${({ theme, isDarkBackground }) =>
      isDarkBackground ? theme.ga.white : theme.ga.black};

    &:-webkit-autofill::first-line {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }

    ::placeholder {
      color: ${(props) => props.theme.ga.gray};
      -webkit-text-stroke-width: 0;
      letter-spacing: 0;
    }

    &:focus {
      border-color: ${({ theme }) => theme.ga.gray};
    }
  }

  ${({ invalid, theme }) =>
    invalid &&
    css`
      input {
        margin-bottom: 0;
        border: 1px solid ${theme.ga.magenta};
      }
    `}

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.ga.white} inset !important;
  }

  ${TextError} {
    margin-top: 5px;
  }

  .instructions {
    margin-top: 5;
    color: ${({ theme }) => theme.ga.gray};
  }

  .actve {
    display: inline-block;
  }

  .inactive {
    display: none;
  }
`;
