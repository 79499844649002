import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CalendarIcon,
  TextRegular,
  H4Bold,
  DropdownMenu,
  TextItalic,
  Label,
  DropdownMenuItem,
  MoreIcon,
} from 'modules/ui';
import { useModal } from 'modules/main';
import { CommonMeetingActions } from 'modules/common-meeting';
import { GalaxyEventModel, GalaxyEventTalentLinkInfo } from '../../types';
import { Variants } from '../Variants';
import {
  Container,
  Header,
  Info,
  DateContainer,
  MoreContainer,
  MergeInfo,
  LinkContainer,
  SessionLink,
} from './styled';
import { MergeSessionModal } from '../MergeSessionModal';
import { EventsActions } from '../../redux';

type Props = {
  key: string;
  galaxyEvent: GalaxyEventModel;
};

export const GalaxyEvent: React.FC<Props> = React.memo(
  ({ key, galaxyEvent }) => {
    const dispatch = useDispatch();
    const [isOpen, open, close] = useModal(false);

    const handleSessionDelete = useCallback(() => {
      dispatch(EventsActions.deleteGalaxyEvent(galaxyEvent));
    }, [galaxyEvent]);

    const routes = [
      {
        label: 'Edit',
        path: `/admin/home/sessions/${galaxyEvent.id}/edit`,
      },
    ];

    const copyLink = (linkInfo: GalaxyEventTalentLinkInfo) => {
      dispatch(
        CommonMeetingActions.copyTalentLinkToClipboard({
          waitingRoomGuid: galaxyEvent.roomId,
          atendeeGuid: linkInfo.atendeeGuid,
        }),
      );
    };

    const getMenuItems = () => {
      const talentLinks = galaxyEvent.talentLinkInfos.map((linkInfo, index) => (
        <DropdownMenuItem
          key={linkInfo.atendeeGuid}
          onClick={() => copyLink(linkInfo)}
        >
          <LinkContainer>
            <TextRegular>
              <SessionLink>Copy link</SessionLink>
              for Talent No.
              {index + 1}
            </TextRegular>
          </LinkContainer>
        </DropdownMenuItem>
      ));
      const items = [
        ...talentLinks,
        <DropdownMenuItem
          className="delete-session"
          onClick={handleSessionDelete}
        >
          <TextRegular>Delete</TextRegular>
        </DropdownMenuItem>,
        ...routes.map((route) => (
          <DropdownMenuItem key={route.path}>
            <Link to={route.path}>
              <TextRegular>{route.label}</TextRegular>
            </Link>
          </DropdownMenuItem>
        )),
      ];

      return items;
    };

    return (
      <Container key={key}>
        <Header>
          <img src={galaxyEvent.src} alt="placeholder" width="164px" />
          <Info>
            <div>
              <DateContainer>
                <CalendarIcon />
                <TextRegular>
                  {galaxyEvent.month.long}
                  <span> </span>
                  {galaxyEvent.day.short}
                </TextRegular>
              </DateContainer>
              <MoreContainer>
                <DropdownMenu icon={<MoreIcon />}>
                  {getMenuItems()}
                </DropdownMenu>
              </MoreContainer>
            </div>
            <H4Bold>{galaxyEvent.title}</H4Bold>
          </Info>
        </Header>
        {galaxyEvent.canBeMerged && (
          <MergeInfo>
            <TextItalic>
              Some users bought multiple sessions at different times. You can
              merge their sessions on Shopify.
            </TextItalic>
            <Label onClick={open}>VIEW</Label>
          </MergeInfo>
        )}
        <Variants variants={galaxyEvent.variants} />
        {isOpen && (
          <MergeSessionModal
            isOpen={isOpen}
            closeModal={close}
            galaxyEvent={galaxyEvent}
          />
        )}
      </Container>
    );
  },
);
