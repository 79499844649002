import styled from 'styled-components';
import { device } from 'modules/utils';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;

  img {
    max-width: 180px;
    margin-right: 40px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
`;
