import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { AuthSelectors, Roles } from 'modules/auth';
import { Label, SendMessageIcon, SmallCaption } from 'modules/ui';
import { useTwilioChat } from 'modules/chat/hooks';
import { ChatActions } from 'modules/chat';
import { MessageType } from '../../types';
import {
  ChatBody,
  ChatHeader,
  ChatInput,
  Message,
  MessageContainer,
  Container,
  TextInput,
  SendButton,
} from './styled';

export type Props = {
  isTechCheck?: boolean;
  isTalentView?: boolean;
  isAdminView?: boolean;
  isMobileOpened?: boolean;
  onChatToggle?: () => void;
  id: string;
};

export const Chat: React.FC<Props> = React.memo(
  ({
    isTalentView = false,
    isTechCheck = false,
    isAdminView = false,
    isMobileOpened = false,
    onChatToggle,
    id,
  }) => {
    const dispatch = useDispatch();
    const [isChatActive, toggleChat] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const chatRef = useRef<HTMLDivElement>(null);
    const user = useSelector(AuthSelectors.getUser);
    const audioVideo = useAudioVideo();
    const isAdmin = user.role === Roles.ADMIN;

    const { messages, sendMessage } = useTwilioChat(id);

    const handleChatToggle = useCallback(() => {
      toggleChat((isActive) => !isActive);
      if (onChatToggle) {
        onChatToggle();
      }
    }, [onChatToggle]);

    const handleSendMessage = () => {
      if (inputRef.current) {
        if (!inputRef.current.value.trim()) {
          // eslint-disable-next-line no-param-reassign
          inputRef.current.value = '';
          return;
        }

        // const value = {
        //   content: chatRef.current.value.trim(),
        //   role: user.role,
        //   name: user.profile.firstName,
        //   time: Date.now(),
        // };

        sendMessage(inputRef.current.value.trim());

        // eslint-disable-next-line no-param-reassign
        inputRef.current.value = '';
      }
    };

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          handleSendMessage();
        }
      },
      [audioVideo, sendMessage],
    );

    useEffect(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }

      if (!isChatActive && !!messages.length) {
        toggleChat(true);
      }
    }, [messages, toggleChat]);

    useEffect(() => {
      return () => {
        if (isTechCheck) {
          dispatch(ChatActions.cleanTechCheckChat());
        }
      };
    }, []);

    return (
      <Container
        isTechCheckView={isTechCheck}
        isTalentView={isTalentView}
        isAdminView={isAdminView}
        isAdmin={isAdmin}
        isActive={isChatActive}
        isMobileOpened={isMobileOpened}
        index={0}
      >
        <ChatHeader onClick={handleChatToggle}>
          {!isAdmin && isTechCheck && <Label>Chat with Admin</Label>}
          {isAdmin && isAdminView && <Label>Chat with Talent</Label>}
          {isTalentView && <Label>Chat with Admin</Label>}
          {/* {isAdmin && isAdminView && isChatActive && <MinusIcon />} */}
        </ChatHeader>
        <ChatBody ref={chatRef}>
          {messages.map((message: MessageType) => (
            <MessageContainer
              key={message.time}
              isRight={message.role === user.role}
            >
              <SmallCaption>{message.name}</SmallCaption>
              <Message>{message.content}</Message>
            </MessageContainer>
          ))}
        </ChatBody>
        <ChatInput>
          <TextInput
            placeholder="Enter a message"
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <SendButton onClick={handleSendMessage}>
            <SendMessageIcon />
          </SendButton>
        </ChatInput>
      </Container>
    );
  },
);
