import { useState, useCallback } from 'react';
// REMOVE ASAP NEEDS TO GO TO HOOKS!!!!!
export const useSettingsMenuModal = (
  initialValue: boolean,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);
  return [isOpen, openMenu, closeMenu];
};
