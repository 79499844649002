import { ColumnContainer, H2, H6 } from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  color: white;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 51px 44px;

  ${H6}, ${H2} {
    margin: 10px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  position: relative;
  margin-top: 46px;

  ${H2} {
    margin: 0px;
    position: absolute;
    top: 83px;
    left: 90px;
    color: ${({ theme }) => theme.ga.fluorescentGreen};
  }
`;
