import React from 'react';
import { H3, AvatarSvg, GalaxyGirlBot } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { Container } from './styled';

type Props = {
  isAdmin?: boolean;
  isSchedule?: boolean;
  isVideos?: boolean;
  isPastSessions?: boolean;
  isOrders?: boolean;
};

export const EmptySessions: React.FC<Props> = React.memo(
  ({ isAdmin, isSchedule, isVideos, isPastSessions, isOrders }) => {
    if (isAdmin) {
      return (
        <Container>
          <H3>{Dictionary.NO_SESSIONS}</H3>
          <GalaxyGirlBot />
        </Container>
      );
    }

    if (isSchedule) {
      return (
        <Container>
          <H3>{Dictionary.NO_SESSIONS_SCHEDULE}</H3>
          <AvatarSvg />
        </Container>
      );
    }

    if (isPastSessions) {
      return (
        <Container>
          <H3>{Dictionary.NO_SESSIONS}</H3>
          <AvatarSvg />
        </Container>
      );
    }

    if (isVideos) {
      return (
        <Container className="videos">
          <H3>{Dictionary.NO_VIDEOS}</H3>
          <GalaxyGirlBot />
        </Container>
      );
    }

    if (isOrders) {
      return (
        <Container className="videos">
          <H3>{Dictionary.NO_ORDERS}</H3>
          <GalaxyGirlBot />
        </Container>
      );
    }

    return <></>;
  },
);
