import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, TextInput, FormCheckbox, EyeIcon } from 'modules/ui';
import { Validators } from 'modules/utils';
import { SignupData } from '../../types';
import {
  SubmitContainer,
  SideBySideFormContainer,
  PasswordContainer,
} from '../../pages/styled';
import { passwordMinLength } from '../../consts';
import AgreementTextWithLinks from './AgreementTextWithLinks';
import EmailInstructionsText from './EmailInstructionsText';

type Props = {
  onSubmit: (data: SignupData) => void;
  isUser?: boolean;
};

export const SignupForm: React.FC<Props> = React.memo(({ onSubmit }) => {
  const [isRevealingPassword, setisRevealingPassword] = useState(false);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        terms: false,
      }}
      render={({ handleSubmit }): React.ReactNode => (
        <form onSubmit={handleSubmit}>
          <SideBySideFormContainer>
            <Field
              type="text"
              name="firstName"
              label="First name"
              component={TextInput}
              validate={Validators.required}
            />
            <Field
              type="text"
              name="lastName"
              label="Last name"
              component={TextInput}
              validate={Validators.required}
            />
          </SideBySideFormContainer>
          <Field
            type="email"
            name="email"
            label="Email"
            instructions={<EmailInstructionsText />}
            component={TextInput}
            validate={Validators.composeValidators(
              Validators.required,
              Validators.mustBeValidEmail,
            )}
          />
          <PasswordContainer>
            <Field
              type={isRevealingPassword ? 'text' : 'password'}
              name="password"
              label="Set password"
              validate={Validators.composeValidators(
                Validators.required,
                Validators.mustHaveLowercase,
                Validators.mustHaveUppercase,
                Validators.mustHaveNumber,
                Validators.mustHaveCharacter,
                Validators.minLength(passwordMinLength),
              )}
              component={TextInput}
              password
              createPassword
              isRevealingPassword={isRevealingPassword}
            />
            <EyeIcon
              onClick={() => setisRevealingPassword(!isRevealingPassword)}
            />
          </PasswordContainer>
          <Field
            type="checkbox"
            name="terms"
            label={<AgreementTextWithLinks />}
            component={FormCheckbox}
            validate={Validators.mustBeChecked}
          />
          <SubmitContainer>
            <Button label="Create account" clickHandler={handleSubmit} />
          </SubmitContainer>
        </form>
      )}
    />
  );
});
