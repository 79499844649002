import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';

import { isAdminRole } from 'modules/account';
import { AuthSelectors } from 'modules/auth';

import { DevicesDebugger } from 'modules/devices';
import { MessageBrokerActions } from 'modules/message-broker';
import { WaitingRoomState } from '../../consts';
import { CommonMeetingActions, CommonMeetingSelectors } from '../../redux';
import { useHeartbeat } from '../../hooks';
import { DisconnectedFromSessionModal } from '../DisconnectedFromSessionModal';
import { MeetingStatus } from '../MeetingStatus';
import { DevicePermissionsModal } from '../DevicePermissionsModal';

interface LiveSessionProps {
  // todo: check is this return type correct
  renderReadyState(): JSX.Element;
}

export const CommonMeeting: React.FC<LiveSessionProps> = React.memo(
  ({ renderReadyState }) => {
    const dispatch = useDispatch();
    const { uid: waitingRoomId } = useParams<Record<string, string>>();

    const shouldPrepareWaitingRoom = useSelector(
      CommonMeetingSelectors.shouldPrepareWaitingRoom,
    );

    const waitingRoomState = useSelector(
      CommonMeetingSelectors.waitingRoomState,
    );
    const userRole = useSelector(AuthSelectors.getUserRole);
    const sessionName = useSelector(
      CommonMeetingSelectors.waitingRoomNameSelector,
    );
    const isEnterWaitingRoomUnauthorized = useSelector(
      CommonMeetingSelectors.isEnterWaitingRoomUnauthorized,
    );
    const isDisconnectedSelector = useSelector(
      CommonMeetingSelectors.isDisconnectedSelector,
    );
    const isConnectedToMessageBroker = useSelector(
      CommonMeetingSelectors.isConnectedToMessageBrokerSelector,
    );
    const loggedInParticipantBpsId = useSelector(
      CommonMeetingSelectors.getLoggedInParticipantBpsId,
    );
    const isAdmin = isAdminRole(userRole);

    useEffect(() => {
      dispatch(MessageBrokerActions.initiateConnection(waitingRoomId));

      return () => {
        dispatch(MessageBrokerActions.stopConnection());
      };
    }, []);

    useEffect(() => {
      if (isConnectedToMessageBroker && !loggedInParticipantBpsId) {
        dispatch(CommonMeetingActions.enterWaitingRoom(waitingRoomId));
      }
    }, [isConnectedToMessageBroker, loggedInParticipantBpsId]);

    useEffect(() => {
      if (shouldPrepareWaitingRoom) {
        dispatch(
          CommonMeetingActions.prepareWaitingRoom({
            waitingRoomGuid: waitingRoomId,
          }),
        );
      }
    }, [shouldPrepareWaitingRoom]);

    useHeartbeat();

    const getWaitingRoomLoadingMessage = () => {
      if (isAdmin) {
        return "Preparing the session... This process might take a few minutes. Try refreshing the browser if it didn't start.";
      }
      if (isEnterWaitingRoomUnauthorized) {
        return "We couldn't verify your purchase for this event. If you think this is a mistake, please reach out to support.";
      }

      return 'The admin is preparing a session. Please wait...';
    };

    const renderMeetingPhase = () => {
      if (!waitingRoomState || !loggedInParticipantBpsId) {
        return (
          <MeetingStatus
            message={getWaitingRoomLoadingMessage()}
            sessionName={sessionName}
          />
        );
      }

      switch (waitingRoomState) {
        case WaitingRoomState.CREATED:
        case WaitingRoomState.PREPARING_VIDEO_RECORDER:
        case null:
          return (
            <MeetingStatus
              message={getWaitingRoomLoadingMessage()}
              sessionName={sessionName}
            />
          );
        case WaitingRoomState.READY:
        case WaitingRoomState.TALENT_BREAK:
          return renderReadyState();
        case WaitingRoomState.FINISHED:
          return (
            <MeetingStatus
              message="This session has ended."
              sessionName={sessionName}
            />
          );

        default:
          throw new Error('Unsupported session state');
      }
    };

    return (
      <>
        <MeetingProvider>
          {renderMeetingPhase()}
          <DevicePermissionsModal />
          <DevicesDebugger />
        </MeetingProvider>
        {isDisconnectedSelector && <DisconnectedFromSessionModal />}
      </>
    );
  },
);
