import { getAll } from 'modules/adapter';
// todo: remove this import
import { RootState } from 'modules/main';
import { fanVideosReducer } from './reducer';

const rootSelector = (state: RootState): ReturnType<typeof fanVideosReducer> =>
  state.fanVideos;

const fanVideos = (state: RootState) => rootSelector(state).videos;

export const allFanVideos = getAll(fanVideos);
