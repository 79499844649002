import styled from 'styled-components';
import { RowContainer } from 'modules/ui';
import { device } from 'modules/utils';

export const Controls = styled(RowContainer)`
  width: 288px;
  height: 40px;
  position: absolute;
  bottom: 8px;
  left: 36px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
  align-items: center;
  justify-content: center;

  svg {
    margin: auto 12px;
  }

  @media ${device.mobile} {
    justify-content: center;
    left: 0px;
    width: 100%;
    background: none;
    bottom: -50px;

    svg {
      margin: 0px;
    }
  }
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    margin: 4px;
    background: white;
    align-items: center;
    justify-content: center;
  }
`;

export const ChatIconButton = styled(IconButton)`
  display: none;

  @media ${device.mobile} {
    display: flex;
  }
`;

export const Default = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`;
