import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { TextRegular } from '../../theme/typography';
import { CheckboxContainer, ErrorText } from './styled';

type Props = {
  label: string;
  name: string;
  isSecondary?: boolean;
  disabled?: boolean;
  value: boolean;
  onChange: () => void;
};

export const Checkbox: React.FC<Props> = ({
  label,
  onChange,
  isSecondary,
  value,
  name,
  disabled,
}) => {
  const eventHandler = useCallback((): void => {
    onChange();
  }, [onChange]);

  return (
    <CheckboxContainer isSecondary={!!isSecondary} disabled={!!disabled}>
      <input
        onChange={eventHandler}
        type="checkbox"
        checked={value}
        name={name}
      />
      <span className="checkmark" />
      <TextRegular>{label}</TextRegular>
    </CheckboxContainer>
  );
};

type FormProps = {
  label: string | React.ReactChild;
  name: string;
  isSecondary?: boolean;
  disabled?: boolean;
} & FieldRenderProps<boolean, HTMLElement>;

export const FormCheckbox: React.FC<FormProps> = React.memo(
  ({ label, input, name, isSecondary, meta, disabled }) => {
    return (
      <CheckboxContainer isSecondary={!!isSecondary} disabled={!!disabled}>
        <input
          type="checkbox"
          {...input}
          value={undefined}
          checked={input.checked}
          name={name}
        />
        <span>{label}</span>
        {meta.touched && meta.error && <ErrorText>{meta.error}</ErrorText>}
      </CheckboxContainer>
    );
  },
);
