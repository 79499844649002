import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CommonMeetingActions } from 'modules/common-meeting';
import {
  H2,
  Label,
  LogoV3,
  PostSessionInfoCard,
  OrderSnapshot,
  H4Bold,
  CTA,
} from 'modules/ui';
import { MessageBrokerActions } from 'modules/message-broker';
import {
  Container,
  ContentContainer,
  ProductsContainer,
  SnapshotCard,
  SnapshotCardContent,
  OrderButton,
} from './styled';

export const PostMeeting: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CommonMeetingActions.clearUserSessionData());
    dispatch(CommonMeetingActions.getShopifyRedirect());

    return () => {
      dispatch(MessageBrokerActions.stopConnection());
    };
  }, []);

  return (
    <Container>
      <ContentContainer>
        <div>
          <img
            className="desktop-tablet"
            src={LogoV3}
            alt="GalaxyCon"
            width="175px"
          />
          <H2>Thank You for Joining GalaxyCon Live!</H2>
        </div>
        <PostSessionInfoCard />
        <Link to="/">Go to home screen</Link>
      </ContentContainer>

      <ProductsContainer>
        <Label>RECOMMENDED FOR YOU</Label>
        <SnapshotCard>
          <img src={OrderSnapshot} alt="snapshot" />
          <SnapshotCardContent>
            <H4Bold>
              Order a digital snapshot, or a printed 8x10 to be signed by the
              celebrity
            </H4Bold>
            <OrderButton
              target="_blank"
              rel="noreferrer"
              href="https://galaxycon.com/pages/snapshots?return_to=/pages/snapshots#"
            >
              <CTA> Order a Snapshot</CTA>
            </OrderButton>
          </SnapshotCardContent>
        </SnapshotCard>
      </ProductsContainer>
    </Container>
  );
});
