import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { TwilioChatType, MessageType, QueuedOutgoingMessage } from '../types';
import * as ChatActions from './actions';

const main = createReducer([] as MessageType[], (builder) => {
  builder.addCase(ChatActions.setChatMessage, (state, action) => [
    ...state,
    action.payload,
  ]);
});

const support = createReducer([] as MessageType[], (builder) => {
  builder
    .addCase(ChatActions.setTechCheckMessage, (state, action) => [
      ...state,
      action.payload,
    ])
    .addCase(ChatActions.cleanTechCheckChat, () => [] as MessageType[]);
});

const twilioAccessToken = createReducer('', (builder) => {
  builder.addCase(
    ChatActions.fetchTwilioAccessToken.fulfilled,
    (state, action) => action.payload.result.accessToken,
  );
});

const isTwilioAccessTokenFetchingInProgress = createReducer(
  false,
  (builder) => {
    builder
      .addCase(ChatActions.fetchTwilioAccessToken.pending, () => true)
      .addCase(ChatActions.fetchTwilioAccessToken.fulfilled, () => false)
      .addCase(ChatActions.fetchTwilioAccessToken.rejected, () => false);
  },
);

const channelNames = createReducer([] as string[], (builder) =>
  builder.addCase(ChatActions.joinChannel, (state, action) => {
    const channelName = action.payload;

    const channelExists = !!state.find((c) => c === channelName);

    if (channelExists) {
      return state;
    }

    return [...state, channelName];
  }),
);

const outgoingMessagesQueue = createReducer(
  {} as Record<string, QueuedOutgoingMessage[]>,
  (builder) => {
    builder
      .addCase(ChatActions.enqueueOutgoingMessage, (draft, action) => {
        const {
          message: { channelName },
        } = action.payload;

        if (!draft[channelName]) {
          draft[channelName] = [] as QueuedOutgoingMessage[];
        }

        draft[channelName].push(action.payload);
      })
      .addCase(ChatActions.removeMessageFromQueue, (draft, action) => {
        const {
          message: { channelName },
          id,
        } = action.payload;

        draft[channelName] = draft[channelName].filter(
          (m: QueuedOutgoingMessage) => m.id !== id,
        );
      });
  },
);

const twilioChats = createReducer(
  {} as Record<string, TwilioChatType>,
  (builder) => {
    builder
      .addCase(ChatActions.setTwilioMessage, (state, action) => {
        const { chatId, message } = action.payload;
        const chat = state[chatId] || {
          messages: [],
          hasNew: false,
        };
        const { messages } = chat;
        const messageExists = !!messages.find((m) => m.id === message.id);

        if (!messageExists) {
          if (message.new) {
            chat.hasNew = true;
          }
          chat.messages.push(message);
        }

        // eslint-disable-next-line no-param-reassign
        state[chatId] = chat;
      })
      .addCase(ChatActions.loadChannelPage, (draft, action) => {
        const { chatId, messages: newMessages } = action.payload;
        let chat: TwilioChatType | undefined = draft[chatId];

        if (!chat) {
          chat = {
            messages: [],
            hasNew: false,
          };

          draft[chatId] = chat;
        }

        const { messages: existingMessages } = chat;
        const filteredMessages = newMessages.filter(
          (newMessage) => !existingMessages.find((m) => m.id === newMessage.id),
        );

        chat.messages = filteredMessages;
      })
      .addCase(ChatActions.setTwilioChat, (state, action) => {
        const { chatId, chat } = action.payload;
        const chatItem = state[chatId] || {
          messages: [],
          hasNew: false,
        };
        chatItem.hasNew = chat.hasNew;
        // eslint-disable-next-line no-param-reassign
        state[chatId] = chatItem;
      });
  },
);

export const chatReducer = combineReducers({
  main,
  support,
  twilioAccessToken,
  twilioChats,
  isTwilioAccessTokenFetchingInProgress,
  channelNames,
  outgoingMessagesQueue,
});
