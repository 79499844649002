import React from 'react';
import { H2, Button, TextInput } from 'modules/ui';
import { Field, Form } from 'react-final-form';
import { Dictionary } from 'modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { LobbySelectors } from 'modules/lobby';
import { HeaderWithClock } from 'modules/common-meeting';
import { useParams } from 'react-router-dom';
import { TalentActions } from '../../redux';

import {
  JoinSessionContainer,
  MainContainer,
  Container,
  FormContainer,
  SubmitContainer,
} from './styled';

export const TalentLobby: React.FC = React.memo(() => {
  const sessionName = useSelector(LobbySelectors.getSessionName);
  const dispatch = useDispatch();

  const { attendeeGuid } = useParams<Record<string, string>>();

  return (
    <MainContainer>
      <Container>
        <HeaderWithClock />
        <JoinSessionContainer>
          <H2>{sessionName}</H2>
          <FormContainer>
            <Form
              // todo
              onSubmit={() => undefined}
              initialValues={{
                name: '',
              }}
              render={({ handleSubmit, values }): React.ReactNode => (
                <>
                  <Field
                    type="text"
                    name="name"
                    label="ENTER YOUR NAME TO JOIN THE SESSION"
                    placeholder="Your name"
                    component={TextInput}
                    isSecondary
                    isDarkBackground
                  />
                  <SubmitContainer>
                    <Button
                      label={Dictionary.JOIN_SESSION}
                      clickHandler={() => {
                        dispatch(TalentActions.setTalentName(values.name));
                        if (attendeeGuid) {
                          dispatch(
                            TalentActions.authorizeTalent({
                              attendeeGuid,
                            }),
                          );
                        }
                        handleSubmit();
                      }}
                    />
                  </SubmitContainer>
                </>
              )}
            />
          </FormContainer>
        </JoinSessionContainer>
      </Container>
    </MainContainer>
  );
});
