import React from 'react';
import { Button, Modal, H2, TextRegular } from 'modules/ui';
import { useModal } from 'modules/main';
import { ButtonContainer, ModalContainer } from './styled';

type Props = {
  title: string;
  text: string;
  onAfterClose?: () => void;
};
export const RecordingConsentModal: React.FC<Props> = React.memo(
  ({ title, text, onAfterClose }) => {
    const [isConsentModalOpen, , closeConsentModal] = useModal(true);

    return (
      <Modal
        isOpen={isConsentModalOpen}
        fullWidth={false}
        closeModal={closeConsentModal}
        onRequestClose={onAfterClose}
        isDark
        height={675}
        width={500}
      >
        <ModalContainer>
          <H2>{title}</H2>
          <TextRegular className="text">{text}</TextRegular>
          <ButtonContainer>
            <Button label="Dismiss" clickHandler={closeConsentModal} />
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    );
  },
);
