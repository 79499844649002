import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Attendee } from 'modules/utils';
import { MessageBrokerActions } from 'modules/message-broker';
import { WaitingRoomState } from 'modules/common-meeting';
import * as CommonMeetingActions from './actions';
import { MeetingInfo, Timer, Questions, WaitingRoomModel } from '../types';

import { UserType, ConsentStatus } from '../consts';

export const mainMeetingInfo = createReducer({} as MeetingInfo, (builder) => {
  builder
    .addCase(CommonMeetingActions.clearAttendeeData, () => ({} as MeetingInfo))
    .addCase(
      CommonMeetingActions.clearMainMeetingJoiningInfo,
      () => ({} as MeetingInfo),
    )
    .addCase(
      CommonMeetingActions.getMainCallJoiningInfo.fulfilled,
      (draft, action) => action.payload.result,
    );
});

export const techCheckMeetingInfo = createReducer(
  {} as MeetingInfo,
  (builder) => {
    builder
      .addCase(
        CommonMeetingActions.getTechCheckCallJoiningInfo.fulfilled,
        (draft, action) => action.payload.result,
      )
      .addCase(
        CommonMeetingActions.clearTechCheckJoiningInfo,
        () => ({} as MeetingInfo),
      )
      .addCase(
        CommonMeetingActions.clearAttendeeData,
        () => ({} as MeetingInfo),
      );
  },
);

export const readinessCheckMeetingInfo = createReducer(
  {} as MeetingInfo,
  (builder) => {
    builder
      .addCase(
        CommonMeetingActions.getReadinessCallJoiningInfo.fulfilled,
        (draft, action) => action.payload.result,
      )
      .addCase(
        CommonMeetingActions.clearReadinessCheckJoiningInfo,
        () => ({} as MeetingInfo),
      )
      .addCase(
        CommonMeetingActions.clearAttendeeData,
        () => ({} as MeetingInfo),
      );
  },
);

export const currentAttendee = createReducer({} as Attendee, (builder) => {
  builder.addCase(
    CommonMeetingActions.setCurrentAttendee,
    (_state, action) => action.payload,
  );
});

export const attendeeInTechCheck = createReducer(
  {} as Attendee,
  (builder) => builder,
);

export const timer = createReducer(
  { duration: 0, isPaused: false, start: '', addons: 0 } as Timer,
  (builder) => {
    builder.addCase(
      CommonMeetingActions.clearAttendeeData,
      () => ({ duration: 0, isPaused: false, start: '', addons: 0 } as Timer),
    );
  },
);

// todo
export const participants = createReducer([], (builder) => builder);

export const shopifyUrl = createReducer('', (builder) => {
  builder.addCase(
    CommonMeetingActions.getShopifyRedirect.fulfilled,
    (_state, action) => action.payload.result?.url,
  );
});

// todo
export const talent = createReducer('', (builder) => builder);

export const questionnaire = createReducer(
  {} as { [key: string]: Questions },
  (builder) => builder,
  // TODO: Need new event for this
  // .addCase(EventSourceActions.stopSession, () => ({}));
);

// todo: initial waiting room state
const waitingRoomState = createReducer(
  null as WaitingRoomState | null,
  (builder) => {
    builder
      .addCase(
        MessageBrokerActions.receiveMessage,
        (_state, action) => (action.payload as WaitingRoomModel).State || null,
      )
      .addCase(MessageBrokerActions.stopConnection, () => null);
  },
);

const isEnterWaitingRoomUnauthorized = createReducer(false, (builder) => {
  builder.addCase(
    CommonMeetingActions.setEnterWaitingRoomUnauthorized,
    () => true,
  );
});

const isCallWithTalentInRedZone = createReducer(false, (builder) => {
  builder.addCase(
    CommonMeetingActions.setIsCallInRedZone,
    (draft, action) => action.payload,
  );
});

const waitingRoomId = createReducer(null as string | null, (builder) =>
  builder
    .addCase(
      CommonMeetingActions.enterWaitingRoom.pending,
      (_state, action) => action.meta.arg,
    )
    .addCase(MessageBrokerActions.stopConnection, () => null),
);

const bookedParticipantSlotId = createReducer(
  null as number | null,
  (builder) => {
    builder
      .addCase(
        CommonMeetingActions.enterWaitingRoom.fulfilled,
        (_state, action) =>
          action.payload.result.bookedParticipantSlotId || null,
      )
      .addCase(MessageBrokerActions.stopConnection, () => null);
  },
);

const waitingRoomReducer = createReducer({} as WaitingRoomModel, (builder) => {
  builder
    .addCase(
      MessageBrokerActions.receiveMessage,
      (draft, action) => action.payload as WaitingRoomModel,
    )
    .addCase(CommonMeetingActions.tickPhotoTimer, (draft, action) => {
      draft.TalentVideoCall.PhotoSecondsLeft = action.payload;
    })
    .addCase(CommonMeetingActions.reorderWaitingRoomFans, (draft, action) => {
      const nonFanParticipants = draft.Participants.filter(
        (participant) => participant.Type !== UserType.FAN,
      );
      draft.Participants = [...nonFanParticipants, ...action.payload];
    })
    .addCase(MessageBrokerActions.stopConnection, (draft) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.keys(draft).forEach((key) => delete draft[key]);
    });
});

const lastMQMessageReceivedTimestamp = createReducer(0 as number, (builder) => {
  builder.addCase(
    MessageBrokerActions.setMessageReceivedTimestamp,
    (draft, action) => action.payload,
  );
});

const isDisconnected = createReducer(false as boolean, (builder) => {
  builder.addCase(
    CommonMeetingActions.setIsDisconnected,
    (draft, action) => action.payload,
  );
});

const isConnectedToMessageBroker = createReducer(
  false as boolean,
  (builder) => {
    builder.addCase(
      MessageBrokerActions.setIsConnectedToMessageBroker,
      () => true,
    );
  },
);

const permissionsStatus = createReducer(
  ConsentStatus.NotAnswered as ConsentStatus,
  (builder) => {
    builder.addCase(
      CommonMeetingActions.setPermissionsStatus,
      (draft, action) => action.payload,
    );
  },
);

const shouldHidePermissionsModal = createReducer(false, (builder) => {
  builder.addCase(
    CommonMeetingActions.setShouldHidePermissionsModal,
    (draft, action) => action.payload,
  );
});

const isPreferDesktopUsageBannerHidden = createReducer(false, (builder) => {
  builder.addCase(
    CommonMeetingActions.hidePreferDesktopUsageBanner,
    () => true,
  );
});

const reducer = combineReducers({
  waitingRoomState,
  bookedParticipantSlotId,
  waitingRoomId,
  waitingRoom: waitingRoomReducer,
  mainMeetingInfo,
  techCheckMeetingInfo,
  readinessCheckMeetingInfo,
  currentAttendee,
  timer,
  participants,
  shopifyUrl,
  talent,
  questionnaire,
  attendeeInTechCheck,
  isEnterWaitingRoomUnauthorized,
  isCallWithTalentInRedZone,
  permissionsStatus,
  shouldHidePermissionsModal,
  isPreferDesktopUsageBannerHidden,
  lastMQMessageReceivedTimestamp,
  isDisconnected,
  isConnectedToMessageBroker,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'meeting',
  storage,
  whitelist: ['techCheckReloadPerformed', 'lastMQMessageReceivedTimestamp'],
};

export const commonMeetingReducer = persistReducer(persistConfig, reducer);

export type CommonMeetingState = ReturnType<typeof commonMeetingReducer>;
