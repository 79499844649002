/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import * as ErrorActions from '../redux/actions';

export const globalErrorMiddleware = (): Middleware => {
  return (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
    action: any,
  ): any => {
    if (action.error) {
      store.dispatch(ErrorActions.addError(action.error));

      setTimeout(() => {
        store.dispatch(ErrorActions.removeErrors());
      }, 5000);

      Sentry.captureException(action.error);

      return null;
    }

    return next(action);
  };
};
