import dateFormat from 'dateformat';
import { create, get, URL } from 'modules/api';
import { Response } from 'modules/utils';
import { OrderItem } from '../types';

export const getImportedOrdersWithStatusRequest = (
  date: Date,
): Promise<OrderItem[]> => {
  const formattedStartDate = dateFormat(date, 'yyyy-mm-dd');

  return get(
    `${URL.GET_IMPORTED_ORDERS_WITH_STATUS}?StartDate=${formattedStartDate}&Take=1000&Skip=0`,
  ).then((response) => {
    const { result } = response as Response<OrderItem[]>;
    return result;
  });
};

export const resendVerificationEmailRequest = (
  email: string,
): Promise<Response<unknown>> => {
  return create(`${URL.RESEND_VERIFICATION_EMAIL}`, { email });
};
