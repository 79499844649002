import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  h5 {
    margin: 0 11px 0 0;
  }
  span {
    line-height: 26px;
  }
`;
