import { ForgotPasswordCheck } from 'modules/auth';
import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Label, TextError, TextItalic } from '../../theme/typography';
import { InputContainer } from './styled';

type Props = {
  type?: string;
  label?: string;
  alternate?: boolean;
  password?: boolean;
  isSecondary?: boolean;
  placeholder?: string;
  isDarkBackground?: boolean;
  disabled?: boolean;
  response?: ForgotPasswordCheck;
  createPassword?: boolean;
  isRevealingPassword?: boolean;
  instructions?: string;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FieldRenderProps<string, HTMLElement>;

export const TextInput: React.FC<Props> = React.memo(
  ({
    type,
    label,
    input,
    meta,
    password,
    isSecondary,
    placeholder,
    isDarkBackground,
    disabled,
    response,
    createPassword,
    isRevealingPassword,
    onChange,
    instructions,
    maxLength,
  }) => {
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
      setInvalid(
        (meta.touched && meta.error) ||
          (response?.hasResponse && !response?.success),
      );
    }, [meta, response]);

    return (
      <InputContainer
        invalid={invalid}
        isRevealingPassword={!!isRevealingPassword}
        password={!!password}
        isDarkBackground={!!isDarkBackground}
        disabled={!!disabled}
      >
        {!!isSecondary && <Label>{label}</Label>}
        <input
          type={type}
          placeholder={placeholder || label}
          {...input}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }

            input.onChange(event);
          }}
          disabled={disabled}
          maxLength={maxLength}
        />
        {/* {meta.touched && meta.error && <TextError>{meta.error}</TextError>} */}

        <TextError
          className={meta.touched && meta.error ? 'active' : 'inactive'}
        >
          {meta.error}
        </TextError>

        {((!invalid && createPassword) || instructions) && (
          <TextItalic className="instructions">
            {instructions ||
              'Password must contain at least 1 lowercase and uppercase character, 1 number, and 1 special character.'}
          </TextItalic>
        )}
        {response?.hasResponse && (
          <TextError className={!response.success ? 'active' : 'inactive'}>
            {response.message}
          </TextError>
        )}
      </InputContainer>
    );
  },
);
