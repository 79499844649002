import React from 'react';
import { NavLink } from 'react-router-dom';
import { SessionTabContainer, TabText } from './styled';

type RouteType = {
  label: string;
  path: string;
};

const routes: RouteType[] = [
  { label: 'NEXT SESSIONS', path: 'next-sessions' },
  { label: 'PAST SESSIONS', path: 'past-sessions' },
];

export const ScheduleNavigation: React.FC = React.memo(() => {
  return (
    <SessionTabContainer>
      {routes.map((route: RouteType) => (
        <NavLink
          key={route.path}
          strict
          to={route.path}
          activeClassName="active"
        >
          <TabText>{route.label}</TabText>
        </NavLink>
      ))}
    </SessionTabContainer>
  );
});
