import { RouteType } from 'modules/utils';
import { Paths } from 'paths';

export const ClientRoutes: RouteType[] = [
  {
    label: 'Upcoming Live Events',
    path: '/',
  },
  {
    label: 'My Schedule',
    path: Paths.NextSessions,
  },
  {
    label: 'Videos',
    path: Paths.ClientVideos,
  },
  {
    label: 'Settings',
    path: Paths.AccountDetails,
  },
];
