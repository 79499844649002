import { ColumnContainer, CTA, RowContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const MainContainer = styled(RowContainer)`
  align-items: stretch;

  .canvas {
    position: fixed;
    z-index: 0;
    left: -9999px;
  }

  .preview-image {
    width: 900px;
    height: 600px;
    z-index: 99;
    border-radius: 8px;
    position: fixed;
    top: 10px;
    left: 50%;
  }
`;

export const Container = styled(ColumnContainer)`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.ga.darkGray};
  color: ${({ theme }) => theme.ga.white};
  padding: 8px 16px 0 16px;
  box-sizing: border-box;
  overflow: hidden;

  @media ${device.mobile}, ${device.tablet} {
    padding: 0px;
  }
`;

export const VideoContainer = styled(ColumnContainer)`
  position: relative;
  height: 100%;
  border-radius: 8px;
  margin: 20px 0px 50px;
  background-color: ${(props) => props.theme.ga.blueTile};
  box-sizing: border-box;
  .video-grid {
    background-color: transparent;
  }
  .snapshot-container {
    width: 100%;
    height: 100%;
  }
  .message-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${device.mobile}, ${device.tablet} {
    margin: 0px;
    height: 100vh;

    .video-grid {
      height: 100vh;
      .video-tile {
        height: 100vh;
      }
    }
  }
`;

export const TitleContainer = styled(RowContainer)`
  align-items: center;
  .camera-icon {
    height: 20px;
    color: ${({ theme }) => theme.ga.gray};
  }
  ${CTA} {
    margin: 0px 10px;
    color: ${({ theme }) => theme.ga.gray};
  }

  @media ${device.mobile}, ${device.tablet} {
    display: none;
  }
`;

export const InfoCardContainer = styled.div`
  position: absolute;
  left: 32px;
  max-width: 420px;
  top: 32px;
  z-index: 3;
  @media ${device.mobile}, ${device.tablet} {
    //position: absolute;
    max-width: 337px;
    bottom: 120px;
    left: 18px;
    top: unset;
  }
`;
