import styled from 'styled-components';
import { device } from 'modules/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  height: calc(100% - 67px);

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobile} {
    display: block;
    position: relative;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 0 !important;

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  padding-right: 100px;
  padding-left: 100px;

  div {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 60px;
  }

  @media ${device.laptop} {
    padding-left: 5%;
    padding-right: 50px;
  }

  @media ${device.tablet} {
    width: 85%;
    margin: 0 auto;
    padding: 0;
  }

  @media ${device.mobile} {
    h1 {
      display: none;
    }
  }
`;

export const MobileSubmitButton = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    height: 100px;
    width: 100vw;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.ga.magenta};
    margin: 0 !important;
    text-align: center;
    box-sizing: border-box;
    padding-top: 12px;
    color: ${({ theme }) => theme.ga.white};
    span {
      font-weight: 800;
    }
  }
`;

export const SideBySideFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0 !important;

  & > div {
    flex-grow: 1;
  }

  & :first-child {
    margin-right: 20px;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const AccountNavBar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: auto;
  margin-top: 20px;
  position: relative;

  a {
    text-decoration: none;
    color: black;
    border-left: 5px solid transparent;
    margin-bottom: 8px;
    padding: 20px 0 20px 50px;

    &.active {
      color: ${(props) => props.theme.ga.magenta};
      border-left: 5px solid ${(props) => props.theme.ga.magenta};
    }
  }
`;

export const LogOutContainer = styled.div`
  display: none;

  @media ${device.tablet} {
    display: inline-block;
  }

  @media ${device.tablet} {
    display: inline-block;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 16px;

  button {
    padding: 12px 24px;
  }

  .forgot-password {
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
  }

  a {
    color: inherit;
  }

  @media ${device.laptop} {
    padding-top: 20px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media ${device.mobile} {
    button {
      width: 100%;
    }
  }
`;

export const CenterAlign = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const LoginButton = styled.button`
  color: ${(props) => props.theme.ga.black};
  background: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 20px;
  border: 0;
  margin-top: 5px;
`;
