/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
type Validator = (value: string, allValues?: any) => string | undefined;
const characters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const lowercase = /(?=.*[a-z])/;
const uppercase = /(?=.*[A-Z])/;

export type DatePickerDate = {
  day: number;
  month: number;
  year: number;
};

export enum ValidatorMessages {
  REQUIRED = 'Required',
  MIN_LENGTH = 'Password should be at least 8 characters!',
  PASSWORD_ERROR = 'Password must contain at least 1 lowercase and uppercase character, 1 number, and 1 special character.',
  NO_USER = "This email address isn't associated with the GalaxyCon Live platform",
}

export const isNumeric = (value: string): boolean => /\d/.test(value);
export const isMatch = (val1: string, val2: string): boolean => val1 === val2;
export const hasCharacter = (value: string): boolean => characters.test(value);
export const hasLowercase = (value: string): boolean => lowercase.test(value);
export const hasUppercase = (value: string): boolean => uppercase.test(value);
export const isDateInPast = (value: DatePickerDate): string | undefined => {
  if (!value) {
    return undefined;
  }

  const present = Date.now();
  const selectedDate = new Date();
  selectedDate.setFullYear(value.year, value.month - 1, value.day);

  return present > selectedDate.getTime()
    ? "You can't select past date."
    : undefined;
};

export const isTimeInPast = (value: string, allValues: any) => {
  const current = new Date();
  const hours = current.getHours();
  const { date } = allValues;

  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  const isToday =
    year === date.year && month === date.month && day === date.day;

  return isToday && Number(value) < hours
    ? "You can't select past time."
    : undefined;
};

export const isPositiveNumber = (value: string) => {
  return Number(value) < 0 ? 'Number needs to be bigger than zero' : undefined;
};

export const isStartBeforeEnd = (
  _value: string,
  allValues: any,
): string | undefined => {
  const { startTime, endTime } = allValues;

  return Number(startTime) > Number(endTime) ||
    Number(endTime) === 24 ||
    Number(startTime) === Number(endTime)
    ? 'Start time should be before end time'
    : undefined;
};

export const isEndAfterStart = (
  _value: string,
  allValues: any,
): string | undefined => {
  const { startTime, endTime } = allValues;

  return Number(startTime) > Number(endTime) ||
    Number(endTime) === 24 ||
    Number(startTime) === Number(endTime)
    ? 'End time should be after start time'
    : undefined;
};
export const hasValidEmail = (value: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const mustBeChecked = (value: boolean): undefined | string =>
  !value ? ValidatorMessages.REQUIRED : undefined;

export const required = (value: string): undefined | string =>
  !value ? ValidatorMessages.REQUIRED : undefined;

export const mustBeValidEmail = (value: string): undefined | string =>
  hasValidEmail(value) ? undefined : 'You need to enter a valid email address.';

export const minLength = (min: number) => (value: string): undefined | string =>
  value.length >= min ? undefined : `Should be at least ${min} characters`;

export const mustHaveNumber = (value: string): undefined | string =>
  isNumeric(value) ? undefined : ValidatorMessages.PASSWORD_ERROR;

export const mustHaveCharacter = (value: string): undefined | string =>
  hasCharacter(value) ? undefined : ValidatorMessages.PASSWORD_ERROR;

export const mustHaveLowercase = (value: string): undefined | string =>
  hasLowercase(value) ? undefined : ValidatorMessages.PASSWORD_ERROR;

export const mustHaveUppercase = (value: string): undefined | string =>
  hasUppercase(value) ? undefined : ValidatorMessages.PASSWORD_ERROR;

export const matchPassword = (pass1: string) => (
  value: string,
): undefined | string => {
  return isMatch(pass1, value)
    ? undefined
    : 'Please make sure your passwords match';
};

export const composeValidators = (...validators: Validator[]) => (
  value: string,
  allValues: any,
): string | undefined =>
  validators.reduce(
    (error: string | undefined, validator) =>
      error || validator(value, allValues),
    undefined,
  );
