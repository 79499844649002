import React, { useCallback, useEffect, useState } from 'react';
import { CalendarPicker, DayValue, Spinner, EmptySessions } from 'modules/ui';
import { getImportedOrdersWithStatusRequest } from '../../api';
import { OrderItem } from '../../types';
import { OrdersHeader } from '../OrdersHeader';
import { OrderRow } from '../OrderRow';
import {
  Container,
  MainContainer,
  SideContainer,
  ScrollableContainer,
  OrdersContainer,
  SpinnerContainer,
  THead,
  TBody,
} from './styled';

export const Orders: React.FC = React.memo(() => {
  const today = new Date();
  const todayDayValue: DayValue = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };
  const [selectedDate, setSelectedDate] = useState<DayValue>(todayDayValue);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<OrderItem[]>([]);

  const handleGetOrders = useCallback(async () => {
    if (!selectedDate) {
      return;
    }

    const date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day,
    );

    try {
      setIsLoading(true);
      const importedOrders = await getImportedOrdersWithStatusRequest(date);
      setOrders(importedOrders);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    document.title = `GalaxyCon Imported Orders`;
    handleGetOrders();
  }, [selectedDate]);

  return (
    <Container>
      <SideContainer>
        <CalendarPicker value={selectedDate} onChange={setSelectedDate} />
      </SideContainer>
      <ScrollableContainer>
        <MainContainer>
          {isLoading && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
          {!isLoading && orders.length === 0 && <EmptySessions isOrders />}
          {!isLoading && orders.length > 0 && (
            <OrdersContainer>
              <THead>
                <OrdersHeader />
              </THead>
              <TBody>
                {orders.map((order: OrderItem) => (
                  <OrderRow
                    order={order}
                    key={`${order.shopifyOrderEmail}-${order.shopifyOrderId}-${order.orderItemTitle}-${order.variantTitle}`}
                  />
                ))}
              </TBody>
            </OrdersContainer>
          )}
        </MainContainer>
      </ScrollableContainer>
    </Container>
  );
});
