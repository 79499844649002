type RouteType = {
  label: string;
  path: string;
};

export const AccountRoutes: RouteType[] = [
  { label: 'Account Details', path: '/account/details' },
  { label: 'Change Password', path: '/account/change-password' },
  { label: 'My Orders', path: '/account/my-orders' },
];

export const TechRoutes: RouteType[] = [
  {
    label: 'Account Details',
    path: '/admin/account/details',
  },
  {
    label: 'Change Password',
    path: '/admin/account/change-password',
  },
];

export const AdminAccountRoutes: RouteType[] = [
  ...TechRoutes,
  {
    label: 'Members',
    path: '/admin/account/members',
  },
];
