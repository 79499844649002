import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VideoTileGrid } from 'amazon-chime-sdk-component-library-react';
import { Attendee } from 'modules/utils';

import {
  SessionControls,
  CommonMeetingActions,
  CommonMeetingSelectors,
  AdminTalentControls,
  useConnectToMainCall,
  DevicePermissionsModal,
} from 'modules/common-meeting';

import {
  CameraIcon,
  CTA,
  SnapshotCountdownInfoCard,
  TextRegular,
  H6,
} from 'modules/ui';

import { CustomerQueue } from 'modules/queue';

import { GlobalChat, GlobalChatHandle } from '../GlobalChat';
import {
  MainContainer,
  Container,
  VideoContainer,
  TitleContainer,
  InfoCardContainer,
} from './styled';

export const InACallView: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const sessionName = useSelector(
    CommonMeetingSelectors.waitingRoomNameSelector,
  );
  const photoTimerSecondsLeft = useSelector(
    CommonMeetingSelectors.getPhotoSecondsLeft,
  );
  const isTalentOnBreak = useSelector(CommonMeetingSelectors.isTalentOnBreak);
  const talentName = useSelector(CommonMeetingSelectors.talentName);

  const divRef = useRef<HTMLDivElement>(null);

  useConnectToMainCall();

  const isPhotoTimerVisible =
    !!photoTimerSecondsLeft && photoTimerSecondsLeft !== -1;

  const globalChatRef = useRef<GlobalChatHandle>(null);

  const handleOpenChat = (attendee: Attendee) => {
    globalChatRef.current?.openChat(attendee);
  };

  return (
    <MainContainer>
      <CustomerQueue onOpenChat={handleOpenChat} />
      <Container>
        <TitleContainer>
          <CameraIcon className="camera-icon" />
          <CTA>LIVE</CTA>
          <TextRegular>{sessionName}</TextRegular>
        </TitleContainer>
        <VideoContainer>
          <InfoCardContainer>
            {isPhotoTimerVisible && (
              <SnapshotCountdownInfoCard
                initialSecondsRemaining={photoTimerSecondsLeft}
              />
            )}
          </InfoCardContainer>
          {!isTalentOnBreak && (
            <div ref={divRef} className="snapshot-container">
              <VideoTileGrid layout="standard" className="video-grid" />
            </div>
          )}
          {isTalentOnBreak && (
            <div className="message-container">
              <H6>{`${talentName || 'Talent'} took a break`}</H6>
            </div>
          )}

          <SessionControls />
          <AdminTalentControls
            onTakeSnapshot={() =>
              dispatch(CommonMeetingActions.startScreenshotTimer())
            }
            isAdmin
          />
        </VideoContainer>
        <GlobalChat ref={globalChatRef} />
      </Container>
      <DevicePermissionsModal />
    </MainContainer>
  );
});
