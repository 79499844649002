import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Client, Channel } from 'twilio-chat';
import { Response } from 'modules/utils';
import { RootState } from 'modules/main';
import { fetchAccessTokenRequest } from '../api';
import {
  MessageType,
  TwilioChatType,
  TwilioMessageType,
  OutgoingMessage,
  QueuedOutgoingMessage,
} from '../types';
import {
  isTwilioAccessTokenFetchingInProgress,
  getAccessToken,
} from './selectors';

export const setChatMessage = createAction<MessageType>(
  'chat/SET_CHAT_MESSAGE',
);
export const cleanTechCheckChat = createAction('chat/CLEAN_TECH_CHECK_CHAT');
export const setTechCheckMessage = createAction<MessageType>(
  'chat/SET_TECH_CHECK_MESSAGE',
);

// todo: delete condition if unnecessary
export const fetchTwilioAccessToken = createAsyncThunk<
  Response<{ accessToken: string }>
>('chat/FETCH_TWILIO_ACCESS_TOKEN', fetchAccessTokenRequest, {
  condition: (_, { getState }) => {
    const state = getState() as RootState;

    const isAlreadyInProgress = isTwilioAccessTokenFetchingInProgress(state);
    const token = getAccessToken(state);

    return token === '' && !isAlreadyInProgress;
  },
});

export const setTwilioClient = createAction<Client>('chat/SET_TWILIO_CLIENT');
export const setTwilioChannel = createAction<Channel>(
  'chat/SET_TWILIO_CHANNEL',
);
export const setTwilioMessage = createAction<{
  chatId: string;
  message: TwilioMessageType;
}>('chat/SET_TWILIO_MESSAGE');
export const setTwilioChat = createAction<{
  chatId: string;
  chat: TwilioChatType;
}>('chat/SET_TWILIO_CHAT');

export const connect = createAction('chat/CONNECT');

export const joinChannel = createAction<string>('chat/JOIN_CHANNEL');

export const sendMessage = createAction<OutgoingMessage>('chat/SEND_MESSAGE');

export const disconnect = createAction('chat/DISCONNECT');

export const loadChannelPage = createAction<{
  chatId: string;
  messages: TwilioMessageType[];
}>('chat/LOAD_CHANNEL_PAGE');

export const enqueueOutgoingMessage = createAction<QueuedOutgoingMessage>(
  'chat/ENQUEUE_OUTGOING_MESSAGE',
);
export const removeMessageFromQueue = createAction<QueuedOutgoingMessage>(
  'chat/REMOVE_MESSAGE_FROM_QUEUE',
);
