import React from 'react';
import { BtnText } from 'modules/ui';
import { ButtonContainer } from './styled';

type Props = {
  label: string;
  isMeeting?: boolean;
  isSecondary?: boolean;
  isTertiary?: boolean;
  isDark?: boolean;
  isBlue?: boolean;
  isLink?: boolean;
  disabled?: boolean;
  clickHandler: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  type?: 'submit' | 'button';
};

export const Button: React.FC<Props> = React.memo(
  ({
    label,
    isSecondary,
    isTertiary,
    isMeeting,
    isDark,
    isBlue,
    isLink,
    disabled,
    clickHandler,
    children,
    type,
  }) => {
    return (
      <ButtonContainer
        isMeeting={!!isMeeting}
        isSecondary={!!isSecondary}
        isTertiary={!!isTertiary}
        isDark={!!isDark}
        isBlue={!!isBlue}
        isLink={!!isLink}
        disabled={!!disabled}
        type={type || 'button'}
        onClick={clickHandler}
      >
        {children}
        <BtnText>{label}</BtnText>
      </ButtonContainer>
    );
  },
);
