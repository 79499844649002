/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import * as ErrorSelectors from '../../redux/selectors';
import { ErrorContainer } from './styled';

export const ErrorInfo: React.FC = React.memo(() => {
  const errors = useSelector(ErrorSelectors.getErrors);

  if (!errors.length) return null;

  return (
    <>
      {errors.map((error: any, index: number) => (
        <ErrorContainer index={index}>
          {/* <span>Error</span> */}
          <span>{error.message}</span>
        </ErrorContainer>
      ))}
    </>
  );
});
