import { get, Response } from 'modules/api';
import { JoinWaitingRoomResponseData } from 'modules/common-meeting';
import { URL } from '../consts';

type Position = {
  bookedParticipantSlotId: number;
  positionInQueue: number;
};

type Positions = {
  positionItems: Position[];
};

export const getPositionsRequest = (): Promise<Response<Positions>> =>
  get(URL.GET_POSITIONS);

export const getBpsForWaitingRoomRequest = (
  waitingRoomUid: string,
): Promise<Response<JoinWaitingRoomResponseData[]>> =>
  get(`${URL.GET_BPS_FOR_WAITING_ROOM}?WaitingRoomGuid=${waitingRoomUid}`);
