import { combineReducers, createReducer } from '@reduxjs/toolkit';
import * as AccountActions from './actions';
import { MemberType, UserOrderType, OwnerAdminResponseData } from '../types';

export const userOrders = createReducer([] as UserOrderType[], (builder) => {
  builder.addCase(
    AccountActions.getUserOrders.fulfilled,
    (_state, action) => action.payload.result,
  );
});

export const adminMembers = createReducer([] as MemberType[], (builder) => {
  builder
    .addCase(AccountActions.getAdminMembers.fulfilled, (_state, action) =>
      action.payload.result.sort((a) => (a.emailConfirmed ? 1 : 0)),
    )
    .addCase(AccountActions.removeAdminMember.fulfilled, (state, action) =>
      state.filter((item) => item.userId !== action.payload.result.userId),
    );
});

export const adminOwner = createReducer(
  {} as OwnerAdminResponseData,
  (builder) => {
    builder.addCase(
      AccountActions.getIsOwner.fulfilled,
      (_state, action) => action.payload.result,
    );
  },
);

export const accountReducer = combineReducers({
  userOrders,
  adminMembers,
  adminOwner,
});
