import styled from 'styled-components';

export const SvgButton = styled.button<{ disabled?: boolean }>`
  padding: 5px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: transparent;

  svg {
    stroke: ${({ theme, disabled }) =>
      disabled ? theme.ga.gray : theme.ga.pink};
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.ga.gray : theme.ga.pink};
      stroke: ${({ theme, disabled }) =>
        disabled ? theme.ga.gray : theme.ga.pink};
    }
  }
`;
