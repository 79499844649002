import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Button, TextInput, BtnText } from 'modules/ui';
import { ChangePasswordData } from 'modules/auth';
import { Validators } from 'modules/utils';
import { SubmitWrapper, FormWrapper, CenterAlign } from '../../styled';

type Props = {
  onSubmit: (data: ChangePasswordData) => void;
  disabled?: boolean;
};

export const ChangePasswordForm: React.FC<Props> = React.memo(
  ({ onSubmit, disabled }) => {
    const [pass1, setPass1] = useState('');

    const changeHandler = (value: string) => {
      setPass1(value);
    };

    return (
      <FormWrapper>
        <Form
          onSubmit={onSubmit}
          validateOnBlur
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirm: '',
          }}
          render={({ handleSubmit, invalid }): React.ReactNode => (
            <>
              <Field
                type="password"
                name="oldPassword"
                label="OLD PASSWORD"
                placeholder="Enter old password"
                component={TextInput}
                validate={Validators.required}
                isSecondary
              />
              <Field
                type="password"
                name="newPassword"
                label="SET NEW PASSWORD"
                placeholder="Set new password"
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.mustHaveNumber,
                  Validators.minLength(6),
                  Validators.mustHaveCharacter,
                  Validators.mustHaveUppercase,
                  Validators.mustHaveLowercase,
                )}
                component={TextInput}
                isSecondary
              />
              <OnChange name="newPassword">{changeHandler}</OnChange>
              <Field
                type="password"
                name="confirm"
                label="CONFIRM NEW PASSWORD"
                placeholder="Enter new password again"
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.matchPassword(pass1),
                )}
                component={TextInput}
                isSecondary
              />
              <SubmitWrapper>
                <CenterAlign>
                  <Button
                    label="Change password"
                    clickHandler={handleSubmit}
                    disabled={invalid || disabled}
                  />
                  <Link to="/forgot-password" className="forgot-password">
                    <BtnText>Forgot password</BtnText>
                  </Link>
                </CenterAlign>
              </SubmitWrapper>
            </>
          )}
        />
      </FormWrapper>
    );
  },
);
