import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  AudioIcon,
  CameraIcon,
  CloseIcon,
  H3,
  Modal,
  TabCaptionText,
} from 'modules/ui';
import 'react-tabs/style/react-tabs.css';

import {
  CameraSelection,
  MicSelection,
  SpeakerSelection,
  QualitySelection,
} from 'amazon-chime-sdk-component-library-react';
import { Container, Header, ContentContainer, TabCaption } from './styled';

type Props = {
  isOpen: boolean;
  onCloseRequest: () => void;
};

export const ChimeDeviceSettings: React.FC<Props> = React.memo(
  ({ isOpen, onCloseRequest }) => {
    return (
      <Modal
        isOpen={isOpen}
        width={632}
        height={485}
        fullWidth={false}
        closeModal={onCloseRequest}
        onRequestClose={onCloseRequest}
        isDark
      >
        <Container>
          <Header>
            <H3>Settings</H3>
            <CloseIcon onClick={onCloseRequest} />
          </Header>
          <ContentContainer>
            <Tabs>
              <TabList>
                <Tab>
                  <TabCaption>
                    <AudioIcon />
                    <TabCaptionText>Audio</TabCaptionText>
                  </TabCaption>
                </Tab>
                <Tab>
                  <TabCaption>
                    <CameraIcon />
                    <TabCaptionText>Video</TabCaptionText>
                  </TabCaption>
                </Tab>
              </TabList>

              <TabPanel>
                <MicSelection appendSampleDevices={false} />
                <SpeakerSelection />
              </TabPanel>
              <TabPanel>
                <CameraSelection appendSampleDevices={false} />
                <QualitySelection />
              </TabPanel>
            </Tabs>
          </ContentContainer>
        </Container>
      </Modal>
    );
  },
);
