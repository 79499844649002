import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paths } from 'paths';
import {
  EmailForm,
  ForgotPasswordData,
  forgotPassword,
  FormContainer,
  Header,
  AuthLayout,
} from 'modules/auth';
import { H3, Spinner } from 'modules/ui';
import { Instructions } from './styled';

export const ForgotPassword: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: false,
    message: '',
    hasResponse: false,
  });

  const onSubmit = useCallback(
    async ({ email }: ForgotPasswordData) => {
      setLoading(true);
      // TODO: temporary implementation. Fix this ASAP.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await dispatch(forgotPassword({ email }));
      const { success } = res;
      const message =
        "This email address isn't associated with the GalaxyCon Live platform.";
      setResponse({ success, message, hasResponse: true });
      setLoading(false);
      if (success) {
        history.push(Paths.CheckMail, {
          email,
        });
      }
    },
    [dispatch],
  );

  return (
    <AuthLayout>
      <FormContainer>
        <Header>
          <H3>Forgot your password?</H3>
          <Instructions>
            Please enter your email, and we'll send you a link so you can reset
            your password that you can change at any time.
          </Instructions>
        </Header>
        <EmailForm onSubmit={onSubmit} response={response} />
        {loading && <Spinner />}
      </FormContainer>
    </AuthLayout>
  );
});
