import styled, { css } from 'styled-components';

export const Underline = styled.span`
  width: 24px;
  height: 2px;

  background-color: transparent;
`;

export const Nav = styled.nav<{ isDark: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 auto;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0px 24px;

    color: ${({ theme }) => theme.ga.darkBlue};
    text-decoration: none;
    cursor: pointer;

    span:first-child {
      margin-bottom: 7px;
    }

    &.active {
      color: ${({ theme }) => theme.ga.magenta};

      ${Underline} {
        background-color: ${({ theme }) => theme.ga.magenta};
      }
    }
  }

  ${({ isDark, theme }) =>
    isDark &&
    css`
      a {
        color: ${theme.ga.white};

        &.active {
          color: ${theme.ga.fluorescentGreen};

          ${Underline} {
            background-color: ${theme.ga.fluorescentGreen};
          }
        }
      }
    `}
`;
