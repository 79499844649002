import { useEffect } from 'react';
import {
  useLocalVideo,
  useAudioVideo,
  useMeetingStatus,
  MeetingStatus,
} from 'amazon-chime-sdk-component-library-react';

export const useAutoStartVideo = (): void => {
  const audioVideo = useAudioVideo();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  // Check the following for more information:
  // https://github.com/aws/amazon-chime-sdk-component-library-react/issues/420
  const meetingStatus = useMeetingStatus();

  useEffect(() => {
    if (!audioVideo) return;

    const isMeetingSucceeded = meetingStatus === MeetingStatus.Succeeded;
    if (isMeetingSucceeded && !isVideoEnabled) {
      toggleVideo();
    }
  }, [audioVideo, meetingStatus]);
};
