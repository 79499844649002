import { RouteType } from 'modules/utils';
import { Paths } from 'paths';
import { Category } from './types';

export const ClientRoutes: RouteType[] = [
  {
    label: 'Upcoming Live Events',
    path: '/',
  },
  {
    label: 'My Schedule',
    path: Paths.NextSessions,
  },
  {
    label: 'Videos',
    path: Paths.ClientVideos,
  },
];

export const ClientDropdownRoutes: RouteType[] = [
  {
    label: 'Account details',
    path: Paths.AccountDetails,
  },
  {
    label: 'My Orders',
    path: Paths.MyOrders,
  },
];

export const categories: Category[] = [
  {
    name: 'Animation',
    imageKey: 'animation',
    path: '/pages/animation',
  },
  {
    name: 'Anime',
    imageKey: 'anime',
    path: '/pages/anime',
  },
  {
    name: 'Heroes & Villains',
    imageKey: 'heroesAndVillains',
    path: '/pages/heroes-villains',
  },
  {
    name: 'Pop Culture',
    imageKey: 'popCulture',
    path: '/pages/pop-culture-and-nostalgia',
  },
  {
    name: 'Sci-Fi & Fantasy',
    imageKey: 'sciFi',
    path: '/pages/sci-fi',
  },
  {
    name: 'Video Gaming',
    imageKey: 'videoGames',
    path: '/pages/video-gaming',
  },
];
