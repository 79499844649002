import React from 'react';
import { GroupIcon, TextRegular } from 'modules/ui';
import { GalaxyVariantModel } from '../../types';
import { VariantsContainer, Variant, Attendees } from './styled';

type Props = {
  variants: GalaxyVariantModel[];
};

export const Variants: React.FC<Props> = React.memo(({ variants }) => {
  return (
    <VariantsContainer>
      {variants.map((variant: GalaxyVariantModel) => (
        <Variant key={variant.id}>
          <TextRegular>{variant.title}</TextRegular>
          <Attendees>
            <GroupIcon />
            <TextRegular>{variant.soldItems}</TextRegular>
          </Attendees>
        </Variant>
      ))}
    </VariantsContainer>
  );
});
