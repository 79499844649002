import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  unwrapResult,
} from '@reduxjs/toolkit';
import { Client, IMessage } from '@stomp/stompjs';
import { Response } from 'modules/api';
import { MessageBrokerActionType } from '../consts';
import { GetCredentialsResponse } from '../types';
import {
  receiveMessage,
  getCredentials,
  setMessageReceivedTimestamp,
  setIsConnectedToMessageBroker,
} from './actions';

export const messageBrokerMiddleware = (): Middleware => {
  let client: Client | undefined;

  const startClient = async (waitingRoomId: string, dispatch: Dispatch) => {
    const credentials = unwrapResult(
      // todo: fix typing to avoid using any and casting
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (await dispatch(getCredentials() as any)) as any,
    ) as Response<GetCredentialsResponse>;

    const {
      amqUsername: login,
      amqPassword: passcode,
      amqUri: brokerURL,
      topicWr,
    } = credentials.result;

    client = new Client({
      brokerURL,
      connectHeaders: {
        login,
        passcode,
      },
      debug() {
        // console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      if (client) {
        client.subscribe(
          `/topic/${topicWr}`,
          (message: IMessage) => {
            if (message.body) {
              dispatch(receiveMessage(JSON.parse(message.body)));
              dispatch(setMessageReceivedTimestamp(Date.now()));
            }
          },
          {
            selector: `JMSCorrelationID = '${waitingRoomId}'`,
          },
        );
        dispatch(setIsConnectedToMessageBroker());
      }
    };

    // todo: dispatch error action
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    client.onStompError = () => {};

    client.activate();
  };

  const stopClient = () => {
    if (client) {
      client.deactivate();
    }
  };

  // todo: return type
  return (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (
    action: AnyAction,
  ): Promise<unknown> => {
    switch (action.type) {
      case MessageBrokerActionType.CONNECT: {
        const waitingRoomId = action.payload;

        await startClient(waitingRoomId, store.dispatch);

        break;
      }
      case MessageBrokerActionType.DISCONNECT: {
        stopClient();
        break;
      }
      default:
        break;
    }

    return next(action);
  };
};
