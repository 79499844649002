import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Paths } from 'paths';
import { logOutUser, AuthSelectors, Roles } from 'modules/auth';
import { SubHeader } from 'modules/galaxy-events';
import { Dictionary } from 'modules/utils';
import {
  Header,
  Navigation,
  Button,
  AddIcon,
  DropdownMenu,
  DropdownMenuItem,
  TextRegular,
  TextBold,
  SettingsIcon,
} from 'modules/ui';
import { AdminRoutes, SettingsMenuRoutes } from '../../consts';
import { AdminContainer, SideContainer } from './styled';

export const AdminLayout: React.FC = React.memo(({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.getUser);

  const userName = useMemo(() => {
    return user?.profile?.firstName;
  }, [user]);

  const handleLogOut = () => {
    dispatch(logOutUser());
  };

  const isSubHeaderVisible =
    location.pathname === Paths.Sessions ||
    location.pathname === Paths.Media ||
    location.pathname === Paths.Orders;

  const navigateToEventCreate = useCallback(() => {
    history.push(Paths.CreateEvent);
  }, [history]);

  const getMenuItems = () => {
    const items = [
      <DropdownMenuItem key="logout" onClick={handleLogOut}>
        <TextRegular>
          Hi,
          {userName}
        </TextRegular>
        <TextBold className="sign-out">Sign out</TextBold>
      </DropdownMenuItem>,
      ...SettingsMenuRoutes.map((route) => (
        <DropdownMenuItem key={route.path}>
          <Link to={route.path}>
            <TextRegular>{route.label}</TextRegular>
          </Link>
        </DropdownMenuItem>
      )),
    ];

    return items;
  };

  return (
    <AdminContainer>
      <Header>
        <Navigation routes={AdminRoutes} />
        <SideContainer>
          {user.role !== Roles.TECH && (
            <Button
              label={Dictionary.CREATE_SESSION}
              isSecondary
              clickHandler={navigateToEventCreate}
            >
              <AddIcon />
            </Button>
          )}
          <DropdownMenu icon={<SettingsIcon />}>{getMenuItems()}</DropdownMenu>
        </SideContainer>
      </Header>
      {isSubHeaderVisible && <SubHeader />}
      {children}
    </AdminContainer>
  );
});
