import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialButton = styled.button`
  padding: 14px 30px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  background: ${({ theme }) => theme.ga.white};
  outline: none;
  cursor: pointer;
`;

export const SubmitContainer = styled(ColumnContainer)`
  align-items: center;
  .terms {
    position: absolute;
    bottom: 50px;
  }
  button {
    padding: 12px 30px;
  }
  a {
    color: ${(props) => props.theme.ga.magenta};
    text-decoration: none;
    font-family: inherit;
    font-weight: bold;
    font-size: 100%;
    border: 0;
    padding: 0 10px;
  }
`;
