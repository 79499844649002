// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDeviceConstraints = () => {
  return {
    audio: true,
    video: {
      echoCancellation: true,
      noiseSuppression: true,
      width: { ideal: 1280 },
      height: { ideal: 720 },
      facingMode: 'user',
    },
  };
};
