export const SiteRoutes = [
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Schedule',
    path: '/schedule',
  },
];

export type SelectedUser = {
  userId: number;
  role: number;
  isOwner: boolean;
};

// eslint-disable-next-line no-shadow
export enum ModalVersion {
  REMOVE_MEMBER,
  INVITE_FORM,
  LEAVE_MEMBERSHIP,
}
