/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useOutsideClick = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ref: any,
  isOpen: boolean,
  handleOutsideClick: () => void,
): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        handleOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, isOpen]);
};
