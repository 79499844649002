import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, H3, Spinner, TextSuccess } from 'modules/ui';
import { Paths } from 'paths';
import { authResponse } from 'modules/auth/redux/selectors';
import { AdminSignupForm, AuthLayout } from 'modules/auth/components';
import { VerifyAdminData } from 'modules/auth/types';
import { AuthDictionary } from 'modules/auth/dictionary';
import { resendVerifyEmail, verifyAdminAndSetPassword } from '../../redux';
import { FormContainer, Header, LoginRedirectContainer } from '../styled';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const VerifyAdmin: React.FC = React.memo(() => {
  const verifyResponse = useSelector(authResponse);
  const query = useQuery();
  const token = query.get('token') || '';
  const email = query.get('email') || '';
  const dispatch = useDispatch();
  const history = useHistory();
  const [resentEmail, setResentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const verifySuccess = verifyResponse.success;

  const redirectToLogin = () => {
    history.push(Paths.UserLogin);
  };

  const handleResendEmail = async () => {
    await dispatch(
      resendVerifyEmail({
        email,
      }),
    );
    setResentEmail(true);
  };

  const onSubmit = useCallback(
    async (data: VerifyAdminData) => {
      setLoading(true);
      const { password } = data;
      await dispatch(
        verifyAdminAndSetPassword({
          email,
          password,
          token,
        }),
      );
      setSubmitted(true);
      setLoading(false);
    },
    [dispatch, verifyAdminAndSetPassword],
  );

  const resendEmailButton = (
    <>
      {!resentEmail && (
        <Button isLink label="Resend email" clickHandler={handleResendEmail} />
      )}
      {resentEmail && (
        <TextSuccess>{AuthDictionary.EMAIL_RESENT_SUCCESS}</TextSuccess>
      )}
    </>
  );

  if (loading) {
    return (
      <LoginRedirectContainer>
        <Spinner />
      </LoginRedirectContainer>
    );
  }

  if (!submitted) {
    return (
      <FormContainer>
        <Header>
          <H3>{AuthDictionary.SETUP_YOUR_ACCOUNT}</H3>
        </Header>
        <AdminSignupForm email={email} onSubmit={onSubmit} />
      </FormContainer>
    );
  }

  return (
    <AuthLayout>
      {token && verifySuccess && (
        <LoginRedirectContainer>
          <H3>Your account is successfully verified.</H3>
          <Button label="Log in" clickHandler={redirectToLogin} />
        </LoginRedirectContainer>
      )}
      {token && !verifySuccess && !loading && (
        <LoginRedirectContainer>
          <H3>There was an error verifying your email</H3>
          {resendEmailButton}
        </LoginRedirectContainer>
      )}
    </AuthLayout>
  );
});
