import styled from 'styled-components';

export const JoinSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  button {
    margin: 20px 0;
    padding: 12px 30px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.ga.darkBlue};
  color: ${({ theme }) => theme.ga.white};
  padding: 8px 16px 0px 16px;
`;

export const FormContainer = styled.div`
  flex-direction: column;
  max-width: 444px;
  width: 100%;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;

  label {
    margin-bottom: 60px;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  .terms {
    margin-top: 10px;
  }

  button {
    padding: 12px 71px;
    margin: 27px 0px 32px 0px;
  }
`;
