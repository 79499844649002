import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductSelectors, ProductActions, categories } from 'modules/client';
import {
  NoEventsHero,
  CurrentEventsHero,
  CategoryItem,
} from '../../components';
import {
  LiveEventsContainer,
  HeaderBG,
  BodyBG,
  PageTitle,
  CategoriesGrid,
} from './styled';

export const UpcomingLiveEvents: React.FC = React.memo(() => {
  const events = useSelector(ProductSelectors.getUpcomingEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProductActions.getUpcomingEvents());
  }, [dispatch]);

  return (
    <>
      <HeaderBG>
        {events.length ? (
          <CurrentEventsHero events={events} />
        ) : (
          <NoEventsHero />
        )}
      </HeaderBG>
      <BodyBG>
        <LiveEventsContainer>
          <PageTitle>GalaxyCon Live Events</PageTitle>
          <CategoriesGrid>
            {categories.map((category) => (
              <CategoryItem key={category.name} category={category} />
            ))}
          </CategoriesGrid>
        </LiveEventsContainer>
      </BodyBG>
    </>
  );
});
