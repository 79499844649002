import styled from 'styled-components';
import { ColumnContainer, H4, Label, RowContainer } from 'modules/ui';

export const Container = styled(ColumnContainer)`
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.ga.white};
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
`;

export const Header = styled(RowContainer)`
  align-items: flex-start;
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.ga.grayBackground};

  img {
    min-width: 164px;
    height: 92px;
    object-fit: contain;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.ga.grayBackground};
  }

  svg {
    path,
    rect {
      fill: ${({ theme }) => theme.ga.darkBlue};
    }
  }
`;

export const Info = styled(ColumnContainer)`
  width: 100%;
  padding: 0 10px 0px 20px;

  ${H4} {
    margin: 8px 0px 8px 0px;
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

export const DateContainer = styled(RowContainer)`
  align-items: center;
  span {
    margin-left: 6px;
  }
`;

export const MoreContainer = styled(RowContainer)`
  margin: 0px 0px 0px auto;
`;

export const MergeInfo = styled(RowContainer)`
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  padding: 0px 8px;
  border-radius: 2px;
  max-height: 32px;
  color: ${({ theme }) => theme.ga.blue};
  background: ${({ theme }) => theme.ga.grayBackground};

  ${Label} {
    cursor: pointer;
  }
`;

export const LinkContainer = styled.a``;

export const SessionLink = styled.span`
  color: ${({ theme }) => theme.ga.cyan};
  padding-right: 10px;
`;
