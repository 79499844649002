import styled, { css } from 'styled-components';
import { BtnText } from 'modules/ui';

export const ButtonContainer = styled.button<{
  isSecondary: boolean;
  isTertiary: boolean;
  isMeeting: boolean;
  isDark: boolean;
  isBlue: boolean;
  isLink: boolean;
  disabled: boolean;
}>`
  background-color: ${({ theme }) => theme.ga.magenta};
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ disabled, isMeeting, theme }) =>
    !isMeeting &&
    css`
      ${BtnText} {
        color: ${disabled ? theme.ga.whiteHalfOpacity : theme.ga.white};
      }
    `}

  // Main Secondary
  ${({ isBlue, disabled, theme, isMeeting }) =>
    !isMeeting &&
    isBlue &&
    css`
      background-color: ${theme.ga.secondaryButton};
      border: none;

      ${BtnText} {
        color: ${disabled ? theme.ga.whiteHalfOpacity : theme.ga.white};
      }
    `}

  // Main Secondary
  ${({ isDark, isMeeting, theme }) =>
    !isMeeting &&
    isDark &&
    css`
      background-color: ${theme.ga.grayButton};
      border: none;

      ${BtnText} {
        color: ${theme.ga.darkBlue};
      }
    `}

  // Shopping Blue Button
  ${({ isBlue, theme, isMeeting }) =>
    !isMeeting &&
    isBlue &&
    css`
      background-color: ${theme.ga.mediumBlue};
      border: none;

      ${BtnText} {
        color: ${theme.ga.white};
      }
    `}

  // Main Secondary
  ${({ isSecondary, isMeeting, theme }) =>
    isSecondary &&
    !isMeeting &&
    css`
      background-color: ${theme.ga.white};
      border: 1px solid ${theme.ga.magenta};

      ${BtnText} {
        color: ${theme.ga.magenta};
      }

      svg path {
        fill: ${theme.ga.magenta};
      }
    `}

  // Main Tertiary
  ${({ isMeeting, isTertiary, theme }) =>
    isTertiary &&
    !isMeeting &&
    css`
      background-color: transparent;
      border: 1px dashed ${theme.ga.magenta};

      ${BtnText} {
        color: ${theme.ga.magenta};
      }
      svg path {
        fill: ${theme.ga.magenta};
      }
    `}

  & > svg {
    margin-right: 8px;
  }

  // Meeting Primary
  ${({ isMeeting, isSecondary, isTertiary, theme }) =>
    isMeeting &&
    !isTertiary &&
    !isSecondary &&
    css`
      color: ${theme.ga.magenta};
      background: transparent;
      border: 1px solid ${theme.ga.magenta};
    `}

  // Meeting Secondary
  ${({ isMeeting, isSecondary, isTertiary, theme }) =>
    isMeeting &&
    isSecondary &&
    !isTertiary &&
    css`
      color: ${theme.ga.white};
      background: rgba(0, 0, 0, 0.5);
      border: none;
    `}

  //Meeting Tertiary
  ${({ isMeeting, isSecondary, isTertiary, theme }) =>
    isMeeting &&
    !isSecondary &&
    isTertiary &&
    css`
      color: ${theme.ga.magenta};
      background: rgba(0, 0, 0, 0.5);
      border: none;
    `}

  //Link Button
  ${({ isLink, isMeeting, theme }) =>
    isLink &&
    css`
      color: ${theme.ga.magenta};
      background-color: transparent;
      border: ${isMeeting ? `1px solid ${theme.ga.magenta}` : 'none'};

      ${BtnText} {
        color: ${theme.ga.magenta};
      }
    `}
`;
