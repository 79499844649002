import { useState, useCallback } from 'react';

export const useModal = (
  initialValue: boolean,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);

  const openModal = useCallback(() => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  }, []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  }, []);
  return [isOpen, openModal, closeModal];
};
