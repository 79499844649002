import styled from 'styled-components';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 26px 20px 56px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.ga.white};

  .text {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 260px;
  margin: 60px auto 0 auto;
`;
