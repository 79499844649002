import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${({ theme }) => theme.ga.darkBlue};
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.ga.white};
  text-align: center;
  max-width: 635px;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  margin-top: 130px;
`;

export const SubtitleContainer = styled.div`
  margin-top: 50px;
`;
