import styled from 'styled-components';
import { H4Bold, TextItalic } from 'modules/ui';
import { device } from 'modules/utils';

export const VideoUnavalableDescription = styled(TextItalic)`
  color: ${({ theme }) => theme.ga.pink};
  line-height: 18px;
`;

export const VideoTitle = styled(H4Bold)`
  color: ${({ theme }) => theme.ga.darkBlue};
  margin-block-start: 22px;
  margin-block-end: 16px;
`;

export const VideoItem = styled.div`
  background-color: ${({ theme }) => theme.ga.grayBackground};
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 4px;

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const Header = styled.div`
  flex-direction: row;
  display: flex;
`;

export const DateContainer = styled.div`
  flex: auto;
`;

export const CalendarIconContainer = styled.span`
  opacity: 0.7;
  margin-right: 12.5px;

  svg {
    path {
      fill: ${({ theme }) => theme.ga.black};
    }
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const VideoControlsContainer = styled.div`
  width: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
