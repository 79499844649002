import React, { useState } from 'react';
import styled from 'styled-components';
import useDimensions from 'react-cool-dimensions';

export enum HorizontalAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum VerticalAlign {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
}

interface ContainerAlignment {
  horizontalAlign: HorizontalAlign;
  verticalAlign: VerticalAlign;
}

interface Size {
  width: number;
  height: number;
}

interface InnerContainerProps extends ContainerAlignment, Size {}

const resolveTop = ({ verticalAlign }: InnerContainerProps) => {
  switch (verticalAlign) {
    case VerticalAlign.Top:
      return '0';
    case VerticalAlign.Center:
      return '50%';
    default:
      return 'auto';
  }
};

const resolveBottom = ({ verticalAlign }: InnerContainerProps) => {
  switch (verticalAlign) {
    case VerticalAlign.Bottom:
      return '0';
    case VerticalAlign.Center:
      return '50%';
    default:
      return 'auto';
  }
};

const resolveLeft = ({ horizontalAlign }: InnerContainerProps) => {
  switch (horizontalAlign) {
    case HorizontalAlign.Left:
      return '0';
    case HorizontalAlign.Center:
      return '50%';
    default:
      return 'auto';
  }
};

const resolveRight = ({ horizontalAlign }: InnerContainerProps) => {
  switch (horizontalAlign) {
    case HorizontalAlign.Right:
      return '0';
    case HorizontalAlign.Center:
      return '50%';
    default:
      return 'auto';
  }
};

const resolveMarginTop = ({ verticalAlign, height }: InnerContainerProps) => {
  switch (verticalAlign) {
    case VerticalAlign.Center:
      return `${-height / 2}px`;
    default:
      return 'auto';
  }
};

const resolveMarginLeft = ({ horizontalAlign, width }: InnerContainerProps) => {
  switch (horizontalAlign) {
    case HorizontalAlign.Center:
      return `${-width / 2}px`;
    default:
      return 'auto';
  }
};

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const InnerContainer = styled.div<InnerContainerProps>`
  position: absolute;

  width: ${({ width }) => width}px;

  top: ${resolveTop};
  bottom: ${resolveBottom};
  left: ${resolveLeft};
  right: ${resolveRight};

  margin-top: ${resolveMarginTop};
  margin-left: ${resolveMarginLeft};
`;

interface Props extends ContainerAlignment {
  ratio: number;
}

export const FitContain: React.FC<Props> = ({
  ratio,
  horizontalAlign,
  verticalAlign,
  children,
}) => {
  const [innerSize, setInnerSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const { ref } = useDimensions<HTMLDivElement>({
    onResize: ({ width, height }) => {
      const containerAspectRatio = width / height;
      const touchingVertically = containerAspectRatio > ratio;

      let contentHeight;
      let contentWidth;

      if (touchingVertically) {
        contentHeight = height;
        contentWidth = ratio * height;
      } else {
        contentWidth = width;
        contentHeight = width / ratio;
      }

      setInnerSize({ width: contentWidth, height: contentHeight });
    },
  });

  return (
    <OuterContainer ref={ref}>
      <InnerContainer
        horizontalAlign={horizontalAlign}
        verticalAlign={verticalAlign}
        {...innerSize}
      >
        {children}
      </InnerContainer>
    </OuterContainer>
  );
};
