import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { H5, CameraVideoIcon, TextRegular, SvgButton } from 'modules/ui';

export const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  padding: 124px 60px 132px 60px;
  box-sizing: border-box;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  /* z-index: 10; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PlayerContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.ga.white};

  .MuiSlider-root {
    color: ${({ theme }) => theme.ga.pink};
  }

  .MuiSlider-rail {
    color: #383838;
    opacity: 1;
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-track {
    opacity: 1;
    height: 4px;
    border-radius: 2px;
  }

  video {
    object-fit: cover;
    outline: none;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.ga.white};
    }
  }
`;

export const Player = styled(ReactPlayer)`
  overflow: hidden;
  /* z-index: 20; */
`;

export const TitleContainer = styled.div`
  height: 64px;
  position: absolute;
  top: -64px;
  width: 100%;
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.ga.darkBlue};
  border-radius: 8px 8px 0px 0px;
`;

export const CameraIcon = styled(CameraVideoIcon)`
  margin: auto 24px;
`;

export const CloseButton = styled.button`
  padding: 0 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const Title = styled(H5)`
  flex: auto;
  margin-block-start: auto;
  margin-block-end: auto;
`;

export const PlayPauseButton = styled(SvgButton)`
  margin: auto 0 auto 12px;
`;

export const VideoControlsContainer = styled.div`
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  bottom: -72px;
  width: 100%;

  background-color: ${({ theme }) => theme.ga.darkBlue};
  border-radius: 0px 0px 8px 8px;
`;

export const Timestamp = styled(TextRegular)`
  margin: auto 16px;
`;
