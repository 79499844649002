import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { CountdownTimer, CommonMeetingSelectors } from 'modules/common-meeting';
import { TalentSelectors } from '../../redux';
import LabeledInfo from '../LabeledInfo';
import { Container, Title, Subtitle, Footer } from './styled';

const FanInfoModal: React.FC = () => {
  const announcementSecondsLeft = useSelector(
    CommonMeetingSelectors.getAnnouncementSecondsLeft,
  );
  const fanParticipantName = useSelector(
    CommonMeetingSelectors.getNameForFanParticipantInAnnouncement,
  );

  const whereFromAnswer = useSelector(
    TalentSelectors.whereFromFanQuestionnaireAnswerSelector,
  );
  const favouriteMovieAnswer = useSelector(
    TalentSelectors.favouriteMovieFanQuestionnaireAnswerSelector,
  );
  const talkAboutAnswer = useSelector(
    TalentSelectors.talkAboutFanQuestionnaireAnswerSelector,
  );

  const hasNoAnswers =
    !whereFromAnswer && !favouriteMovieAnswer && !talkAboutAnswer;
  const modalTitle = hasNoAnswers
    ? `Unfortunately, ${fanParticipantName} didn't answer the questionnaire.`
    : ' See your fan answers! It’s a nice way to start a conversation.';

  return (
    <Container>
      <Title>Meet your fan!</Title>
      <Subtitle>{modalTitle}</Subtitle>
      {!!fanParticipantName && (
        <LabeledInfo label="FAN NAME" value={fanParticipantName} />
      )}
      {!!whereFromAnswer && (
        <LabeledInfo label="WHERE ARE YOU FROM" value={whereFromAnswer} />
      )}
      {!!favouriteMovieAnswer && (
        <LabeledInfo
          label="WHAT ARE YOU A FAN OF?"
          value={favouriteMovieAnswer}
        />
      )}
      {!!talkAboutAnswer && (
        <LabeledInfo
          label="WHAT WOULD YOU TALK ABOUT TODAY?"
          value={talkAboutAnswer}
        />
      )}
      <Footer>
        <CountdownTimer
          inputRedZone={0}
          timeLeft={announcementSecondsLeft}
          initialTime={5}
        />
      </Footer>
    </Container>
  );
};

export default memo(FanInfoModal);
