import { createReducer, combineReducers, unwrapResult } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { LobbyInfo } from '../types';
import * as LobbyActions from './actions';

export const info = createReducer({} as LobbyInfo, (builder) => {
  builder.addCase(LobbyActions.getWaitingRoomInfo.fulfilled, (_state, action) =>
    unwrapResult(action),
  );
});

export const questionnaire = createReducer(true, (builder) => {
  builder
    .addCase(LobbyActions.hideQuestionnaire, () => false)
    .addCase(LobbyActions.clearAttendeeData, () => true);
});

export const reducer = combineReducers({
  info,
  questionnaire,
});

export type Lobby = ReturnType<typeof reducer>;

const persistConfig: PersistConfig<Lobby> = {
  key: 'lobby',
  storage,
};

export const lobbyReducer = persistReducer(persistConfig, reducer);
