import {
  ColumnContainer,
  H1,
  H2Regular,
  H5,
  RowContainer,
  SmallCaption,
} from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const Container = styled(RowContainer)<{ isMobileSafari: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.ga.darkGray};

  @media ${device.laptop} {
    flex-direction: column;
    height: ${({ isMobileSafari }) => (!isMobileSafari ? 'auto' : 'inherit')};
    background: ${({ theme }) => theme.ga.darkBlue};
    padding: 45px 22px 0;
    box-sizing: border-box;
  }
`;

export const PositionInfo = styled(ColumnContainer)`
  @media ${device.tablet} {
    display: none;
  }

  display: flex;
  flex-grow: 1;
`;

export const PositionInfoMobile = styled(RowContainer)`
  display: none;
  color: white;
  justify-content: space-between;

  ${H2Regular} {
    margin-top: 0px;
    color: ${({ theme }) => theme.ga.cyan};
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
  }
`;
export const SessionStartInfo = styled(ColumnContainer)``;

export const InfoContainer = styled(ColumnContainer)`
  min-width: 512px;
  height: 100%;
  padding: 63px 76px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.ga.darkBlue};
  color: ${({ theme }) => theme.ga.white};
  display: flex;

  ${H1} {
    color: ${({ theme }) => theme.ga.cyan};
  }

  ${H5} {
    margin-bottom: 0px;
  }

  @media ${device.tablet} {
    min-width: auto;
    width: 100%;
    padding: 10px;
    height: auto;
  }
`;

export const VideosContainer = styled(ColumnContainer)`
  padding: 85px 76px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${SmallCaption} {
    color: ${({ theme }) => theme.ga.white};
  }

  @media ${device.tablet} {
    max-width: 100%;
    padding: 10px;
    height: auto;
  }
`;

export const PlayerContainer = styled.div`
  width: 544px;
  height: 306px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const PreviewVideoContainer = styled.div`
  video {
    width: 320px;
    height: 213px;
  }

  @media ${device.tablet} {
    max-width: 100%;
    height: auto;
    video {
      width: 100%;
    }
  }
`;

export const ChatPopup = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  border-radius: 100px;
  background: ${({ theme }) => theme.ga.pink};
`;

export const InfoCardMobileContainer = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const ChatWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;

  @media ${device.tablet} {
    position: unset;
  }
`;
