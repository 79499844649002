import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Howl } from 'howler';
import callAnnouncementSound from 'modules/ui/assets/sounds/call-announcement.mp3';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { H2, H6, Modal, VideoCountdown } from 'modules/ui';
import { Container, ImageContainer } from './styled';

type Props = {
  isOpen: boolean;
};

export const CallAnnouncement: React.FC<Props> = React.memo(({ isOpen }) => {
  const announcementsSecondsLeft = useSelector(
    CommonMeetingSelectors.getAnnouncementSecondsLeft,
  );
  const [countdown, setCountdown] = useState(announcementsSecondsLeft);

  const talentName = useSelector(CommonMeetingSelectors.talentName);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((current) => current - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown, setCountdown]);

  useEffect(() => {
    const sound = new Howl({
      src: callAnnouncementSound,
      loop: true,
    });
    sound.play();

    return () => {
      sound.stop();
    };
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        width={632}
        height={485}
        fullWidth={false}
        closeModal={() => null}
        onRequestClose={() => null}
        isDark
        hideOverlayColor
      >
        <Container>
          <H2>Be ready!</H2>
          <H6>{`You will meet ${talentName} in`}</H6>
          <ImageContainer>
            <H2>{countdown}</H2>
            <img src={VideoCountdown} alt="countdown" />
          </ImageContainer>
        </Container>
      </Modal>
    </>
  );
});
