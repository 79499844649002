import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { Channel, Message, Client } from 'twilio-chat';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { AuthSelectors } from 'modules/auth';
import { v4 as uuidv4 } from 'uuid';
import * as ChatSelectors from './selectors';
import * as ChatActions from './actions';

import {
  MessageWithAttributes,
  TwilioMessageType,
  OutgoingMessage,
  QueuedOutgoingMessage,
} from '../types';

const CHANNEL_NOT_FOUND_ERROR_CODE = 50300;
const CHANNEL_ALREADY_EXISTS_ERROR_CODE = 50307;

const createMessagingClient = (token: string): Promise<Client> => {
  return new Promise((resolve, reject) => {
    const client = new Client(token);
    client.on('stateChanged', (state) => {
      switch (state) {
        case 'initialized':
          resolve(client);
          break;
        case 'failed':
          reject();
          break;
        default:
          break;
      }
    });
  });
};

const getRemoteChannel = (client: Client, channelName: string) =>
  client
    .getChannelByUniqueName(channelName)
    .catch((error) => {
      if (error?.body?.code === CHANNEL_NOT_FOUND_ERROR_CODE) {
        return client.createChannel({
          uniqueName: channelName,
          isPrivate: false,
        });
      }

      throw error;
    })
    .catch((error) => {
      // handles case when two clients try to create a channel at the same time
      if (error?.body?.code === CHANNEL_ALREADY_EXISTS_ERROR_CODE) {
        return client.getChannelByUniqueName(channelName);
      }

      throw error;
    })
    .then((channelVar) => {
      if (channelVar.status === 'joined') {
        return channelVar;
      }

      return channelVar.join();
    });

const subscribeToNewMessages = (store: MiddlewareAPI, channel: Channel) => {
  const userEmail = AuthSelectors.getUserEmail(store.getState());

  channel.on('messageAdded', (rawMessage: Message) => {
    const message = rawMessage as MessageWithAttributes; // todo: fix typing

    const chatId = channel.uniqueName;
    store.dispatch(
      ChatActions.setTwilioMessage({
        chatId,
        message: {
          id: message.sid,
          content: message.body,
          role: message.attributes.senderRole,
          time: message.dateCreated.getTime(),
          name: message.attributes.senderName,
          new: message.author !== userEmail,
        },
      }),
    );
  });
};

const getPastMessages = async (store: MiddlewareAPI, channel: Channel) => {
  const pastChannelMessages = (await channel
    .getMessages(100)
    .then((paginator) => paginator.items)) as MessageWithAttributes[]; // todo: fix typing

  const chatId = channel.uniqueName;
  const userEmail = AuthSelectors.getUserEmail(store.getState());

  const messages: TwilioMessageType[] = pastChannelMessages.map((message) => ({
    id: message.sid,
    content: message.body,
    role: message.attributes.senderRole,
    time: message.dateCreated.getTime(),
    name: message.attributes.senderName,
    new: message.author !== userEmail,
  }));

  store.dispatch(ChatActions.loadChannelPage({ chatId, messages }));
};

const sendMessage = (
  store: MiddlewareAPI,
  channel: Channel,
  outgoingMessage: OutgoingMessage,
) => {
  const senderName = CommonMeetingSelectors.getLoggedInParticipantName(
    store.getState(),
  );
  const { message, senderRole } = outgoingMessage;

  return channel.sendMessage(message, {
    senderRole,
    senderName,
  });
};

const sendMessagesInOutgoingQueue = (
  store: MiddlewareAPI,
  channel: Channel,
) => {
  const outgoingMessagesQueue = ChatSelectors.getOutgoingMessagesQueue(
    store.getState(),
  );

  outgoingMessagesQueue[channel.uniqueName]?.forEach(
    (queuedOutgoingMessage: QueuedOutgoingMessage) => {
      sendMessage(store, channel, queuedOutgoingMessage.message);
      store.dispatch(ChatActions.removeMessageFromQueue(queuedOutgoingMessage));
    },
  );
};

const joinChannel = async (
  store: MiddlewareAPI,
  client: Client,
  channelName: string,
  channelCache: Map<string, Channel>,
) => {
  let channel = channelCache.get(channelName);

  if (channel === undefined) {
    channel = await getRemoteChannel(client, channelName);
    channelCache.set(channelName, channel);
    subscribeToNewMessages(store, channel);
  }

  sendMessagesInOutgoingQueue(store, channel);
  getPastMessages(store, channel);
};

export const createTwilioMiddleware = (): Middleware => {
  let clientOrNull: Client | null = null;
  let channels = new Map<string, Channel>();

  return (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (
    action: AnyAction,
  ): Promise<unknown> => {
    switch (action.type) {
      case ChatActions.connect.type: {
        store.dispatch(
          (ChatActions.fetchTwilioAccessToken() as unknown) as AnyAction,
        );

        return next(action);
      }

      case ChatActions.disconnect.type: {
        if (clientOrNull === null) {
          return next(action);
        }

        const client = clientOrNull;
        client.shutdown();

        clientOrNull = null;
        channels = new Map<string, Channel>();

        return next(action);
      }

      case ChatActions.fetchTwilioAccessToken.fulfilled.type: {
        const {
          result: { accessToken },
        } = action.payload;

        const client = await createMessagingClient(accessToken);
        const channelNames = ChatSelectors.getChannelNames(store.getState());

        clientOrNull = client;
        await Promise.all(
          channelNames.map((channelName) =>
            joinChannel(store, client, channelName, channels),
          ),
        );

        return next(action);
      }

      case ChatActions.joinChannel.type: {
        const output = next(action);

        const channelName = action.payload;

        if (clientOrNull !== null) {
          const client = clientOrNull;
          await joinChannel(store, client, channelName, channels);
        }

        return output;
      }

      case ChatActions.sendMessage.type: {
        const { channelName } = action.payload;

        const channel = channels.get(channelName);

        if (channel) {
          sendMessage(store, channel, action.payload);

          return next(action);
        }

        return next(
          ChatActions.enqueueOutgoingMessage({
            message: action.payload,
            id: uuidv4(),
          }),
        );
      }

      default:
        return next(action);
    }
  };
};
