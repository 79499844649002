import styled from 'styled-components';
import { H1, TextItalic } from 'modules/ui';
import { device } from 'modules/utils';

export const ContentWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`;

export const Title = styled(H1)`
  @media ${device.mobile} {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
`;

export const Subtitle = styled(TextItalic)`
  color: ${({ theme }) => theme.ga.grayForms};
  opacity: 0.5;
`;

export const VideosContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;
