import { Urls } from 'modules/main';
import { get, create } from 'modules/api';
import {
  AuthBody,
  Region,
  RegisterBody,
  ResetPasswordData,
} from 'modules/auth';
import { Response } from 'modules/utils';
import {
  ChangePasswordData,
  ForgotPasswordData,
  UpdateProfile,
  AuthResult,
  VerifyEmailData,
  ResendVerifyEmailData,
  VerifyAdminData,
} from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UserProfile {
  userId: number;
  firstName: string;
  lastName: string;
  role: number;
  email: string;
}
export const forgotPasswordRequest = (
  body: ForgotPasswordData,
): Promise<Response<unknown>> => {
  return create(Urls.FORGOT_PASSWORD, body);
};

export const resetPasswordRequest = (
  body: ResetPasswordData,
): Promise<Response<unknown>> => {
  return create(Urls.RESET_PASSWORD, body);
};

export const verifyEmailRequest = (
  body: VerifyEmailData,
): Promise<Response<unknown>> => {
  return create<VerifyEmailData, unknown>(Urls.VERIFY_EMAIL, body);
};

export const changePasswordRequest = (
  body: ChangePasswordData,
): Promise<Response<unknown>> => {
  return create(Urls.CHANGE_PASSWORD, body);
};

export const changeProfileRequest = (
  body: UpdateProfile,
): Promise<Response<unknown>> => {
  return create(Urls.UPDATE, body);
};

export const authRequest = (body: AuthBody): Promise<Response<AuthResult>> => {
  return create(Urls.AUTH, body);
};

export const createUserRequest = (
  body: RegisterBody,
): Promise<Response<unknown>> => {
  return create(Urls.REGISTER, body);
};

export const getNearestMediaRegion = (): Promise<Response<Region>> => {
  return get<Region>(Urls.GET_NEAREST_MEDIA_REGION);
};

export const resendVerifyEmailRequest = (
  body: ResendVerifyEmailData,
): Promise<Response<unknown>> => {
  return create(Urls.RESEND_VERIFICATION_EMAIL, body);
};

export const verifyAdminAndSetPasswordRequest = (
  body: VerifyAdminData,
): Promise<Response<unknown>> => {
  return create(Urls.VERIFY_ADMIN_AND_SET_PASSWORD, { ...body, force: true });
};
