import React, { useEffect, useState } from 'react';
import {
  useAudioInputs,
  useAudioOutputs,
  useVideoInputs,
  useMediaStreamMetrics,
  useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react';
// eslint-disable-next-line import/no-unresolved
import { DeviceType } from 'amazon-chime-sdk-component-library-react/lib/types';
import { Container } from './styled';

export const DevicesDebugger: React.FC = () => {
  const [renderDiagnostics, setRenderDiagnostics] = useState<boolean>(false);

  const {
    devices: audioOutputs,
    selectedDevice: selectedAudioOutput,
    selectDeviceError: selectAudioOutputError,
  } = useAudioOutputs();
  const {
    devices: audioInputs,
    selectedDevice: selectedAudioInput,
    selectDeviceError: selectAudioInputError,
  } = useAudioInputs();
  const {
    devices: videoDevices,
    selectedDevice: selectedVideoDevice,
    selectDeviceError: selectVideoDeviceError,
  } = useVideoInputs();
  const metrics = useMediaStreamMetrics();
  const meetingStatus = useMeetingStatus();

  function downHandler({ ctrlKey, key }: { key: string; ctrlKey: boolean }) {
    if (ctrlKey && key === 'd') {
      setRenderDiagnostics(!renderDiagnostics);
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [renderDiagnostics]);

  const renderMeetingStatus = () => {
    return (
      <p>
        Status:
        {meetingStatus}
      </p>
    );
  };

  const renderDevices = (
    label: string,
    devices: DeviceType[],
    selectedDeviceId: string | null,
    selectDeviceError: Error | null | undefined,
  ) => {
    const items = devices.map((device) => (
      <li key={device.deviceId}>
        {device.deviceId === selectedDeviceId && <span>(*) </span>}
        {device.label}
      </li>
    ));

    return (
      <div>
        <p>{label}</p>
        <ul>{items}</ul>
        {selectDeviceError && <p>{selectDeviceError.message}</p>}
      </div>
    );
  };

  const renderBandwidth = () => {
    return (
      <div>
        <p>
          UL loss:
          {metrics.audioPacketsSentFractionLossPercent}
        </p>
        <p>
          DL loss:
          {metrics.audioPacketsReceivedFractionLossPercent}
        </p>
        <p>
          Bandwidth Incoming:
          {metrics.availableIncomingBandwidth}
        </p>
        <p>
          Bandwidth Outgoing:
          {metrics.availableOutgoingBandwidth}
        </p>
      </div>
    );
  };

  if (!renderDiagnostics) {
    return null;
  }

  return (
    <Container>
      {renderMeetingStatus()}
      {renderDevices(
        'AudioIN',
        audioInputs,
        selectedAudioInput,
        selectAudioInputError,
      )}
      {renderDevices(
        'AudioOUT',
        audioOutputs,
        selectedAudioOutput,
        selectAudioOutputError,
      )}
      {renderDevices(
        'Video',
        videoDevices,
        selectedVideoDevice,
        selectVideoDeviceError,
      )}
      {renderBandwidth()}
    </Container>
  );
};
