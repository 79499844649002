import { Urls } from 'modules/main';
import { get } from 'modules/api';
import { Response } from 'modules/utils';
import { LiveProductType, OrderEvent } from '../types';

export const getProductsRequest = (): Promise<Response<LiveProductType[]>> => {
  return get(Urls.GET_PRODUCTS);
};

export const getUpcomingEventsRequest = (): Promise<Response<OrderEvent[]>> =>
  get(Urls.GET_NEXT_SESSIONS);
