import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
} from 'modules/common-meeting';

export const useSendNextFanToMainSession = (): {
  sendNextFanToMainSession: () => void;
  actionDisabled: boolean;
} => {
  const dispatch = useDispatch();
  const bpsIdForFanNextForMainCall = useSelector(
    CommonMeetingSelectors.getBpsIdForFanParticipantNextForMainCall,
  );
  const isSendNextFanToCallWithTalentEnabled = useSelector(
    CommonMeetingSelectors.isSendNextFanToCallWithTalentEnabled,
  );
  const waitingRoomGuid = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );

  const sendNextFanToMainSession = useCallback(() => {
    if (isSendNextFanToCallWithTalentEnabled && bpsIdForFanNextForMainCall) {
      dispatch(
        CommonMeetingActions.sendFanToCallWithTalent({
          bookedParticipantSlotId: bpsIdForFanNextForMainCall,
          waitingRoomGuid,
        }),
      );
    }
  }, [
    bpsIdForFanNextForMainCall,
    dispatch,
    isSendNextFanToCallWithTalentEnabled,
  ]);

  return {
    sendNextFanToMainSession,
    actionDisabled: !isSendNextFanToCallWithTalentEnabled,
  };
};
