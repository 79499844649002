import { useLocalVideo } from 'amazon-chime-sdk-component-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonMeetingSelectors, CommonMeetingActions } from '../redux';
import { ConsentStatus } from '../consts';

export const useLocalVideoWithWarning = (): {
  isVideoEnabled: boolean;
  toggleVideo: () => void;
} => {
  const { isVideoEnabled, toggleVideo: toggleLocalVideo } = useLocalVideo();
  const dispatch = useDispatch();
  const permissionStatus = useSelector(
    CommonMeetingSelectors.permissionsStatus,
  );

  const toggleVideo = () => {
    const isRecordingConsentGiven = permissionStatus === ConsentStatus.Given;
    if (!isRecordingConsentGiven) {
      dispatch(CommonMeetingActions.setShouldHidePermissionsModal(false));
      return;
    }

    toggleLocalVideo();
  };

  return { isVideoEnabled, toggleVideo };
};
