import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleLayout } from 'modules/schedule';

import { VideoListItem } from '../VideoListItem';
import { NoVideosPlaceholder } from '../NoVideosPlaceholder';
import { FanVideosActions, FanVideosSelectors } from '../../redux';
import { ContentWrapper, Title, Subtitle, VideosContainer } from './styled';

export const VideoList: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FanVideosActions.getFanVideos());
  }, [dispatch]);

  const fanVideos = useSelector(
    FanVideosSelectors.allFanVideos,
  ).sort((first, second) =>
    first.startDateTime < second.startDateTime ? 1 : -1,
  );
  const hasFanVideos = fanVideos.length > 0;

  if (!hasFanVideos) {
    return <NoVideosPlaceholder />;
  }

  return (
    <ScheduleLayout>
      <ContentWrapper>
        <Title>Video gallery from your sessions</Title>
        <Subtitle>
          Video Chat Recordings are available for 7 days after the events.
        </Subtitle>
        <VideosContainer>
          {fanVideos.map((fanVideo) => (
            <VideoListItem key={fanVideo.id} videoItem={fanVideo} />
          ))}
        </VideosContainer>
      </ContentWrapper>
    </ScheduleLayout>
  );
};
