import React, { useEffect, useRef } from 'react';
import { SmallCaption } from 'modules/ui';

import { DevicePermissionsModal } from 'modules/common-meeting';
import { ChimeDeviceSettings, usePreviewStream } from 'modules/devices';
import { useModal } from 'modules/main';
import { PreviewControls } from '../PreviewControls';
import { Container, Video, VideoContainer } from './styled';

export const PreviewVideo: React.FC = React.memo(() => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isOpen, openModal, closeModal] = useModal(false);

  const {
    stream,
    toggleAudio,
    toggleVideo,
    isAudioEnabled,
    isVideoEnabled,
  } = usePreviewStream();

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef]);

  return (
    <Container>
      <SmallCaption>Check your camera and microphone</SmallCaption>
      <VideoContainer>
        <Video ref={videoRef} muted autoPlay />
        <PreviewControls
          onToggleVideo={toggleVideo}
          onToggleAudio={toggleAudio}
          isAudioEnabled={isAudioEnabled}
          isVideoEnabled={isVideoEnabled}
          onDevicesOpen={openModal}
        />
      </VideoContainer>
      <DevicePermissionsModal />
      {isOpen && <ChimeDeviceSettings isOpen onCloseRequest={closeModal} />}
    </Container>
  );
});
