import { useEffect, useState } from 'react';

export const useCountdown = (
  externalSecondsLeft: number,
  shouldPauseTimer = false,
): {
  secondsLeft: number;
} => {
  const [secondsLeft, setSecondsLeft] = useState(externalSecondsLeft);

  useEffect(() => {
    setSecondsLeft(externalSecondsLeft);
  }, [externalSecondsLeft]);

  useEffect(() => {
    let interval = 0;

    if (!shouldPauseTimer) {
      interval = setInterval(() => {
        if (secondsLeft > 0) {
          setSecondsLeft((currentSecondsLeft) => currentSecondsLeft - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [secondsLeft, shouldPauseTimer]);

  return { secondsLeft };
};
