import React, { useEffect } from 'react';
import { H3, H2 } from 'modules/ui';
import { HeaderWithClock } from '../HeaderWithClock';
import {
  Container,
  TextContainer,
  SubtitleContainer,
  TitleContainer,
} from './styled';

interface SessionStatusProps {
  message: string;
  sessionName: string;
  handleUnmount?: () => void;
}

export const MeetingStatus: React.FC<SessionStatusProps> = React.memo(
  ({ message, sessionName, handleUnmount }) => {
    useEffect(() => {
      return () => {
        if (handleUnmount) {
          handleUnmount();
        }
      };
    }, [handleUnmount]);

    return (
      <Container>
        <HeaderWithClock />
        <TextContainer>
          <TitleContainer>
            <H2>{sessionName}</H2>
          </TitleContainer>
          <SubtitleContainer>
            <H3>{message}</H3>
          </SubtitleContainer>
        </TextContainer>
      </Container>
    );
  },
);
