import { GalaxyConTheme } from 'modules/ui';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export const config = {
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  initialView: 'dayGridDay',
  height: '100%',
  slotDuration: '00:30:00',
  slotLabelInterval: '1:00',
  allDaySlot: false,
  slotLabelClassNames: 'time-label',
  firstDay: 1,
  forceEventDuration: true,
  dayHeaderFormat: {
    weekday: 'short',
    day: 'numeric',
    omitCommas: true,
  },
  headerToolbar: {
    left: 'title',
    center: '',
    right: 'prev next today dayGridMonth timeGridWeek dayGridDay',
  },
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  eventBackgroundColor: GalaxyConTheme.ga.blue,
  eventBorderColor: 'white',
  nowIndicator: true,
};
