import React from 'react';
import { Header } from 'modules/ui';
import { Container, Footer } from './styled';

export const AdminEventLayout: React.FC = React.memo(({ children }) => {
  return (
    <Container>
      <Header isSecondary />
      {children}
      <Footer />
    </Container>
  );
});
