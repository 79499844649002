import { createAction } from '@reduxjs/toolkit';
import { createAsyncApiThunk } from 'modules/adapter';
import { getWaitingRoomInfoRequest } from '../api';

export const getWaitingRoomInfo = createAsyncApiThunk(
  'GET_WAITING_ROOM_INFO',
  getWaitingRoomInfoRequest,
);

export const openQuestionnaire = createAction('SHOW_QUESTIONNAIRE');
export const hideQuestionnaire = createAction('HIDE_QUESTIONNAIRE');
export const clearAttendeeData = createAction('CLEAR_DATA');
