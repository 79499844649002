import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { QuestionnaireFormData } from 'modules/common-meeting';
import { LiveProductType, OrderEvent } from '../types';
import {
  getProducts,
  getUpcomingEvents,
  saveQuestionnaireAnswers,
} from './actions';

export const productList = createReducer([] as LiveProductType[], (builder) => {
  builder.addCase(getProducts, (_state, action) => action.payload);
});

export const upcomingEvents = createReducer([] as OrderEvent[], (builder) => {
  builder.addCase(
    getUpcomingEvents.fulfilled,
    (_state, action) => action.payload.result,
  );
});

export const questionnaireAnswers = createReducer(
  {} as QuestionnaireFormData,
  (builder) => {
    builder.addCase(
      saveQuestionnaireAnswers,
      (draft, action) => action.payload,
    );
  },
);

const reducer = combineReducers({
  productList,
  upcomingEvents,
  questionnaireAnswers,
});

export const productReducer = reducer;
