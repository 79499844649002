import { ColumnContainer, EventTitle, TextSmall } from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  position: relative;
  padding: 16px 18px;
  width: 100%;

  ${TextSmall} {
    text-decoration: uppercase;
    color: ${({ theme }) => theme.ga.darkBlue};
    margin-bottom: 6px;
  }

  ${EventTitle} {
    margin: 8px 0px 24px 0px;
  }

  button {
    width: 100%;
    margin-bottom: 8px;
  }

  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;
