import styled from 'styled-components';
import { device } from 'modules/utils';

export const UserOrdersContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  width: 800px;
  padding-right: 100px;
  padding-left: 100px;

  label {
    margin-bottom: 60px;
  }

  @media ${device.laptop} {
    padding-left: 5%;
    padding-right: 50px;
  }

  @media ${device.tablet} {
    width: 85%;
    margin: 0 auto;
    padding: 0;
  }

  @media ${device.mobile} {
    h1 {
      display: none;
    }
  }
`;
