import { device } from 'modules/utils';
import styled from 'styled-components';
import { RowContainer, BtnText } from 'modules/ui';

import { Countdown } from '../CountdownTimer';

export const IconBtn = styled.button`
  padding: 10px;
  margin-right: 0px;
  color: ${(props) => props.theme.ga.green};
  background: ${({ theme }) => theme.ga.semiDarkGray};
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ChatIconBtn = styled.button`
  display: none;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const AddSeconds = styled.div<{ disabled: boolean }>`
  padding: 10px 24px;
  color: ${(props) => props.theme.ga.green};
  background: ${({ theme }) => theme.ga.semiDarkGray};
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const PictureBtn = styled.button`
  color: ${(props) => props.theme.ga.white};
  padding: 10px 24px;
  border: none;
  outline: none;
  margin-left: 8px;
  background: ${({ theme }) => theme.ga.semiDarkGray};
  display: flex;
  border-radius: 4px;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;

  ${BtnText} {
    margin-left: 10px;
  }

  .photo-icon {
    height: 20px;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Controls = styled(RowContainer)`
  height: 76px;
  background-color: ${({ theme }) => theme.ga.black};
  padding: 16px;
  box-sizing: border-box;

  .take-photo {
    margin-left: auto;
  }

  .more {
    display: none;
  }

  ${Countdown} {
    margin-right: 8px;
  }

  @media ${device.laptop} {
    .take-photo {
      display: none;
    }
  }

  @media ${device.tablet} {
    height: 60px;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 100px;
    margin: 0 auto;
    padding: 8px;
    z-index: 9999;
    position: sticky;
    bottom: 16px;

    .take-photo {
      display: none;
    }

    .more {
      display: flex;
    }

    .toggle-timer {
      display: none;
    }

    .settings {
      display: none;
    }

    ${IconBtn} {
      background: transparent;
    }

    ${AddSeconds} {
      display: none;
    }
  }
`;
