import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paths } from 'paths';
import {
  LoginForm,
  LoginData,
  AuthSelectors,
  authenticateUser,
  LoginResponse,
  AuthLayout,
} from 'modules/auth';
import { H3, TextRegular, BtnText, Spinner } from 'modules/ui';
import { Dictionary } from 'modules/utils';

import { FormContainer, Header } from '../styled';

export const LoginPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(AuthSelectors.getUser);
  const [response, setResponse] = useState<LoginResponse>({
    message: '',
    success: true,
    hasResponse: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.isLoggedIn) {
      history.push(Paths.Root);
    }
  }, [user]);

  const onSubmit = useCallback(
    async (arg: LoginData) => {
      setLoading(true);
      // TODO: temporary implementation. Fix this ASAP.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await dispatch(authenticateUser(arg));
      const { success } = res;
      let errorMessage = '';

      if (!success) {
        errorMessage = res.message;
      }

      setLoading(false);
      setResponse({ success, message: errorMessage, hasResponse: true });
    },
    [dispatch, authenticateUser],
  );

  return (
    <AuthLayout>
      <FormContainer>
        <Header>
          <H3>{Dictionary.LOGIN_HEADING}</H3>
        </Header>
        <LoginForm
          onSubmit={onSubmit}
          response={response}
          setResponse={setResponse}
        />
        {loading && <Spinner />}
        <TextRegular>
          {Dictionary.DONT_HAVE_ACCOUNT}
          <BtnText>
            <Link to={Paths.UserSignup}>{Dictionary.SIGN_UP}</Link>
          </BtnText>
        </TextRegular>
      </FormContainer>
    </AuthLayout>
  );
});
