import { ColumnContainer, Label, RowContainer } from 'modules/ui';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  flex: 1;
`;

export const FormContainer = styled(ColumnContainer)`
  width: 532px;
  margin: 0px auto 0px 80px;

  h6 {
    color: ${({ theme }) => theme.ga.darkBlue}; //TODO: Ask Anja
  }

  ${Label} {
    color: ${({ theme }) => theme.ga.gray};
  }
`;

export const DateContainer = styled(RowContainer)`
  margin: 34px 0px 34px 0px;

  & > div {
    width: 100%;
  }

  & > div:first-child {
    margin-right: 20px;
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const ButtonsContainer = styled(RowContainer)`
  position: fixed;
  bottom: 9px;
  right: 21px;
  z-index: 999;

  button:first-child {
    margin-right: 20px;
  }
`;
