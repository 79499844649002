import styled, { css } from 'styled-components';

export const CustomEvent = styled.div`
  color: ${({ theme }) => theme.ga.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .title {
    font-weight: 700;
  }
`;

export const Month = styled.span`
  color: ${({ theme }) => theme.ga.gray};
`;

export const Day = styled.span<{ isToday: boolean }>`
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ isToday, theme }) =>
    isToday &&
    css`
      border-radius: 50%;
      color: ${theme.ga.white};
      background-color: ${theme.ga.blue};
    `}
`;

export const CustomDayHeader = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 11px 30px 6px 30px;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.ga.grayBackground};
  box-sizing: border-box;
  padding: 24px 32px;

  .fc {
    border-radius: 4px;
    overflow: hidden;
    background: ${({ theme }) => theme.ga.white};
    font-family: 'Open Sans';

    .fc-event {
      background-color: ${({ theme }) => theme.ga.blue};
    }

    .event-container {
      color: ${({ theme }) => theme.ga.white};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .title {
        font-weight: 700;
      }
    }

    .fc-button-primary {
      border: none;
      border-color: transparent;
      outline: none;
      background-color: ${({ theme }) => theme.ga.gray};

      &:focus,
      &:active,
      &:not(:disabled):active:focus,
      &:not(:disabled).fc-button-active:focus {
        box-shadow: none;
      }

      &.fc-button-active {
        background-color: ${({ theme }) => theme.ga.gray};
      }
    }

    .fc-prev-button,
    .fc-next-button {
      color: ${({ theme }) => theme.ga.darkBlue};
      background: transparent;
      border: none;

      &:focus,
      &:active {
        box-shadow: none;
        background-color: transparent;
        color: ${({ theme }) => theme.ga.darkBlue};
      }
    }

    table {
      border-color: white;
      border-top-color: #f3f4f9;

      th {
        border-color: #f3f4f9;
      }
    }

    .fc-timegrid-slot-minor {
      border: none;
    }

    .fc-toolbar {
      padding: 20px 16px 12px 16px;
    }

    tr td {
      border-color: #f3f4f9;
    }

    tr:nth-child(even) {
      td:last-of-type {
        border-top: none;
      }
    }

    .fc-header-toolbar {
      margin-bottom: 0px;
    }

    .time-label {
      border: none;
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
      color: ${({ theme }) => theme.ga.gray};

      & > div {
        position: relative;

        & > div {
          /* NOTE: Check with Anja */
          /* position: absolute;
          left: 7px;
          top: -20px; */
        }
      }
    }

    .fc-toolbar-title {
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
