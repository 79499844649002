import React, { useMemo, useRef, useState, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { ArrowIcon } from 'modules/ui';
import { TwilioChat } from 'modules/chat/components/Chat/TwilioChat';
import { Chat, ChatHelpers, useMobileChat } from 'modules/chat';
import { Attendee } from 'modules/utils';
import { QueueSelectors } from 'modules/queue';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import {
  ChatContainer,
  Pagination,
  Chats,
  ChatsWrap,
  TalentChatWrap,
} from './styled';

const singleChatWidth = 276;

export type GlobalChatHandle = {
  openChat: (attendee: Attendee) => void;
};

export const GlobalChat = React.forwardRef<GlobalChatHandle>((_, ref) => {
  const [scroll, setScroll] = useState(0);

  const chatsRef = useRef<HTMLDivElement>(null);
  const waitingRoomId = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );

  const {
    isChatOpen: isTalentChatOpen,
    onChatToggle: toggleTalentChat,
  } = useMobileChat();

  const attendees = useSelector(QueueSelectors.getAttendees);

  const chats = useMemo(
    () =>
      attendees.map((attendee) => ({
        id: ChatHelpers.getFanChatIdFromBpsId(
          attendee.waitingRoomGuid.toString(),
          attendee.bookedParticipantSlotId.toString(),
        ),
        name: `${attendee.firstName} ${attendee.lastName}`,
      })),
    [attendees],
  );

  const [visibleChats, setVisibleChats] = useState<string[]>([]);

  const handleOpenChat = (attendee: Attendee) => {
    const chatId = ChatHelpers.getFanChatIdFromBpsId(
      attendee.waitingRoomGuid,
      attendee.bookedParticipantSlotId.toString(),
    );

    if (visibleChats.includes(chatId)) {
      return;
    }

    setVisibleChats([...visibleChats, chatId]);
  };

  useImperativeHandle(ref, () => ({
    openChat: handleOpenChat,
  }));

  const handleCloseChat = (chatId: string) => {
    const newChats = visibleChats.filter((chat) => chat !== chatId);
    setVisibleChats(newChats);
  };

  const width = useMemo(() => {
    return 5 * singleChatWidth;
  }, [chats, chatsRef.current]);

  const isChatVisible = (chatId: string) => visibleChats.includes(chatId);

  const handleScroll = (direction: number) => () => {
    let newScroll = scroll + singleChatWidth * direction;
    let wrapWidth = 0;
    if (chatsRef && chatsRef.current) {
      wrapWidth = chatsRef.current.clientWidth;
    }

    let maxScroll = width - wrapWidth;

    if (maxScroll < 0) {
      maxScroll = 0;
    }

    if (newScroll > 0) {
      newScroll = 0;
    } else if (newScroll * direction > maxScroll) {
      newScroll = maxScroll * direction;
    }

    setScroll(newScroll);
  };

  return (
    <ChatContainer>
      <Pagination>
        <ArrowIcon onClick={handleScroll(-1)} />
        <ArrowIcon className="rotate-right" onClick={handleScroll(1)} />
      </Pagination>
      <Chats ref={chatsRef}>
        <ChatsWrap scrollAmount={scroll} width={width}>
          <div>
            {chats.map((chat) => (
              <TwilioChat
                key={chat.id}
                chatId={chat.id}
                name={chat.name}
                index={visibleChats.indexOf(chat.id)}
                onClose={handleCloseChat}
                visible={isChatVisible(chat.id)}
              />
            ))}
          </div>
        </ChatsWrap>
      </Chats>
      <TalentChatWrap>
        <Chat
          id={ChatHelpers.getTalentChatId(waitingRoomId)}
          isAdminView
          isMobileOpened={isTalentChatOpen}
          onChatToggle={toggleTalentChat}
        />
      </TalentChatWrap>
    </ChatContainer>
  );
});
