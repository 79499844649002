import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useToggleLocalMute,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import {
  BtnText,
  CameraIcon,
  CameraOffWhiteIcon,
  MicrophoneIcon,
  MicrophoneOffWhiteIcon,
  PauseIcon,
  PhotoIcon,
  PlayIconWhite,
  SettingsWhiteIcon,
} from 'modules/ui';
import { useModal } from 'modules/main';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
  CountdownTimer,
  useLocalVideoWithWarning,
} from 'modules/common-meeting';

import { ChimeDeviceSettings } from 'modules/devices';

import { AddSeconds, Controls, IconBtn, PictureBtn } from './styled';

type Props = {
  isAdmin?: boolean;
  isTalent?: boolean;
  onTakeSnapshot?: () => void;
};

const SESSION_TIME_INCREMENT_SECONDS = 10;

export const AdminTalentControls: React.FC<Props> = React.memo(
  ({ isAdmin = false, isTalent = false, onTakeSnapshot }) => {
    const dispatch = useDispatch();
    const [isSettingsModalOpen, openSettingsModal, handleClose] = useModal(
      false,
    );

    const waitingRoomGuid = useSelector(
      CommonMeetingSelectors.waitingRoomIdSelector,
    );
    const fanBpsId = useSelector(
      CommonMeetingSelectors.getBpsIdForFanParticipantInLiveCallOrCallPaused,
    );
    const isAnyFanParticipantInLiveCallOrInCallPaused = useSelector(
      CommonMeetingSelectors.isAnyFanParticipantInLiveCallOrInCallPaused,
    );
    const isTakingPhotoEnabled = useSelector(
      CommonMeetingSelectors.isTakingPhotoEnabled,
    );
    const sessionSecondsLeft = useSelector(
      CommonMeetingSelectors.getSessionSecondsLeft,
    );
    const totalSessionSeconds = useSelector(
      CommonMeetingSelectors.getTotalSessionSeconds,
    );
    const isAnyFanParticipantInLiveCall = useSelector(
      CommonMeetingSelectors.isAnyFanParticipantInLiveCall,
    );
    const isAnyWaitingRoomParticipantPaused = useSelector(
      CommonMeetingSelectors.isAnyWaitingRoomParticipantPaused,
    );
    const { isVideoEnabled, toggleVideo } = useLocalVideoWithWarning();
    const { muted, toggleMute } = useToggleLocalMute();
    const audioVideo = useAudioVideo();

    // mute admins initially in the waiting room
    useEffect(() => {
      if (!audioVideo || !isAdmin) {
        return;
      }

      audioVideo.realtimeMuteLocalAudio();
    }, [audioVideo]);

    const handleAddTime = () => {
      if (fanBpsId) {
        dispatch(
          CommonMeetingActions.incrementCallSecondsLeft({
            waitingRoomGuid,
            bookedParticipantSlotId: fanBpsId,
            seconds: SESSION_TIME_INCREMENT_SECONDS,
          }),
        );
      }
    };

    const handlePauseResumeTime = (isPaused: boolean) => {
      dispatch(
        CommonMeetingActions.pauseCallWithTalent({
          waitingRoomGuid,
          paused: isPaused,
        }),
      );
    };

    const timeIncrementLabel = `+${SESSION_TIME_INCREMENT_SECONDS}sec`;

    return (
      <Controls>
        <CountdownTimer
          timeLeft={sessionSecondsLeft}
          initialTime={totalSessionSeconds}
        />
        {(isAdmin || isTalent) && (
          <AddSeconds
            onClick={handleAddTime}
            disabled={!isAnyFanParticipantInLiveCallOrInCallPaused}
          >
            <BtnText>{timeIncrementLabel}</BtnText>
          </AddSeconds>
        )}
        {isAnyWaitingRoomParticipantPaused ? (
          <IconBtn
            className="toggle-timer"
            onClick={() => handlePauseResumeTime(false)}
          >
            <PlayIconWhite />
          </IconBtn>
        ) : (
          <IconBtn
            className="toggle-timer"
            onClick={() => handlePauseResumeTime(true)}
            disabled={!isAnyFanParticipantInLiveCall}
          >
            <PauseIcon />
          </IconBtn>
        )}

        <PictureBtn
          onClick={onTakeSnapshot}
          disabled={!isTakingPhotoEnabled}
          className="take-photo"
        >
          <PhotoIcon className="photo-icon" />
          <BtnText>Take a picture</BtnText>
        </PictureBtn>
        <IconBtn onClick={toggleMute}>
          {muted ? <MicrophoneOffWhiteIcon /> : <MicrophoneIcon />}
        </IconBtn>
        <IconBtn onClick={toggleVideo}>
          {isVideoEnabled ? <CameraIcon /> : <CameraOffWhiteIcon />}
        </IconBtn>
        <IconBtn className="settings" onClick={openSettingsModal}>
          <SettingsWhiteIcon />
        </IconBtn>
        {isSettingsModalOpen && (
          <ChimeDeviceSettings
            isOpen={isSettingsModalOpen}
            onCloseRequest={handleClose}
          />
        )}
      </Controls>
    );
  },
);
