import { RowContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const ControlsContainer = styled(RowContainer)`
  width: 284px;
  height: 40px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;

  & > svg {
    margin: 0px 12px;
  }

  .settings path {
    fill: white;
  }

  @media ${device.tablet} {
    background-color: transparent;
    position: relative;
    margin: 0 auto;
    left: auto;
    bottom: auto;
  }
`;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 4px;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background: white;
  }
`;

export const Default = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;
