import {
  CameraDarkIcon,
  CameraIcon,
  CameraOffDarkIcon,
  CameraOffWhiteIcon,
  MicrophoneDarkIcon,
  MicrophoneIcon,
  MicrophoneOffWhiteIcon,
  MutedIcon,
  SettingsIcon,
  SettingsWhiteIcon,
} from 'modules/ui';
import React from 'react';
import { ControlsContainer, ToggleButton, Default, Mobile } from './styled';

type Props = {
  onToggleVideo: () => void;
  onToggleAudio: () => void;
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  onDevicesOpen: () => void;
};

export const PreviewControls: React.FC<Props> = React.memo(
  ({
    onToggleVideo,
    onToggleAudio,
    isVideoEnabled,
    isAudioEnabled,
    onDevicesOpen,
  }) => {
    return (
      <ControlsContainer>
        <ToggleButton onClick={onToggleAudio}>
          <Default>
            {isAudioEnabled ? <MicrophoneIcon /> : <MicrophoneOffWhiteIcon />}
          </Default>
          <Mobile>
            {isAudioEnabled ? <MicrophoneDarkIcon /> : <MutedIcon />}
          </Mobile>
        </ToggleButton>
        <ToggleButton onClick={onToggleVideo}>
          <Default>
            {isVideoEnabled ? <CameraIcon /> : <CameraOffWhiteIcon />}
          </Default>
          <Mobile>
            {isVideoEnabled ? <CameraDarkIcon /> : <CameraOffDarkIcon />}
          </Mobile>
        </ToggleButton>
        <ToggleButton onClick={onDevicesOpen}>
          <Default>
            <SettingsWhiteIcon />
          </Default>
          <Mobile>
            <SettingsIcon />
          </Mobile>
        </ToggleButton>
      </ControlsContainer>
    );
  },
);
