import {
  ColumnContainer,
  Label,
  RowContainer,
  TextItalic,
  TextRegular,
} from 'modules/ui';
import styled from 'styled-components';

export const ProductsContainer = styled(ColumnContainer)`
  margin-left: 80px;

  ${Label} {
    color: ${({ theme }) => theme.ga.gray};
  }

  ${TextItalic} {
    color: ${({ theme }) => theme.ga.gray};
    margin: -10px 0px 14px;
  }

  padding-bottom: 50px;
  button {
    width: 261px;
  }
`;

export const Product = styled(RowContainer)`
  width: 600px;
  margin: 16px 0px 16px;
  align-items: center;

  ${TextRegular} {
    margin-right: 26px;
  }

  svg {
    cursor: pointer;
  }
`;
