import { createSelector } from '@reduxjs/toolkit';
import {
  CommonMeetingSelectors,
  ParticipantState,
} from 'modules/common-meeting';

export const shouldForceFanInMeeting = createSelector(
  CommonMeetingSelectors.getLoggedInParticipantState,
  (participantState) =>
    participantState !== undefined &&
    [
      ParticipantState.IN_A_TECH_CHECK,
      ParticipantState.IN_ANNOUNCEMENT,
      ParticipantState.IN_A_CALL,
      ParticipantState.IN_PHOTO_TIMER,
      ParticipantState.CALL_PAUSED,
    ].includes(participantState),
);
