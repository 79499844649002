import { useState, useCallback } from 'react';

export const useMobileModal = (
  initialValue: boolean,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue || false);

  const openMenu = useCallback(() => {
    setIsOpen(true);
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';
  }, []);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
    document.body.style.height = '';
    document.body.style.overflow = '';
  }, []);
  return [isOpen, openMenu, closeMenu];
};
