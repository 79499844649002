import styled from 'styled-components';

import { H2, TextRegular } from 'modules/ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 40px 24px 40px;
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.ga.white};
`;

export const Subtitle = styled(TextRegular)`
  color: ${({ theme }) => theme.ga.white};
  margin-bottom: 40px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
