export type DatePickerDate = {
  day: number;
  month: number;
  year: number;
};

export const customLocale = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekDays: [
    {
      name: 'Monday',
      short: 'M',
    },
    {
      name: 'Tuesday',
      short: 'T',
    },
    {
      name: 'Wednesday',
      short: 'W',
    },
    {
      name: 'Thursday',
      short: 'T',
    },
    {
      name: 'Friday',
      short: 'F',
    },
    {
      name: 'Saturday',
      short: 'S',
      isWeekend: true,
    },
    {
      name: 'Sunday',
      short: 'S',
      isWeekend: true,
    },
  ],
  weekStartingIndex: 6,
  getToday(gregorainTodayObject: DatePickerDate): DatePickerDate {
    return gregorainTodayObject;
  },
  toNativeDate(date: DatePickerDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  },
  getMonthLength(date: DatePickerDate): number {
    return new Date(date.year, date.month, 0).getDate();
  },
  transformDigit(digit: string | number): string | number {
    return digit;
  },
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',
  from: 'from',
  to: 'to',
  digitSeparator: ',',
  yearLetterSkip: 0,
  isRtl: false,
};
