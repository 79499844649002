import React from 'react';

import { LogoV3 } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { Clock } from '../Clock';

import { Header } from './styled';

export const HeaderWithClock: React.FC = React.memo(() => {
  return (
    <Header>
      <img src={LogoV3} alt={Dictionary.GALAXYCON} />
      <Clock />
    </Header>
  );
});
