import React from 'react';
import { CalendarIcon, TextBold, TextRegular } from 'modules/ui';
import {
  NextSession,
  DateContainer,
  IconContainer,
  AccessSessionDesktop,
  AccessSessionMobile,
} from './styled';

type NextPops = {
  title: string;
  date: string;
  hasAccess?: boolean;
};

export const NextSessionComponent: React.FC<NextPops> = React.memo(
  ({ title, date, hasAccess }) => {
    return (
      <NextSession>
        <IconContainer>
          <DateContainer>
            <CalendarIcon />
            <TextRegular>{date}</TextRegular>
          </DateContainer>
          {hasAccess && (
            // eslint-disable-next-line no-console
            <AccessSessionDesktop onClick={() => console.log('access session')}>
              Access Session Lobby
            </AccessSessionDesktop>
          )}
        </IconContainer>
        <TextBold className="title">{title}</TextBold>
        {hasAccess && (
          // eslint-disable-next-line no-console
          <AccessSessionMobile onClick={() => console.log('access session')}>
            Access Session Lobby
          </AccessSessionMobile>
        )}
      </NextSession>
    );
  },
);
