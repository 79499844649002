import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Paths } from 'paths';
import { Button, TextInput, SubmitContainer } from 'modules/ui';
import { Validators } from 'modules/utils';
import { ForgotPasswordCheck, ForgotPasswordData } from '../../types';

type Props = {
  onSubmit: (data: ForgotPasswordData) => void;
  response?: ForgotPasswordCheck;
};

export const EmailForm: React.FC<Props> = React.memo(
  ({ onSubmit, response }) => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{
          email: '',
        }}
        render={({ handleSubmit }): React.ReactNode => (
          <>
            <Field
              type="email"
              name="email"
              label="Email"
              component={TextInput}
              validate={Validators.composeValidators(
                Validators.required,
                Validators.mustBeValidEmail,
              )}
              response={response}
            />
            <SubmitContainer>
              <Button label="Send" clickHandler={handleSubmit} />
              <span className="terms">
                Already have an account?
                <Link to={Paths.UserLogin}>Log in</Link>
              </span>
            </SubmitContainer>
          </>
        )}
      />
    );
  },
);
