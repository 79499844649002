import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommonMeeting } from 'modules/common-meeting';
import {
  AccountLayout,
  ChangePassword,
  AccountDetails,
  MyOrders,
} from 'modules/account';
import {
  ScheduleLayout,
  ScheduleNavigation,
  NextSessionsPage,
  PastSessionsPage,
} from 'modules/schedule';
import { VideoList } from 'modules/fan-videos';
import { UpcomingLiveEvents, ClientLayout } from 'modules/client';
// import { NotFoundPage } from 'modules/ui';
import { FanMeeting, FanMeetingSelectors } from 'modules/fan-meeting';
import { Paths } from './paths';

export const FanRoutes: React.FC = (): JSX.Element => {
  const shouldForceFanInMeeting = useSelector(
    FanMeetingSelectors.shouldForceFanInMeeting,
  );

  return (
    <Switch>
      <Route path={Paths.FanSession}>
        <CommonMeeting renderReadyState={() => <FanMeeting />} />
      </Route>

      {shouldForceFanInMeeting && <Redirect to={Paths.FanSession} />}

      <Route
        path={[Paths.AccountDetails, Paths.ChangePassword, Paths.MyOrders]}
      >
        <ClientLayout>
          <AccountLayout>
            <Switch>
              <Route path={Paths.ChangePassword} component={ChangePassword} />
              <Route path={Paths.AccountDetails} component={AccountDetails} />
              <Route path={Paths.MyOrders} component={MyOrders} />
            </Switch>
          </AccountLayout>
        </ClientLayout>
      </Route>

      <Route
        path={[
          Paths.UserSchedule,
          Paths.NextSessions,
          Paths.PastSessions,
          Paths.FanVideos,
        ]}
      >
        <Route path={Paths.UserSchedule}>
          <Redirect to={Paths.NextSessions} />
        </Route>
        <Route path={[Paths.NextSessions, Paths.PastSessions, Paths.FanVideos]}>
          <ClientLayout>
            <Route path={[Paths.NextSessions, Paths.PastSessions]}>
              <ScheduleLayout>
                <ScheduleNavigation />
                <Route path={Paths.NextSessions} component={NextSessionsPage} />
                <Route path={Paths.PastSessions} component={PastSessionsPage} />
              </ScheduleLayout>
            </Route>
            <Route path={Paths.FanVideos} component={VideoList} />
          </ClientLayout>
        </Route>
      </Route>

      <Route exact path={Paths.Root}>
        <ClientLayout isDark>
          <UpcomingLiveEvents />
        </ClientLayout>
      </Route>

      <Redirect to={Paths.Root} />
    </Switch>
  );
};
