import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
  useConnectToChime,
} from 'modules/common-meeting';

export const useConnectToMainCall = (): void => {
  const dispatch = useDispatch();

  const { uid } = useParams<Record<string, string>>();
  const loggedInParticipantBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );

  const chimeMeetingInfo = useSelector(
    CommonMeetingSelectors.getMainMeetingChimeMeetingInfo,
  );

  useEffect(() => {
    if (uid === undefined || loggedInParticipantBpsId === null) {
      throw new Error(
        'useConnectToMainCall called with uid=undefined or loggedInParticipantBpsId=null',
      );
    }

    dispatch(
      CommonMeetingActions.getMainCallJoiningInfo({
        waitingRoomGuid: uid,
        bpsId: loggedInParticipantBpsId,
      }),
    );

    return () => {
      dispatch(CommonMeetingActions.clearMainMeetingJoiningInfo());
    };
  }, []);

  useConnectToChime(chimeMeetingInfo);
};
