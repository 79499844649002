import styled from 'styled-components';
import { device } from 'modules/utils';
import { H1 } from 'modules/ui';

export const LiveEventsContainer = styled.div`
  padding: 60px 72px 24px 72px;
  background-color: ${({ theme }) => theme.ga.grayBackground};
  max-width: 1136px;
  margin: 0 auto;

  @media ${device.laptop} {
    padding: 5px 20px 24px 20px;
  }
  @media ${device.tablet} {
    padding: 5px 30px 24px 30px;
  }
  @media ${device.mobile} {
    padding: 5px 20px 24px 20px;
  }
`;

export const PageTitle = styled(H1)`
  @media ${device.tablet} {
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
  }
`;

export const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 16px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const HeaderBG = styled.div`
  background-color: ${({ theme }) => theme.ga.darkBlue};
  width: 100vw;
  @media ${device.mobile} {
    width: auto;
  }

  @media ${device.tablet} {
    h1 {
      padding-left: 5%;
    }
  }
`;

export const BodyBG = styled.div`
  background-color: ${({ theme }) => theme.ga.grayBackground};
  width: 100vw;
`;
