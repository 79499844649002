import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changePassword, ChangePasswordData } from 'modules/auth';
import { H1, Spinner, TextError, TextSuccess } from 'modules/ui';
import { ChangePasswordForm } from '../../components';
import { FormContainer } from '../../styled';

export const ChangePassword: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: true,
    message: '',
    hasResponse: false,
  });

  const onSubmit = useCallback(
    async (data: ChangePasswordData) => {
      setLoading(true);
      try {
        const { newPassword, oldPassword } = data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res: any = await dispatch(
          changePassword({ oldPassword, newPassword }),
        );
        const { success, message } = res;
        setResponse({ success, message, hasResponse: true });
      } catch (err) {
        setResponse({
          success: false,
          message: 'Error occured. Please try again.',
          hasResponse: true,
        });
      }
      setLoading(false);
    },
    [dispatch],
  );

  return (
    <FormContainer>
      <H1>Change password</H1>
      {response.hasResponse &&
        (response.success ? (
          <TextSuccess>{response.message}</TextSuccess>
        ) : (
          <TextError>{response.message}</TextError>
        ))}
      <ChangePasswordForm onSubmit={onSubmit} disabled={loading} />
      {loading && <Spinner />}
    </FormContainer>
  );
});
