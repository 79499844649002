import { InfoDate } from 'modules/schedule';

export const getEventInfoDate = (startDate: Date, endDate: Date): InfoDate => {
  const startTime = startDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const endTime = endDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const dayName = startDate.toLocaleString('default', {
    weekday: 'long',
  });

  const monthName = startDate.toLocaleString('default', {
    month: 'long',
  });

  const dayNumber = startDate.getDate();

  return {
    startTime,
    endTime,
    dayName,
    dayNumber,
    monthName,
  };
};
