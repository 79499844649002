import React from 'react';
import { useSelector } from 'react-redux';
import { VideoTileGrid } from 'amazon-chime-sdk-component-library-react';
import { SnapshotCountdownInfoCard } from 'modules/ui';
import {
  useConnectToMainCall,
  CommonMeetingSelectors,
  useAutoStartVideo,
} from 'modules/common-meeting';

import { FanControls } from '../FanControls';

import { Container, InfoCardContainer } from './styled';

export const InACallView: React.FC = React.memo(() => {
  const photoTimerSecondsLeft = useSelector(
    CommonMeetingSelectors.getPhotoSecondsLeft,
  );

  const isPhotoTimerVisible =
    !!photoTimerSecondsLeft && photoTimerSecondsLeft !== -1;
  const isCallWithTalentInRedZone = useSelector(
    CommonMeetingSelectors.isCallWithTalentInRedZone,
  );

  useConnectToMainCall();
  useAutoStartVideo();

  return (
    <Container applyRedBorder={isCallWithTalentInRedZone}>
      <VideoTileGrid layout="standard" />
      <InfoCardContainer>
        {isPhotoTimerVisible && (
          <SnapshotCountdownInfoCard
            initialSecondsRemaining={photoTimerSecondsLeft}
          />
        )}
      </InfoCardContainer>
      <FanControls />
    </Container>
  );
});
