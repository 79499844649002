import React from 'react';
import {
  MicrophoneOffWhiteIcon,
  CameraIcon,
  CameraOffWhiteIcon,
  MicrophoneIcon,
  SettingsWhiteIcon,
  MutedIcon,
  MicrophoneDarkIcon,
  CameraDarkIcon,
  CameraOffDarkIcon,
  SettingsIcon,
  ChatDarkIcon,
} from 'modules/ui';
import { useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';
import { useLocalVideoWithWarning } from 'modules/common-meeting';
import { ChimeDeviceSettings } from 'modules/devices';
import { useModal } from 'modules/main';
import {
  Controls,
  IconButton,
  Default,
  Mobile,
  ChatIconButton,
} from './styled';

type Props = {
  onChatToggle?: () => void;
};

export const TechCheckControls: React.FC<Props> = React.memo(
  ({ onChatToggle }) => {
    const [isOpen, open, handleClose] = useModal(false);
    const { isVideoEnabled, toggleVideo } = useLocalVideoWithWarning();
    const { muted, toggleMute } = useToggleLocalMute();
    return (
      <>
        <Controls>
          <IconButton onClick={toggleMute}>
            <Default>
              {muted ? <MicrophoneOffWhiteIcon /> : <MicrophoneIcon />}
            </Default>
            <Mobile>{muted ? <MutedIcon /> : <MicrophoneDarkIcon />}</Mobile>
          </IconButton>
          <IconButton onClick={toggleVideo}>
            <Default>
              {isVideoEnabled ? <CameraIcon /> : <CameraOffWhiteIcon />}
            </Default>
            <Mobile>
              {isVideoEnabled ? <CameraDarkIcon /> : <CameraOffDarkIcon />}
            </Mobile>
          </IconButton>
          <ChatIconButton onClick={onChatToggle}>
            <ChatDarkIcon />
          </ChatIconButton>
          <IconButton onClick={open}>
            <Default>
              <SettingsWhiteIcon />
            </Default>
            <Mobile>
              <SettingsIcon />
            </Mobile>
          </IconButton>
        </Controls>
        {isOpen && <ChimeDeviceSettings isOpen onCloseRequest={handleClose} />}
      </>
    );
  },
);
