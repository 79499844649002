/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import minby from 'lodash.minby';
import { Button, H3, MenuIcon, TextBold } from 'modules/ui';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useParams } from 'react-router-dom';
import { Attendee } from 'modules/utils';
import {
  CommonMeetingActions,
  CommonMeetingSelectors,
  Fan,
  Participant,
  ParticipantState,
} from 'modules/common-meeting';
import { TechCheckActions, TechCheckStatusIcon } from 'modules/tech-check';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useSendNextFanToMainSession } from 'modules/queue';

import { Container, Customers, Customer, TooltipText } from './styled';
import * as QueueActions from '../../redux/actions';
import { QueueSelectors } from '../../index';
import { AttendeeChatIcon } from '../AttendeeChatIcon';

const reorder = (list: Fan[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  onOpenChat: (attendee: Attendee) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomerQueue: React.FC<Props> = React.memo(({ onOpenChat }) => {
  const dispatch = useDispatch();
  const meetingManager = useMeetingManager();

  const { uid } = useParams<Record<string, string | undefined>>();
  const isSendNextFanToCallWithTalentEnabled = useSelector(
    CommonMeetingSelectors.isSendNextFanToCallWithTalentEnabled,
  );
  const adminBookedParticipantSlotId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const waitingRoomFans = useSelector(
    CommonMeetingSelectors.waitingRoomFansSelector,
  );
  const waitingRoomFansCount = useSelector(
    CommonMeetingSelectors.waitingRoomFansCountSelector,
  );
  const waitingRoomTechCheckList = useSelector(
    CommonMeetingSelectors.waitingRoomTechCheckListSelector,
  );

  const attendees = useSelector(QueueSelectors.getAttendees);

  const { sendNextFanToMainSession } = useSendNextFanToMainSession();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const waitingRoomFansReordered = reorder(
      waitingRoomFans,
      result.source.index,
      result.destination.index,
    );

    // TODO: Remove ln 85-90 once backend defaults admins and talents positions to 0
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const minimumPosition = minby(
      waitingRoomFansReordered,
      (participant) => participant.Position,
    ).Position;

    const waitingRoomFansRepositioned = waitingRoomFansReordered.map(
      (fanParticipant, index) => ({
        ...fanParticipant,
        Position: minimumPosition + index,
      }),
    );

    dispatch(
      CommonMeetingActions.reorderWaitingRoomFans(waitingRoomFansRepositioned),
    );

    const reorderFansReqBody = {
      waitingRoomGuid: uid,
      positionItems: waitingRoomFansRepositioned.map((reorderedFan) => ({
        bookedParticipantSlotId: reorderedFan.BpsId,
        positionInQueue: reorderedFan.Position,
      })),
    };

    if (uid) {
      dispatch(CommonMeetingActions.reorderFansPositions(reorderFansReqBody));
    }
  };

  useEffect(() => {
    if (uid) {
      dispatch(QueueActions.getBpsForWaitingRoom(uid));
    }
  }, [uid, waitingRoomFansCount]);

  return (
    <Container>
      <H3>Customers Queue</H3>
      <Button
        isMeeting
        disabled={!isSendNextFanToCallWithTalentEnabled}
        clickHandler={sendNextFanToMainSession}
        label="Send first Fan to Live"
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <Customers ref={provided.innerRef} {...provided.droppableProps}>
              {waitingRoomFans.map((fan: Participant, i: number) => {
                const attendee = attendees.find(
                  (currentAttendee) =>
                    currentAttendee.bookedParticipantSlotId === fan.BpsId,
                ) as Attendee;

                return (
                  <Draggable
                    key={`${fan.BpsId}-${fan.Position}`}
                    draggableId={`${fan.BpsId}-${fan.Position}`}
                    index={i}
                  >
                    {(draggableProvider) => {
                      const isFanAlreadyInTechCheck = waitingRoomTechCheckList.some(
                        ({ FanBpsId }) => FanBpsId === fan.BpsId,
                      );
                      const disabled =
                        isFanAlreadyInTechCheck ||
                        fan.State === ParticipantState.DISCONNECTED;
                      return (
                        <Customer
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.draggableProps}
                          {...draggableProvider.dragHandleProps}
                          onDoubleClick={async () => {
                            if (
                              !disabled &&
                              uid &&
                              adminBookedParticipantSlotId
                            ) {
                              await meetingManager.leave();
                              dispatch(
                                TechCheckActions.sendFanToTechCheck({
                                  waitingRoomGuid: uid,
                                  adminBookedParticipantSlotId,
                                  fanBookedParticipantSlotId: fan.BpsId,
                                }),
                              );
                            }
                          }}
                          disabled={disabled}
                        >
                          {!!attendee && !!attendee.variantTitle && (
                            <TooltipText>{`Variant ${attendee.variantTitle}`}</TooltipText>
                          )}
                          <MenuIcon className="menu" />
                          <TextBold className="info">
                            <span>{i + 1}</span>
                            <span>. </span>
                            {fan.Name}
                          </TextBold>
                          {!!attendee && (
                            <AttendeeChatIcon
                              attendee={attendee}
                              onIconClick={() => onOpenChat(attendee)}
                            />
                          )}
                          <TechCheckStatusIcon fanBpsId={fan.BpsId} />
                        </Customer>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Customers>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
});
