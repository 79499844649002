import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';

export const Container = styled(RowContainer)`
  height: 100%;
  width: 100%;
`;

export const SideContainer = styled.div`
  flex: 0 0 350px;
`;

export const MainContainer = styled(ColumnContainer)`
  width: 100%;
  padding-bottom: 100px;
  max-width: 1250px;
  margin: 0 auto;
  background: ${(props) => props.theme.ga.white};
  border: 1px solid ${(props) => props.theme.ga.grayBackground};
  border-radius: 4px;
`;

export const OrdersContainer = styled.table`
  width: 100%;
  table-layout: auto;
  margin: 0 auto;
`;

export const SpinnerContainer = styled.div`
  margin-top: 48px;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const THead = styled.thead`
  padding: 0;
`;

export const TBody = styled.tbody`
  padding: 0;
`;
