import styled from 'styled-components';
import { device } from 'modules/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const ScheduleContainer = styled.div`
  flex-grow: 1;
  padding: 35px 55px;
  box-sizing: border-box;

  @media ${device.mobile} {
    padding: 22px 22px;
  }
`;
