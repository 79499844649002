import styled from 'styled-components';
import { SnapshotWaveBackground } from '../../assets';
import { RowContainer } from '../Styled';
import { H2Regular } from '../../theme';

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.ga.white};
  padding: 16px 28px 16px 8px;

  flex-direction: column;
  ${H2Regular} {
    line-height: 0px;

    span {
      margin-left: 8px;
      font-weight: 700;
      color: ${({ theme }) => theme.ga.pink};
    }
  }
`;

export const LeftContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;

  min-width: 108px;
  padding-left: 10px;
  background-image: url(${SnapshotWaveBackground});
`;

export const Container = styled(RowContainer)`
  border-radius: 8px;
  overflow: hidden;

  width: 100%;
  max-height: 98px;
  background: ${({ theme }) => theme.ga.darkBlue};
`;
