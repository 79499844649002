import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';

export const SubNav = styled(RowContainer)`
  width: 100%;
  height: 109px;
  background: ${({ theme }) => theme.ga.grayBackground};
  color: ${({ theme }) => theme.ga.black};
  margin-bottom: 16px;
`;

export const Container = styled(ColumnContainer)`
  width: 900px;
  margin: 0 auto;

  div {
    a {
      text-decoration: none;
      border-bottom: 2px solid transparent;
      color: ${({ theme }) => theme.ga.darkBlue};
      margin-right: 42px;

      &.active {
        border-color: ${({ theme }) => theme.ga.magenta};
      }
    }
  }
`;
