import styled from 'styled-components';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 26px 20px 56px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  overflow-y: hidden;
`;

export const ButtonContainer = styled.div`
  margin: 25px auto 0 auto;
`;
