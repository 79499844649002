import styled, { css } from 'styled-components';
import { device } from 'modules/utils';
import { ColumnContainer, RowContainer } from '../Styled';

export const MobileHeader = styled(RowContainer)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    css`
      .hamburger {
        display: ${isOpen ? 'none' : 'inline-block'};
      }

      .close {
        display: ${isOpen ? 'inline-block' : 'none'};
        & path {
          fill: ${({ theme }) => theme.ga.white};
        }
      }
    `}

  img {
    width: 150px;
  }

  height: 56px;
  background-color: ${({ theme }) => theme.ga.darkBlue};
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 22px;

  svg {
    cursor: pointer;
  }

  @media ${device.tablet} {
    display: flex;
    position: relative;
  }
`;

export const MenuContainer = styled(ColumnContainer)<{
  isOpen: boolean;
}>`
  display: none;
  @media ${device.tablet} {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.ga.white};
    }
    display: ${({ isOpen }) => (isOpen ? 'inline-block' : 'none')};
    height: calc(100vh - (56px + 14px + 14px));
    width: 100vw;
    box-sizing: border-box;
    padding: 14px 22px;
    background-color: ${({ theme }) => theme.ga.darkBlue};
    position: absolute;
    top: calc(56px + 14px + 14px);
    z-index: 2;

    transition: left 0.5s;

    ${({ isOpen }) => `left: ${isOpen ? '0px' : '100vw'};`}
  }
`;

export const LogoutContainer = styled.div`
  bottom: 40px;
  position: absolute;
`;
