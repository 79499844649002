/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  H3,
  TextBold,
  TextDescription,
  TextRegular,
  Spinner,
  TextSuccess,
} from 'modules/ui';
import { Paths } from 'paths';
import { authResponse } from 'modules/auth/redux/selectors';
import { AuthDictionary } from 'modules/auth/dictionary';
import { resendVerifyEmail, verifyEmail } from '../../redux';
import { LoginRedirectContainer } from '../styled';
import { CenterTextContainer, BottomTextContainer } from './styled';
import { AuthLayout } from '../../components';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const VerifyEmail: React.FC = React.memo(() => {
  const verifyResponse = useSelector(authResponse);
  const query = useQuery();
  const token = query.get('token') || '';
  const email = query.get('email') || '';
  const dispatch = useDispatch();
  const history = useHistory();
  const [resentEmail, setResentEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifySuccess = verifyResponse.success;

  const handler = useCallback(async () => {
    setLoading(true);
    await dispatch(verifyEmail({ token, email }));
    setLoading(false);
  }, [token, email]);

  useEffect(() => {
    if (token && email) {
      handler();
    }
  }, [token, email]);

  const redirectToLogin = () => {
    history.push(Paths.UserLogin);
  };

  const handleResendEmail = async () => {
    await dispatch(
      resendVerifyEmail({
        email,
      }),
    );
    setResentEmail(true);
  };

  const resendEmailButton = (
    <>
      {!resentEmail && (
        <Button isLink label="Resend email" clickHandler={handleResendEmail} />
      )}
      {resentEmail && (
        <TextSuccess>{AuthDictionary.EMAIL_RESENT_SUCCESS}</TextSuccess>
      )}
    </>
  );

  if (loading) {
    return (
      <LoginRedirectContainer>
        <Spinner />
      </LoginRedirectContainer>
    );
  }

  return (
    <AuthLayout>
      {token && verifySuccess && (
        <LoginRedirectContainer>
          <H3>Your account is successfully verified.</H3>
          <Button label="Log in" clickHandler={redirectToLogin} />
        </LoginRedirectContainer>
      )}
      {token && !verifySuccess && !loading && (
        <LoginRedirectContainer>
          <H3>There was an error verifying your email</H3>
          {resendEmailButton}
        </LoginRedirectContainer>
      )}
      {!token && (
        <LoginRedirectContainer>
          <CenterTextContainer>
            <H3>Please verify your email address</H3>
            <TextRegular>
              Verification email has just been sent to
              <TextBold> {email}. </TextBold>
              Verify your email and complete registration.
            </TextRegular>
          </CenterTextContainer>
          <BottomTextContainer>
            <TextDescription>
              If you have not receive the email after a few minutes, please
              check your spam folder or resend email.
            </TextDescription>
            {resendEmailButton}
          </BottomTextContainer>
        </LoginRedirectContainer>
      )}
    </AuthLayout>
  );
});
