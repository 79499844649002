import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Attendee } from 'modules/utils';
import * as QueueActions from './actions';

// todo
export const recorder = createReducer(true, (builder) => builder);

export const list = createReducer([] as Attendee[], (builder) => {
  builder
    .addCase(QueueActions.getBpsForWaitingRoom.fulfilled, (_state, action) => {
      return action.payload.map((attendee) => ({
        id: attendee.userId,
        firstName: attendee.userProfileDto.firstName,
        lastName: attendee.userProfileDto.lastName,
        role: attendee.userProfileDto.role,
        bookedParticipantSlotId: attendee.bookedParticipantSlotId,
        position: attendee.position,
        duration: attendee.duration,
        positionInQueue: attendee.positionInQueue,
        attendeeGuid: attendee.attendeeGuid,
        talentSessionGuid: attendee.talentSessionGuid,
        techCheckSessionGuid: '',
        waitingRoomGuid: attendee.waitingRoomGuid,
        isApproved: false,
        variantId: 0,
        variantTitle: attendee.variantTitle,
        showInQueue: attendee.positionInQueue !== null,
      }));
    })
    .addCase(
      QueueActions.updateAttendeesOrder,
      (_state, action) => action.payload,
    );
});

export const reducer = combineReducers({
  list,
  recorder,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'queue',
  storage,
};

export const queueReducer = persistReducer(persistConfig, reducer);
