/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';
import {
  EmptySessions,
  H4Bold,
  TextRegular,
  PersonIcon,
  PlayIcon,
  ImageIcon,
  StartIcon,
  TimerIcon,
  Button,
  Spinner,
  SvgButton,
  CalendarPicker,
  DayValue,
  TextSmall,
} from 'modules/ui';
import { VideoPlayer, VideoDownloadButton } from 'modules/video-player';
import {
  getDigitalAssetsGroupedByBookingSlot,
  // DigitalAssetsGroupedByBookingSlot,
  changeDigitalAssetAvailability,
  DigitalAssetGroupedBySlot,
  // DigitalAsset,
  DigitalVideoAsset,
  // URL as ApiURLS,
} from 'modules/digital-assets';

import { useModal } from 'modules/main';
import fileDownload from 'js-file-download';
import {
  Container,
  MainContainer,
  SideContainer,
  VideosContainer,
  Video,
  Actions,
  Attendee,
  AttendeeContainer,
  StatTime,
  VideoLength,
  ButtonWrapper,
  ScrollableContainer,
  SessionContainer,
} from './styled';

export const Videos: React.FC = React.memo(() => {
  const [isOpen, open, close] = useModal(false);
  const [activeAsset, setActiveAsset] = useState<DigitalVideoAsset | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [bpsToggling, setBpsToggling] = useState(-1);
  const [groupedDigitalAssets, setGroupedDigitalAssets] = useState<
    DigitalAssetGroupedBySlot[]
  >([]);

  const today = new Date();
  const todayDayValue: DayValue = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };
  const [selectedDate, setSelectedDate] = useState<DayValue>(todayDayValue);

  const handleDownloadVideos = useCallback(async () => {
    if (!selectedDate) {
      return;
    }

    const date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day,
    );

    try {
      setIsLoading(true);
      const videoAssetsResponse = await getDigitalAssetsGroupedByBookingSlot(
        date,
      );
      setGroupedDigitalAssets(videoAssetsResponse.result);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    document.title = `GalaxyCon Media`;
    handleDownloadVideos();
  }, [selectedDate]);

  const disableVideo = useCallback(
    async (
      bookedParticipantSlotId: number,
      previousValue: boolean,
      name: string,
    ) => {
      try {
        setBpsToggling(bookedParticipantSlotId);
        await changeDigitalAssetAvailability({
          bookedParticipantSlotId,
          isEnabled: !previousValue,
        });
        setGroupedDigitalAssets((state) => {
          const newState = state.map((value) => {
            if (value.waitingRoomName !== name) return value;
            return {
              bookingSlotId: value.bookingSlotId,
              bookingSlotStartTime: value.bookingSlotStartTime,
              bookingSlotEndTime: value.bookingSlotEndTime,
              waitingRoomGuid: value.waitingRoomGuid,
              waitingRoomName: value.waitingRoomName,
              items: value.items.map((asset) => {
                if (asset.bookedParticipantSlotId !== bookedParticipantSlotId)
                  return asset;
                return {
                  ...asset,
                  isEnabled: !previousValue,
                };
              }),
            } as DigitalAssetGroupedBySlot;
          });
          return newState;
        });
      } catch (error) {
        // Handle error
      } finally {
        setBpsToggling(-1);
      }
    },
    [],
  );

  const playVideo = useCallback(
    (asset: DigitalVideoAsset) => {
      setActiveAsset({ ...asset });
      open();
    },
    [open],
  );

  const closeVideoPlayer = useCallback(() => {
    setActiveAsset(null);
    close();
  }, [close]);

  const downloadImage = async (imageUrl: string, imageName: string) => {
    const imageRes = await fetch(imageUrl);
    const imageBlob = await imageRes.blob();

    fileDownload(imageBlob, imageName);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      <SideContainer>
        <CalendarPicker value={selectedDate} onChange={setSelectedDate} />
      </SideContainer>
      <ScrollableContainer>
        <MainContainer>
          {isOpen && activeAsset && (
            <VideoPlayer
              isOpen={isOpen}
              onRequestClose={closeVideoPlayer}
              url={activeAsset.videoUrl}
              title={activeAsset.firstName}
              startTime={activeAsset.startTime}
              bookedParticipantSlotId={activeAsset.bookedParticipantSlotId}
            />
          )}
          {!groupedDigitalAssets.length && <EmptySessions isVideos />}
          {groupedDigitalAssets.map((group) => {
            if (group !== null) {
              return (
                <SessionContainer key={group.bookingSlotId}>
                  <H4Bold>{group.waitingRoomName}</H4Bold>
                  <VideosContainer>
                    {group.items.map((video) => {
                      const isDisabled = !video.isEnabled;
                      const imagesUrls = video.imageUrl;
                      const lastImageUrl = imagesUrls[imagesUrls.length - 1];
                      const durationMin = Math.floor(video.duration / 60);
                      const durationSec = String(video.duration % 60).padStart(
                        2,
                        '0',
                      );
                      const duration = `${durationMin}:${durationSec}`;

                      return (
                        <Video key={`${video.digitalAssetId}`}>
                          <Attendee>
                            <PersonIcon />
                            <AttendeeContainer>
                              <TextRegular>{`${video.firstName} ${video.lastName}`}</TextRegular>
                              <TextSmall>{video.email}</TextSmall>
                            </AttendeeContainer>
                          </Attendee>
                          <StatTime>
                            <StartIcon />
                            <TextRegular>
                              {format(
                                utcToZonedTime(new Date(video.startTime), 'ET'),
                                'yyyy/MM/dd/ hh:mm aa',
                              )}
                            </TextRegular>
                          </StatTime>
                          <VideoLength>
                            <TimerIcon />
                            <TextRegular>{duration}</TextRegular>
                          </VideoLength>
                          <Actions>
                            <SvgButton
                              disabled={isDisabled}
                              onClick={() => playVideo(video)}
                            >
                              <PlayIcon />
                            </SvgButton>
                            <VideoDownloadButton
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              url={video.videoUrl!}
                              bps={video.bookedParticipantSlotId}
                              name={video.firstName}
                              isVideoAvailable={!isDisabled}
                            />
                            <SvgButton
                              disabled={isDisabled || !lastImageUrl}
                              onClick={() => {
                                const imageName = lastImageUrl.substring(
                                  lastImageUrl.lastIndexOf(`/`),
                                );
                                downloadImage(lastImageUrl, imageName);
                              }}
                            >
                              <ImageIcon />
                            </SvgButton>
                            <ButtonWrapper isDisabled={isDisabled}>
                              {bpsToggling !== video.bookedParticipantSlotId ? (
                                <Button
                                  label={isDisabled ? 'Enable' : 'Disable'}
                                  isDark
                                  clickHandler={() =>
                                    disableVideo(
                                      video.bookedParticipantSlotId,
                                      video.isEnabled,
                                      group.waitingRoomName,
                                    )
                                  }
                                />
                              ) : (
                                <Spinner />
                              )}
                            </ButtonWrapper>
                          </Actions>
                        </Video>
                      );
                    })}
                  </VideosContainer>
                </SessionContainer>
              );
            }
            return null;
          })}
        </MainContainer>
      </ScrollableContainer>
    </Container>
  );
});
