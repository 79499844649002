/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Paths } from 'paths';
import { AdminEventLayout } from 'modules/admin';
import { EventForm, ProductsPicker } from '../components';
import { EventDictionary } from '../dictionary';
import { useProducts } from '../hooks';
import { EventsSelectors, EventsActions } from '../redux';
import { EventFormData } from '../types';

export const EditEventPage: React.FC = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<Record<string, string | undefined>>();

  const event = useSelector(EventsSelectors.getEventById(Number(id)));
  const [selectedProducts, selectProduct, deselectProduct] = useProducts(
    event ? event.products : [],
  );

  const onSubmit = useCallback(
    (formData: EventFormData) => {
      dispatch(
        EventsActions.updateGalaxyEvent({
          ...formData,
          id: event.id,
          products: selectedProducts.map((p) => p.id),
        }),
      );
      history.push(Paths.Sessions);
    },
    [dispatch, event, selectedProducts],
  );

  const closeHandler = useCallback(() => {
    history.push(Paths.Sessions);
  }, [history]);

  useEffect(() => {
    dispatch(EventsActions.getEventProducts());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(EventsActions.getEventById(Number(id)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    document.title = `GalaxyCon Edit Session`;
  });

  return (
    <AdminEventLayout>
      {event && (
        <>
          <EventForm
            onSubmit={onSubmit}
            onClose={closeHandler}
            title={EventDictionary.EDIT_SESSION}
            disabled={!selectedProducts.length}
            event={event}
          />
          <ProductsPicker
            eventProducts={event.products}
            selectedProducts={selectedProducts}
            deselectProduct={deselectProduct}
            selectProduct={selectProduct}
          />
        </>
      )}
    </AdminEventLayout>
  );
});
