/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Response } from 'modules/utils';
import { api } from './api';

export const get = <T>(url: string): Promise<Response<T>> => {
  return api.get(url).json();
};

export const create = <T, P>(url: string, body: P): Promise<Response<T>> => {
  return api.post(url, { json: body }).json();
};

export const update = <T, P>(url: string, body: P): Promise<Response<T>> => {
  return api.put(url, { json: body }).json();
};

export const remove = <T>(url: string): Promise<Response<T>> => {
  return api.delete(url).json();
};

export const getFile = <T>(url: string): Promise<any> => {
  return api.get(url);
};
