import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { PAUSE } from 'redux-persist';
import { TalentMeetingActionType, AuthorizeTalentBody } from '../types';
import {
  authorizeTalentRequest,
  toggleTalentBreakRequest,
  getFanQuestionnaireAnswersRequest,
} from '../api';

export const setTalentName = createAction<string>('SET_TALENT_NAME');
export const hideOptionsOnNextFanJoin = createAction(
  'HIDE_OPTIONS_ON_NEXT_FAN_JOIN',
);
export const authorizeTalent = createAsyncThunk(
  TalentMeetingActionType.AUTHORIZE_TALENT,
  async (req: AuthorizeTalentBody, { dispatch }) => {
    const response = await authorizeTalentRequest(req);

    // prevents persisting redux state in case talent is authorized
    dispatch({ type: PAUSE });
    window.localStorage.clear();

    return response;
  },
);

export const toggleTalentBreak = createAsyncThunk(
  TalentMeetingActionType.TOGGLE_TALENT_BREAK,
  toggleTalentBreakRequest,
);

export const getFanQuestionnaireAnswers = createAsyncThunk(
  TalentMeetingActionType.GET_FAN_QUESTIONNAIRE_ANSWERS,
  getFanQuestionnaireAnswersRequest,
);
