/* eslint-disable no-shadow */
export enum Roles {
  NONE = 0,
  ADMIN = 1,
  TECH = 2,
  FAN = 3,
  TALENT = 4,
}

export const passwordMinLength = 6;
