import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullCalendar, {
  EventContentArg,
  EventClickArg,
  DayHeaderContentArg,
} from '@fullcalendar/react';
import { GalaxyEventModel } from 'modules/galaxy-events';
import { CalendarEventTitle } from 'modules/ui';
import { EventInfo, DatePickerDate } from '../../types';
import { EventModal } from '../EventModal';
import * as ScheduleUtils from '../../utils';
import { config } from './config';
import {
  CalendarContainer,
  CustomDayHeader,
  Month,
  Day,
  CustomEvent,
} from './styled';

type Props = {
  events: GalaxyEventModel[];
  selectedDate: DatePickerDate | null;
};

const renderCustomHeader = (arg: DayHeaderContentArg) => {
  const { text, isToday } = arg;
  const [day, month] = text.split(' ');

  return (
    <CustomDayHeader>
      <Month>{month}</Month>
      <span> </span>
      <Day isToday={!!isToday}>{day}</Day>
    </CustomDayHeader>
  );
};

const renderCustomEvent = (arg: EventContentArg) => {
  return (
    <CustomEvent>
      <CalendarEventTitle>{arg.event.title}</CalendarEventTitle>
      <br />
      <span>{arg.timeText}</span>
    </CustomEvent>
  );
};

export const Calendar: React.FC<Props> = React.memo(
  ({ events, selectedDate }) => {
    const calendarRef = useRef<FullCalendar>(null);
    const [selectedEvent, setSelectedEvent] = useState<EventInfo>();

    const handleEventClick = useCallback(
      (arg: EventClickArg) => {
        const { event } = arg;
        const start = event.start as Date;
        const end = event.end as Date;

        const infoDate = ScheduleUtils.getEventInfoDate(start, end);

        setSelectedEvent({
          url: event.url,
          title: event.title,
          uid: event.extendedProps.sessionId,
          ...infoDate,
          data: event.extendedProps,
        });
      },
      [selectedEvent],
    );

    const handleRequestClose = useCallback(() => {
      setSelectedEvent(undefined);
    }, []);

    const newDate = useMemo((): string => {
      if (!selectedDate) return '';
      const date = new Date();

      date.setFullYear(
        selectedDate.year,
        selectedDate.month - 1,
        selectedDate.day,
      );
      return date.toISOString();
    }, [selectedDate]);

    useEffect(() => {
      if (calendarRef && calendarRef.current && newDate) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(newDate);
      }
    }, [newDate]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendarEvents = [...events] as any;

    return (
      <>
        <CalendarContainer>
          <FullCalendar
            ref={calendarRef}
            events={calendarEvents}
            {...config}
            eventClick={handleEventClick}
            eventContent={renderCustomEvent}
            dayHeaderContent={renderCustomHeader}
          />
        </CalendarContainer>
        {!!selectedEvent && (
          <EventModal
            isOpen={!!selectedEvent}
            onRequestClose={handleRequestClose}
            event={selectedEvent}
          />
        )}
      </>
    );
  },
);
