import React from 'react';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'modules/auth';
import { NavLink } from 'react-router-dom';
import {
  H3,
  SideNavigationText,
  TextBold,
  Button,
  LeftChevronIcon,
} from 'modules/ui';
import {
  AccountNavBar,
  LogOutContainerDesktop,
  LogOutContainerMobile,
  MobileAccountMenu,
} from './styled';
import { useSettingsMenuModal } from '../../hooks';

type RouteType = {
  label: string;
  path: string;
};

type Props = {
  routes: RouteType[];
};

export const SideNavigation: React.FC<Props> = React.memo(({ routes }) => {
  const [isOpen, openMenu, closeMenu] = useSettingsMenuModal(true);
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logOutUser());
  };
  return (
    <AccountNavBar>
      {routes.map((route: RouteType) => (
        <NavLink
          key={route.path}
          strict
          to={route.path}
          activeClassName="active"
        >
          <SideNavigationText className="desktop-text">
            {route.label}
          </SideNavigationText>
          <span className="mobile-menu-button" onClick={openMenu} role="button">
            <LeftChevronIcon />
            <TextBold>{route.label}</TextBold>
          </span>
        </NavLink>
      ))}
      <MobileAccountMenu isOpen={isOpen}>
        {routes.map((route: RouteType) => (
          <NavLink
            key={route.path}
            strict
            to={route.path}
            activeClassName="active"
          >
            <H3 className="mobile-text" onClick={closeMenu}>
              {route.label}
            </H3>
          </NavLink>
        ))}
        <LogOutContainerMobile>
          <Button isDark label="Log out" clickHandler={logOutHandler} />
        </LogOutContainerMobile>
      </MobileAccountMenu>
      <LogOutContainerDesktop>
        <Button isDark label="Log out" clickHandler={logOutHandler} />
      </LogOutContainerDesktop>
    </AccountNavBar>
  );
});
