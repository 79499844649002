import styled from 'styled-components/macro';
import { ColumnContainer, RowContainer, TextRegular } from 'modules/ui';
import { device } from 'modules/utils';

export const MainContainer = styled(RowContainer)<{ applyRedBorder?: boolean }>`
  height: 100%;
  background-color: ${({ theme }) => theme.ga.darkBlue};
  padding: 8px 16px 16px 16px;
  box-sizing: border-box;
  border: ${({ applyRedBorder }) =>
    applyRedBorder ? 'solid red 5px' : 'unset'};

  & > button {
    height: 40px;
    margin: auto;
    background: #de106b;
    border-radius: 4px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 4px 10px;
  }

  @media ${device.mobile} {
    padding: 0px;
  }
`;
export const Container = styled(ColumnContainer)`
  width: 100%;
  color: ${({ theme }) => theme.ga.white};
  margin-right: 16px;

  @media ${device.mobile} {
    margin: 0px;
  }
`;

export const TitleContainer = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }

  @media ${device.mobile} {
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 999;
    svg {
      display: none;
    }
  }
`;

export const Network = styled(RowContainer)`
  justify-content: flex-end;
  align-items: center;
  svg {
    margin-left: 8px;
  }
`;

export const SessionControls = styled(ColumnContainer)`
  width: 200px;
  position: absolute;
  right: 20px;
  bottom: 97px;
  z-index: 99999;

  button:first-child() {
    margin: 10px;
  }
`;

export const VideoContainer = styled(ColumnContainer)`
  position: relative;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.ga.semiDarkGray};
  .video-grid {
    background-color: transparent;

    & > div:last-child {
      display: none;
    }
  }

  @media ${device.mobile} {
    width: 100%;
    border-radius: 0px;
  }
`;

export const AsideContainer = styled(ColumnContainer)`
  width: 300px;
  height: 100%;
  color: ${({ theme }) => theme.ga.white};
  ${TextRegular} {
    text-align: right;
  }

  @media ${device.mobile} {
    position: fixed;
    left: 0px;
    bottom: -267px;
    z-index: 9999;
    width: 100%;
    max-height: 267px;
  }
`;

export const LocalVideoContainer = styled.div`
  width: 300px;
  min-height: 180px;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;

  @media ${device.mobile} {
    display: none;
  }
`;

export const InfoCardContainer = styled.div`
  position: absolute;
  left: 32px;
  max-width: 420px;
  top: 32px;
  z-index: 3;
  @media ${device.mobile}, ${device.tablet} {
    position: absolute;
    max-width: 337px;
    bottom: 120px;
    left: 18px;
    top: unset;
  }
`;

export const ChatOverContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 75px;
  /* z-index: 4; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
