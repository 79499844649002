/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import * as ErrorActions from './actions';

const errors = createReducer([] as any[], (builder) => {
  builder
    .addCase(ErrorActions.addError, (state, action) => [
      ...state,
      action.payload,
    ])
    .addCase(ErrorActions.removeErrors, () => []);
});

export const errorsReducer = combineReducers({
  collection: errors,
});
