/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { format } from 'date-fns';
import { useModal } from 'modules/main';

import { CalendarIcon, PlayIcon, SvgButton } from 'modules/ui';
import { VideoPlayer, VideoDownloadButton } from 'modules/video-player';
import { DigitalAsset } from '../../types';
import {
  VideoItem,
  Header,
  DateContainer,
  CalendarIconContainer,
  VideoControlsContainer,
  VideoTitle,
  VideoUnavalableDescription,
} from './styled';

type Props = {
  videoItem: DigitalAsset;
};

const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;

export const VideoListItem: React.FC<Props> = ({ videoItem }) => {
  const [isOpen, open, close] = useModal(false);

  const now = new Date().getTime();
  const productTime = new Date(videoItem.startDateTime).getTime();
  const isVideoAvailable =
    Boolean(videoItem.referenceUri) && now - productTime <= SEVEN_DAYS;

  return (
    <VideoItem>
      <Header>
        <DateContainer>
          <CalendarIconContainer>
            <CalendarIcon />
          </CalendarIconContainer>
          {format(new Date(videoItem.startDateTime), 'MMMM do, p')}
        </DateContainer>
        {isVideoAvailable && (
          <VideoControlsContainer>
            <SvgButton disabled={!isVideoAvailable} onClick={open}>
              <PlayIcon />
            </SvgButton>
            <VideoDownloadButton
              url={videoItem.referenceUri!}
              bps={videoItem.bookedParticipantSlotId}
              name={videoItem.productName}
              isVideoAvailable={isVideoAvailable}
            />
          </VideoControlsContainer>
        )}
      </Header>
      <VideoTitle>{videoItem.productName}</VideoTitle>
      {!isVideoAvailable && (
        <VideoUnavalableDescription>
          This video is not available for download. If you have questions, email
          info@galaxycon.com
        </VideoUnavalableDescription>
      )}

      {isVideoAvailable && isOpen && (
        <VideoPlayer
          isOpen={isOpen}
          onRequestClose={close}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          url={videoItem.referenceUri!}
          title={videoItem.productName}
          startTime={videoItem.startDateTime}
          bookedParticipantSlotId={videoItem.bookedParticipantSlotId}
        />
      )}
    </VideoItem>
  );
};
