import { ColumnContainer, Arrow, Label, RowContainer } from 'modules/ui';
import styled from 'styled-components';

export const NavigatorContainer = styled(ColumnContainer)`
  flex: 0 0 350px;

  & > ${Label} {
    text-transform: none;
    margin: 33px 0px 0px 32px;
  }

  .event,
  .Calendar__day.-today.event:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    position: relative;
    &::after {
      content: '';
      z-index: 99;
      position: absolute;
      bottom: 0.2em;
      display: block;
      width: 0.4em;
      height: 0.4em;
      background: ${({ theme }) => theme.ga.magenta};
      left: 50%;
      border-radius: 50%;
      transform: translateX(-50%);
      transition: 0.2s;
      opacity: 1;
    }

    &.-selected {
      &::after {
        background: ${({ theme }) => theme.ga.white};
      }
    }
  }

  .Calendar {
    box-shadow: none;

    .Calendar__header {
      display: flex;
      flex-direction: row;

      button:first-child {
        order: 2;
      }

      button:last-child {
        order: 3;
      }

      .Calendar__monthYearContainer {
        .Calendar__monthYear > * {
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .Calendar__monthYear {
        left: 29%;
      }

      .Calendar__monthArrowWrapper {
        .Calendar__monthArrow {
          background-image: url(${Arrow});
        }

        &.-left {
          transform: rotate(180deg);
        }
        &.-right {
          transform: rotate(0deg);
        }
      }
    }
  }
`;

export const Header = styled(RowContainer)`
  padding: 20px 33px;
  align-items: center;

  .dropdown {
    transform: rotate(-90deg);
    margin-left: 8px;
  }
`;
