import React, { useEffect } from 'react';
import { H4, ButtonLink } from 'modules/ui';
import { Category } from 'modules/client/types';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
} from 'modules/common-meeting';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryContainer, InfoBox } from './styled';

import AnimationImage from '../../assets/animation.jpeg';
import AnimeImage from '../../assets/anime.jpeg';
import HeroesImage from '../../assets/heroesAndVillains.jpeg';
import PopCultureImage from '../../assets/popCulture.jpeg';
import SciFiImage from '../../assets/sciFi.jpeg';
import VideoGamesImage from '../../assets/videoGames.jpeg';

interface CategoryImages {
  [key: string]: string;
}

const Images: CategoryImages = {
  animation: AnimationImage,
  anime: AnimeImage,
  heroesAndVillains: HeroesImage,
  popCulture: PopCultureImage,
  sciFi: SciFiImage,
  videoGames: VideoGamesImage,
};

type Props = {
  category: Category;
};

export const CategoryItem: React.FC<Props> = React.memo(({ category }) => {
  const dispatch = useDispatch();
  const { name, imageKey, path } = category;
  const url = useSelector(CommonMeetingSelectors.getShopifyRedirectUrl);
  const imageUrl = Images[imageKey as keyof typeof Images];

  useEffect(() => {
    if (!url) {
      dispatch(CommonMeetingActions.getShopifyRedirect());
    }
  }, [url]);

  const categoryUrl = `https://galaxycon.com${category.path}?return_to=${path}`;

  return (
    <CategoryContainer>
      <img src={imageUrl} alt={category.name} />
      <InfoBox>
        <H4>{name}</H4>
        <ButtonLink href={categoryUrl} target="_blank" rel="noreferrer">
          See more details
        </ButtonLink>
      </InfoBox>
    </CategoryContainer>
  );
});
