import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';

export const chatSelector = (state: RootState): RootState['chat'] => state.chat;

export const getMainChatMessages = createSelector(
  [chatSelector],
  (chat) => (isTechCheck: boolean) => (isTechCheck ? chat.support : chat.main),
);

export const getAccessToken = createSelector(
  [chatSelector],
  (chat) => chat.twilioAccessToken,
);

export const getTwilioChats = createSelector(
  [chatSelector],
  (chat) => chat.twilioChats,
);

export const isTwilioAccessTokenFetchingInProgress = createSelector(
  [chatSelector],
  (chat) => chat.isTwilioAccessTokenFetchingInProgress,
);

export const getChannelNames = createSelector(
  [chatSelector],
  (chat) => chat.channelNames,
);

export const getOutgoingMessagesQueue = createSelector(
  [chatSelector],
  (chat) => chat.outgoingMessagesQueue,
);
