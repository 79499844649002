import { Button } from 'modules/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'modules/main';
import { FinishSessionModal } from '../FinishSessionModal';

import { CommonMeetingActions, CommonMeetingSelectors } from '../../redux';
import { Container } from './styled';

export const SessionControls: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [isFinishModalOpen, openFinishModal, closeFinishModal] = useModal(
    false,
  );

  const waitingRoomGuid = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );
  const fanInCallBpsId = useSelector(
    CommonMeetingSelectors.getBpsIdForFanParticipantInLiveCall,
  );

  const isAnyFanParticipantInLiveCall = useSelector(
    CommonMeetingSelectors.isAnyFanParticipantInLiveCall,
  );

  const sendToQueue = () => {
    if (fanInCallBpsId) {
      dispatch(
        CommonMeetingActions.sendToQueue({
          bookedParticipantSlotId: fanInCallBpsId,
          waitingRoomGuid,
        }),
      );
    }
  };

  const finishSessionForFan = () => {
    if (fanInCallBpsId) {
      dispatch(
        CommonMeetingActions.finishParticipant({
          bookedParticipantSlotId: fanInCallBpsId,
          waitingRoomGuid,
        }),
      );
      closeFinishModal();
    }
  };

  return (
    <>
      <Container>
        <Button
          isMeeting
          isSecondary
          label="Send fan to Queue"
          clickHandler={sendToQueue}
          disabled={!isAnyFanParticipantInLiveCall}
        />
        <Button
          isMeeting
          isTertiary
          label="Finish this session"
          clickHandler={openFinishModal}
          disabled={!isAnyFanParticipantInLiveCall}
        />
      </Container>
      {isFinishModalOpen && (
        <FinishSessionModal
          isOpen={isFinishModalOpen}
          onRequestClose={closeFinishModal}
          finishSession={finishSessionForFan}
        />
      )}
    </>
  );
});
