import { useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import {
  NetworkGoodIcon,
  NetworkNoIcon,
  NetworkWeakIcon,
} from 'modules/ui/assets';
import { TextRegular } from 'modules/ui/theme/typography';
import React from 'react';
import { Container } from './styled';

type Props = {
  attendeeUid: string;
};

export const NetworkQuality: React.FC<Props> = React.memo(({ attendeeUid }) => {
  const { signalStrength } = useAttendeeStatus(attendeeUid);

  return (
    <Container>
      <TextRegular>Network quality</TextRegular>
      {signalStrength === 0 && <NetworkNoIcon />}
      {signalStrength === 0.5 && <NetworkWeakIcon />}
      {signalStrength === 1 && <NetworkGoodIcon />}
    </Container>
  );
});
