import styled from 'styled-components';
import { Colors } from 'modules/ui';
import { device } from 'modules/utils';

export const RandomEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 350px;
  background-color: ${Colors.GrayBackground};
  padding: 50px 32px;

  span {
    margin-bottom: 22px;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
  }

  button {
    color: black;
    align-self: start;
    span {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    min-width: 300px;
  }

  @media ${device.tablet} {
    box-sizing: border-box;
    width: 100%;
  }
`;

export const InterestText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
`;
