import React from 'react';
import { Button, H2, Modal, TextRegular } from 'modules/ui';
import { ModalContainer, ButtonContainer } from './styled';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  finishSession: () => void;
};

export const FinishSessionModal: React.FC<Props> = React.memo(
  ({ isOpen, onRequestClose, finishSession }) => {
    return (
      <Modal
        isOpen={isOpen}
        fullWidth={false}
        closeModal={onRequestClose}
        onRequestClose={onRequestClose}
        isDark
        height={675}
        width={500}
      >
        <ModalContainer>
          <H2>Finish Session</H2>
          <TextRegular className="text">
            Are you sure you want to finish this session?
          </TextRegular>
          <ButtonContainer>
            <Button label="Cancel" isDark clickHandler={onRequestClose} />
            <Button label="Yes, Finish" clickHandler={finishSession} />
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    );
  },
);
