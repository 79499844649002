import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorInfo } from 'modules/errors';
import { configureAppStore } from 'modules/main';
import { GalaxyConTheme } from 'modules/ui';
import { Routes } from './Routes';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  // depth of state objects to report for redux
  normalizeDepth: 10,
});

export const { store, persistor } = configureAppStore();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <p>Something went wrong. Try refreshing a page or contact support.</p>
      }
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={GalaxyConTheme}>
            <Router>
              <Routes />
            </Router>
            <ErrorInfo />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();
