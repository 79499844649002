import dateFormat from 'dateformat';
import { Response, get, update } from 'modules/api';

import { URL } from '../const';
import {
  DigitalAssetGroupedBySlot,
  ToggleSendingEmailWithVideoUrlData,
} from '../types';

export const getDigitalAssetsGroupedByBookingSlot = (
  date: Date,
): Promise<Response<DigitalAssetGroupedBySlot[]>> => {
  const start = new Date(date);
  const formattedStart = dateFormat(start, 'yyyy-mm-dd');

  return get(
    `${URL.GET_DIGITAL_ASSETS_GROUPED_BY_BOOKING_SLOT}?skip=0&take=1000&StartTime=${formattedStart}&EndTime=${formattedStart}`,
  );
};

export const changeDigitalAssetAvailability = (
  body: ToggleSendingEmailWithVideoUrlData,
): Promise<Response<DigitalAssetGroupedBySlot[]>> =>
  update(URL.TOGGLE_SENDING_EMAIL_WITH_VIDEO_URL, body);
