import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, TextInput, FormCheckbox, EyeIcon } from 'modules/ui';
import { Validators } from 'modules/utils';
import { VerifyAdminData } from '../../types';
import { SubmitContainer, PasswordContainer } from '../../pages/styled';
import { passwordMinLength } from '../../consts';

type Props = {
  onSubmit: (data: VerifyAdminData) => void;
  email: string;
};

export const AdminSignupForm: React.FC<Props> = React.memo(
  ({ onSubmit, email }) => {
    const [isRevealingPassword, setisRevealingPassword] = useState(false);

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{
          email,
          password: '',
          terms: false,
        }}
        render={({ handleSubmit }): React.ReactNode => (
          <form onSubmit={handleSubmit}>
            <Field
              type="email"
              name="email"
              label="Email"
              component={TextInput}
              validate={Validators.composeValidators(
                Validators.required,
                Validators.mustBeValidEmail,
              )}
            />
            <PasswordContainer>
              <Field
                type={isRevealingPassword ? 'text' : 'password'}
                name="password"
                label="Set password"
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.mustHaveLowercase,
                  Validators.mustHaveUppercase,
                  Validators.mustHaveNumber,
                  Validators.mustHaveCharacter,
                  Validators.minLength(passwordMinLength),
                )}
                component={TextInput}
                password
                createPassword
                isRevealingPassword={isRevealingPassword}
              />
              <EyeIcon
                onClick={() => setisRevealingPassword(!isRevealingPassword)}
              />
            </PasswordContainer>
            <Field
              type="checkbox"
              name="terms"
              label="I agree to the Terms of Service and Privacy Policy"
              component={FormCheckbox}
              validate={Validators.mustBeChecked}
            />
            <SubmitContainer>
              <Button label="Create account" clickHandler={handleSubmit} />
            </SubmitContainer>
          </form>
        )}
      />
    );
  },
);
