import { createSelector } from '@reduxjs/toolkit';
import { BaseResource, Collection, Selector } from './types';

export const getAll = <S, T extends BaseResource>(
  rootSelector: Selector<S, Collection<T>>,
): Selector<S, T[]> =>
  createSelector([rootSelector], (items): T[] =>
    items.ids.map((id) => items.storage[id]),
  );

export const getById = <S, T extends BaseResource>(
  rootSelector: Selector<S, Collection<T>>,
) => (id: number): Selector<S, T> => (state: S) =>
  rootSelector(state).storage[id];
