/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCountdown } from 'modules/countdown';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { GalaxyConTheme } from 'modules/ui';
import { CommonMeetingActions, CommonMeetingSelectors } from '../../redux';
import { DEFAULT_CALL_VIEW_RED_ZONE_THRESHOLD } from '../../consts';
import { Countdown, CountdownNumber } from './styled';

export { Countdown };

type Props = {
  timeLeft: number;
  initialTime: number;
  inputRedZone?: number;
  isPaused?: boolean;
};

export const CountdownTimer: React.FC<Props> = React.memo(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ timeLeft, inputRedZone, isPaused, initialTime }) => {
    const dispatch = useDispatch();

    const isCallWithTalentInRedZone = useSelector(
      CommonMeetingSelectors.isCallWithTalentInRedZone,
    );
    const shouldPauseTimer = useSelector(
      CommonMeetingSelectors.isAnyFanParticipantInCallPaused,
    );

    const { secondsLeft } = useCountdown(timeLeft, shouldPauseTimer);

    const redZone = inputRedZone ?? DEFAULT_CALL_VIEW_RED_ZONE_THRESHOLD;
    const isLastSeconds = secondsLeft <= redZone;

    useEffect(() => {
      if (
        secondsLeft <= 0 ||
        secondsLeft > DEFAULT_CALL_VIEW_RED_ZONE_THRESHOLD
      ) {
        dispatch(CommonMeetingActions.setIsCallInRedZone(false));
      } else if (secondsLeft <= redZone && !isCallWithTalentInRedZone) {
        dispatch(CommonMeetingActions.setIsCallInRedZone(true));
      }
    }, [secondsLeft]);

    return (
      <Countdown>
        <CircularProgressbarWithChildren
          minValue={0}
          maxValue={initialTime}
          value={secondsLeft}
          styles={buildStyles({
            strokeLinecap: 'round',
            pathTransitionDuration: 1,
            pathColor: !isLastSeconds
              ? GalaxyConTheme.ga.green
              : GalaxyConTheme.ga.magenta,
            trailColor: GalaxyConTheme.ga.semiDarkGray,
          })}
          counterClockwise
        >
          <CountdownNumber time={secondsLeft} lastSeconds={isLastSeconds}>
            {secondsLeft}
          </CountdownNumber>
        </CircularProgressbarWithChildren>
      </Countdown>
    );
  },
);
