import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ChatActions } from '../redux';

export const useTwilioConnection = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ChatActions.connect());

    return () => {
      dispatch(ChatActions.disconnect());
    };
  }, []);
};
