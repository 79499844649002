import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  text-align: center;
`;

export const SpinnerRing = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;

  &:after {
    content: ' ';
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 6px solid ${({ theme }) => theme.ga.magenta};
    border-color: ${({ theme }) => theme.ga.magenta} transparent
      ${({ theme }) => theme.ga.magenta} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
