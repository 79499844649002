import styled from 'styled-components';
import {
  BtnText,
  ColumnContainer,
  RowContainer,
  TextRegular,
} from 'modules/ui';
import { device } from 'modules/utils';

export const Container = styled(RowContainer)``;
export const FormContainer = styled(ColumnContainer)`
  width: 56%;
  max-width: 550px;
  margin: 0 auto;

  form {
    display: block;
  }

  div {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 60px;
  }

  ${TextRegular} {
    margin: auto auto 20px auto;
  }

  ${BtnText} a {
    color: ${({ theme }) => theme.ga.magenta};
    text-decoration: none;
    margin-left: 11px;
  }

  @media ${device.tablet} {
    width: 90%;
    max-width: 550px;
    div {
      margin-bottom: 32px;
    }
  }

  @media ${device.mobile} {
    width: 100%;
    box-sizing: border-box;
    padding: 0 22px;
    div {
      margin-bottom: 22px;
    }
    h3 {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
`;

export const LoginRedirectContainer = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
  width: 56%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  button {
    margin: 20px 0;
    padding: 12px 30px;
  }
`;

export const ImageContainer = styled(ColumnContainer)`
  width: 45%;
  height: 100vh;
  background: ${(props) => props.theme.ga.blue};
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 60px;
  }
`;

export const LogoTextWrapper = styled.div`
  width: 45%;
  text-align: center;
`;

export const Separator = styled(RowContainer)`
  border-bottom: 1px solid #c4c4c4;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 36px !important;

  span {
    margin-bottom: -20px;
    background: ${(props) => props.theme.ga.white};
    padding: 10px 22px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => props.theme.ga.gray};
  }

  @media ${device.mobile} {
    border: none;
    margin-top: 0;
    margin-bottom: 20px !important;
  }
`;

export const Header = styled(ColumnContainer)`
  margin-top: 60px;
  margin-bottom: 0 !important;
  align-items: center;
  @media ${device.tablet} {
    margin-top: 19px;
  }

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const SubmitContainer = styled(ColumnContainer)`
  margin-top: 25px;
  align-items: center;
  .terms {
    position: absolute;
    bottom: 50px;
  }
  button {
    padding: 12px 30px;
  }
  a {
    color: ${(props) => props.theme.ga.magenta};
    text-decoration: none;
    font-family: inherit;
    font-weight: bold;
    font-size: 100%;
    border: 0;
    padding: 0 10px;
  }
`;

export const LoginButton = styled.button`
  color: ${(props) => props.theme.ga.magenta};
  background: none;
  font-family: inherit;
  font-weight: bold;
  font-size: 100%;
  border: 0;
  && {
    padding: 0 10px;
  }
`;

export const SideBySideFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0 !important;
  & > div {
    flex-grow: 1;
  }
  div:first-child {
    margin-right: 24px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    div:first-child {
      margin-right: 0;
    }
  }
`;

export const IconButtonContainer = styled(RowContainer)`
  button:first-child {
    margin-right: 24px;
  }

  @media ${device.mobile} {
    button:first-child {
      margin-right: 12px;
    }
  }
`;

export const PasswordContainer = styled.div`
  position: relative;
  margin: 0;

  svg {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;

    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const CheckMailLoginRedirect = styled.div`
  margin-top: 25px;
  align-items: center;
  a {
    background-color: ${({ theme }) => theme.ga.magenta};
    color: white;
    padding: 12px 26px;
    border-radius: 4px;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
  }
`;
