import { get } from 'modules/api';
import { Response } from 'modules/utils';
import { URL } from '../const';
import {
  GetWaitingRoomInfoResponse,
  GetCallJoiningInfoResponse,
} from '../types';

export const getWaitingRoomInfoRequest = (
  uid: string,
): Promise<Response<GetWaitingRoomInfoResponse>> => {
  return get(`${URL.GET_WAITING_ROOM_BY_UID}?WaitingRoomGuid=${uid}`);
};

export const getMainCallJoiningInfoRequest = ({
  waitingRoomGuid,
  bpsId,
}: {
  waitingRoomGuid: string;
  bpsId: number;
}): Promise<Response<GetCallJoiningInfoResponse>> =>
  get(
    `${URL.GET_MAIN_CALL_JOIN_INFO}?WaitingRoomGuid=${waitingRoomGuid}&BpsId=${bpsId}`,
  );

export const getReadinessCallJoiningInfoRequest = ({
  waitingRoomGuid,
  bpsId,
}: {
  waitingRoomGuid: string;
  bpsId: number;
}): Promise<Response<GetCallJoiningInfoResponse>> =>
  get(
    `${URL.GET_READINESS_CALL_JOIN_INFO}?WaitingRoomGuid=${waitingRoomGuid}&BpsId=${bpsId}`,
  );
