import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';
// import { User } from 'modules/auth';

export const userSelector = (state: RootState): RootState['user'] => state.user;

export const getUser = createSelector([userSelector], (user) => user);
export const getUserId = createSelector([userSelector], (user) => user.id);
export const getUserRole = createSelector([userSelector], (user) => user.role);
export const getTokens = createSelector([userSelector], (user) => user.tokens);
export const getUserEmail = createSelector(
  [userSelector],
  (user) => user.profile.email,
);
export const isUserLoggedIn = createSelector(
  [userSelector],
  (user) => user.isLoggedIn,
);
export const getExternalUserId = createSelector(
  [userSelector],
  (user) => user.externalId,
);
export const authResponse = createSelector(
  [userSelector],
  (user) => user.authResponse,
);
