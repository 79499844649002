import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import fileDownload from 'js-file-download';

import { DownloadIcon } from 'modules/ui';
import { DownloadButton, SpinnerContainer, SpinnerRing } from './styled';

type Props = {
  name: string;
  bps: number;
  url: string;
  isVideoAvailable: boolean;
};

export const VideoDownloadButton: React.FC<Props> = ({
  url,
  name,
  bps,
  isVideoAvailable,
}) => {
  const [isVideoDownloading, setIsVideoDownloading] = useState(false);

  const saveVideo = async () => {
    try {
      setIsVideoDownloading(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await fetch(url);
      const videoBlob = await response.blob();
      fileDownload(videoBlob, `${name}_${bps}.mp4`);

      setIsVideoDownloading(false);
    } catch (error) {
      Sentry.captureException(error, { extra: { name, bps, url } });
      setIsVideoDownloading(false);
    }
  };

  if (isVideoDownloading) {
    return (
      <SpinnerContainer>
        <SpinnerRing />
      </SpinnerContainer>
    );
  }

  return (
    <DownloadButton disabled={!isVideoAvailable} onClick={() => saveVideo()}>
      <DownloadIcon />
    </DownloadButton>
  );
};
