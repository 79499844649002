import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, FormSelectInput, TextInput } from 'modules/ui';
import { Validators } from 'modules/utils';
import { InviteFormContainer, ButtonContainer } from './styled';
import { NewAdminData } from '../../types';

type Props = {
  onSubmit: (data: NewAdminData) => void;
  closeModal: () => void;
};

export const InviteMemberForm: React.FC<Props> = React.memo(
  ({ onSubmit, closeModal }) => {
    return (
      <InviteFormContainer>
        <Form
          onSubmit={onSubmit}
          validateOnBlur
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            role: 1,
          }}
          render={({ handleSubmit }): React.ReactNode => (
            <>
              <Field
                type="text"
                name="firstName"
                label="FIRST NAME"
                placeholder="First name"
                component={TextInput}
                validate={Validators.required}
                isSecondary
              />
              <Field
                type="text"
                name="lastName"
                label="LAST NAME"
                placeholder="Last name"
                validate={Validators.required}
                component={TextInput}
                isSecondary
              />

              <Field
                type="text"
                name="email"
                label="EMAIL"
                placeholder="Email"
                component={TextInput}
                isSecondary
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.mustBeValidEmail,
                )}
              />
              <Field
                type="text"
                name="role"
                label="ROLE"
                placeholder="Role"
                component={FormSelectInput}
                isSecondary
                options={[
                  {
                    label: 'Admin',
                    value: 1,
                  },
                  {
                    label: 'Handler',
                    value: 2,
                  },
                ]}
                validate={Validators.composeValidators(Validators.required)}
              />
              <ButtonContainer>
                <Button label="Cancel" isDark clickHandler={closeModal} />
                <Button label="Send Invite" clickHandler={handleSubmit} />
              </ButtonContainer>
            </>
          )}
        />
      </InviteFormContainer>
    );
  },
);
