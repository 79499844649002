import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Modal,
  PersonIcon,
  TabCaptionText,
  TextBold,
} from 'modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import { EventsSelectors } from 'modules/galaxy-events/redux';
import { GalaxyEventModel } from '../../types';
import { SessionSlot } from '../SessionSlot';
import { getUserOrderInfos } from '../../redux/actions';
import {
  Container,
  ItalicText,
  SessionList,
  FanHeader,
  SessionsContainer,
  ButtonContainer,
  Header,
  OpenOrder,
} from './styled';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  galaxyEvent: GalaxyEventModel;
};

export const MergeSessionModal: React.FC<Props> = React.memo(
  ({ isOpen, closeModal, galaxyEvent }) => {
    const dispatch = useDispatch();
    const infos = useSelector(EventsSelectors.getOrderInfos);

    const shopifyBaseUrl = process.env.REACT_APP_SHOPIFY_BASE_URL;

    const navigateToShopify = useCallback(() => {
      window.open(`${shopifyBaseUrl}/admin/orders`, '_blank');
    }, []);

    const navigateOnShopifyOrder = useCallback((name: string) => {
      window.open(
        `${shopifyBaseUrl}/admin/orders?selectedView=all&query=${name}&status=open`,
        '_blank',
      );
    }, []);

    useEffect(() => {
      dispatch(getUserOrderInfos(galaxyEvent.id));
    }, [dispatch]);

    return (
      <>
        <Modal
          height={500}
          width={800}
          isOpen={isOpen}
          closeModal={closeModal}
          onRequestClose={closeModal}
        >
          <Container>
            <Header>
              <TextBold>Fan's Sessions</TextBold>
              <ItalicText>
                Merged sessions will be assigned to the last purchased session
              </ItalicText>
            </Header>
            <SessionsContainer>
              {infos.map(({ userProfile, productInfos }) => {
                return (
                  // Use email because userId possibly null
                  <SessionList key={userProfile.email}>
                    <FanHeader>
                      <PersonIcon />
                      <TextBold>
                        {userProfile.firstName}
                        {userProfile.lastName}
                      </TextBold>
                      <OpenOrder
                        onClick={() =>
                          navigateOnShopifyOrder(userProfile.firstName)
                        }
                      >
                        <TabCaptionText>Open order</TabCaptionText>
                      </OpenOrder>
                    </FanHeader>
                    {productInfos.map(
                      ({
                        externalOrderId,
                        duration,
                        productTitle,
                        variantTitle,
                        productId,
                      }) => {
                        return (
                          <SessionSlot
                            orderId={externalOrderId}
                            title={`${productTitle}, ${variantTitle}`}
                            duration={duration}
                            key={productId}
                          />
                        );
                      },
                    )}
                  </SessionList>
                );
              })}
            </SessionsContainer>
            <ButtonContainer>
              <Button label="Close" isDark clickHandler={closeModal} />
              <Button
                label="Open Shopify"
                isBlue
                clickHandler={navigateToShopify}
              />
            </ButtonContainer>
          </Container>
        </Modal>
      </>
    );
  },
);
