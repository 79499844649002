import { Response } from 'modules/utils';
import { create } from 'modules/api';
import {
  SendFanToTechCheckBody,
  ApproveFanRequestData,
  SetTechCheckFailedRequestData,
} from '../types';
import { URL } from '../consts';

export const sendFanToTechCheckRequest = (
  body: SendFanToTechCheckBody,
): Promise<Response<unknown>> => {
  return create(URL.SEND_FAN_TO_TECH_CHECK, body);
};

export const approveFanRequest = (
  body: ApproveFanRequestData,
): Promise<Response<unknown>> => create(URL.APPROVE_FAN, body);

export const setTechCheckFailedRequest = (
  body: SetTechCheckFailedRequestData,
): Promise<Response<unknown>> => create(URL.SET_TECH_CHECK_FAILED, body);
