import styled from 'styled-components';
import { ColumnContainer, H4Bold, RowContainer } from 'modules/ui';

export const Container = styled(ColumnContainer)`
  padding: 0px 24px;
  width: 100%;

  ${H4Bold} {
    margin: 22px 0px 15px;
  }

  label {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.ga.grayBackground};
    color: ${({ theme }) => theme.ga.darkBlue};

    .checkmark {
      border: 2px solid ${({ theme }) => theme.ga.darkBlue};
      top: calc(50% - 8px);
      left: 14px;
      height: 14px;
      width: 14px;
    }

    span:not(.checkmark) {
      margin-left: 11px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.ga.grayBackground};
    }
  }
`;

export const Products = styled(ColumnContainer)`
  overflow-y: scroll;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  border-radius: 8px;
`;

export const Footer = styled(RowContainer)`
  padding: 8px 0px 16px;
  align-items: center;

  & > span {
    color: ${({ theme }) => theme.ga.gray};
  }

  button:first-of-type {
    margin-right: 8px;
    margin-left: auto;
  }
`;
