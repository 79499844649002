import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Label, TextError } from 'modules/ui';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { InputContainer } from '../TextInput/styled';
import { customLocale } from './config';

// XXX: Commented due core package issue
// github.com/Kiarash-Z/react-modern-calendar-datepicker/issues/204
// import DatePicker from 'react-modern-calendar-datepicker';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';

type Props = {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & FieldRenderProps<any, HTMLElement>;

export const DateTimePicker: React.FC<Props> = React.memo(
  ({ label, input, meta, ...rest }) => {
    return (
      <InputContainer invalid={meta.modified && meta.error}>
        <Label>{label}</Label>
        <DatePicker
          {...input}
          {...rest}
          shouldHighlightWeekends
          locale={customLocale}
        />
        {meta.modified && meta.error && <TextError>{meta.error}</TextError>}
      </InputContainer>
    );
  },
);
