import { ColumnContainer, SmallCaption } from 'modules/ui';
import { device } from 'modules/utils';
// import { Controls } from 'modules/session/components/MeetingControls/styled';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  margin-bottom: 45px;
  ${SmallCaption} {
    color: ${({ theme }) => theme.ga.white};
  }
`;
export const Video = styled.video`
  height: 213px;
  border-radius: 8px;

  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    height: auto;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 320px;
  height: 213px;

  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    height: auto;
  }
`;
