import styled from 'styled-components';

export const H1 = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H2Regular = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H3 = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 36px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const LogoText = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 36px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H4 = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H4Bold = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H5 = styled.h5`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const H6 = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const NavigationText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

export const SideNavigationText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  /* font-feature-settings: 'pnum' on, 'lnum' on; */
`;

export const BtnText = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Label = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
`;

export const TextRegular = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
`;

export const SmallCaption = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.05em;
`;

export const TabCaptionText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
`;

export const TextBold = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
`;

export const TextItalic = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const TextError = styled(TextItalic)`
  color: ${({ theme }) => theme.ga.magenta};
`;

export const TextSuccess = styled(TextRegular)`
  color: ${({ theme }) => theme.ga.green};
`;

export const EventTitle = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.01em;
`;

export const TextSmall = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
`;

export const TextDescription = styled(TextItalic)`
  color: ${({ theme }) => theme.ga.gray};
`;

export const CTA = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const CalendarEventTitle = styled.span`
  font-family: Open Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
`;
