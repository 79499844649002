import { darkTheme } from 'amazon-chime-sdk-component-library-react';
import { DefaultTheme } from 'styled-components';

/* eslint-disable no-shadow */
export enum Colors {
  Magenta = '#EC1E79',
  Cyan = '#00BFF2',
  Blue = '#0D46AB',
  DarkBlue = '#030C18',
  SkyBlue = '#196CD1',
  LightBlue = '#3F92F7',
  MediumBlue = '#196CD1',
  BlueTile = '#121E2B',
  BlueTileLight = '#27394B',
  White = '#FFFFFF',
  WhiteHalfOpacity = 'rgba(255,255,255, 0.5)',
  LightGray = '#F5F5F5',
  FluorescentGreen = '#73FCFC',
  GrayBackground = '#EFF2F5',
  Pink = '#DE106B',
  GrayForms = '#1B232E',
  Green = '#42F033',
  Gray = '#98A7B8',
  SemiDarkGray = '#1A1E21',
  BlueGray = '#EDF4FE',
  DarkGray = '#061220',
  DarkGrayHalfOpacity = 'rgba(6,18,32,0.5)',
  Black = '#000000',
  GrayButton = '#EEEEEE',
  GrayBlueBackground = '#274554',
  CyanDarkBackground = '#205d74',
}

export const GalaxyConTheme: DefaultTheme = {
  ...darkTheme,
  ga: {
    secondaryButton: Colors.SkyBlue,
    magenta: Colors.Magenta,
    cyan: Colors.Cyan,
    blue: Colors.Blue,
    darkBlue: Colors.DarkBlue,
    mediumBlue: Colors.MediumBlue,
    blueTile: Colors.BlueTile,
    blueTileLight: Colors.BlueTileLight,
    white: Colors.White,
    whiteHalfOpacity: Colors.WhiteHalfOpacity,
    fluorescentGreen: Colors.FluorescentGreen,
    grayBackground: Colors.GrayBackground,
    grayForms: Colors.GrayForms,
    grayButton: Colors.GrayButton,
    gray: Colors.Gray,
    darkGray: Colors.DarkGray,
    darkGrayHalfOpacity: Colors.DarkGrayHalfOpacity,
    semiDarkGray: Colors.SemiDarkGray,
    blueGray: Colors.BlueGray,
    pink: Colors.Pink,
    green: Colors.Green,
    black: Colors.Black,
    grayBlueBackground: Colors.GrayBlueBackground,
    cyanDarkBackground: Colors.CyanDarkBackground,
  },
};

export type Theme = typeof GalaxyConTheme;
export * from './typography';
