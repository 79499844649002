import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  CommonMeetingActions,
  useConnectToChime,
} from 'modules/common-meeting';

export const useConnectToTechCheck = (): void => {
  const dispatch = useDispatch();

  const loggedInParticipantBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );

  const chimeMeetingInfo = useSelector(
    CommonMeetingSelectors.getTechCheckChimeMeetingInfo,
  );

  useEffect(() => {
    if (loggedInParticipantBpsId === null) {
      throw new Error(
        'useConnectToTechCheck called with loggedInParticipantBpsId=null',
      );
    }

    dispatch(
      CommonMeetingActions.getTechCheckCallJoiningInfo(
        loggedInParticipantBpsId,
      ),
    );

    return () => {
      dispatch(CommonMeetingActions.clearTechCheckJoiningInfo());
    };
  }, []);

  useConnectToChime(chimeMeetingInfo);
};
