import { matchPath } from 'react-router-dom';
import { Roles } from '../auth';
import { Paths } from '../../paths';

export const isAdminRole = (role: number): boolean => role === Roles.ADMIN;
export const isTechRole = (role: number): boolean => role === Roles.TECH;
export const isFanRole = (role: number): boolean => role === Roles.FAN;
// todo: check why do we need to match path
export const isTalentRole = (role: number): boolean =>
  role === Roles.TALENT ||
  !!matchPath(window.location.pathname, {
    path: [Paths.TalentSession, Paths.PrepareSession],
  });
