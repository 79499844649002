import styled from 'styled-components';
import { device } from 'modules/utils';

export const UserOrderContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.ga.grayBackground};
  margin-bottom: 16px;
  box-sizing: border-box;
  border-radius: 4px;

  .price {
    color: ${({ theme }) => theme.ga.secondaryButton};
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.ga.white};
  padding: 12px 24px 14px 24px;

  @media ${device.mobile} {
    flex-direction: column;
    .items {
      display: none;
    }
  }
`;

export const MidContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 24px 16px 24px;

  img {
    object-fit: cover;
    height: 86px;
    width: 86px;
    border-radius: 6px;
  }

  .title {
    margin-left: 10px;
    font-size: 18px;
  }

  @media ${device.laptop} {
    .title {
      font-size: 17px;
    }
  }

  @media ${device.mobile} {
    img {
      display: none;
    }
    .title {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.ga.white};
  padding: 12px 24px 14px 24px;
`;

export const ViewMoreToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .view-more {
    margin-right: 10px;
  }

  @media ${device.mobile} {
    .view-more {
      margin-right: 0;
    }
    .icon {
      display: none;
    }
  }
`;
