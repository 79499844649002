import styled from 'styled-components';
import { ColumnContainer, CTA, Label, RowContainer } from 'modules/ui';

export const Container = styled(ColumnContainer)`
  padding: 32px;
`;
export const Content = styled(ColumnContainer)`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};

  a {
    text-decoration: none;
  }

  ${CTA} {
    color: ${({ theme }) => theme.ga.magenta};
  }
`;

export const Info = styled(RowContainer)`
  align-items: center;
  ${Label} {
    margin-right: auto;
  }
`;

export const Live = styled(RowContainer)`
  background: ${({ theme }) => theme.ga.magenta};
  margin-left: auto;
  align-items: center;
  border-radius: 2px;
  padding: 0px 8px 0px 8px;
  height: 21px;
  ${Label} {
    color: ${({ theme }) => theme.ga.white};
    margin-right: 5px;
  }
`;
