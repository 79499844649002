import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setNextSessions, setPastSessions } from './actions';
import { SessionType } from '../types';

export const nextSessions = createReducer([] as SessionType[], (builder) => {
  builder.addCase(setNextSessions, (_state, action) => action.payload);
});

export const pastSessions = createReducer([] as SessionType[], (builder) => {
  builder.addCase(setPastSessions, (_state, action) => action.payload);
});

const reducer = combineReducers({
  nextSessions,
  pastSessions,
});

export type UserSessionsState = ReturnType<typeof reducer>;

const persistConfig: PersistConfig<UserSessionsState> = {
  key: 'userSessions',
  storage,
};

export const userSessionReducer = persistReducer(persistConfig, reducer);
