import styled from 'styled-components';

import { H2, TextRegular } from 'modules/ui';
import { device } from 'modules/utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 40px 24px 40px;

  @media ${device.tablet} {
    padding: 20px;
  }
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.ga.white};
`;

export const Subtitle = styled(TextRegular)`
  color: ${({ theme }) => theme.ga.white};
`;

export const FormContainer = styled.div`
  width: 100%;
  input {
    color: ${({ theme }) => theme.ga.white};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button:first-child {
    margin-right: 16px;
  }
`;
