import React, { useCallback, useEffect } from 'react';
import { Modal, H4Bold, Checkbox, Button, TextItalic } from 'modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import { EventsSelectors, EventsActions } from '../../redux';
import { GalaxyProductModel } from '../../types';
import { Container, Products, Footer } from './styled';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  selectProduct: (product: GalaxyProductModel) => void;
  selectedProducts: GalaxyProductModel[];
  eventProducts?: GalaxyProductModel[];
};

export const ProductsModal: React.FC<Props> = React.memo(
  ({
    isOpen,
    onRequestClose,
    selectProduct,
    selectedProducts,
    eventProducts,
  }) => {
    const dispatch = useDispatch();
    const products = useSelector(EventsSelectors.getProducts);

    const isSelected = useCallback(
      (product: GalaxyProductModel) => {
        return selectedProducts.some(({ id }) => id === product.id);
      },
      [selectedProducts],
    );

    const isCurrent = useCallback(
      (product: GalaxyProductModel) => {
        return eventProducts?.some(({ id }) => id === product.id);
      },
      [eventProducts],
    );

    const isDisabled = useCallback(
      (product: GalaxyProductModel) => {
        return (
          (!!selectedProducts.length && !isSelected(product)) ||
          (product.isAssigned && !isSelected(product) && !isCurrent(product))
        );
      },
      [selectedProducts],
    );

    useEffect(() => {
      dispatch(EventsActions.getEventProducts());
    }, [dispatch]);

    return (
      <Modal
        isOpen={isOpen}
        width={800}
        height={532}
        closeModal={onRequestClose}
        onRequestClose={onRequestClose}
      >
        <Container>
          <H4Bold>Select product</H4Bold>
          <Products>
            {products.map((product: GalaxyProductModel) => (
              <Checkbox
                key={product.id}
                label={product.title}
                name={product.title}
                onChange={() => selectProduct(product)}
                value={isSelected(product)}
                disabled={isDisabled(product)}
              />
            ))}
          </Products>
          <Footer>
            <TextItalic>
              Selected products:
              {selectedProducts.length}
            </TextItalic>
            {/* TODO: Change clickHandler to onClick */}
            <Button isDark label="Close" clickHandler={onRequestClose} />
            <Button
              isBlue
              disabled={!selectedProducts.length}
              label="Add"
              clickHandler={onRequestClose}
            />
          </Footer>
        </Container>
      </Modal>
    );
  },
);
