import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { CommonMeetingSelectors } from '../redux';
import { ChimeMeetingInfo } from '../types';
import { ConsentStatus } from '../consts';

export const useConnectToChime = (
  chimeMeetingInfo: ChimeMeetingInfo | null,
): void => {
  const meetingManager = useMeetingManager();
  const permissionsStatus = useSelector(
    CommonMeetingSelectors.permissionsStatus,
  );

  useEffect(() => {
    async function connectToChime(meetingInfo: ChimeMeetingInfo) {
      // TODO: meeting manager is accessed in multiple places at the moment
      // this makes sure there isn't a meeting from previous call state active still
      if (meetingManager.meetingId !== meetingInfo.meetingInfo.meetingId) {
        await meetingManager.leave();
      }

      await meetingManager.join(meetingInfo);
      await meetingManager.start();
    }

    const isPermissionGiven = permissionsStatus === ConsentStatus.Given;
    if (chimeMeetingInfo && isPermissionGiven) {
      connectToChime(chimeMeetingInfo);
    }
  }, [chimeMeetingInfo, permissionsStatus]);

  useEffect(() => {
    async function leaveChimeMeeting() {
      await meetingManager.leave();
    }
    return () => {
      leaveChimeMeeting();
    };
  }, []);
};
