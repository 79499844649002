/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import ordinal from 'ordinal';
import { H5, Label, TextRegular } from 'modules/ui';
import { useHistory } from 'react-router-dom';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { LobbySelectors } from '../../redux';
import { InfoContainer, PopupContainer, VideoContainer } from './styled';

export const WaitingRoomPopup: React.FC = React.memo(() => {
  const history = useHistory();
  const lobby = useSelector(LobbySelectors.getLobbyInfo);
  const loggedInFanPositionInQueue = useSelector(
    CommonMeetingSelectors.getLoggedInFanPosition,
  );
  const waitingRoomGuid = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );

  const navigateToLiveSession = useCallback(() => {
    history.push(`/session/${waitingRoomGuid}`);
  }, [waitingRoomGuid]);

  const queuePositionLabel = `You are ${ordinal(
    loggedInFanPositionInQueue || 0,
  )} in line`;

  return (
    <PopupContainer onClick={navigateToLiveSession}>
      <VideoContainer>
        <ReactPlayer width="215px" height="133px" url={lobby.youTubeLink} />
      </VideoContainer>
      <InfoContainer>
        <Label>WAITING ROOM</Label>
        <TextRegular>{lobby.name}</TextRegular>
        {loggedInFanPositionInQueue && <H5>{queuePositionLabel}</H5>}
      </InfoContainer>
    </PopupContainer>
  );
});
