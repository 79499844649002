import * as Sentry from '@sentry/react';
import { ThunkAction } from 'modules/main';
import {
  authRequest,
  createUserRequest,
  resetPasswordRequest,
  // getNearestMediaRegion,
  UserData,
} from 'modules/auth';
import { Response } from 'modules/utils';
import {
  ForgotPasswordData,
  ResetPasswordData,
  ResetPasswordResponse,
  ForgotPasswordResponse,
  UpdateProfile,
  ChangePasswordData,
  AuthResult,
  VerifyEmailData,
  ResendVerifyEmailData,
  VerifyAdminData,
} from '../types';
import {
  logIn,
  setMediaRegion,
  logOut,
  setVerifyEmailResponse,
  setUserProfile,
} from './actions';
import {
  changePasswordRequest,
  changeProfileRequest,
  forgotPasswordRequest,
  verifyEmailRequest,
  resendVerifyEmailRequest,
  verifyAdminAndSetPasswordRequest,
} from '../api';

export const createUserAccount = ({
  email,
  password,
  role,
  firstName,
  lastName,
}: UserData): ThunkAction<Response<unknown>> => async (): Promise<
  Response<unknown>
> => {
  return createUserRequest({
    firstname: firstName,
    lastname: lastName,
    email,
    password,
    role,
  });
};

export const authenticateUser = ({
  email,
  password,
}: UserData): ThunkAction<Response<AuthResult>> => async (
  dispatch,
): Promise<Response<AuthResult>> => {
  try {
    const response = await authRequest({ email, password });
    const { result } = response;
    if (result) {
      dispatch(
        logIn({
          email,
          firstName: result.userProfile.firstName,
          lastName: result.userProfile.lastName,
          role: result.userProfile.role,
          id: result.userProfile.userId,
          tokens: {
            access: result.accessToken,
            refresh: result.refreshToken,
          },
        }),
      );
    }
    Sentry.setUser({ email });

    return response;
  } catch (error) {
    Sentry.configureScope((scope) => scope.setUser(null));
    throw new Error(error);
  }
};

export const logOutUser = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(logOut());
    Sentry.configureScope((scope) => scope.setUser(null));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateProfile = ({
  firstName,
  lastName,
  email,
}: UpdateProfile): ThunkAction<Response<unknown>> => async (
  dispatch,
): Promise<Response<unknown>> => {
  try {
    const response = await changeProfileRequest({ firstName, lastName, email });
    if (response.success) {
      dispatch(setUserProfile({ firstName, lastName, email }));
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyEmail = ({
  token,
  email,
}: VerifyEmailData): ThunkAction<Response<unknown>> => async (dispatch) => {
  try {
    const response = await verifyEmailRequest({ token, email });
    return dispatch(setVerifyEmailResponse(response));
  } catch (error) {
    return error;
  }
};

export const forgotPassword = ({
  email,
}: ForgotPasswordData): ThunkAction<
  ForgotPasswordResponse
> => async (): Promise<ForgotPasswordResponse> => {
  try {
    return forgotPasswordRequest({ email });
  } catch (error) {
    return error;
  }
};

export const resetPassword = ({
  token,
  email,
  newPassword,
}: ResetPasswordData): ThunkAction<ResetPasswordResponse> => async (): Promise<
  ResetPasswordResponse
> => {
  try {
    return resetPasswordRequest({ token, email, newPassword });
  } catch (error) {
    return error;
  }
};

export const changePassword = ({
  newPassword,
  oldPassword,
}: ChangePasswordData): ThunkAction<ResetPasswordResponse> => async (): Promise<
  ResetPasswordResponse
> => {
  try {
    return changePasswordRequest({
      newPassword,
      oldPassword,
    });
  } catch (error) {
    return error;
  }
};

export const setAwsRegion = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  const defaultRegion = 'us-east-1';
  try {
    // const region = await getNearestMediaRegion();
    // dispatch(setMediaRegion(region));
  } catch (error) {
    dispatch(setMediaRegion({ region: defaultRegion }));
  }
};

export const resendVerifyEmail = ({
  email,
}: ResendVerifyEmailData): ThunkAction<void> => async (): Promise<void> => {
  try {
    await resendVerifyEmailRequest({
      email,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.toString());
  }
};

export const verifyAdminAndSetPassword = ({
  email,
  password,
  token,
}: VerifyAdminData): ThunkAction<Response<unknown>> => async (dispatch) => {
  try {
    const response = await verifyAdminAndSetPasswordRequest({
      token,
      email,
      password,
    });
    return dispatch(setVerifyEmailResponse(response));
  } catch (error) {
    return error;
  }
};
