import styled, { css } from 'styled-components';
import { device } from 'modules/utils';
import { RowContainer } from '../Styled';
import { MenuContainer } from '../DropdownMenu/styled';

export const Container = styled(RowContainer)`
  width: 100%;
`;

export const HeaderContainer = styled(RowContainer)<{
  isDark: boolean;
  isSecondary: boolean;
  isTertiary: boolean;
}>`
  position: sticky;
  height: 66px;
  top: 0px;
  align-items: center;
  flex: 0 0 66px;
  background-color: ${({ theme }) => theme.ga.white};
  border-bottom: 1px solid rgba(152, 167, 184, 0.1);
  z-index: 999;
  padding: 10px;

  img {
    margin-left: 32px;
    width: 150px;
  }

  ${({ isDark, theme }) =>
    isDark &&
    css`
      background-color: ${theme.ga.darkBlue};
    `}

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      img {
        margin: 0 auto;
      }
    `}

  ${Container} {
    display: ${({ isSecondary }) => (isSecondary ? 'none' : 'flex')};
  }

  ${MenuContainer} {
    margin-right: 35px;
  }

  @media ${device.tablet} {
    display: none;
  }
  ${({ isTertiary }) =>
    isTertiary &&
    css`
      border: none;
    `}
`;
