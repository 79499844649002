import React, { memo } from 'react';

import { Label, TextRegular } from 'modules/ui';
import { Container } from './styled';

type Props = {
  label: string;
  value: string;
};

const LabeledInfo: React.FC<Props> = ({ label, value }) => (
  <Container>
    <Label>{label}</Label>
    <TextRegular>{value}</TextRegular>
  </Container>
);

export default memo(LabeledInfo);
