import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'modules/ui';
import { AccountSelectors, UserOrder, AccountActions } from 'modules/account';
import { UserOrdersContainer } from './styled';

export const MyOrders: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AccountActions.getUserOrders());
  }, [dispatch]);

  const orders = useSelector(AccountSelectors.userOrders);

  return (
    <UserOrdersContainer>
      <H1>My Orders</H1>
      {orders.map((order) => (
        <UserOrder
          key={order.shopifyOrderId}
          purchaseDate={order.purchaseDate}
          totalPrice={order.totalPrice}
          id={order.shopifyOrderId}
          products={order.userOrderLineItemInfos}
        />
      ))}
    </UserOrdersContainer>
  );
});
