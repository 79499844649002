import React from 'react';
import { Label, CTA, H4Bold, TextRegular, CircleIcon } from 'modules/ui';
import { GalaxyEventModel } from 'modules/galaxy-events';
import { Link } from 'react-router-dom';
import { ScheduleDictionary } from '../../dictionary';
import { Container, Content, Info, Live } from './styled';

type Props = {
  event: GalaxyEventModel;
};
export const NextEvent: React.FC<Props> = React.memo(({ event }) => {
  const date = `${event.month.long}, ${event.day.short}`;
  return (
    <Container>
      <Label>{ScheduleDictionary.NEXT_SESSION}</Label>
      <Content>
        <Info>
          <TextRegular>{date}</TextRegular>

          {event.isActive && (
            <Live>
              <Label>{ScheduleDictionary.LIVE}</Label>
              <CircleIcon />
            </Live>
          )}
        </Info>

        <H4Bold>{event.title}</H4Bold>
        <Link to={`/admin/session/${event.roomId}`}>
          <CTA>{ScheduleDictionary.JOIN_SESSION}</CTA>
        </Link>
      </Content>
    </Container>
  );
});
