import React from 'react';
import { useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  ParticipantState,
} from 'modules/common-meeting';
import { useTwilioConnection } from 'modules/chat';
import { InACallView } from '../InACallView';
import { CallAnnouncement } from '../CallAnnouncement';

export const TalentMeeting: React.FC = React.memo(() => {
  const talentParticipant = useSelector(
    CommonMeetingSelectors.getLoggedInParticipant,
  );
  useTwilioConnection();

  switch (talentParticipant?.State) {
    case ParticipantState.IN_A_CALL:
      return <InACallView />;

    case ParticipantState.IN_ANNOUNCEMENT:
      return <CallAnnouncement />;

    default:
      return <InACallView />;
  }
});
