import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';
import { MenuContainer } from 'modules/ui/components/DropdownMenu/styled';
import { device } from 'modules/utils';

export const AdminContainer = styled(ColumnContainer)`
  height: 100vh;
  background-color: ${({ theme }) => theme.ga.white};
  overflow: hidden;

  @media ${device.tablet} {
    overflow-y: visible;
  }
`;

export const SideContainer = styled(RowContainer)`
  align-items: center;

  ${MenuContainer} {
    margin: 0px 35px;
  }
`;
