import styled from 'styled-components';
import { device } from 'modules/utils';
import { H3 } from 'modules/ui';

export const HeroContainer = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 338px;
  background-color: ${({ theme }) => theme.ga.darkBlue};
  color: white;
  justify-content: space-around;
  align-items: center;
  img {
    flex: 4;
    height: 210px;
  }

  @media ${device.tablet} {
    height: auto;
    padding: 54px 22px 54px 22px;

    img {
      flex: 2;
      height: 150px;
    }
  }
`;

export const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex: 5;

  @media ${device.mobile} {
    flex: 2;
    padding-left: 30px;
    span {
      font-size: 18px;
    }
  }
`;

export const HeroText = styled(H3)`
  font-weight: 300;
  margin: 0;
`;
