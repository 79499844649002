import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Button, H6 } from 'modules/ui';
import { useSendNextFanToMainSession } from 'modules/queue';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { TalentActions, TalentSelectors } from 'modules/talent-meeting';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NextFanButtonWrapper = styled.div`
  width: 235px;
  margin-bottom: 32px;
  button {
    width: 100%;
  }
`;

const BreakButtonWrapper = styled.div`
  width: 180px !important;
  button {
    :disabled {
      opacity: 0.5;
    }
    width: 100%;
  }
  span {
    color: ${({ theme }) => theme.ga.secondaryButton} !important;
  }
`;

const ChatOverOptions: React.FC = () => {
  const dispatch = useDispatch();
  const [, setLastAttendeeName] = useState('');
  const currentAttendee = useSelector(
    CommonMeetingSelectors.getCurrentAttendee,
  );
  const waitingRoomGuid = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );
  const isAnyFanParticipantInCallPaused = useSelector(
    CommonMeetingSelectors.isAnyFanParticipantInCallPaused,
  );
  const isActive = useSelector(TalentSelectors.shouldShowChatOptions);
  const isMainCallPaused = useSelector(
    CommonMeetingSelectors.isMainCallPausedSelector,
  );

  useEffect(() => {
    return () => setLastAttendeeName(currentAttendee.firstName);
  }, [currentAttendee]);

  const isOnPause = useSelector(CommonMeetingSelectors.isTalentOnBreak);
  const {
    sendNextFanToMainSession,
    actionDisabled,
  } = useSendNextFanToMainSession();
  const takeABreakHandler = useCallback(() => {
    dispatch(
      TalentActions.toggleTalentBreak({
        waitingRoomGuid,
        onABrake: !isOnPause,
      }),
    );
  }, [dispatch, isOnPause]);

  const chatWithNextFan = useCallback(() => {
    dispatch(TalentActions.hideOptionsOnNextFanJoin());
    sendNextFanToMainSession();
  }, [sendNextFanToMainSession]);

  const getTitle = () => {
    if (isOnPause) {
      return 'You took a break';
    }
    if (isAnyFanParticipantInCallPaused) {
      return 'Call with fan has been paused';
    }
    return "You're ready to chat with the next Fan";
  };

  if (!isActive) return null;

  return (
    <Container>
      <H6>{getTitle()}</H6>
      <NextFanButtonWrapper>
        <Button
          disabled={actionDisabled}
          clickHandler={chatWithNextFan}
          label="Chat with the next fan"
        />
      </NextFanButtonWrapper>
      <BreakButtonWrapper>
        <Button
          isDark
          clickHandler={takeABreakHandler}
          label={!isOnPause ? 'Take a break' : "I'm ready"}
          disabled={isMainCallPaused}
        />
      </BreakButtonWrapper>
    </Container>
  );
};

export default React.memo(ChatOverOptions);
