import {
  AsyncThunk,
  createReducer,
  combineReducers,
  Draft,
} from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { AsyncThunkConfig, BaseResource, Collection } from './types';

export const ids = <T extends BaseResource, G, C, R>(
  getAll: AsyncThunk<T[], G, AsyncThunkConfig>,
  getById?: AsyncThunk<T, number, AsyncThunkConfig>,
  create?: AsyncThunk<T, C, AsyncThunkConfig>,
  remove?: AsyncThunk<T, R, AsyncThunkConfig>,
): Reducer<number[]> =>
  createReducer([] as number[], (builder) => {
    if (getAll) {
      builder.addCase(getAll.fulfilled, (_state, action) => {
        return action.payload.map((item) => item.id);
      });
    }

    if (getById) {
      builder.addCase(getById.fulfilled, (draft, action) => {
        const { id } = action.payload;
        const isInArray = draft.includes(id);

        if (!isInArray) {
          draft.push(id);
        }
      });
    }

    if (create) {
      builder.addCase(create.fulfilled, (state, action) => [
        ...state,
        action.payload.id,
      ]);
    }

    if (remove) {
      builder.addCase(remove.fulfilled, (state, action) =>
        state.filter((id) => id !== action.payload.id),
      );
    }
  });

export const storage = <T extends BaseResource, G, C, U, R>(
  getAll: AsyncThunk<T[], G, AsyncThunkConfig>,
  getById?: AsyncThunk<T, number, AsyncThunkConfig>,
  create?: AsyncThunk<T, C, AsyncThunkConfig>,
  update?: AsyncThunk<T, U, AsyncThunkConfig>,
  remove?: AsyncThunk<T, R, AsyncThunkConfig>,
): Reducer<Record<number, T>> =>
  createReducer({} as Record<number, T>, (builder) => {
    if (getAll) {
      builder.addCase(getAll.fulfilled, (_state, action) =>
        action.payload.reduce(
          (state, item) => ({ ...state, [item.id]: item }),
          {} as Record<number, T>,
        ),
      );
    }

    if (getById) {
      builder.addCase(getById.fulfilled, (draft, action) => {
        const item = action.payload;
        draft[item.id] = item as Draft<T>;
      });
    }

    if (create) {
      builder.addCase(create.fulfilled, (draft, action) => {
        const item = action.payload;
        draft[item.id] = item as Draft<T>;
      });
    }

    if (update) {
      builder.addCase(update.fulfilled, (draft, action) => {
        const item = action.payload;
        draft[item.id] = item as Draft<T>;
      });
    }

    if (remove) {
      builder.addCase(remove.fulfilled, (draft, action) => {
        const item = action.payload;
        delete draft[item.id];
      });
    }
  });

export const collection = <T extends BaseResource, G, C, U, R>(
  getAll: AsyncThunk<T[], G, AsyncThunkConfig>,
  getById?: AsyncThunk<T, number, AsyncThunkConfig>,
  create?: AsyncThunk<T, C, AsyncThunkConfig>,
  update?: AsyncThunk<T, U, AsyncThunkConfig>,
  remove?: AsyncThunk<T, R, AsyncThunkConfig>,
): Reducer<Collection<T>> =>
  combineReducers({
    ids: ids(getAll, getById, create, remove),
    storage: storage(getAll, getById, create, update, remove),
  });
