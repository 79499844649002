import styled from 'styled-components';
import { TextError } from 'modules/ui/theme';

export const CheckboxContainer = styled.label<{
  isSecondary: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  pointer-events: ${({ disabled }): string | undefined =>
    disabled ? 'none' : undefined};
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
  input {
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid #061220;
    border-radius: 4px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  &:hover input ~ .checkmark {
    background-color: #eeeef3;
  }
  input:checked ~ .checkmark {
    border: 2px solid ${({ theme }) => theme.ga.darkGray};
    background-color: ${({ isSecondary, theme }): string =>
      isSecondary ? '#8488AD' : theme.ga.darkGray};
  }
  span {
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    font-weight: 500;
  }
`;

export const ErrorText = styled(TextError)`
  margin-left: 16px;
`;
