import { createAction } from '@reduxjs/toolkit';
import { Response } from 'modules/utils';
import { Region, User, UserData, Profile } from '../types';

export const createUser = createAction<UserData>('CREATE_USER');
export const logIn = createAction<User>('LOG_IN');
export const setMediaRegion = createAction<Region>('SET_MEDIA_REGION');
export const logOut = createAction('LOG_OUT_USER');
export const setVerifyEmailResponse = createAction<Response<unknown>>(
  'SET_VERIFY_EMAIL_RESPONSE',
);
export const setUserProfile = createAction<Profile>('SET_USER_PROFILE');
