import { addGlobalErrors } from 'modules/adapter';
import { ThunkAction } from 'modules/main';
import { getNextSessionsRequest, getPastSessionsRequest } from '../api';
import { setNextSessions, setPastSessions } from './actions';

export const getNextSessions = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    const { result } = await getNextSessionsRequest();
    dispatch(setNextSessions(result));
  } catch (error) {
    dispatch(addGlobalErrors(error));
  }
};

export const getPastSessions = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    const { result } = await getPastSessionsRequest();
    dispatch(setPastSessions(result));
  } catch (error) {
    dispatch(addGlobalErrors(error));
  }
};
