import React from 'react';
import { H4Bold, TextRegular, ButtonLink } from 'modules/ui';
import { EmptyEventsPlaceholderContainer } from './styled';

export const EmptyEventsPlaceholder: React.FC = React.memo(() => {
  return (
    <EmptyEventsPlaceholderContainer>
      <H4Bold>Join Celebrities in Virtual Experiences Online</H4Bold>
      <TextRegular>
        GalaxyCon Live connects you with celebrities like never before, through
        One-to-One Video Chats with optional Virtual Photo Ops, Personalized
        Autographs, and Live Q&A's.
      </TextRegular>
      <ButtonLink
        href="https://galaxycon.com"
        target="_blank"
        rel="noreferrer"
        background="white"
      >
        See more details
      </ButtonLink>
    </EmptyEventsPlaceholderContainer>
  );
});
