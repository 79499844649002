import React, { FC, memo } from 'react';
import { Button, H2, Modal, TextRegular } from 'modules/ui';
import { ButtonContainer, ModalContainer } from './styled';

type Props = {
  isOpen: boolean;
};

export const AskForFocusModal: FC<Props> = memo(({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} fullWidth={false} isDark height={675} width={500}>
      <ModalContainer>
        <H2>Application not focused</H2>
        <TextRegular className="text">
          Please click anywhere on the app to put it back in the focus.
          Otherwise, we will not be able to take screenshots of you and your
          fans.
        </TextRegular>
        <ButtonContainer>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <Button label="OK" clickHandler={() => {}} />
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
});
