import React from 'react';
import { Route } from 'react-router-dom';
import { CommonMeeting } from 'modules/common-meeting';
import { TalentMeeting } from 'modules/talent-meeting';

import { Paths } from './paths';

export const TalentRoutes: React.FC = () => (
  <Route path={Paths.TalentSession}>
    <CommonMeeting renderReadyState={() => <TalentMeeting />} />
  </Route>
);
