import { NavigationText } from 'modules/ui';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Underline } from './styled';

type RouteType = {
  label: string;
  path: string;
};

type Props = {
  routes: RouteType[];
  isDark?: boolean;
};

export const Navigation: React.FC<Props> = React.memo(({ routes, isDark }) => {
  return (
    <Nav isDark={!!isDark}>
      {routes.map((route: RouteType) => (
        <NavLink
          key={route.path}
          strict
          exact
          to={route.path}
          activeClassName="active"
        >
          <NavigationText>{route.label}</NavigationText>
          <Underline />
        </NavLink>
      ))}
    </Nav>
  );
});
