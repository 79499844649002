import styled from 'styled-components';
import { device } from 'modules/utils';

export const AccountNavBar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: auto;
  margin-top: 20px;

  .mobile-menu-button {
    display: none;
  }

  a {
    text-decoration: none;
    color: black;
    border-left: 5px solid transparent;
    margin-bottom: 8px;
    padding: 20px 0 20px 50px;

    &.active {
      color: ${(props) => props.theme.ga.magenta};
      border-left: 5px solid ${(props) => props.theme.ga.magenta};
    }
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    margin-top: 20px;
    justify-content: space-evenly;

    a {
      border-left: none;
      border-bottom: 5px solid transparent;
      padding: 0px 0px 20px 0px;

      &.active {
        color: ${(props) => props.theme.ga.magenta};
        border-bottom: 5px solid ${(props) => props.theme.ga.magenta};
        border-left: none;
      }
    }
  }

  @media ${device.mobile} {
    justify-content: flex-start;
    width: 85%;
    height: auto;
    margin: 0 auto;

    .desktop-text {
      display: none;
    }

    .mobile-menu-button {
      display: inline-block;
      svg {
        margin-right: 20px;
      }
    }

    a {
      display: none;
      border-left: none;
      border-bottom: 5px solid transparent;
      padding: 20px 0px;
      margin: 0;

      &.active {
        display: inline-block;
        color: ${(props) => props.theme.ga.black};
        border-bottom: none;
        border-left: none;
      }
    }
  }
`;

export const MobileAccountMenu = styled.div<{ isOpen?: boolean }>`
  display: none;

  @media ${device.mobile} {
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - (56px + 14px + 14px));
    margin-top: 0;
    justify-content: flex-start;
    padding: 100px 0 0 20px;
    box-sizing: border-box;
    ${({ isOpen }) => `right: ${isOpen ? '0px' : '100vw'};`}
    transition: right 0.5s;
    z-index: 1;

    h3 {
      margin: 0;
    }

    .mobile-route {
      display: inline-block;
    }

    a {
      display: inline-block;
      border-left: none;
      border-bottom: 5px solid transparent;
      padding: 20px 0px;
      margin: 0;

      &.active {
        display: inline-block;
        color: ${(props) => props.theme.ga.magenta};
        border-bottom: none;
        border-left: none;
      }
    }
  }
`;

export const LogOutContainerDesktop = styled.div`
  position: absolute;
  bottom: 32px;
  left: 32px;

  button {
    padding: 12px 37px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const LogOutContainerMobile = styled.div`
  display: none;

  @media ${device.mobile} {
    display: inline-block;
    margin-top: 30%;
    button {
      padding: 12px 37px;
    }
  }
`;
