import React from 'react';
import { useSelector } from 'react-redux';
import {
  Calendar,
  DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import { EventsSelectors } from 'modules/galaxy-events';
import { DatePickerDate } from 'modules/schedule';
import { GalaxyConTheme } from 'modules/ui';
import { NextEvent } from '../NextEvent';
import { myCustomLocale } from './config';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { NavigatorContainer } from './styled';

type Props = {
  onDateChange: (date: DatePickerDate) => void;
  selectedDate: DayValue;
};

export const Navigator: React.FC<Props> = React.memo(
  ({ onDateChange, selectedDate }) => {
    const events = useSelector(EventsSelectors.getNavigatorEvents);
    const nextEvent = useSelector(EventsSelectors.getNextEvent);

    return (
      <NavigatorContainer>
        <Calendar
          value={selectedDate}
          onChange={onDateChange}
          shouldHighlightWeekends
          locale={myCustomLocale}
          colorPrimary={GalaxyConTheme.ga.blue}
          customDaysClassName={events}
        />
        {!!nextEvent.month && <NextEvent event={nextEvent} />}
      </NavigatorContainer>
    );
  },
);
