import React from 'react';
import { H4, Logo, LogoV3 } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { Container, ImageContainer } from './styled';

export const AuthLayout: React.FC = React.memo(({ children }) => {
  return (
    <Container>
      {children}
      <ImageContainer>
        <img
          className="mobile"
          src={Logo}
          alt={Dictionary.GALAXYCON}
          width="290px"
        />
        <img
          className="desktop-tablet"
          src={LogoV3}
          alt={Dictionary.GALAXYCON}
          width="290px"
        />
        <H4>{Dictionary.AUTH_SIDE_HEADING}</H4>
      </ImageContainer>
    </Container>
  );
});
