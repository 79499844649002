import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Roles } from 'modules/auth';
import { RootState, ThunkAction } from 'modules/main';

import { Attendee } from 'modules/utils';
import {
  getMainCallJoiningInfoRequest,
  getReadinessCallJoiningInfoRequest,
} from 'modules/lobby';
import * as CommonMeetingSelectors from './selectors';
import {
  getShopifyRedirectRequest,
  getTechCheckJoiningInfoRequest,
  sendToCallWithTalentRequest,
  finishParticipantRequest,
  sendToQueueRequest,
  pauseCallWithTalentRequest,
  incrementCallSecondsLeftRequest,
  startScreenshotTimerRequest,
  prepareWaitingRoomRequest,
  enterWaitingRoomRequest,
  updateTalentNameRequest,
  reorderFansRequest,
  setOffParticipantHeartbeatRequest,
} from '../api';
import { TalentLinkParameters, Fan } from '../types';
import {
  CommonMeetingActionType,
  SCREENSHOT_TIMER_SECONDS,
  ConsentStatus,
} from '../consts';

export const setTalentName = createAction<string>('SET_TALENT_NAME');
export const clearTechSession = createAction('CLEAR_TECH_SESSION');
export const clearAttendeeData = createAction('CLEAR_DATA');

export const setCurrentAttendee = createAction<Attendee>(
  'common-meeting/SET_CURRENT_ATTENDEE',
);

export const setPermissionsStatus = createAction<ConsentStatus>(
  'common-meeting/SET_PERMISSION_STATUS',
);

export const hidePreferDesktopUsageBanner = createAction(
  'common-meeting/HIDE_PREFER_DESKTOP_USAGE_BANNER',
);

export const setShouldHidePermissionsModal = createAction<boolean>(
  'common-meeting/SET_IS_CONSENT_BLOCKED_MODAL_SHOWN',
);

export const getShopifyRedirect = createAsyncThunk(
  'common-meeting/GET_SHOPIFY_REDIRECT',
  getShopifyRedirectRequest,
);

export const getTechCheckCallJoiningInfo = createAsyncThunk(
  'common-meeting/GET_TECH_CHECK_JOINING_INFO',
  getTechCheckJoiningInfoRequest,
);

export const clearTechCheckJoiningInfo = createAction(
  'common-meeting/CLEAR_TECH_CHECK_JOINING_INFO',
);

export const getMainCallJoiningInfo = createAsyncThunk(
  CommonMeetingActionType.GET_MAIN_CALL_JOIN_INFO,
  getMainCallJoiningInfoRequest,
);

export const getReadinessCallJoiningInfo = createAsyncThunk(
  CommonMeetingActionType.GET_READINESS_CALL_JOIN_INFO,
  getReadinessCallJoiningInfoRequest,
);

export const clearReadinessCheckJoiningInfo = createAction(
  'common-meeting/CLEAR_READINESS_CHECK_JOINING_INFO',
);

export const clearMainMeetingJoiningInfo = createAction(
  'common-meeting/CLEAR_MAIN_MEETING_JOINING_INFO',
);

export const sendFanToCallWithTalent = createAsyncThunk(
  CommonMeetingActionType.SEND_TO_CALL_WITH_TALENT,
  sendToCallWithTalentRequest,
);

export const finishParticipant = createAsyncThunk(
  'common-meeting/FINISH_PARTICIPANT',
  finishParticipantRequest,
);

export const copyTalentLinkToClipboard = createAsyncThunk(
  'common-meeting/COPY_TALENT_LINK_TO_CLIPBOARD',
  async (params: TalentLinkParameters) => {
    const { waitingRoomGuid, atendeeGuid } = params;
    const talentMeetingUrl = `${process.env.REACT_APP_WEB_BASE_URL}/talent/session/${atendeeGuid}/${waitingRoomGuid}`;
    try {
      await navigator.clipboard.writeText(talentMeetingUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.toString());
    }
  },
);

export const clearUserSessionData = (): ThunkAction<void> => async (
  dispatch,
  getState,
): Promise<void> => {
  const { user } = getState() as RootState;

  if (user.role === Roles.FAN) {
    dispatch(clearAttendeeData());
  }
};

export const sendToQueue = createAsyncThunk(
  'common-meeting/SEND_TO_QUEUE',
  sendToQueueRequest,
);

export const pauseCallWithTalent = createAsyncThunk(
  'common-meeting/PAUSE_CALL_WITH_TALENT',
  pauseCallWithTalentRequest,
);

export const incrementCallSecondsLeft = createAsyncThunk(
  'common-meeting/INCREMENT_CALL_SECONDS_LEFT',
  incrementCallSecondsLeftRequest,
);

export const setIsCallInRedZone = createAction<boolean>(
  'common-meeting/SET_IS_CALL_IN_RED_ZONE',
);

export const startScreenshotTimer = createAsyncThunk(
  'common-meeting/START_SCREENSHOT_TIMER',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const waitingRoomGuid = CommonMeetingSelectors.waitingRoomIdSelector(state);
    const bookedParticipantSlotId = CommonMeetingSelectors.getBpsIdForFanParticipantInLiveCall(
      state,
    );

    if (waitingRoomGuid && bookedParticipantSlotId) {
      await startScreenshotTimerRequest({
        waitingRoomGuid,
        bookedParticipantSlotId,
        seconds: SCREENSHOT_TIMER_SECONDS,
      });
    }
  },
);

export const setEnterWaitingRoomUnauthorized = createAction(
  'common-meeting/SET_ENTER_WAITING_ROOM_UNAUTHORIZED',
);

export const enterWaitingRoom = createAsyncThunk(
  CommonMeetingActionType.ENTER_WAITING_ROOM,
  async (waitingRoomId: string, { dispatch }) => {
    let payload;
    try {
      payload = await enterWaitingRoomRequest({
        waitingRoomGuid: waitingRoomId,
      });
    } catch (e) {
      dispatch(setEnterWaitingRoomUnauthorized());
      throw e;
    }

    return payload;
  },
);

export const prepareWaitingRoom = createAsyncThunk(
  CommonMeetingActionType.PREPARE_WAITING_ROOM,
  prepareWaitingRoomRequest,
);

// todo: move to talent module
export const updateTalentName = createAsyncThunk(
  CommonMeetingActionType.UPDATE_TALENT_NAME,
  updateTalentNameRequest,
);

export const tickPhotoTimer = createAction<number>(
  CommonMeetingActionType.TICK_PHOTO_TIMER,
);

export const reorderWaitingRoomFans = createAction<Fan[]>(
  CommonMeetingActionType.REORDER_WAITING_ROOM_FANS,
);

export const reorderFansPositions = createAsyncThunk(
  CommonMeetingActionType.REORDER_FANS_THUNK,
  reorderFansRequest,
);

export const setOffParticipantHeartbeat = createAsyncThunk(
  CommonMeetingActionType.SET_OFF_PARTICIPANT_HEARTBEAT,
  setOffParticipantHeartbeatRequest,
);

export const setIsDisconnected = createAction<boolean>('SET_IS_DISCONNECTED');
