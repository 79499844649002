import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'modules/auth';
import {
  SendMessageIcon,
  ChatMessageIcon,
  CloseIcon,
  GalaxyConTheme,
} from 'modules/ui';
import { useTwilioChat } from 'modules/chat/hooks';

import { useMobileChat } from 'modules/chat';
import { MessageType } from 'modules/chat/types';
import {
  ChatBody,
  ChatHeader,
  ChatInput,
  ChatName,
  Message,
  MessageContainer,
  Container,
  TextInput,
  SendButton,
  ChatIconWrap,
  ChatTooltip,
  FanChatWrap,
} from './styled';

export type Props = {
  chatId: string;
  onClose?: (id: string) => void;
  index?: number;
  name?: string;
  isFan?: boolean;
  visible?: boolean;
};

export const TwilioChat: React.FC<Props> = React.memo(
  ({ chatId, name, index = 0, isFan = false, onClose, visible = true }) => {
    const [isChatActive, toggleChat] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const chatRef = useRef<HTMLDivElement>(null);
    const user = useSelector(AuthSelectors.getUser);
    const { isChatOpen, onChatToggle } = useMobileChat();
    const { messages, sendMessage, hasNewMessage, onRead } = useTwilioChat(
      chatId,
    );

    const handleChatToggle = useCallback(() => {
      onRead();
      toggleChat((isActive) => !isActive);

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }

      if (onChatToggle) {
        onChatToggle();
      }
    }, [onChatToggle]);

    const handleSendMessage = useCallback(() => {
      if (inputRef.current) {
        sendMessage(inputRef.current.value);
        // eslint-disable-next-line no-param-reassign
        inputRef.current.value = '';
      }
      onRead();
    }, [sendMessage]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter' && inputRef.current) {
          handleSendMessage();
        }
      },
      [sendMessage],
    );

    const handleCloseClick = () => {
      return onClose && onClose(chatId);
    };

    useEffect(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, [messages, toggleChat]);

    return (
      <Container
        isTechCheckView={false}
        isTalentView={false}
        isAdminView
        isAdmin
        isActive={isChatActive}
        isMobileOpened={isChatOpen}
        index={index}
        isFan={isFan}
        visible={visible}
      >
        {isFan && (!isChatActive || !isChatOpen) ? (
          <FanChatWrap>
            <ChatTooltip>
              Chat with us if you have any technical difficulties.
            </ChatTooltip>
            <ChatIconWrap onClick={handleChatToggle}>
              <ChatMessageIcon />
            </ChatIconWrap>
          </FanChatWrap>
        ) : (
          <ChatHeader onClick={handleChatToggle}>
            <ChatName isActive={hasNewMessage}>
              {name || 'Customer Support'}
            </ChatName>
            {!isFan && (
              <CloseIcon
                color={GalaxyConTheme.ga.white}
                onClick={handleCloseClick}
              />
            )}
          </ChatHeader>
        )}
        <ChatBody ref={chatRef}>
          {messages.map((message: MessageType) => (
            <MessageContainer
              key={message.time}
              isRight={message.role === user.role}
            >
              <Message>{message.content}</Message>
            </MessageContainer>
          ))}
        </ChatBody>
        <ChatInput>
          <TextInput
            placeholder="Enter a message"
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <SendButton onClick={handleSendMessage}>
            <SendMessageIcon />
          </SendButton>
        </ChatInput>
      </Container>
    );
  },
);
