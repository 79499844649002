import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';

export const errorsReducer = (state: RootState): RootState['errors'] =>
  state.errors;

export const getErrors = createSelector(
  [errorsReducer],
  (errors) => errors.collection,
);
