import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { CommonMeetingSelectors, CommonMeetingActions } from '../redux';

// Use recommendation from Chime team: https://github.com/aws/amazon-chime-sdk-component-library-react/issues/565
// connect to dummy call to enable device selection when not in real meeting
export const useConnectToReadinessCall = (): void => {
  const dispatch = useDispatch();
  const meetingManager = useMeetingManager();
  const { uid } = useParams<Record<string, string>>();
  const loggedInParticipantBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );

  const chimeMeetingInfo = useSelector(
    CommonMeetingSelectors.getReadinessCheckMeetingInfo,
  );

  useEffect(() => {
    if (uid === undefined || loggedInParticipantBpsId === null) {
      throw new Error(
        'useConnectToReadinessCall called with uid=undefined or loggedInParticipantBpsId=null',
      );
    }

    dispatch(
      CommonMeetingActions.getReadinessCallJoiningInfo({
        waitingRoomGuid: uid,
        bpsId: loggedInParticipantBpsId,
      }),
    );

    return () => {
      dispatch(CommonMeetingActions.clearReadinessCheckJoiningInfo());
    };
  }, []);

  useEffect(() => {
    async function joinCall() {
      if (!chimeMeetingInfo) {
        return;
      }

      if (meetingManager.meetingId !== chimeMeetingInfo.meetingInfo.meetingId) {
        await meetingManager.leave();
      }

      await meetingManager.join(chimeMeetingInfo);
    }

    joinCall();
  }, [chimeMeetingInfo]);
};
