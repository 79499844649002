import React from 'react';
import { useSelector } from 'react-redux';
import { useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';

import {
  CameraIcon,
  CameraOffWhiteIcon,
  MicrophoneIcon,
  MicrophoneOffWhiteIcon,
  SettingsWhiteIcon,
} from 'modules/ui';
import { useModal } from 'modules/main';
import { ChimeDeviceSettings } from 'modules/devices';
import {
  CountdownTimer,
  CommonMeetingSelectors,
  useLocalVideoWithWarning,
} from 'modules/common-meeting';

import { Controls, IconBtn } from './styled';

type Props = {
  isFan?: boolean;
  isAdmin?: boolean;
  isTalent?: boolean;
  onTakeSnapshot?: () => void;
  isSnapshotDisabled?: boolean;
};

export const FanControls: React.FC<Props> = React.memo(() => {
  const [isOpen, open, handleClose] = useModal(false);
  const sessionSecondsLeft = useSelector(
    CommonMeetingSelectors.getSessionSecondsLeft,
  );
  const totalSessionSeconds = useSelector(
    CommonMeetingSelectors.getTotalSessionSeconds,
  );

  const { isVideoEnabled, toggleVideo } = useLocalVideoWithWarning();
  const { muted, toggleMute } = useToggleLocalMute();

  return (
    <Controls>
      <CountdownTimer
        timeLeft={sessionSecondsLeft}
        initialTime={totalSessionSeconds}
      />
      <IconBtn onClick={toggleMute}>
        {muted ? <MicrophoneOffWhiteIcon /> : <MicrophoneIcon />}
      </IconBtn>
      <IconBtn onClick={toggleVideo}>
        {isVideoEnabled ? <CameraIcon /> : <CameraOffWhiteIcon />}
      </IconBtn>
      <IconBtn onClick={open}>
        <SettingsWhiteIcon />
      </IconBtn>
      {isOpen && <ChimeDeviceSettings isOpen onCloseRequest={handleClose} />}
    </Controls>
  );
});
