import React from 'react';
import { Snackbar } from 'modules/ui';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { CommonMeetingSelectors, CommonMeetingActions } from '../../redux';

export const PreferDesktopUsageBanner = React.memo(() => {
  const dispatch = useDispatch();

  const isPreferDesktopUsageBannerHidden = useSelector(
    CommonMeetingSelectors.isPreferDesktopUsageBannerHidden,
  );

  return (
    <Snackbar
      isOpen={isMobile && !isPreferDesktopUsageBannerHidden}
      handleClose={() =>
        dispatch(CommonMeetingActions.hidePreferDesktopUsageBanner())
      }
      title="For best video and audio experience we recommend using the desktop browser. In case you need help, please reach out to our support."
    />
  );
});
