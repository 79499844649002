import React, { useEffect, useState } from 'react';
import { H5, TextRegular } from 'modules/ui';
import { Container } from './styled';

export const Clock: React.FC = React.memo(() => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [time, setTime]);

  return (
    <Container>
      <H5>
        {time.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      </H5>
      <TextRegular>
        {time.toLocaleString('en-En', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        })}
      </TextRegular>
    </Container>
  );
});
