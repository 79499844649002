import React from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {
  Calendar,
  DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import { customLocale } from '../DateTimePicker/config';
import { CalendarContainer } from './styled';

export type { DayValue };
export type CalendarProps = {
  value: DayValue;
  onChange?(value: DayValue): void;
};

export const CalendarPicker: React.FC<CalendarProps> = React.memo(
  ({ value, onChange, ...rest }) => {
    return (
      <CalendarContainer>
        <Calendar
          value={value}
          onChange={onChange}
          {...rest}
          shouldHighlightWeekends
          locale={customLocale}
        />
      </CalendarContainer>
    );
  },
);
