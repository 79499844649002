import styled from 'styled-components';
import { RowContainer } from 'modules/ui';

export const ChatContainer = styled(RowContainer)`
  height: 42px;
  margin-top: 41px;
  display: flex;
  flex-direction: row;
`;

export const Pagination = styled(RowContainer)`
  z-index: 100000;
  background: ${({ theme }) => theme.ga.darkGray};
  padding: 8px 16px 0;
  margin-left: -16px;
  display: flex;

  .rotate-right {
    transform: rotate(180deg);
  }
  svg {
    cursor: pointer;
    margin: 0px 8px;
  }
`;

export const Chats = styled(RowContainer)`
  display: flex;
  flex: auto;
  position: relative;
  flex: 1;
`;

export const ChatsWrap = styled.div<{ scrollAmount: number; width: number }>`
  display: block;
  position: absolute;
  width: ${({ width }) => width}px;
  bottom: 0;
  display: block;
  ${({ scrollAmount }) => `right: ${scrollAmount}px;`}
`;

export const TalentChatWrap = styled.div`
  display: flex;
  position: relative;
  width: 264px;
  z-index: 100000;
  background: ${({ theme }) => theme.ga.darkGray};
`;
