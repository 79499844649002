/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Logo } from 'modules/ui';
import {
  EventType,
  GalaxyEventModel,
  GalaxyProductModel,
  GalaxyVariantModel,
  ProductType,
  VariantType,
  DatePickerDate,
} from './types';

type Option = {
  label: string;
  value: number | null;
};

export const getMonthAsString = (month: number): string => {
  return month >= 10 ? `${month}` : `0${month}`;
};

export const getDayAsString = (day: number): string => {
  return day >= 10 ? `${day}` : `0${day}`;
};

export const getStringDateInET = (
  date: DatePickerDate,
  time: number,
): string => {
  const { year, month, day } = date;
  const monthAsString = getMonthAsString(month);
  const dayAsString = getDayAsString(day);

  const isDecimal = time % 1 !== 0;
  const hours =
    Math.floor(Number(time)) >= 10
      ? Math.floor(Number(time))
      : `0${Math.floor(Number(time))}`;

  const minutes = isDecimal ? '30' : '00';
  const dateTime = `${year}-${monthAsString}-${dayAsString} ${hours}:${minutes}:00`;

  const dateInEasternTimezone = zonedTimeToUtc(dateTime, 'America/New_York');

  return dateInEasternTimezone.toISOString();
};

export const generateHours = (): Option[] => {
  const fullHour = Array.from({ length: 24 }, (v, k) => k + 1);
  const halfHour = Array.from({ length: 24 }, (v, k) => k + 0.5);

  const day = [...fullHour, ...halfHour].sort((a, b) => a - b);

  return day.map((hour: number) => {
    const isDecimal = hour % 1 !== 0;
    const abbreviations = `${hour < 12 || hour === 24 ? `AM` : `PM`}`;
    const hh = hour > 12.5 ? hour - 12 : hour;

    return {
      label: `${Math.floor(hh) === 0 ? 12 : Math.floor(hh)}:${
        isDecimal ? `30` : `00`
      } ${abbreviations}`,
      value: hour === 24 ? 0 : hour,
    };
  });
};

export const getGalaxyProductFromProduct = (
  product: ProductType,
): GalaxyProductModel => ({
  id: product.id,
  title: product.title,
  isAssigned: product.isAlreadyAssigned,
});

export const getGalaxyVariantsFromVariants = (
  variant: VariantType,
): GalaxyVariantModel => ({
  id: variant.variantId,
  title: variant.variantTitle,
  soldItems: variant.numberOfBookings,
});

export const getGalaxyEventFromEvent = (event: EventType): GalaxyEventModel => {
  const {
    id,
    name,
    startTime,
    endTime,
    duration,
    isActive,
    waitingRoomGuid,
    bookingsByVariant,
    youTubeLink,
    canBeMerged,
  } = event;

  const date = new Date(startTime);
  const monthLong = date.toLocaleString('default', { month: 'long' });
  const weekLong = date.toLocaleString('default', {
    weekday: 'long',
  });

  const startET = utcToZonedTime(startTime, 'America/New_York');
  const endET = utcToZonedTime(endTime, 'America/New_York');

  const startHours = startET.getHours();
  const endHours = endET.getHours();

  const products = bookingsByVariant.map((v) => ({
    id: v.productId,
    title: v.productTitle,
    isAssigned: true,
  }));
  const talentLinkInfos = event.talentLinkInfos.map((linkInfo) => {
    return {
      waitingRoomGuid: linkInfo.waitingRoomGuid,
      atendeeGuid: linkInfo.atendeeGuid,
      description: linkInfo.description,
    };
  });

  return {
    id,
    title: name,
    start: startTime,
    end: endTime,
    isActive,
    duration,
    youTubeLink,
    canBeMerged,
    roomId: waitingRoomGuid,
    products: [products[0]],
    variants: bookingsByVariant.map(getGalaxyVariantsFromVariants),
    src:
      bookingsByVariant.find((variant) => variant.src) &&
      !!bookingsByVariant.find((variant) => variant.src)?.src
        ? bookingsByVariant.find((variant) => variant.src)!.src
        : Logo,
    startH: startHours === 0 ? 24 : startHours,
    endH: endHours === 0 ? 24 : endHours,
    day: {
      short: date.getDate(),
      long: weekLong,
    },
    month: {
      short: date.getMonth() + 1,
      long: monthLong,
    },
    talentLinkInfos,
  };
};
