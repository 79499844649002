import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { H3, TextRegular, BtnText } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { Paths } from 'paths';
import { createUserAccount } from '../../redux';
import { SignupForm, AuthLayout } from '../../components';
import { SignupData } from '../../types';
import { Roles } from '../../consts';

import { FormContainer, Header } from '../styled';

export const UserSignup: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(
    async (data: SignupData) => {
      const { email, password, firstName, lastName } = data;
      try {
        await dispatch(
          createUserAccount({
            email,
            password,
            firstName,
            lastName,
            role: Roles.FAN,
          }),
        );
        history.push(`${Paths.VerifyEmail}?email=${encodeURIComponent(email)}`);
      } catch (err) {
        setError('User with given email already exists');
      }
    },
    [dispatch, createUserAccount],
  );

  return (
    <AuthLayout>
      <FormContainer>
        <Header>
          <H3>Create an account</H3>
        </Header>
        <SignupForm isUser onSubmit={onSubmit} />
        {error && (
          <p>
            {`Already registered. `}
            <Link to={Paths.ResetPassword}>Reset your password</Link>
            {` or `}
            <Link to={Paths.UserLogin}>{Dictionary.LOG_IN}</Link>
          </p>
        )}
        <TextRegular>
          {Dictionary.LOGIN_HEADING}
          <BtnText>
            <Link to={Paths.UserLogin}>{Dictionary.LOG_IN}</Link>
          </BtnText>
        </TextRegular>
      </FormContainer>
    </AuthLayout>
  );
});
