import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';
import { Attendee } from '../../utils';

export const queueSelector = (state: RootState): RootState['queue'] =>
  state.queue;

export const getAttendees = createSelector([queueSelector], (queue) => {
  return queue.list.filter((attendee) => attendee.showInQueue) as Attendee[];
});

export const recorderStatus = createSelector([queueSelector], (queue) => {
  return queue.recorder;
});
