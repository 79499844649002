import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main/redux';

export const sessionsSelector = (state: RootState): RootState['userSessions'] =>
  state.userSessions;

export const getNextSessions = createSelector(
  [sessionsSelector],
  (userSessions) => userSessions.nextSessions,
);

export const getPastSessions = createSelector(
  [sessionsSelector],
  (userSessions) => userSessions.pastSessions,
);
