import styled from 'styled-components';

export const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-bottom: 34px;
  text-align: center;
`;

export const CenterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  text-align: center;
`;
