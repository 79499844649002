import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorException } from 'modules/utils';
import { AsyncThunkConfig, Options, RequestFunction } from './types';

export const addGlobalErrors = createAction<ErrorException[]>(
  'ADD_GLOBAL_ERROR',
);

// TODO: add with SSE dispatch
export const createAsyncApiThunk = <R, T = void>(
  name: string,
  apiCall: RequestFunction<T, R>,
  options: Options = {},
): AsyncThunk<R, T, AsyncThunkConfig> =>
  createAsyncThunk(
    name,
    async (arg, thunkApi): Promise<R> => {
      const { mapper, useErrorHandler } = options;

      const handleErrors = (errors: ErrorException[]) => {
        if (useErrorHandler) {
          thunkApi.dispatch(addGlobalErrors(errors));
        }

        throw errors;
      };

      try {
        // todo: pass thunkApi to apiCall
        const response = await apiCall(arg);

        if (!response.success) {
          return handleErrors(response.errors);
        }

        if (mapper) {
          return mapper(response.result);
        }

        return response.result;
      } catch (error) {
        return handleErrors([error]);
      }
    },
  );
