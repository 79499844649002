import { useCallback, useEffect, useState } from 'react';
import { GalaxyProductModel } from '../types';

export const useProducts = (
  initialProducts: GalaxyProductModel[],
): [
  GalaxyProductModel[],
  (product: GalaxyProductModel) => void,
  (product: GalaxyProductModel) => void,
] => {
  const [selectedProducts, setProducts] = useState<GalaxyProductModel[]>(
    initialProducts,
  );

  const deselectProduct = useCallback(
    (product: GalaxyProductModel): void => {
      setProducts(selectedProducts.filter((p) => p.id !== product.id));
    },
    [setProducts, selectedProducts],
  );

  const selectProduct = useCallback(
    (product: GalaxyProductModel): void => {
      const isSelected = selectedProducts.some(({ id }) => id === product.id);

      if (isSelected) {
        deselectProduct(product);
      } else {
        setProducts([...selectedProducts, product]);
      }
    },
    [setProducts, deselectProduct, selectedProducts],
  );

  useEffect(() => {
    if (initialProducts.length) {
      setProducts(initialProducts);
    }
  }, [setProducts, initialProducts]);

  return [selectedProducts, selectProduct, deselectProduct];
};
