import { ColumnContainer, H3, RowContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled, { css } from 'styled-components';

export const MainContainer = styled(RowContainer)`
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.ga.darkGray};

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const CircleButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;

  &.approve {
    border: 2px solid ${({ theme }) => theme.ga.green};

    svg path {
      fill: ${({ theme }) => theme.ga.green};
    }
  }

  &.disapprove {
    margin-left: auto;
    border: 2px solid ${({ theme }) => theme.ga.magenta};
    margin-right: 16px;
    svg path {
      fill: ${({ theme }) => theme.ga.magenta};
    }
  }
`;

export const ButtonsContainer = styled(RowContainer)`
  button:not(.approve, .disapprove) {
    padding: 8px 16px;
    width: 145px;
    background: white;
    color: black;
    outline: none;
  }

  @media ${device.mobile} {
    margin-top: 100px;
  }
`;
export const Container = styled(ColumnContainer)`
  margin: auto;
  /* height: 476px; */
  text-align: center;

  ${H3} {
    color: white;
  }

  @media ${device.mobile} {
    height: auto;
    margin: 0px;
    padding: 46px 22px;
    box-sizing: border-box;
  }
`;

export const VideoPlaceholder = styled.div`
  width: 360px;
  height: 202px;
  position: relative;
  background: ${({ theme }) => theme.ga.darkBlue};

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const VideoContainer = styled(ColumnContainer)`
  width: 360px;
  height: 400px;

  & > div:not(.btn-container) {
    border-radius: 8px;
    margin-bottom: 14px;
    min-height: 202px;
  }

  .video-element {
    border-radius: 8px;
    overflow: hidden;
  }

  @media ${device.mobile} {
    width: 100%;
    height: auto;
  }
`;

export const InnerContainer = styled(RowContainer)``;
export const ChatContainer = styled(ColumnContainer)<{
  isFan?: boolean;
  isChatOpen?: boolean;
}>`
  height: 476px;

  & > div {
    height: 476px;
    margin-left: 16px;
  }

  ${({ isFan }) =>
    isFan &&
    css`
      height: 420px;
    `}

  @media ${device.mobile} {
    & > div {
      margin-left: 0px;
      height: 270px;
    }
    position: fixed;
    left: 0px;
    z-index: 9999;
    width: 100%;
    display: ${({ isChatOpen }) => (!isChatOpen ? 'none' : 'initial')};
  }
`;
