import styled from 'styled-components';

export const MembersContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  overflow-y: scroll;
`;

export const MemberListContainer = styled.div`
  width: 100%;
  padding-bottom: 48px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 28px 0 8px 0;

  h1 {
    margin: 0;
  }
`;

export const ModalContainer = styled.div`
  height: 472px;
  width: 375px;
`;
