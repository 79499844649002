import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Paths } from 'paths';
import { BtnText, Button, TextInput } from 'modules/ui';
import { Dictionary, Validators } from 'modules/utils';
import { LoginData, LoginResponse } from '../../types';
import { SubmitContainer, FormContainer } from './styled';

type Props = {
  onSubmit: (data: LoginData) => void;
  response: LoginResponse;
  setResponse: (response: LoginResponse) => void;
};

export const LoginForm: React.FC<Props> = React.memo(
  ({ onSubmit, response, setResponse }) => {
    return (
      <FormContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            email: '',
            password: '',
          }}
          render={({ handleSubmit }): React.ReactNode => (
            <form onSubmit={handleSubmit}>
              <Field
                type="email"
                name="email"
                label="Email"
                component={TextInput}
                validate={Validators.mustBeValidEmail}
              />
              <Field
                type="password"
                name="password"
                label="Password"
                validate={Validators.composeValidators(Validators.required)}
                component={TextInput}
                password
                response={response}
                onChange={() =>
                  response.message &&
                  response.message !== '' &&
                  setResponse({
                    ...response,
                    hasResponse: false,
                    message: '',
                  })
                }
              />
              <SubmitContainer>
                <Button
                  type="submit"
                  label={Dictionary.LOG_IN}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  clickHandler={() => {}}
                />
                <Link className="forgot-password" to={Paths.ForgotPassword}>
                  <BtnText>{Dictionary.FORGOT_PASSWORD}</BtnText>
                </Link>
              </SubmitContainer>
            </form>
          )}
        />
      </FormContainer>
    );
  },
);
