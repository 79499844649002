import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, EventTitle, Modal, TextSmall, CloseIcon } from 'modules/ui';
import { useDispatch } from 'react-redux';
import { CommonMeetingActions } from 'modules/common-meeting';
import { GalaxyEventTalentLinkInfo } from 'modules/galaxy-events';
import { EventInfo } from '../../types';
import { ScheduleDictionary } from '../../dictionary';
import { Container } from './styled';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  event: EventInfo;
};

export const EventModal: React.FC<Props> = React.memo(
  ({ isOpen, onRequestClose, event }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const date = `${event.dayName}, ${event.monthName} ${event.dayNumber}`;
    const time = `${event.startTime} - ${event.endTime}`;
    // eslint-disable-next-line prefer-destructuring
    const talentLinkInfos: GalaxyEventTalentLinkInfo[] =
      event.data.talentLinkInfos;

    // TODO: Use enum
    const joinSession = useCallback(() => {
      // todo: compose route
      history.push(`/admin/session/${event.data.roomId}`);
    }, []);

    const copyLink = (linkInfo: GalaxyEventTalentLinkInfo) => {
      dispatch(
        CommonMeetingActions.copyTalentLinkToClipboard({
          waitingRoomGuid: event.data.roomId,
          atendeeGuid: linkInfo.atendeeGuid,
        }),
      );
    };

    return (
      <Modal
        isBlur
        isSmall
        width={250}
        isOpen={isOpen}
        closeModal={onRequestClose}
        onRequestClose={onRequestClose}
      >
        <Container>
          <TextSmall>{date}</TextSmall>
          <TextSmall>{time}</TextSmall>
          <EventTitle>{event?.title}</EventTitle>
          <Button
            clickHandler={joinSession}
            label={ScheduleDictionary.JOIN_THE_SESSION}
          />
          {talentLinkInfos.map((linkInfo, index) => (
            <Button
              key={linkInfo.atendeeGuid}
              isDark
              clickHandler={() => copyLink(linkInfo)}
              label={`${ScheduleDictionary.COPY_LINK} No. ${index + 1}`}
            />
          ))}
          <CloseIcon onClick={onRequestClose} className="close-icon" />
        </Container>
      </Modal>
    );
  },
);
