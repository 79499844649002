import { ColumnContainer, H2, H4Bold, RowContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const Container = styled(RowContainer)`
  background: ${({ theme }) => theme.ga.grayBackground};
  min-height: 100%;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;
export const ContentContainer = styled(ColumnContainer)`
  width: 512px;
  min-height: 100%;
  background: ${({ theme }) => theme.ga.darkBlue};
  padding: 62px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  ${H2} {
    color: white;
  }

  a {
    text-decoration: none;
    padding: 12px 29px;
    background: ${({ theme }) => theme.ga.white};
    color: ${({ theme }) => theme.ga.darkBlue};
    font-weight: bold;
    border-radius: 4px;
  }

  @media ${device.laptop} {
    width: 100%;
    padding: 62px 22px 22px 22px;
    a {
      margin-top: 20px;
    }
  }
`;
export const ProductsContainer = styled(ColumnContainer)`
  padding: 77px;

  @media ${device.tablet} {
    padding: 77px 22px 22px 22px;
  }
`;

export const SnapshotCard = styled(RowContainer)`
  max-width: 600px;
  img {
    max-width: 307px;
    border-radius: 8px;
  }

  @media ${device.tablet} {
    flex-direction: column;

    img {
      max-width: 100%;
      margin-bottom: 8px;
    }
  }
`;
export const SnapshotCardContent = styled(ColumnContainer)`
  padding-left: 22px;
  justify-content: space-between;
  ${H4Bold} {
    margin-top: 0px;
  }

  @media ${device.tablet} {
    padding-left: 0px;
  }
`;

export const OrderButton = styled.a`
  text-decoration: none;
  padding: 12px 32px;
  max-width: 145px;
  border-radius: 8px;
  color: ${({ theme }) => theme.ga.white};
  background: ${({ theme }) => theme.ga.magenta};
`;
