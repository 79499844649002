import React from 'react';
import { Button, CalendarIcon, Modal, TextRegular } from 'modules/ui';
import { useHistory } from 'react-router-dom';
import { Questionnaire } from 'modules/lobby';
import { QuestionnaireFormData } from 'modules/common-meeting';
import { useModal } from 'modules/main';
import { useDispatch } from 'react-redux';
import { ProductActions } from '../../redux';
import { OrderEvent } from '../../types';

import {
  EventContainer,
  InfoContainer,
  TopContainer,
  MiscInfoText,
  DateContainer,
  EventTitleText,
  TextBold,
  ButtonWrapper,
  Placeholder,
} from './styled';

type Props = {
  orderEvent: OrderEvent;
};

export const CurrentEvent: React.FC<Props> = React.memo(({ orderEvent }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isQuestionnaireOpen, openQuestionnaire, closeQuestionnaire] = useModal(
    false,
  );

  const handleSubmitQuestionnaire = (data: QuestionnaireFormData) => {
    dispatch(ProductActions.saveQuestionnaireAnswers(data));
    history.push(`/session/${orderEvent.waitingRoomGuid}`);
  };

  return (
    <>
      <EventContainer>
        {orderEvent.imageSrc ? (
          <img src={orderEvent.imageSrc} alt={orderEvent.variantTitle} />
        ) : (
          <Placeholder />
        )}
        <InfoContainer>
          <TopContainer>
            <DateContainer>
              <TextRegular>
                Quantity:
                {orderEvent.quantity}
              </TextRegular>
              <CalendarIcon />
              <TextRegular>{orderEvent.variantTitle}</TextRegular>
            </DateContainer>
            <Button label="Access Session" clickHandler={openQuestionnaire} />
          </TopContainer>
          <EventTitleText>{orderEvent.title}</EventTitleText>
          <TextBold>{orderEvent.title}</TextBold>
          <MiscInfoText>
            Recording of your video chat and purchase of Selfie snapshot
            available after the session
          </MiscInfoText>
          <ButtonWrapper>
            {/* TODO: this should be Link */}
            <Button label="Access Session" clickHandler={openQuestionnaire} />
          </ButtonWrapper>
        </InfoContainer>
      </EventContainer>
      {/* TODO: Do nor render questionnaire if already filled, waiting for this to be implemented on the backend */}
      {isQuestionnaireOpen && (
        <Modal
          isSmall
          isOpen={isQuestionnaireOpen}
          fullWidth={false}
          closeModal={closeQuestionnaire}
          onRequestClose={closeQuestionnaire}
          isDark
          width={632}
        >
          <Questionnaire
            closeModal={closeQuestionnaire}
            submitQuestionnaire={handleSubmitQuestionnaire}
          />
        </Modal>
      )}
    </>
  );
});
