import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';

export const lobbySelector = (state: RootState): RootState['lobby'] =>
  state.lobby;

export const getSessionName = createSelector(
  [lobbySelector],
  (lobby) => lobby.info.name,
);

export const getLobbyInfo = createSelector(
  [lobbySelector],
  (lobby) => lobby.info,
);

export const showQuestionnaire = createSelector(
  [lobbySelector],
  (lobby) => lobby.questionnaire,
);
