import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    color: ${({ theme }) => theme.ga.white};
  }
  span:nth-child(2) {
    margin-bottom: 32px;
  }
`;
