import React from 'react';
import {
  CalendarIcon,
  DownloadIcon,
  PlayIcon,
  TextBold,
  TextRegular,
} from 'modules/ui';
import {
  PastSession,
  DateContainer,
  IconContainer,
  MediaContainer,
} from './styled';

type PastProps = {
  title: string;
  date: string;
  disabled?: boolean;
  hasMediaIcons?: boolean;
};

export const PastSessionComponent: React.FC<PastProps> = React.memo(
  ({ title, date, disabled, hasMediaIcons }) => {
    return (
      <PastSession disabled={disabled}>
        <IconContainer>
          <DateContainer>
            <CalendarIcon />
            <TextRegular>{date}</TextRegular>
          </DateContainer>
          {hasMediaIcons && (
            <MediaContainer>
              <PlayIcon className="play" />
              <DownloadIcon />
            </MediaContainer>
          )}
        </IconContainer>
        <TextBold className="title">{title}</TextBold>
      </PastSession>
    );
  },
);
