import { useCallback, useState } from 'react';

type Returned = {
  isChatOpen: boolean;
  onChatToggle: () => void;
};

export const useMobileChat = (): Returned => {
  const [isChatOpen, toggleChat] = useState(false);

  const onChatToggle = useCallback(() => {
    toggleChat((chat) => !chat);
  }, [toggleChat]);

  return {
    isChatOpen,
    onChatToggle,
  };
};
