import React, { useState, useEffect } from 'react';
import { H2Regular, TextRegular } from 'modules/ui';
import { Polaroid } from '../../assets';
import { Container, LeftContainer, RightContainer } from './styled';

interface Props {
  initialSecondsRemaining: number;
}

export const SnapshotCountdownInfoCard: React.FC<Props> = ({
  initialSecondsRemaining,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState(
    initialSecondsRemaining,
  );

  useEffect(() => {
    setSecondsRemaining(initialSecondsRemaining);

    const intervalHandle = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining((c) => c - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [initialSecondsRemaining]);

  return (
    <Container>
      <LeftContainer>
        <img src={Polaroid} alt="polaroid" />
      </LeftContainer>
      <RightContainer>
        <TextRegular>Hey, we want to take a picture of you</TextRegular>
        <H2Regular>
          {'Get ready in '}
          <span>{secondsRemaining}</span>
        </H2Regular>
      </RightContainer>
    </Container>
  );
};
