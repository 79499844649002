import styled from 'styled-components';
import { H4Bold } from 'modules/ui';

export const Tr = styled.tr`
  text-align: left;
`;

export const Th = styled.th<{ width: number }>`
  word-wrap: break-word;
  width: ${(props) => props.width}px;
  border-bottom: 1px solid ${(props) => props.theme.ga.grayBackground};
  padding-left: 8px;

  & > ${H4Bold} {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;
