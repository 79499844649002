import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main/redux';

export const accountSelector = (state: RootState): RootState['account'] =>
  state.account;

export const userOrders = createSelector(
  [accountSelector],
  (account) => account.userOrders,
);

export const adminMembers = createSelector(
  [accountSelector],
  (account) => account.adminMembers,
);

export const isOwner = createSelector(
  [accountSelector],
  (account) => account.adminOwner,
);
