import React from 'react';
import { useSelector } from 'react-redux';
import {
  CommonMeetingSelectors,
  ParticipantState,
} from 'modules/common-meeting';
import { useTwilioConnection } from 'modules/chat';

import { TechCheckMeeting } from 'modules/tech-check';
import { InACallView } from '../InACallView';

export const AdminMeeting: React.FC = React.memo(() => {
  const adminParticipantState = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantState,
  );

  useTwilioConnection();

  switch (adminParticipantState) {
    case ParticipantState.IN_A_CALL:
      return <InACallView />;
    case ParticipantState.IN_A_TECH_CHECK:
      return <TechCheckMeeting />;

    default:
      return <InACallView />;
  }
});
