import React from 'react';
import { NavLink } from 'react-router-dom';
import { H2, Label } from 'modules/ui';
import { SubNav, Container } from './styled';

export const SubHeader: React.FC = React.memo(() => {
  return (
    <SubNav>
      <Container>
        <H2>Home Dashboard</H2>
        <div>
          <NavLink to="/admin/home/sessions" activeClassName="active">
            <Label>SESSIONS</Label>
          </NavLink>
          <NavLink to="/admin/home/media" activeClassName="active">
            <Label>MEDIA</Label>
          </NavLink>
          <NavLink to="/admin/home/orders" activeClassName="active">
            <Label>ORDERS</Label>
          </NavLink>
        </div>
      </Container>
    </SubNav>
  );
});
