import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'modules/ui';
import { CommonMeetingSelectors } from 'modules/common-meeting';
import { TalentActions } from '../../redux';
import FanInfoModal from '../FanInfoModal';

export const CallAnnouncement: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const bpsIdForFanParticipantInAnnouncement = useSelector(
    CommonMeetingSelectors.getBpsIdForFanParticipantInAnnouncement,
  );

  useEffect(() => {
    if (bpsIdForFanParticipantInAnnouncement) {
      dispatch(
        TalentActions.getFanQuestionnaireAnswers(
          bpsIdForFanParticipantInAnnouncement,
        ),
      );
    }
  }, [bpsIdForFanParticipantInAnnouncement]);

  return (
    <Modal isOpen width={632} isSmall isDark hideOverlayColor>
      <FanInfoModal />
    </Modal>
  );
});
