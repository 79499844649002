import React from 'react';
import ScrollLock from 'react-scrolllock';
import { H2, Modal, TextRegular } from 'modules/ui';
import { ModalContainer } from './styled';

export const DeviceNotSupportedModal: React.FC = React.memo(() => {
  return (
    <>
      <Modal isOpen fullWidth={false} isDark height={675} width={500}>
        <ModalContainer>
          <H2>Device not supported</H2>
          <TextRegular>
            It is currently not possible to video chat using Chrome on an iOS
            device. Please use Safari instead.
          </TextRegular>
        </ModalContainer>
      </Modal>
      <ScrollLock />
    </>
  );
});
