import styled from 'styled-components';
import { BtnText, ColumnContainer, TextRegular } from 'modules/ui';

export const FormContainer = styled(ColumnContainer)`
  div {
    margin: 16px 0px 16px 0px;
  }
`;

export const SubmitContainer = styled(ColumnContainer)`
  align-items: center;
  height: 100%;
  .terms {
    margin-top: 10px;
  }

  button {
    padding: 12px 71px;
    margin: 27px 0px 32px 0px;
  }

  & > a:visited,
  a:hover,
  a:active {
    color: ${({ theme }) => theme.ga.darkBlue};
  }
  & > ${BtnText} {
    color: ${({ theme }) => theme.ga.darkBlue};
  }

  ${TextRegular} {
    margin-top: auto;
    color: ${({ theme }) => theme.ga.gray};
  }

  a {
    text-decoration: none;
  }
`;
