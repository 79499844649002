import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: relative;
  width: 24px;
  svg {
    margin: 0px;
    cursor: pointer;
  }
`;

export const Toggler = styled.span``;

export const Menu = styled.ul<{
  isOpen: boolean;
}>`
  min-width: 200px;
  position: absolute;
  right: 0px;
  top: 41px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.15);
  display: ${({ isOpen }) => (isOpen ? 'inline-block' : 'none')};
  margin: 0px;
  padding: 0px;
  z-index: 999;

  .remove {
    color: ${({ theme }) => theme.ga.magenta};
    cursor: pointer;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  white-space: nowrap;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({ theme }) => theme.ga.darkBlue};
  border-bottom: 1px solid ${({ theme }) => theme.ga.grayBackground};
  border-radius: 4px;

  a {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.ga.darkBlue};
    padding: 0;
  }

  .sign-out {
    margin-left: auto;
    padding-left: 8px;
  }

  &.delete-session {
    padding: 8px 8px 8px 16px;
    color: ${({ theme }) => theme.ga.pink};
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.ga.grayBackground};
  }
`;
