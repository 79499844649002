import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncApiThunk } from 'modules/adapter';
import { AuthSelectors } from 'modules/auth';
import { getDigitalAssetsApi } from '../api';
import { AssetType } from '../types';

export const getFanVideosBase = createAsyncApiThunk(
  'GET_FAN_VIDEOS_BASE',
  (id: number) => getDigitalAssetsApi(id, AssetType.Video),
);

export const getFanVideos = createAsyncThunk(
  'GET_FAN_VIDEOS',
  (_: void, { dispatch, getState }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { id } = AuthSelectors.getUser(getState() as any);

    if (!id) {
      throw new Error('No logged in user.');
    }

    dispatch(getFanVideosBase(id));
  },
);
