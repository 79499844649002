/* eslint-disable no-console */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addGlobalErrors, createAsyncApiThunk } from 'modules/adapter';
import { ThunkAction } from 'modules/main';
import {
  getUserOrderInfosRequest,
  deleteGalaxyEventRequest,
  getEventsRequest,
  getEventsForDateRequest,
  createEventRequest,
  updateEventRequest,
  getEventProductsRequest,
  getEventByIdRequest,
  getNextEventRequest,
} from '../api';
import {
  ProductType,
  SessionType,
  EventType,
  GalaxyEventModel,
  EventData,
  EventEditData,
} from '../types';

import { getStringDateInET } from '../utils';

export const saveSession = createAction<SessionType>('SAVE_SESSION');
export const getProducts = createAction<ProductType[]>('GET_PRODUCTS');
export const setEvents = createAction<EventType[]>('SET_EVENTS');
export const setNextEvent = createAction<EventType>('SET_NEXT_EVENT');

export const getEvents = createAsyncThunk('GET_EVENTS', getEventsRequest);

export const getEventsForDate = createAsyncThunk(
  'GET_EVENTS',
  async (arg: Date, { dispatch }): Promise<GalaxyEventModel[]> => {
    try {
      const events = await getEventsForDateRequest(arg);
      return events;
    } catch (error) {
      dispatch(addGlobalErrors(error.toString()));
      throw error;
    }
  },
);

export const createEvent = createAsyncThunk('CREATE_EVENT', createEventRequest);
export const updateEvent = createAsyncThunk('UPDATE_EVENT', updateEventRequest);
export const getEventProducts = createAsyncThunk(
  'GET_EVENT_PRODUCTS',
  getEventProductsRequest,
);
export const getEventById = createAsyncThunk(
  'GET_EVENT_BY_ID',
  getEventByIdRequest,
);

export const getUserOrderInfos = createAsyncApiThunk(
  'GET_USER_ORDER_INFOS',
  getUserOrderInfosRequest,
);

export const deleteGalaxyEvent = createAsyncThunk(
  'DELETE_GALAXY_EVENT',
  async (arg: GalaxyEventModel, { dispatch }): Promise<GalaxyEventModel> => {
    try {
      await deleteGalaxyEventRequest(arg.id);

      return arg;
    } catch (error) {
      dispatch(addGlobalErrors(error.toString()));
      throw error;
    }
  },
);

export const createGalaxyEvent = (data: EventData): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    const {
      date,
      startTime,
      endTime,
      name,
      products,
      youTubeLink,
      sessionDuration,
    } = data;
    const start = getStringDateInET(date, startTime);
    const end = getStringDateInET(date, endTime);

    const body = {
      name,
      startTime: start,
      endTime: end,
      youTubeLink,
      duration: Number(sessionDuration),
      productIds: products,
    };

    dispatch(createEvent(body));
  } catch (error) {
    throw new Error(error);
  }
};

export const updateGalaxyEvent = (
  data: EventEditData,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  try {
    const {
      id,
      date,
      startTime,
      endTime,
      name,
      products,
      youTubeLink,
      sessionDuration,
    } = data;
    const start = getStringDateInET(date, startTime);
    const end = getStringDateInET(date, endTime);

    const body = {
      bookingSlotId: id,
      name,
      startTime: start,
      endTime: end,
      youTubeLink,
      duration: Number(sessionDuration),
      productIds: products,
    };

    dispatch(updateEvent(body));
  } catch (error) {
    throw new Error(error);
  }
};

export const getNextSession = (): ThunkAction<void> => async (
  dispatch,
): Promise<void> => {
  try {
    const date = new Date().toISOString();
    const { result } = await getNextEventRequest(date);

    if (result) {
      dispatch(setNextEvent(result));
    }
  } catch (error) {
    throw new Error(error);
  }
};
