import React, { useCallback, useState } from 'react';
import { H1, TextError, Spinner, TextSuccess } from 'modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors, UpdateProfile, updateProfile } from 'modules/auth';
import { ProfileForm } from '../../components';
import { FormContainer } from '../../styled';

export const AccountDetails: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: true,
    message: '',
    hasResponse: false,
  });
  const user = useSelector(AuthSelectors.getUser);
  const { email, firstName, lastName } = user.profile;

  const onSubmit = useCallback(
    async (data: UpdateProfile) => {
      setLoading(true);
      const { firstName: newFirstName, lastName: newLastName } = data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await dispatch(
        updateProfile({
          firstName: newFirstName,
          lastName: newLastName,
          email,
        }),
      );

      const { success, message } = res;
      setLoading(false);
      setResponse({ success, message, hasResponse: true });
    },
    [dispatch],
  );

  return (
    <FormContainer>
      <H1>Account Details</H1>
      {response.hasResponse &&
        (response.success ? (
          <TextSuccess>{response.message}</TextSuccess>
        ) : (
          <TextError>{response.message}</TextError>
        ))}
      <ProfileForm
        onSubmit={onSubmit}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
      {loading && <Spinner />}
    </FormContainer>
  );
});
