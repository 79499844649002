import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EventsSelectors, EventsActions } from 'modules/galaxy-events';
import { Navigator } from '../Navigator';
import { Calendar } from '../Calendar';
import { DatePickerDate } from '../../types';
import { Container } from './styled';

export const Schedule: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<DatePickerDate | null>(null);
  const events = useSelector(EventsSelectors.getCalendarEvents);

  const onDateChange = useCallback((date: DatePickerDate) => {
    setSelectedDate(date);
  }, []);

  useEffect(() => {
    dispatch(EventsActions.getEvents());
    dispatch(EventsActions.getNextSession());
  }, [dispatch]);

  useEffect(() => {
    document.title = `GalaxyCon Scheduler`;
  });

  return (
    <Container>
      <Navigator selectedDate={selectedDate} onDateChange={onDateChange} />
      <Calendar selectedDate={selectedDate} events={events} />
    </Container>
  );
});
