import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextInput } from 'modules/ui';
import { AuthSelectors } from 'modules/auth';
import { QuestionnaireFormData } from 'modules/common-meeting';

import {
  Title,
  Subtitle,
  Container,
  FormContainer,
  ButtonsContainer,
} from './styled';

type Props = {
  closeModal: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitQuestionnaire: (data: QuestionnaireFormData) => void;
};

const QuestionnaireBase: React.FC<Props> = ({
  closeModal,
  submitQuestionnaire,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(AuthSelectors.getUser);

  const onSubmit = useCallback(
    (data: QuestionnaireFormData) => {
      closeModal();
      submitQuestionnaire(data);
    },
    [dispatch, user, closeModal],
  );
  return (
    <Container>
      <Title>Tell Us About Yourself</Title>
      <Subtitle>Let your superhero meet you before you meet them.</Subtitle>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          from: '',
          fan: '',
          oneThing: '',
        }}
        render={({ handleSubmit, pristine }): React.ReactNode => (
          <FormContainer>
            <Field
              name="from"
              label="Where are you from?"
              placeholder="Country / Region"
              component={TextInput}
              isDarkBackground
              isSecondary
              instructions="Maximum 30 characters"
              maxLength={30}
            />
            <Field
              isDarkBackground
              name="fan"
              label="What are you a fan of?"
              placeholder="People, Characters, TV Shows, Movies, Sports Teams, Activities, etc."
              component={TextInput}
              isSecondary
              instructions="Maximum 30 characters"
              maxLength={30}
            />
            <Field
              isDarkBackground
              name="oneThing"
              label="What is one thing you want to know today?"
              placeholder="Please make your question clear and polite."
              component={TextInput}
              isSecondary
              instructions="Maximum 25 characters"
              maxLength={25}
            />
            <ButtonsContainer>
              <Button
                clickHandler={handleSubmit}
                isSecondary
                label="Skip & Close"
              />
              <Button
                disabled={pristine}
                clickHandler={handleSubmit}
                label="Save"
              />
            </ButtonsContainer>
          </FormContainer>
        )}
      />
    </Container>
  );
};

export const Questionnaire = React.memo(QuestionnaireBase);
