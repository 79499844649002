/* eslint-disable no-shadow */
export enum EventDictionary {
  CREATE_NEW_SESSION = 'Create new session',
  EDIT_SESSION = 'Edit session',
  CONNECT_PRODUCT = 'CONNECT PRODUCT',
  NAME = 'name',
  SESSION_NAME = 'SESSION NAME',
  ENTER_SESSION_NAME = 'Enter session name',
  YOUTUBELINK = 'youTubeLink',
  YOUTUBE_LINK = 'YOUTUBE LINK',
  ENTER_LINK = 'Enter link',
  DATE = 'date',
  SESSION_DATE = 'SESSION DATE',
  ENTER_DATE = 'Enter date',
  SESSIONDURATION = 'sessionDuration',
  SESSION_DURATION = 'SESSION DURATION',
  NUMBER = 'number',
  ENTER_SECONDS = 'Enter seconds',
  ENDTIME = 'endTime',
  STARTTIME = 'startTime',
  SESSION_END_TIME = 'SESSION END TIME',
  SESSION_START_TIME = 'SESSION START TIME',
  SELECT_TIME = 'Select time',
  CONNECT_SHOPIFY_PRODUCTS = 'Connect products created in Shopify',
  CONNECT_PRODUCTS = 'Connect product',
  CLOSE = 'Close',
  SAVE_AND_CREATE = 'Save & Create link',
}
