export enum URL {
  SEND_FAN_TO_TECH_CHECK = 'v2/waitingroom/send-to-tech-check',
  APPROVE_FAN = 'v2/waitingroom/approve-fan',
  SET_TECH_CHECK_FAILED = 'v2/waitingroom/set-tech-check-failed',
}

export enum TechCheckActionType {
  SEND_FAN_TO_TECH_CHECK = 'SEND_FAN_TO_TECH_CHECK',
}

export enum TechCheckStatus {
  READY = 'ready',
  FAILED = 'failed',
  UNKNOWN = 'unknown',
}
