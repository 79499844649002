import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  LocalVideo,
  VideoTileGrid,
} from 'amazon-chime-sdk-component-library-react';

import {
  CommonMeetingSelectors,
  CommonMeetingActions,
  SessionControls,
  useAutoStartVideo,
  useConnectToMainCall,
  AdminTalentControls,
  DevicePermissionsModal,
} from 'modules/common-meeting';
import {
  CameraIcon,
  TextRegular,
  NetworkQuality,
  SnapshotCountdownInfoCard,
} from 'modules/ui';
import { Chat, useMobileChat, ChatHelpers } from 'modules/chat';
import { LobbySelectors } from 'modules/lobby';
import { useShouldAskForFocus } from '../../hooks';
import { TalentSelectors } from '../../redux';

import ChatOverOptions from './ChatOverOptions';
import { AskForFocusModal } from '../AskForFocusModal';
import {
  Container,
  MainContainer,
  VideoContainer,
  AsideContainer,
  TitleContainer,
  LocalVideoContainer,
  InfoCardContainer,
  ChatOverContainer,
} from './styled';

export const InACallView: React.FC = React.memo(() => {
  const { uid } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const sessionName = useSelector(LobbySelectors.getSessionName);
  const meetingInfo = useSelector(CommonMeetingSelectors.getMainMeetingInfo);
  const photoTimerSecondsLeft = useSelector(
    CommonMeetingSelectors.getPhotoSecondsLeft,
  );
  const shouldShowChatOptions = useSelector(
    TalentSelectors.shouldShowChatOptions,
  );

  const isCallWithTalentInRedZone = useSelector(
    CommonMeetingSelectors.isCallWithTalentInRedZone,
  );

  const { isChatOpen, onChatToggle } = useMobileChat();

  const shouldAskForFocus = useShouldAskForFocus();

  useAutoStartVideo();
  useConnectToMainCall();

  const isPhotoTimerVisible =
    !!photoTimerSecondsLeft && photoTimerSecondsLeft !== -1;

  return (
    <>
      <MainContainer applyRedBorder={isCallWithTalentInRedZone}>
        <Container>
          <TitleContainer>
            <CameraIcon />
            <TextRegular>{sessionName}</TextRegular>
          </TitleContainer>
          <VideoContainer>
            {shouldShowChatOptions && (
              <ChatOverContainer>
                <ChatOverOptions />
              </ChatOverContainer>
            )}
            <InfoCardContainer>
              {isPhotoTimerVisible && (
                <SnapshotCountdownInfoCard
                  initialSecondsRemaining={photoTimerSecondsLeft}
                />
              )}
            </InfoCardContainer>
            <VideoTileGrid layout="standard" className="video-grid" />
            <SessionControls />
            <AdminTalentControls
              onTakeSnapshot={() =>
                dispatch(CommonMeetingActions.startScreenshotTimer())
              }
              isTalent
            />
          </VideoContainer>
        </Container>
        <AsideContainer>
          <NetworkQuality attendeeUid={meetingInfo.chimeAttendeeId} />
          <LocalVideoContainer>
            <LocalVideo className="talent-local-video" />
          </LocalVideoContainer>
          <Chat
            id={ChatHelpers.getTalentChatId(uid)}
            isMobileOpened={isChatOpen}
            onChatToggle={onChatToggle}
            isTalentView
          />
        </AsideContainer>
        <DevicePermissionsModal />
        {shouldAskForFocus && <AskForFocusModal isOpen={shouldAskForFocus} />}
      </MainContainer>
    </>
  );
});
