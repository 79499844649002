export enum URL {
  GET_WAITING_ROOM_BY_UID = 'get-waiting-room-by-guid',
  GET_BPS_FOR_WAITING_ROOM = 'get-bps-for-waiting-room',
  GET_SHOPIFY_REDIRECTS = 'shopify-redirect',
  GET_TECH_CHECK_JOINING_INFO = 'v2/general/get-techcheck-call-joining-info',
  SEND_TO_CALL_WITH_TALENT = 'v2/waitingroom/send-to-call-talent',
  FINISH_PARTICIPANT = 'v2/waitingroom/finish-participant',
  SEND_TO_QUEUE = 'v2/waitingroom/send-to-queue',
  PAUSE_TALENT_CALL = 'v2/waitingroom/pause-talent-call',
  INCREMENT_CALL_SECONDS_LEFT = 'v2/waitingroom/add-seconds',
  START_PHOTO_TIMER = 'v2/waitingroom/photo-timer',
  ENTER_WAITING_ROOM = 'v2/waitingroom/enter-waitingroom',
  PREPARE_WAITING_ROOM = 'v2/waitingroom/prepare-waiting-room',
  UPDATE_TALENT_NAME = 'v2/waitingroom/update-talent-name',
  REORDER_FANS = 'v2/waitingroom/reorder-positions',
  SET_OFF_PARTICIPANT_HEARTBEAT = 'v2/waitingroom/participant-heartbeat',
}

export enum Status {
  Unknown = 0,
  Scheduled = 1,
  Lobby = 2,
  InATechCheckCall = 7,
  TechCheckRejected = 8,
  ReadyToJoin = 3,
  InACall = 4,
  Finished = 5,
  Removed = 6,
}

export enum ParticipantType {
  Unknown,
  Admin,
  TechCheck,
  Fan,
  Talent,
}

export const SCREENSHOT_TIMER_SECONDS = 5;

export enum CommonMeetingActionType {
  GET_MAIN_CALL_JOIN_INFO = 'GET_MAIN_CALL_JOIN_INFO',
  GET_READINESS_CALL_JOIN_INFO = 'GET_READINESS_CALL_JOIN_INFO',
  SEND_TO_CALL_WITH_TALENT = 'SEND_TO_CALL_WITH_TALENT',
  PREPARE_WAITING_ROOM = 'PREPARE_WAITING_ROOM',
  ENTER_WAITING_ROOM = 'ENTER_WAITING_ROOM',
  TICK_PHOTO_TIMER = 'TICK_PHOTO_TIMER',
  UPDATE_TALENT_NAME = 'UPDATE_TALENT_NAME',
  REORDER_WAITING_ROOM_FANS = 'REORDER_WAITING_ROOM_FANS',
  REORDER_FANS_THUNK = 'REORDER_FANS_THUNK',
  SET_OFF_PARTICIPANT_HEARTBEAT = 'SET_OFF_PARTICIPANT_HEARTBEAT',
}

export enum UserType {
  ADMIN = 'Admin',
  FAN = 'Fan',
  TALENT = 'Talent',
}

export enum ParticipantState {
  SCHEDULED = 'Scheduled',
  IN_A_QUEUE = 'InAQueue',
  IN_A_TECH_CHECK = 'InATechCheck',
  READY_TO_JOIN = 'ReadyToJoin',
  IN_ANNOUNCEMENT = 'InAnnouncement',
  IN_A_CALL = 'InACall',
  FINISHED = 'Finished',
  IN_PHOTO_TIMER = 'InPhotoTimer',
  CALL_PAUSED = 'CallPaused',
  DISCONNECTED = 'Disconnected',
  TECH_CHECK_FAILED = 'TechCheckFailed',
}

export enum WaitingRoomState {
  CREATED = 'Created',
  PREPARING_VIDEO_RECORDER = 'PreparingVideoRecorder',
  READY = 'Ready',
  TALENT_BREAK = 'TalentBrake',
  FINISHED = 'Finished',
}

export const DEFAULT_CALL_VIEW_RED_ZONE_THRESHOLD = 15;

export enum ConsentStatus {
  NotAnswered = 'NotAnswered',
  Given = 'Given',
  NotGiven = 'NotGiven',
}
