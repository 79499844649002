import React from 'react';
import { AvatarV3, TextBold } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { HeroContainer, HeroTextContainer, HeroText } from './styled';

export const NoEventsHero: React.FC = React.memo(() => {
  return (
    <HeroContainer>
      <img src={AvatarV3} alt={Dictionary.GALAXYCON} />
      <HeroTextContainer>
        <HeroText>You have not registered for any upcoming events,</HeroText>
        <TextBold>
          but you can join us for any of the events listed below.
        </TextBold>
      </HeroTextContainer>
    </HeroContainer>
  );
});
