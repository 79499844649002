import styled from 'styled-components';
import { Label } from 'modules/ui';

export const InviteFormContainer = styled.div`
  width: 390px;

  ${Label} {
    color: ${({ theme }) => theme.ga.gray};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 260px;
  margin: 60px auto 0 auto;
`;
