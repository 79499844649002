import styled from 'styled-components';
import { PostMeetingWaveBackground } from '../../assets';
import { RowContainer } from '../Styled';

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.ga.white};
  padding: 16px 28px 16px 8px;
`;

export const LeftContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 105px;
  padding-left: 20px;
  background-image: url(${PostMeetingWaveBackground});
`;

export const Container = styled(RowContainer)`
  border-radius: 8px;
  overflow: hidden;

  min-width: 360px;
  min-height: 88px;
  background: ${({ theme }) => theme.ga.grayBlueBackground};
`;
