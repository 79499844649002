import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, H1 } from 'modules/ui';
import { useModal } from 'modules/main';
import { Dictionary } from 'modules/utils';
import { AuthSelectors } from 'modules/auth';
import {
  AccountActions,
  AccountSelectors,
  Member,
  InviteModal,
} from 'modules/account';
import {
  MembersContainer,
  TitleContainer,
  MemberListContainer,
} from './styled';
import { SelectedUser, ModalVersion } from '../../consts';

export const Members: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const members = useSelector(AccountSelectors.adminMembers);
  const user = useSelector(AuthSelectors.getUser);
  const [selectedUser, selectUser] = useState<SelectedUser>({
    userId: 0,
    role: 0,
    isOwner: false,
  });
  const [version, setVersion] = useState<ModalVersion>(
    ModalVersion.INVITE_FORM,
  );
  const [isInviteModalOpen, openInviteModal, closeInviteModal] = useModal(
    false,
  );

  useEffect(() => {
    dispatch(AccountActions.getAdminMembers());
    if (user.id) {
      dispatch(AccountActions.getIsOwner(user.id));
    }
  }, [user]);

  const handleRemove = () => {
    const { userId, role, isOwner } = selectedUser;
    if (isOwner) {
      dispatch(AccountActions.removeAdminMember({ userId, role }));
    }
    closeInviteModal();
  };

  const handleCloseModal = () => {
    dispatch(AccountActions.getAdminMembers());
    closeInviteModal();
  };

  return (
    <>
      <MembersContainer>
        <TitleContainer>
          <H1>Members</H1>
          <Button
            label={Dictionary.INVITE_MEMBER}
            isSecondary
            clickHandler={openInviteModal}
          />
        </TitleContainer>
        <MemberListContainer>
          {members &&
            members.map((member) => (
              <Member
                key={member.userId}
                member={member}
                selectUser={selectUser}
                openModal={openInviteModal}
                setModalVersion={setVersion}
              />
            ))}
        </MemberListContainer>
      </MembersContainer>
      {isInviteModalOpen && (
        <InviteModal
          version={version}
          isOpen={isInviteModalOpen}
          closeModal={handleCloseModal}
          handleRemove={handleRemove}
        />
      )}
    </>
  );
});
