import styled from 'styled-components';

export const ErrorContainer = styled.div<{ index: number }>`
  position: fixed;
  top: ${({ index }) => `${10 + index * 80}px`};
  right: 10px;
  width: 280px;
  min-height: 80px;
  height: auto;
  background: #121e2b;
  /* background: white; */
  z-index: 99999;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: white;
  border-top: 4px solid #de106b;
`;
