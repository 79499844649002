import React from 'react';
import ReactModal from 'react-modal';
import { Colors } from 'modules/ui/theme';

ReactModal.setAppElement('#root');

// TODO: move to utils or style.ts
const MODAL_STYLES = (
  isSmall: boolean,
  width?: number,
  height?: number,
  fullWidth?: boolean,
  isBlur?: boolean,
  isDark?: boolean,
  hideOverlayColor?: boolean,
): ReactModal.Styles =>
  fullWidth
    ? {
        overlay: {
          backgroundColor: 'rgba(1,7,36, 0.75)',
          backdropFilter: 'blur(10px)',
          zIndex: 9999,
        },
        content: {
          width: '100%',
          height: '100%',
          right: undefined,
          left: '0px',
          top: '0px',
          overflowY: 'hidden',
          display: 'flex',
          padding: 0,
          bottom: undefined,
          outline: 'none',
          backgroundColor: 'transparent',
        },
      }
    : {
        overlay: {
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: !hideOverlayColor
            ? !isBlur
              ? 'rgba(1,7,36, 0.75)'
              : ''
            : 'rgb(3, 12, 24)',
          backdropFilter: !isBlur ? 'blur(10px)' : 'none',
          zIndex: 9999,
        },
        content: {
          border: 'none',
          boxShadow: !isBlur ? '' : '0px 2px 22px rgba(6, 18, 32, 0.25)',
          backgroundColor: isDark ? Colors.DarkBlue : Colors.White,
          borderColor: isDark ? Colors.DarkBlue : Colors.White,
          width: width ? `${width}px` : `80%`,
          maxHeight: height ? `${height}px` : 'calc(100% - 160px)',
          right: undefined,
          bottom: undefined,
          left: '50%',
          top: height ? `calc(50% - ${height / 2}px)` : '50%',
          transform: isSmall ? 'translate(-50%, -50%)' : 'translate(-50%)',
          overflowY: 'hidden',
          display: 'flex',
          padding: 0,
        },
      };

type Props = {
  isOpen: boolean;
  width?: number;
  height?: number;
  onRequestClose?: () => void;
  children: React.ReactChild | React.ReactNodeArray;
  closeModal?: () => void;
  isSmall?: boolean;
  fullWidth?: boolean;
  isBlur?: boolean;
  isDark?: boolean;
  hideOverlayColor?: boolean;
};

export const Modal: React.FC<Props> = React.memo(
  ({
    isOpen,
    width,
    height,
    children,
    onRequestClose,
    closeModal,
    isSmall,
    fullWidth,
    isBlur,
    isDark,
    hideOverlayColor,
  }) => {
    const updatedWidth =
      !width || window.innerWidth <= width ? undefined : width;

    const updatedFullWidth =
      !width || window.innerWidth <= width ? true : fullWidth;

    return (
      <ReactModal
        isOpen={isOpen}
        style={MODAL_STYLES(
          !!isSmall,
          updatedWidth,
          height,
          !!updatedFullWidth,
          !!isBlur,
          !!isDark,
          hideOverlayColor,
        )}
        onAfterClose={onRequestClose}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        {children}
      </ReactModal>
    );
  },
);
