import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paths } from 'paths';
import { AdminEventLayout } from 'modules/admin';
import { EventForm, ProductsPicker } from '../components';
import { EventDictionary } from '../dictionary';
import { useProducts } from '../hooks';
import { EventsActions } from '../redux';
import { EventFormData } from '../types';

export const CreateEventPage: React.FC = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedProducts, selectProduct, deselectProduct] = useProducts([]);

  const onSubmit = useCallback(
    (formData: EventFormData) => {
      dispatch(
        EventsActions.createGalaxyEvent({
          ...formData,
          products: selectedProducts.map((p) => p.id),
        }),
      );
      history.push(Paths.Sessions);
    },
    [dispatch, selectedProducts],
  );

  const closeHandler = useCallback(() => {
    history.push(Paths.Sessions);
  }, [history]);

  useEffect(() => {
    dispatch(EventsActions.getEventProducts());
  }, [dispatch]);

  useEffect(() => {
    document.title = `GalaxyCon Create Session`;
  });

  return (
    <AdminEventLayout>
      <div>
        <EventForm
          onSubmit={onSubmit}
          onClose={closeHandler}
          disabled={!selectedProducts.length}
          title={EventDictionary.CREATE_NEW_SESSION}
        />
        <ProductsPicker
          selectedProducts={selectedProducts}
          deselectProduct={deselectProduct}
          selectProduct={selectProduct}
        />
      </div>
    </AdminEventLayout>
  );
});
