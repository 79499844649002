import styled, { css } from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { device } from 'modules/utils';

export const MemberContainer = styled.div<{ isEditable?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.ga.white};
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  padding: 18px 24px;
  box-sizing: border-box;

  ${({ isEditable, theme }) =>
    !isEditable &&
    css`
      color: ${theme.ga.gray};
      svg {
        path {
          fill: ${theme.ga.gray};
        }
      }
    `}
`;

export const TextContainer = styled.div`
  display: inline-block;

  h4 {
    margin: 0;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    margin-right: 12px;
  }
`;

export const MemberItem = styled.li`
  white-space: nowrap;
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.ga.darkBlue};
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.ga.grayBackground};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.ga.grayBackground};
  }
`;
