import styled from 'styled-components';

export const ButtonContainer = styled.button`
  background-color: ${({ theme }) => theme.ga.white};
  padding: 12px 26px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.ga.grayBackground};
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
