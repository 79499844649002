export type DigitalAsset = {
  id: number;
  productId: number;
  productName: string;
  variantId: number;
  variantName: string;
  bookedParticipantSlotId: number;
  assestTypeId: number;
  referenceUri?: string;
  isSendingVideoUrlEnabled: boolean;
  createdAt: string;
  deletedAt?: string;
  startDateTime: string;
  endDateTime: string;
};

export enum AssetType {
  Photo = 1,
  Video = 2,
}
