import styled from 'styled-components';
import { device } from 'modules/utils';

export const NextSession = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 153px;
  background: ${({ theme }) => theme.ga.grayBackground};
  margin-bottom: 16px;
  padding: 27px 27px;
  box-sizing: border-box;
  border-radius: 4px;

  .title {
    font-size: 18px;
  }

  @media ${device.laptop} {
    padding: 18px 18px;
    .title {
      font-size: 17px;
    }
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
    height: auto;
    .title {
      font-size: 18px;
      margin-bottom: 22px;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 11px;
  }

  @media ${device.mobile} {
    span {
      font-size: 16px;
    }
  }
`;

export const IconContainer = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AccessSessionDesktop = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.ga.magenta};
  cursor: pointer;
  display: inline-block;

  @media ${device.mobile} {
    display: none;
  }
`;

export const AccessSessionMobile = styled(AccessSessionDesktop)`
  display: none;

  @media ${device.mobile} {
    display: inline-block;
  }
`;
