import { useEffect, useMemo } from 'react';
import { AuthSelectors } from 'modules/auth';

import { useDispatch, useSelector } from 'react-redux';

import { ChatSelectors, ChatActions } from '../redux';
import { MessageType } from '../types';

type Returned = {
  sendMessage: (message: string) => void;
  messages: MessageType[];
  hasNewMessage: boolean;
  onRead: () => void;
};

export const useTwilioChat = (chatId: string): Returned => {
  const dispatch = useDispatch();
  const twilioChats = useSelector(ChatSelectors.getTwilioChats);
  const userRole = useSelector(AuthSelectors.getUserRole);
  const channelName = chatId;

  const chat = useMemo(
    () => twilioChats[chatId] || { messages: [], hasNew: false },
    [twilioChats, chatId],
  );

  const { messages } = chat;

  useEffect(() => {
    dispatch(ChatActions.joinChannel(channelName));
  }, []);

  const sendMessage = (message: string) => {
    dispatch(
      ChatActions.sendMessage({
        channelName,
        message,
        senderRole: userRole,
      }),
    );
  };

  const onRead = () => {
    dispatch(
      ChatActions.setTwilioChat({
        chatId,
        chat: { ...chat, hasNew: false },
      }),
    );
  };

  return {
    sendMessage,
    messages,
    hasNewMessage: chat.hasNew,
    onRead,
  };
};
