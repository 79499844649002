import styled from 'styled-components';
import { ColumnContainer, RowContainer } from 'modules/ui';
import { device } from 'modules/utils';

export const Container = styled(RowContainer)`
  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;
export const ImageContainer = styled(ColumnContainer)`
  width: 40%;
  height: 100vh;
  background: ${(props) => props.theme.ga.darkBlue};
  justify-content: center;
  align-items: center;

  h4 {
    max-width: 324px;
    text-align: center;
    color: ${({ theme }) => theme.ga.white};
    font-weight: 700;
  }

  img {
    margin-bottom: 60px;
  }

  .mobile {
    display: none;
  }

  @media ${device.mobile} {
    .mobile {
      display: block;
    }
    .desktop-tablet {
      display: none;
    }
  }

  @media ${device.tablet} {
    height: 300px;
    width: 100%;
    margin: 0px;
  }

  @media ${device.mobile} {
    height: 32px;
    margin-top: 36px;
    background: ${(props) => props.theme.ga.white};
    img {
      margin: 0;
    }
    h4 {
      display: none;
    }
  }
`;
