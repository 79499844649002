import dateFormat from 'dateformat';
import { get, create, update, URL, remove } from 'modules/api';
import { Response } from 'modules/utils';
import {
  ProductType,
  EventType,
  EventParams,
  GalaxyEventModel,
  GalaxyProductModel,
  UserOrderInfo,
} from '../types';
import { getGalaxyEventFromEvent, getGalaxyProductFromProduct } from '../utils';

export const getProductsRequest = (): Promise<Response<ProductType[]>> => {
  return get(`${URL.GET_PRODUCTS}?skip=00&take=1000`);
};

export const getEventProductsRequest = async (): Promise<
  GalaxyProductModel[]
> => {
  return get(`${URL.GET_EVENT_PRODUCTS}?skip=0&take=2000`).then((res) => {
    const { result } = res as Response<ProductType[]>;
    return result.map(getGalaxyProductFromProduct);
  });
};

export const getEventsRequest = async (): Promise<GalaxyEventModel[]> => {
  const start = new Date();
  const end = new Date();
  start.setMonth(start.getMonth() - 1);
  end.setMonth(end.getMonth() + 1);

  return get(
    `${
      URL.GET_EVENTS
    }?skip=0&take=1000&StartTime=${start.toISOString()}&EndTime=${end.toISOString()}`,
  ).then((res) => {
    const { result } = res as Response<EventType[]>;
    return result.map(getGalaxyEventFromEvent);
  });
};

export const getEventsForDateRequest = async (
  date: Date,
): Promise<GalaxyEventModel[]> => {
  const start = new Date(date);
  const formattedStart = dateFormat(start, 'yyyy-mm-dd');
  return get(
    `${URL.GET_EVENTS}?skip=0&take=1000&StartTime=${formattedStart}`,
  ).then((res) => {
    const { result } = res as Response<EventType[]>;
    return result.map(getGalaxyEventFromEvent);
  });
};

export const getEventByIdRequest = async (
  id: number,
): Promise<GalaxyEventModel> => {
  return get(`${URL.GET_EVENT_BY_ID}?id=${id}`).then((res) => {
    const { result } = res as Response<EventType>;
    return getGalaxyEventFromEvent(result);
  });
};

export const createEventRequest = async (
  body: EventParams,
): Promise<GalaxyEventModel> => {
  return create(URL.CREATE_EVENT, body).then((res) => {
    const { result } = res as Response<EventType>;
    return getGalaxyEventFromEvent(result);
  });
};

export const updateEventRequest = async (
  body: EventParams,
): Promise<GalaxyEventModel> => {
  return update(URL.MODIFY_EVENT, body).then((res) => {
    const { result } = res as Response<EventType>;
    return getGalaxyEventFromEvent(result);
  });
};

export const getNextEventRequest = (
  startTime: string,
): Promise<Response<EventType>> =>
  get(`${URL.GET_NEXT_EVENT}?startTime=${startTime}`);

export const getUserOrderInfosRequest = (
  slotId: number,
): Promise<Response<UserOrderInfo[]>> =>
  get(`${URL.GET_USER_ORDER_INFOS}?BookingSlotId=${slotId}`);

export const deleteGalaxyEventRequest = (
  id: number,
): Promise<Response<unknown>> =>
  remove(`${URL.DELETE_GALAXY_EVENT}?BookingSlotId=${id}`);
