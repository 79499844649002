import { get, URL } from 'modules/api';
import { Response } from 'modules/utils';
import { DigitalAsset, AssetType } from '../types';

export const getDigitalAssetsApi = (
  userId: number,
  assetType: AssetType,
): Promise<Response<DigitalAsset[]>> => {
  return get(
    `${URL.GET_DIGITAL_ASSETS_FOR_USER}?userId=${userId}&assetType=${assetType}`,
  );
};
