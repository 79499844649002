import React from 'react';
import ScrollLock from 'react-scrolllock';
import { H2, Modal, TextRegular, Button } from 'modules/ui';
import { ModalContainer, ButtonContainer } from './styled';

export const DisconnectedFromSessionModal: React.FC = React.memo(() => {
  return (
    <>
      <Modal isOpen fullWidth={false} isDark height={675} width={500}>
        <ModalContainer>
          <H2>You've disconnected</H2>
          <TextRegular>
            It seems you've disconnected from the session. Please click the
            button below to reload the page and reconnect.
          </TextRegular>
          <ButtonContainer>
            <Button
              label="Reload"
              clickHandler={() => window.location.reload(false)}
            />
          </ButtonContainer>
        </ModalContainer>
      </Modal>
      <ScrollLock />
    </>
  );
});
