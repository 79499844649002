import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dictionary, RouteType } from 'modules/utils';
import { LogoV3, HamburgerMenu, CloseIcon, H3, Button } from 'modules/ui';
import { logOutUser } from 'modules/auth';
import { MobileHeader, MenuContainer, LogoutContainer } from './styled';
import { useMobileModal } from './useMobileModal';
import { ClientRoutes } from './consts';

type Props = {
  isDark?: boolean;
};

export const MobileNavMenu: React.FC<Props> = React.memo(() => {
  const [isOpen, openMenu, closeMenu] = useMobileModal(false);
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logOutUser());
  };

  return (
    <>
      <MobileHeader isOpen={isOpen}>
        <img src={LogoV3} alt={Dictionary.GALAXYCON} />
        <HamburgerMenu onClick={openMenu} className="hamburger" />
        <CloseIcon onClick={closeMenu} className="close" />
      </MobileHeader>
      <MenuContainer isOpen={isOpen}>
        {ClientRoutes.map((route: RouteType) => (
          <NavLink
            key={route.path}
            strict
            to={route.path}
            activeClassName="active"
            onClick={closeMenu}
          >
            <H3>{route.label}</H3>
          </NavLink>
        ))}
        <LogoutContainer>
          <Button
            label="Log out"
            clickHandler={logOutHandler}
            isMeeting
            isLink
          />
        </LogoutContainer>
      </MenuContainer>
    </>
  );
});
