import {
  ThunkAction as LibThunkAction,
  Action,
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';

import {
  persistStore,
  Persistor,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { combineReducers } from 'redux';

import * as Sentry from '@sentry/react';

import { adminReducer } from 'modules/admin';
import { eventsReducer } from 'modules/galaxy-events';
import { userReducer } from 'modules/auth';
import { commonMeetingReducer } from 'modules/common-meeting';
import { userSessionReducer } from 'modules/schedule';
import { productReducer } from 'modules/client';
import { queueReducer } from 'modules/queue';
import { chatReducer, createTwilioMiddleware } from 'modules/chat';
import { lobbyReducer } from 'modules/lobby';
import { fanVideosReducer } from 'modules/fan-videos';
import { accountReducer } from 'modules/account';
import { errorsReducer, globalErrorMiddleware } from 'modules/errors';
import { talentReducer } from 'modules/talent-meeting';

import { messageBrokerMiddleware } from 'modules/message-broker';

const rootReducer = combineReducers({
  account: accountReducer,
  admin: adminReducer,
  chat: chatReducer,
  errors: errorsReducer,
  events: eventsReducer,
  fanVideos: fanVideosReducer,
  lobby: lobbyReducer,
  meeting: commonMeetingReducer,
  products: productReducer,
  queue: queueReducer,
  talent: talentReducer,
  user: userReducer,
  userSessions: userSessionReducer, // Rename and fix this
});

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkAction<R> = LibThunkAction<
  Promise<R>,
  RootState,
  null,
  Action
>;

export default (): {
  persistor: Persistor;
  store: EnhancedStore<RootState>;
} => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      globalErrorMiddleware(),
      messageBrokerMiddleware(),
      createTwilioMiddleware(),
    ),
    enhancers: [sentryReduxEnhancer],
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
