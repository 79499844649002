import styled from 'styled-components';

import { TextRegular, H3 } from 'modules/ui/theme';
import { device } from 'modules/utils';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  margin-right: 220px;
  @media ${device.mobile} {
    width: 90vw;
    margin-right: 0px;
  }
  @media ${device.tablet} {
    margin-right: 0px;
  }
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.ga.gray};
`;

export const Subtitle = styled(TextRegular)`
  color: ${({ theme }) => theme.ga.gray};
  margin-bottom: 29px;
`;
