export enum URL {
  GET_MESSAGE_BROKER_CREDENTIALS = 'v2/general/get-variables',
}

export enum MessageBrokerActionType {
  CONNECT = 'MESSAGE_BROKER/CONNECT',
  DISCONNECT = 'MESSAGE_BROKER/DISCONNECT',
  RECEIVE_MESSAGE = 'MESSAGE_BROKER/RECEIVE_MESSAGE',
  GET_MESSAGE_BROKER_CREDENTIALS = 'MESSAGE_BROKER/GET_CREDENTIALS',
  SET_MESSAGE_RECEIVED_TIMESTAMP = ' SET_MESSAGE_RECEIVED_TIMESTAMP',
  SET_IS_CONNECTED_TO_MESSAGE_BROKER = ' SET_IS_CONNECTED_TO_MESSAGE_BROKER',
}
