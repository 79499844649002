import React, { useCallback } from 'react';
import {
  LocalVideo,
  useMeetingManager,
  RemoteVideo,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAutoStartVideo,
  CommonMeetingActions,
  CommonMeetingSelectors,
} from 'modules/common-meeting';
import { Chat, useMobileChat, ChatHelpers } from 'modules/chat';
import { ApproveIcon, Button, CloseIcon, H3 } from 'modules/ui';
import { AuthSelectors, Roles } from 'modules/auth';

import {
  useConnectToTechCheck,
  useTechCheckAudioAnnouncment,
} from '../../hooks';
import { TechCheckActions } from '../../redux';
import { TechCheckControls } from '../TechCheckControls';
import {
  MainContainer,
  Container,
  InnerContainer,
  ChatContainer,
  VideoContainer,
  ButtonsContainer,
  CircleButton,
  VideoPlaceholder,
} from './styled';

export const TechCheckMeeting: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const meetingManager = useMeetingManager();

  const { uid } = useParams<Record<string, string>>();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const user = useSelector(AuthSelectors.getUser);
  const { isChatOpen, onChatToggle } = useMobileChat();
  const adminBookedParticipantSlotId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const fanBookedParticipantSlotId = useSelector(
    CommonMeetingSelectors.getBpsIdForFanParticipantInTechCheckWithLoggedInAdmin,
  );
  const loggedInParticipantBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const fanBeingTechCheckedName = useSelector(
    CommonMeetingSelectors.getNameForFanParticipantInTechCheckWithLoggedInAdmin,
  );

  const isFan = user.role === Roles.FAN;

  const handleDisapprove = useCallback(async () => {
    await meetingManager.leave();

    if (uid && fanBookedParticipantSlotId) {
      dispatch(
        TechCheckActions.setTechCheckFailed({
          bookedParticipantSlotId: fanBookedParticipantSlotId,
          waitingRoomGuid: uid,
        }),
      );
    }
  }, [fanBookedParticipantSlotId, meetingManager, uid]);

  const handleCloseSession = useCallback(async () => {
    await meetingManager.leave();

    if (fanBookedParticipantSlotId && uid) {
      dispatch(
        CommonMeetingActions.sendToQueue({
          bookedParticipantSlotId: fanBookedParticipantSlotId,
          waitingRoomGuid: uid,
        }),
      );
    }
  }, [fanBookedParticipantSlotId, meetingManager, uid]);

  const handleApprove = useCallback(async () => {
    await meetingManager.leave();

    if (fanBookedParticipantSlotId && adminBookedParticipantSlotId && uid) {
      dispatch(
        TechCheckActions.approveFan({
          fanBookedParticipantSlotId,
          adminBookedParticipantSlotId,
          waitingRoomGuid: uid,
        }),
      );
    }
  }, [
    fanBookedParticipantSlotId,
    adminBookedParticipantSlotId,
    uid,
    meetingManager,
  ]);

  useAutoStartVideo();
  useConnectToTechCheck();
  useTechCheckAudioAnnouncment(isFan);

  const techCheckTitle = `Tech Check with ${
    isFan ? 'GalaxyCon Support' : fanBeingTechCheckedName
  }`;

  const bpsIdForFanParticipantInTechCheck = isFan
    ? loggedInParticipantBpsId
    : fanBookedParticipantSlotId;

  return (
    <>
      <MainContainer>
        <Container>
          <H3>{techCheckTitle}</H3>
          <InnerContainer>
            <VideoContainer>
              <VideoPlaceholder>
                {Object.keys(attendeeIdToTileId).map((attendeeUid) => (
                  <RemoteVideo
                    className="video-element"
                    key={attendeeUid}
                    tileId={attendeeIdToTileId[attendeeUid]}
                  />
                ))}
              </VideoPlaceholder>
              <VideoPlaceholder>
                <LocalVideo className="video-element" />
                <TechCheckControls onChatToggle={onChatToggle} />
              </VideoPlaceholder>

              {!isFan && (
                <ButtonsContainer className="btn-container">
                  <Button
                    isDark
                    label="Close Session"
                    clickHandler={handleCloseSession}
                  />
                  <CircleButton
                    className="disapprove"
                    onClick={handleDisapprove}
                  >
                    <CloseIcon />
                  </CircleButton>
                  <CircleButton className="approve" onClick={handleApprove}>
                    <ApproveIcon />
                  </CircleButton>
                </ButtonsContainer>
              )}
            </VideoContainer>
            <ChatContainer isFan={isFan} isChatOpen={isChatOpen}>
              <Chat
                id={ChatHelpers.getFanChatIdFromBpsId(
                  uid,
                  String(bpsIdForFanParticipantInTechCheck),
                )}
                isTechCheck
                isMobileOpened={isChatOpen}
                onChatToggle={onChatToggle}
              />
            </ChatContainer>
          </InnerContainer>
        </Container>
      </MainContainer>
    </>
  );
});
