import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAdminMembersRequest,
  getUserOrdersRequest,
  removeAdminMemberRequest,
  getIsOwnerRequest,
  inviteNewAdminRequest,
  resendAdminInviteRequest,
  updateUserRequest,
} from '../api';

export const getUserOrders = createAsyncThunk(
  'GET_USER_ORDERS',
  getUserOrdersRequest,
);

export const getAdminMembers = createAsyncThunk(
  'GET_ADMIN_MEMBERS',
  getAdminMembersRequest,
);

export const removeAdminMember = createAsyncThunk(
  'REMOVE_ADMIN_MEMBER',
  removeAdminMemberRequest,
);

export const inviteNewAdmin = createAsyncThunk(
  'INVITE_NEW_ADMIN',
  inviteNewAdminRequest,
);

export const resendAdminInvite = createAsyncThunk(
  'RESEND_ADMIN_INVITE',
  resendAdminInviteRequest,
);

export const getIsOwner = createAsyncThunk('GET_IS_OWNER', getIsOwnerRequest);

export const updateAdmin = createAsyncThunk(
  'UPDATE_ADMIN_USER',
  updateUserRequest,
);
