import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNextSessions,
  UserSessionSelectors,
  NextSessionComponent,
} from 'modules/schedule';
import { EmptySessions } from 'modules/ui';
import { NextSessionsContainer } from './styled';

export const NextSessionsPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNextSessions());
  }, [dispatch]);

  const nextSessions = useSelector(UserSessionSelectors.getNextSessions);

  return (
    <NextSessionsContainer>
      {nextSessions.length === 0 && <EmptySessions isSchedule />}
      {nextSessions.map((s) => {
        const formattedDate = new Date(s.startTime).toLocaleDateString(
          'en-En',
          {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          },
        );
        return (
          <NextSessionComponent
            key={s.bookedParticipantSlotId}
            title={s.title}
            date={formattedDate}
          />
        );
      })}
    </NextSessionsContainer>
  );
});
