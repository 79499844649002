import React, { useCallback, useState } from 'react';
import { TextRegular, Spinner } from 'modules/ui';
import { OrderItem } from '../../types';
import { resendVerificationEmailRequest } from '../../api';
import { Tr, Td, RowButton } from './styled';

export type Props = {
  order: OrderItem;
};

export const OrderRow: React.FC<Props> = React.memo(({ order, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const startTime = new Date(order.startTime);
  const endTime = new Date(order.endTime);

  const handleResendVerificationEmail = useCallback(async (email: string) => {
    try {
      setIsLoading(true);
      await resendVerificationEmailRequest(email);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Tr {...rest}>
      <Td width={200}>
        <TextRegular>{order.shopifyOrderEmail}</TextRegular>
      </Td>
      <Td width={100}>
        <TextRegular>{order.shopifyOrderId}</TextRegular>
      </Td>
      <Td width={250}>
        <TextRegular>{order.orderItemTitle}</TextRegular>
      </Td>
      <Td width={50}>
        <TextRegular>{order.orderItemQuantity}</TextRegular>
      </Td>
      <Td width={120}>
        <TextRegular>
          {startTime.toLocaleDateString()}
          &nbsp;
          {startTime.toLocaleTimeString()}
        </TextRegular>
        <br />
        <TextRegular>
          {endTime.toLocaleDateString()}
          &nbsp;
          {endTime.toLocaleTimeString()}
        </TextRegular>
      </Td>
      <Td width={50}>
        <TextRegular>{order.userVerified ? 'Yes' : 'No'}</TextRegular>
      </Td>
      <Td width={80}>
        {isLoading ? (
          <Spinner />
        ) : (
          <RowButton
            label="Resend"
            isDark
            disabled={order.userVerified}
            clickHandler={() =>
              handleResendVerificationEmail(order.shopifyOrderEmail)
            }
          />
        )}
      </Td>
    </Tr>
  );
});
