import { ColumnContainer, Label, RowContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled, { css } from 'styled-components/macro';

export const ChatHeader = styled(RowContainer)<{ show?: boolean }>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')}
  align-items: center;
  border-bottom: 1px solid ${({ theme }): string => theme.ga.blueTileLight};
  height: 41px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.ga.white};
    text-transform: none;
  }

  & > svg {
    margin-top: 8px;
  }

  & > svg path {
    fill: white;
  }
`;

export const SendButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Container = styled(ColumnContainer)<{
  isTechCheckView: boolean;
  isAdminView: boolean;
  isAdmin: boolean;
  isActive: boolean;
  isTalentView: boolean;
  isMobileOpened: boolean;
  isFan?: boolean;
  index: number;
  visible?: boolean;
}>`
  ${({ isFan }) => (isFan ? 'overflow: visible!important;' : '')}

  ${({ visible = true }) => (visible ? '' : 'display: none;')}

  @media ${device.tablet} {
    width: 100%;
    max-height: 267px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    bottom: -331px;
    margin: 0px;
    max-width: 300px;
    right: ${({ index }) => (index ? index * 280 : 10)}px;
    z-index: 9999;
    ${({ isMobileOpened }) =>
      isMobileOpened &&
      css`
        bottom: 0px;
      `}
  }

  @media ${device.laptop} {
    ${({ isMobileOpened }) =>
      isMobileOpened &&
      css`
        position: fixed;
      `}
  }

  ${({ isTechCheckView, isTalentView }) =>
    (isTechCheckView || isTalentView) &&
    css`
      width: 300px;
      height: 100%;
      position: relative;
      background: #1c242f;
      color: white;
      overflow: hidden;
      border-radius: 8px;
    `}

  ${({ isAdminView, isActive, index, isFan }) =>
    isAdminView &&
    css`
      width: 260px;
      height: 372px;
      overflow-y: auto;
      background-color: #1c242f;
      border-radius: 8px 8px 0px 0px;
      z-index: 999;
      position: absolute;
      bottom: ${isFan ? '-372px' : '-331px'};
      right: ${index ? index * 276 : 0}px;

      ${isActive &&
      css`
        bottom: 0px;
      `}

      ${ChatHeader} {
        cursor: pointer;
      }
    `}
`;

export const TextInput = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: white;
  caret-color: ${({ theme }) => theme.ga.magenta};
  padding-left: 14px;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ChatBody = styled(ColumnContainer)`
  height: 100%;
  overflow-y: auto;
`;

export const ChatInput = styled(RowContainer)`
  border-top: 1px solid ${({ theme }): string => theme.ga.blueTileLight};
  height: 40px;

  input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    color: white;
    caret-color: ${({ theme }) => theme.ga.magenta};
    padding-left: 14px;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Message = styled(RowContainer)`
  width: 179px;
  padding: 8px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.ga.white};
`;

export const MessageContainer = styled(ColumnContainer)<{ isRight: boolean }>`
  width: 100%;
  height: auto;
  padding: 10px 5px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;

  ${Message} {
    background: #030c18;
  }

  ${({ isRight }) =>
    isRight &&
    css`
      display: flex;
      align-items: flex-end;
      ${Message} {
        background: #2c3849; //TODO: use theme
      }
    `}
`;

export const ChatName = styled(Label)<{ isActive: boolean }>`
  ${({ isActive, theme }) =>
    isActive ? `color: ${theme.ga.pink}!important;` : ''}
`;

export const FanChatWrap = styled.div`
  @media ${device.laptop} {
    position: fixed;
    bottom: 0px;
    right: 20px;
  }
`;

export const ChatIconWrap = styled.div`
  margin-left: auto;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-top: -58px;
  background-color: ${({ theme }) => theme.ga.magenta};
  cursor: pointer;

  svg {
    margin-top: 6px;
    margin-left: 8px;
  }
`;

export const ChatTooltip = styled.div`
  display: none;
  margin-top: -50px;
  background-color: ${({ theme }) => theme.ga.magenta};
  cursor: pointer;
  color: ${({ theme }) => theme.ga.white};
  font-weight: 600;
  position: absolute;
  right: 50px;
  top: -16px;
  padding: 8px 16px;
  border-radius: 6px;
  width: 192px;

  ${FanChatWrap}:hover & {
    display: block;
  }
`;
