import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CommonMeetingActions, CommonMeetingSelectors } from '../redux';

const HEARTBEAT_PERIOD_MILLISECONDS = 60000;

export const useHeartbeat = (): void => {
  const dispatch = useDispatch();
  const bookedParticipantSlotId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const waitingRoomGuid = useSelector(
    CommonMeetingSelectors.waitingRoomIdSelector,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (bookedParticipantSlotId && waitingRoomGuid) {
        dispatch(
          CommonMeetingActions.setOffParticipantHeartbeat({
            waitingRoomGuid,
            bookedParticipantSlotId,
          }),
        );
      } else {
        clearInterval(interval);
      }
    }, HEARTBEAT_PERIOD_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [bookedParticipantSlotId, waitingRoomGuid]);
};
