import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main/redux';

// TODO: Rename this module
export const productsSelector = (state: RootState): RootState['products'] =>
  state.products;

export const getProducts = createSelector(
  [productsSelector],
  (products) => products.productList,
);

export const getUpcomingEvents = createSelector(
  [productsSelector],
  (products) => products.upcomingEvents,
);

export const getQuestionnaireAnswers = createSelector(
  productsSelector,
  (products) => products.questionnaireAnswers,
);
