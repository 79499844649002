import React from 'react';
import { TextItalic } from 'modules/ui';
import { FilmRoll } from '../../assets';
import { Container, LeftContainer, RightContainer } from './styled';

export const PostSessionInfoCard: React.FC = () => (
  <Container>
    <LeftContainer>
      <img src={FilmRoll} alt="film-roll" />
    </LeftContainer>
    <RightContainer>
      <TextItalic>
        A video recording of your session will be available in the next 60
        minutes and stay in your account for 10 days.
      </TextItalic>
    </RightContainer>
  </Container>
);
