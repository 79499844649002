import { device } from 'modules/utils';
import styled from 'styled-components';
import { RowContainer } from 'modules/ui';
import { Countdown } from 'modules/common-meeting';

export const IconBtn = styled.button`
  padding: 10px;
  margin-right: 0px;
  color: ${(props) => props.theme.ga.green};
  background: ${({ theme }) => theme.ga.semiDarkGray};
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
`;

export const Controls = styled(RowContainer)<{
  isPreview?: boolean;
  isFan?: boolean;
}>`
  height: 60px;
  width: 382px;
  position: fixed;
  left: calc(50% - 192px);
  bottom: 16px;
  z-index: 999;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 auto;
    cursor: pointer;
  }

  ${Countdown} {
    margin-right: auto;
  }

  ${IconBtn} {
    background: transparent;
  }

  .settings-icon {
    margin-left: auto;
    margin-right: 20px;
  }

  @media ${device.laptop} {
    .take-photo {
      display: none;
    }
  }

  @media ${device.tablet} {
    height: 60px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 100px;
    padding: 8px;
    z-index: 9999;
    position: fixed;
    bottom: 16px;
    margin: 0px 4px;
    left: 0;

    .take-photo {
      display: none;
    }

    ${IconBtn} {
      background: transparent;
    }
  }
`;
