import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main';
import { AuthSelectors } from 'modules/auth';
import { isAdminRole, isFanRole } from 'modules/account';
import sortby from 'lodash.sortby';
import { CommonMeetingState } from './reducer';

import { ParticipantState, UserType, WaitingRoomState } from '../consts';
import { Fan, Participant } from '../types';

export const meetingSelector = (state: RootState): RootState['meeting'] =>
  state.meeting;

export const timerSelector = (
  state: RootState,
): RootState['meeting']['timer']['duration'] => state.meeting.timer.duration;

export const addonTime = (
  state: RootState,
): RootState['meeting']['timer']['addons'] => state.meeting.timer.addons;

export const waitingRoomModelSelector = (
  state: RootState,
): RootState['meeting']['waitingRoom'] => state.meeting.waitingRoom;

export const waitingRoomTechCheckListSelector = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) => waitingRoomModel.TechCheckList,
);

export const waitingRoomIdSelector = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) => waitingRoomModel.Id,
);

export const waitingRoomStateSelector = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) => waitingRoomModel.State,
);

export const isWaitingRoomReadySelector = createSelector(
  waitingRoomStateSelector,
  (waitingRoomStateVal) => waitingRoomStateVal === WaitingRoomState.READY,
);

export const getMainMeetingInfo = createSelector(
  [meetingSelector],
  (meeting) => meeting.mainMeetingInfo,
);

export const getReadinessCheeckMeetingInfo = createSelector(
  [meetingSelector],
  (meeting) => meeting.readinessCheckMeetingInfo,
);

export const getTechCheckMeetingInfo = createSelector(
  [meetingSelector],
  (meeting) => meeting.techCheckMeetingInfo,
);

export const getTechCheckChimeMeetingInfo = createSelector(
  [getTechCheckMeetingInfo, AuthSelectors.getExternalUserId],
  ({ rawChimeMeeting, chimeAttendeeId, chimeJoinToken }, externalUserId) => {
    if (
      !rawChimeMeeting ||
      !chimeAttendeeId ||
      !chimeJoinToken ||
      !externalUserId
    ) {
      return null;
    }

    return {
      meetingInfo: rawChimeMeeting,
      attendeeInfo: {
        ExternalUserId: externalUserId,
        AttendeeId: chimeAttendeeId,
        JoinToken: chimeJoinToken,
      },
    };
  },
);

export const getMainMeetingChimeMeetingInfo = createSelector(
  [getMainMeetingInfo, AuthSelectors.getExternalUserId],
  ({ rawChimeMeeting, chimeAttendeeId, chimeJoinToken }, externalUserId) => {
    if (
      !rawChimeMeeting ||
      !chimeAttendeeId ||
      !chimeJoinToken ||
      !externalUserId
    ) {
      return null;
    }

    return {
      meetingInfo: rawChimeMeeting,
      attendeeInfo: {
        ExternalUserId: externalUserId,
        AttendeeId: chimeAttendeeId,
        JoinToken: chimeJoinToken,
      },
    };
  },
);

export const getReadinessCheckMeetingInfo = createSelector(
  [getReadinessCheeckMeetingInfo, AuthSelectors.getExternalUserId],
  ({ rawChimeMeeting, chimeAttendeeId, chimeJoinToken }, externalUserId) => {
    if (
      !rawChimeMeeting ||
      !chimeAttendeeId ||
      !chimeJoinToken ||
      !externalUserId
    ) {
      return null;
    }

    return {
      meetingInfo: rawChimeMeeting,
      attendeeInfo: {
        ExternalUserId: externalUserId,
        AttendeeId: chimeAttendeeId,
        JoinToken: chimeJoinToken,
      },
    };
  },
);

export const getInitialTime = createSelector(
  [timerSelector],
  (duration) => duration,
);

export const getAddedTime = createSelector([timerSelector], (addons) => addons);

export const getTimer = createSelector(
  [meetingSelector],
  (meeting) => meeting.timer,
);

export const getCurrentAttendee = createSelector(
  [meetingSelector],
  (meeting) => meeting.currentAttendee,
);

export const getRoomParticipants = createSelector(
  [meetingSelector],
  (meeting) => meeting.participants,
);

export const getShopifyRedirectUrl = createSelector(
  [meetingSelector],
  (meeting) => meeting.shopifyUrl,
);

export const getQuestionsForFan = (id: number) => (
  state: RootState,
): RootState['meeting']['questionnaire'][''] => state.meeting.questionnaire[id];

const rootState = (state: RootState): CommonMeetingState => state.meeting;

export const waitingRoomState = (state: RootState): WaitingRoomState | null =>
  rootState(state).waitingRoomState;

export const shouldPrepareWaitingRoom = (state: RootState): boolean => {
  const waitingRoomStateVal = waitingRoomState(state);
  const userRole = AuthSelectors.getUserRole(state);

  return (
    isAdminRole(userRole) && waitingRoomStateVal === WaitingRoomState.CREATED
  );
};

export const getLoggedInParticipantBpsId = createSelector(
  rootState,
  (liveSession) => liveSession.bookedParticipantSlotId,
);

export const getLoggedInParticipant = createSelector(
  [waitingRoomModelSelector, getLoggedInParticipantBpsId],
  (waitingRoomModel, loggedInParticipantBpsId): Participant | undefined => {
    return waitingRoomModel.Participants?.find(
      (participant) => participant.BpsId === loggedInParticipantBpsId,
    );
  },
);

export const getLoggedInParticipantName = createSelector(
  getLoggedInParticipant,
  (loggedInParticipant) => loggedInParticipant?.Name,
);

export const getParticipantByBpsId = (bpsId: number) => (
  state: RootState,
): Participant | undefined => {
  const waitingRoomModel = waitingRoomModelSelector(state);

  return waitingRoomModel.Participants?.find(
    (participant) => participant.BpsId === bpsId,
  );
};

export const getLoggedInParticipantState = createSelector(
  getLoggedInParticipant,
  (loggedInParticipant) => loggedInParticipant?.State,
);

export const getLoggedInFanPosition = createSelector(
  getLoggedInParticipant,
  (loggedInParticipant) => loggedInParticipant?.Position,
);

export const isLoggedInFanInQueue = createSelector(
  getLoggedInParticipantState,
  (loggedInFanState) => loggedInFanState === ParticipantState.IN_A_QUEUE,
);

export const getTalentParticipant = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) =>
    waitingRoomModel.Participants.find(
      (participant) => participant.Type === UserType.TALENT,
    ),
);

export const isMainCallPausedSelector = createSelector(
  getTalentParticipant,
  (talentParticipant) =>
    talentParticipant?.State === ParticipantState.CALL_PAUSED,
);

export const talentJoinedWaitingRoomSelector = createSelector(
  getTalentParticipant,
  (talentParticipant) =>
    talentParticipant?.State === ParticipantState.IN_A_CALL ||
    talentParticipant?.State === ParticipantState.CALL_PAUSED,
);

export const getTalentBpsId = createSelector(
  getTalentParticipant,
  (talentParticipant) => talentParticipant?.BpsId,
);

export const getBpsIdForFanParticipantInTechCheckWithLoggedInAdmin = createSelector(
  [waitingRoomTechCheckListSelector, getLoggedInParticipantBpsId],
  (techCheckList, loggedInAdminBpsId) =>
    techCheckList.find(
      (techCheckPair) => techCheckPair.AdminBpsId === loggedInAdminBpsId,
    )?.FanBpsId,
);

export const isTalentOnBreak = createSelector(
  waitingRoomState,
  (waitingRoomStateVal) =>
    waitingRoomStateVal === WaitingRoomState.TALENT_BREAK,
);

export const talentName = createSelector(
  waitingRoomModelSelector,
  (waitingRoomStateVal) => waitingRoomStateVal.TalentInfo.Name,
);

export const waitingRoomFansSelector = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) => {
    const waitingRoomFans = waitingRoomModel.Participants.filter(
      (participant) =>
        participant.Type === UserType.FAN &&
        participant.State !== ParticipantState.FINISHED,
    );

    return sortby(
      waitingRoomFans,
      (participant) => participant.Position,
    ) as Fan[];
  },
);

export const isAnyWaitingRoomParticipantPaused = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) =>
    waitingRoomModel.Participants.some(
      (participant) => participant.State === ParticipantState.CALL_PAUSED,
    ),
);

export const waitingRoomFansCountSelector = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) => waitingRoomFans.length,
);

// The agreed upon convention is that only a single admin can be performing Tech Check at a time
export const getFanParticipantInTechCheck = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fan) => fan.State === ParticipantState.IN_A_TECH_CHECK,
    ),
);

export const getFanParticipantInTechCheckForLoggedInAdmin = createSelector(
  [
    getBpsIdForFanParticipantInTechCheckWithLoggedInAdmin,
    waitingRoomFansSelector,
  ],
  (fanBpsId, waitingRoomFanParticipants) =>
    waitingRoomFanParticipants.find(
      (fanParticipant) => fanParticipant.BpsId === fanBpsId,
    ),
);

export const getFanParticipantInAnnouncement = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fanParticipant) =>
        fanParticipant.State === ParticipantState.IN_ANNOUNCEMENT,
    ),
);

export const getNameForFanParticipantInAnnouncement = createSelector(
  getFanParticipantInAnnouncement,
  (fanParticipant) => fanParticipant?.Name,
);

export const getBpsIdForFanParticipantInAnnouncement = createSelector(
  getFanParticipantInAnnouncement,
  (fanParticipant) => fanParticipant?.BpsId,
);

export const getNameForFanParticipantInTechCheckWithLoggedInAdmin = createSelector(
  getFanParticipantInTechCheckForLoggedInAdmin,
  (fanParticipant) => fanParticipant?.Name,
);

export const getFanParticipantNextForMainCall = createSelector(
  waitingRoomFansSelector,
  (sortedWaitingRoomFans) =>
    sortedWaitingRoomFans.find(
      (fanParticipant) =>
        fanParticipant.State === ParticipantState.READY_TO_JOIN,
    ),
);

export const getBpsIdForFanParticipantNextForMainCall = createSelector(
  getFanParticipantNextForMainCall,
  (fanParticipant) => fanParticipant?.BpsId,
);

export const getFanParticipantInLiveCall = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fanParticipant) => fanParticipant.State === ParticipantState.IN_A_CALL,
    ),
);

export const getFanParticipantInPhotoTimer = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fanParticipant) =>
        fanParticipant.State === ParticipantState.IN_PHOTO_TIMER,
    ),
);

export const getFanParticipantBeingScreenshot = createSelector(
  [getFanParticipantInLiveCall, getFanParticipantInPhotoTimer],
  (fanParticipantInLiveCall, fanParticipantInPhotoTimer) =>
    fanParticipantInLiveCall || fanParticipantInPhotoTimer,
);

export const isAnyFanParticipantInLiveCall = createSelector(
  getFanParticipantInLiveCall,
  (fanParticipant) => !!fanParticipant,
);

export const getFanParticipantInCallPaused = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fanParticipant) => fanParticipant.State === ParticipantState.CALL_PAUSED,
    ),
);

export const isAnyFanParticipantInCallPaused = createSelector(
  getFanParticipantInCallPaused,
  (fanParticipant) => !!fanParticipant,
);

export const getFanParticipantInLiveCallOrCallPaused = createSelector(
  waitingRoomFansSelector,
  (waitingRoomFans) =>
    waitingRoomFans.find(
      (fanParticipant) =>
        fanParticipant.State === ParticipantState.IN_A_CALL ||
        fanParticipant.State === ParticipantState.CALL_PAUSED,
    ),
);

export const isAnyFanParticipantInLiveCallOrInCallPaused = createSelector(
  getFanParticipantInLiveCallOrCallPaused,
  (waitingRoomFan) => !!waitingRoomFan,
);

export const getBpsIdForFanParticipantInLiveCallOrCallPaused = createSelector(
  getFanParticipantInLiveCallOrCallPaused,
  (fanParticipant) => fanParticipant?.BpsId,
);

export const getBpsIdForFanParticipantInLiveCall = createSelector(
  getFanParticipantInLiveCall,
  (fanParticipant) => fanParticipant?.BpsId,
);

export const isSendNextFanToCallWithTalentEnabled = createSelector(
  [
    getFanParticipantInLiveCall,
    getFanParticipantNextForMainCall,
    isTalentOnBreak,
    talentJoinedWaitingRoomSelector,
    isMainCallPausedSelector,
  ],
  (
    fanParticipantInLiveCall,
    fanParticipantNextForMainCall,
    isTalentCurrentlyOnBreak,
    talentJoinedWaitingRoom,
    isMainCallPaused,
  ) => {
    return (
      !fanParticipantInLiveCall &&
      !!fanParticipantNextForMainCall &&
      !isTalentCurrentlyOnBreak &&
      talentJoinedWaitingRoom &&
      !isMainCallPaused
    );
  },
);

export const shouldUnsubscribeFromMessageBroker = (
  state: RootState,
): boolean => {
  const userRole = AuthSelectors.getUserRole(state);
  const isWaitingRoomFinished =
    waitingRoomState(state) === WaitingRoomState.FINISHED;
  const loggedInParticipant = getLoggedInParticipant(state);

  if (isWaitingRoomFinished) {
    return true;
  }

  if (isFanRole(userRole)) {
    return loggedInParticipant?.State === ParticipantState.FINISHED;
  }

  return false;
};

export const getVideoCall = createSelector(
  waitingRoomModelSelector,
  (waitingRoom) => waitingRoom.TalentVideoCall,
);

export const getPhotoSecondsLeft = createSelector(
  getVideoCall,
  (videoCall) => videoCall?.PhotoSecondsLeft,
);

export const getAnnouncementSecondsLeft = createSelector(
  getVideoCall,
  (videoCall) => videoCall?.AnnouncementSecondsLeft,
);

export const getSessionSecondsLeft = createSelector(
  [
    getVideoCall,
    isAnyFanParticipantInLiveCall,
    isAnyFanParticipantInCallPaused,
  ],
  (
    videoCall,
    isAnyParticipantInLiveCallVal,
    isAnyFanParticipantInCallPausedVal,
  ) => {
    if (
      videoCall.AnnouncementSecondsLeft > 0 ||
      !(isAnyParticipantInLiveCallVal || isAnyFanParticipantInCallPausedVal)
    ) {
      return 0;
    }

    return videoCall.SessionSecondsLeft;
  },
);

export const isTakingPhotoEnabled = createSelector(
  [
    isAnyFanParticipantInLiveCallOrInCallPaused,
    isMainCallPausedSelector,
    getSessionSecondsLeft,
  ],
  (
    isAnyFanParticipantInLiveCallOrInCallPausedVal,
    isMainCallPausedVal,
    secondsLeft,
  ) =>
    isAnyFanParticipantInLiveCallOrInCallPausedVal &&
    !isMainCallPausedVal &&
    secondsLeft > 5,
);

export const getTotalSessionSeconds = createSelector(
  getVideoCall,
  (videoCall) => videoCall.TotalSessionSeconds,
);

export const waitingRoomNameSelector = createSelector(
  waitingRoomModelSelector,
  (waitingRoomModel) => waitingRoomModel.WaitingRoomName,
);

export const isEnterWaitingRoomUnauthorized = createSelector(
  meetingSelector,
  (meeting) => meeting.isEnterWaitingRoomUnauthorized,
);

export const isCallWithTalentInRedZone = createSelector(
  meetingSelector,
  (meeting) => meeting.isCallWithTalentInRedZone,
);

export const permissionsStatus = createSelector(
  meetingSelector,
  (meeting) => meeting.permissionsStatus,
);

export const shouldHidePermissionsModal = createSelector(
  meetingSelector,
  (meeting) => meeting.shouldHidePermissionsModal,
);

export const isPreferDesktopUsageBannerHidden = createSelector(
  meetingSelector,
  (meeting) => meeting.isPreferDesktopUsageBannerHidden,
);

export const lastMessageReceivedTimestampSelector = createSelector(
  meetingSelector,
  (meeting) => meeting.lastMQMessageReceivedTimestamp,
);

export const isDisconnectedSelector = createSelector(
  meetingSelector,
  (meeting) => meeting.isDisconnected,
);

export const isConnectedToMessageBrokerSelector = createSelector(
  meetingSelector,
  (meeting) => meeting.isConnectedToMessageBroker,
);

export const shouldTakeScreenshot = createSelector(
  [getLoggedInParticipant, getPhotoSecondsLeft],
  (loggedInParticipant, photoSecondsLeft) =>
    loggedInParticipant?.State === ParticipantState.IN_PHOTO_TIMER &&
    photoSecondsLeft === 0,
);
