import { ColumnContainer, RowContainer } from 'modules/ui';
import styled from 'styled-components';

export const PopupContainer = styled(RowContainer)`
  width: 607px;
  height: 146px;
  position: fixed;
  z-index: 999;
  bottom: 16px;
  right: 16px;
  box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
`;
export const VideoContainer = styled.div`
  width: 215px;
  height: 133px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
`;
export const InfoContainer = styled(ColumnContainer)``;
