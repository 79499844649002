import React from 'react';
import { Logo, LogoV3 } from 'modules/ui';
import { Dictionary } from 'modules/utils';
import { Container, HeaderContainer } from './styled';

type Props = {
  isDark?: boolean;
  isSecondary?: boolean;
  isTertiary?: boolean;
};

export const Header: React.FC<Props> = React.memo(
  ({ children, isSecondary, isDark, isTertiary }) => {
    return (
      <HeaderContainer
        isDark={!!isDark}
        isSecondary={!!isSecondary}
        isTertiary={!!isTertiary}
      >
        {!isDark && <img src={Logo} alt={Dictionary.GALAXYCON} />}
        {!!isDark && <img src={LogoV3} alt={Dictionary.GALAXYCON} />}
        <Container>{children}</Container>
      </HeaderContainer>
    );
  },
);
