import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { GetFanQuestionnaireAnswersResponseData } from '../types';

import * as TalentActions from './actions';

export const name = createReducer('', (builder) => {
  builder.addCase(
    TalentActions.setTalentName,
    (_state, action) => action.payload,
  );
});

export const questionnaireAnswers = createReducer(
  {} as GetFanQuestionnaireAnswersResponseData,
  (builder) => {
    builder.addCase(
      TalentActions.getFanQuestionnaireAnswers.fulfilled,
      (draft, action) => action.payload.result,
    );
  },
);

export const reducer = combineReducers({
  name,
  questionnaireAnswers,
});

const persistConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'talent',
  storage,
};

export const talentReducer = persistReducer(persistConfig, reducer);
