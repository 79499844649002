import { ColumnContainer } from 'modules/ui';
import { device } from 'modules/utils';
import styled from 'styled-components';

export const Container = styled(ColumnContainer)`
  max-width: 200px;
  align-self: flex-end;
  justify-content: space-around;
  margin: 16px;
  position: absolute;
  bottom: 120px;
  z-index: 999;

  button {
    margin: 4px 0px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;
