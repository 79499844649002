import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPastSessions } from 'modules/schedule/redux';
import { UserSessionSelectors, PastSessionComponent } from 'modules/schedule';
import { EmptySessions } from 'modules/ui';
import { PastSessionsContainer } from './styled';

export const PastSessionsPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPastSessions());
  }, [dispatch]);

  const pastSessions = useSelector(UserSessionSelectors.getPastSessions);

  return (
    <PastSessionsContainer>
      {pastSessions.length === 0 && <EmptySessions isPastSessions />}
      {pastSessions.map((s) => {
        const formattedDate = new Date(s.startTime).toLocaleDateString(
          'en-En',
          {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          },
        );
        return <PastSessionComponent title={s.title} date={formattedDate} />;
      })}
    </PastSessionsContainer>
  );
});
