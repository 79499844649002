import styled from 'styled-components';
import { Colors } from 'modules/ui';

export const SessionTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 21px;
  a {
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;
    margin-right: 48px;

    &.active {
      color: ${(props) => props.theme.ga.magenta};
      border-bottom: 2px solid ${(props) => props.theme.ga.magenta};
    }
  }
`;

export const TabText = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: ${Colors.DarkBlue};
`;
