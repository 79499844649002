import styled from 'styled-components';

import { device } from 'modules/utils';

export const Container = styled.div<{ applyRedBorder?: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  border: ${({ applyRedBorder }) =>
    applyRedBorder ? 'solid red 5px' : 'unset'};
  box-sizing: ${({ applyRedBorder }) =>
    applyRedBorder ? 'border-box' : 'content-box'};

  & :first-child {
    & :last-child {
      background: unset;
      header {
        display: none;
      }
      video {
        margin-bottom: 76px;
      }
    }
  }
`;
export const InfoCardContainer = styled.div`
  position: absolute;
  left: 32px;
  max-width: 420px;
  top: 32px;
  z-index: 3;
  @media ${device.mobile}, ${device.tablet} {
    position: absolute;
    max-width: 337px;
    bottom: 120px;
    left: 18px;
    top: unset;
  }
`;
