import { RouteType } from 'modules/utils';

export const SettingsMenuRoutes: RouteType[] = [
  {
    label: 'Account Settings',
    path: '/admin/account/details',
  },
];

export const AdminRoutes: RouteType[] = [
  {
    label: 'Home',
    path: '/admin/home/sessions',
  },
  {
    label: 'Schedule',
    path: '/admin/schedule',
  },
];

export const AdminAccountRoutes: RouteType[] = [
  {
    label: 'Account Details',
    path: '/admin/account/details',
  },
  {
    label: 'Change Password',
    path: '/admin/account/change-password',
  },
  {
    label: 'Members',
    path: '/admin/account/members',
  },
];
