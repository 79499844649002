import React from 'react';
import { H1, H3 } from 'modules/ui';
import { CurrentEvent, OrderEvent } from 'modules/client';
import { HeroContainer, CurrentEventsContainer } from './styled';

type CurrentEventsProps = {
  events: OrderEvent[];
};

export const CurrentEventsHero: React.FC<CurrentEventsProps> = React.memo(
  ({ events }) => {
    return (
      <HeroContainer>
        <H1>Your Events</H1>
        <H3>Your Events</H3>
        <CurrentEventsContainer>
          {events.map((event) => (
            <CurrentEvent
              key={event.bookedParticipantSlotId}
              orderEvent={event}
            />
          ))}
        </CurrentEventsContainer>
      </HeroContainer>
    );
  },
);
