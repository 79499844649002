import { createAsyncThunk } from '@reduxjs/toolkit';
import { TechCheckActionType } from '../consts';
import {
  sendFanToTechCheckRequest,
  approveFanRequest,
  setTechCheckFailedRequest,
} from '../api';

export const sendFanToTechCheck = createAsyncThunk(
  TechCheckActionType.SEND_FAN_TO_TECH_CHECK,
  sendFanToTechCheckRequest,
);

export const approveFan = createAsyncThunk('APPROVE_FAN', approveFanRequest);

export const setTechCheckFailed = createAsyncThunk(
  'SET_TECH_CHECK_FAILED',
  setTechCheckFailedRequest,
);
