/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { ProductType } from 'modules/account';
import {
  ColumnContainer,
  DropdownClose,
  DropdownOpen,
  Placeholder,
  TextBold,
  TextRegular,
} from 'modules/ui';
import {
  UserOrderContainer,
  TopContainer,
  MidContainer,
  BottomContainer,
  ViewMoreToggle,
} from './styled';

type UserOrderProps = {
  purchaseDate: string;
  totalPrice: number;
  id: number;
  products: ProductType[];
};

export const UserOrder: React.FC<UserOrderProps> = React.memo(
  ({ purchaseDate, totalPrice, id, products }) => {
    const [isOpen, toggleIsOpen] = useState(false);
    const numItems = products.length;
    const date = new Date(purchaseDate).toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return (
      <UserOrderContainer>
        <TopContainer>
          <TextBold className="items">{numItems} Items</TextBold>
          <TextRegular>
            <TextBold>Order Number: </TextBold>
            {id}
          </TextRegular>
          <TextRegular>
            <TextBold>Purchase Date: </TextBold>
            {date}
          </TextRegular>
        </TopContainer>
        {products.map((p, index) =>
          isOpen ? (
            <MidContainer key={p.productTitle}>
              <img src={Placeholder} alt="placeholder" />
              <ColumnContainer className="title">
                <TextRegular>
                  {p.productTitle} {p.variantTitle}, Quantity: {p.quantity}
                </TextRegular>
                <TextRegular className="price">${p.price}</TextRegular>
              </ColumnContainer>
            </MidContainer>
          ) : (
            index === 0 && (
              <MidContainer key={p.productTitle}>
                <img src={Placeholder} alt="placeholder" />
                <ColumnContainer className="title">
                  <TextRegular>
                    {p.productTitle} {p.variantTitle}, Quantity: {p.quantity}
                  </TextRegular>
                  <TextRegular className="price">${p.price}</TextRegular>
                </ColumnContainer>
              </MidContainer>
            )
          ),
        )}
        <BottomContainer>
          <span>
            <TextBold>TOTAL: </TextBold>
            <TextRegular className="price">${totalPrice}</TextRegular>
          </span>
          <ViewMoreToggle onClick={() => toggleIsOpen(!isOpen)}>
            <TextBold className="view-more">
              View {isOpen ? 'less' : 'more'}
            </TextBold>
            {isOpen ? (
              <DropdownOpen className="icon" />
            ) : (
              <DropdownClose className="icon" />
            )}
          </ViewMoreToggle>
        </BottomContainer>
      </UserOrderContainer>
    );
  },
);
