import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from 'paths';
import { Button, H3, H4, Spinner } from 'modules/ui';
import { NewPasswordForm, AuthLayout } from '../../components';
import { resetPassword } from '../../redux';
import { NewPasswordData } from '../../types';
import { FormContainer, LoginRedirectContainer, Header } from '../styled';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPassword: React.FC = React.memo(() => {
  const query = useQuery();
  const token = query.get('token') || '';
  const email = query.get('email') || '';
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const onSubmit = useCallback(
    async (data: NewPasswordData) => {
      setLoading(true);
      const { newPassword } = data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await dispatch(
        resetPassword({ token, email, newPassword }),
      );
      if (response.success) {
        setResetSuccess(true);
      } else {
        setResetSuccess(false);
      }
      setLoading(false);
    },
    [dispatch],
  );

  const redirectToLogin = () => {
    history.push(Paths.UserLogin);
  };

  return (
    <AuthLayout>
      {resetSuccess && (
        <LoginRedirectContainer>
          <H3>You’ve successfully changed your password!</H3>
          <H4>You can now sign in with your updated password and email.</H4>
          <Button label="Log in" clickHandler={redirectToLogin} />
        </LoginRedirectContainer>
      )}
      {!resetSuccess && (
        <FormContainer>
          <Header>
            <H3>Create new password</H3>
          </Header>
          <NewPasswordForm onSubmit={onSubmit} email={email} />
          {loading && <Spinner />}
        </FormContainer>
      )}
    </AuthLayout>
  );
});
