/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Paths } from 'paths';
import { BtnText, H3, TextBold, TextRegular } from 'modules/ui';
import { LoginRedirectContainer } from './styled';
import { CheckMailLoginRedirect } from '../styled';
import { AuthLayout } from '../../components';

export const CheckMail: React.FC = React.memo(() => {
  const location = useLocation();
  // TODO: might need proper error handler.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { email }: any = location.state || 'no email found';

  return (
    <AuthLayout>
      <LoginRedirectContainer>
        <H3>Check your mail</H3>
        <TextRegular>A reset link had been successfully sent to</TextRegular>
        <TextBold> {email}. </TextBold>
        <TextRegular>
          Check your email to activate your new password.
        </TextRegular>
        <CheckMailLoginRedirect>
          <Link to={Paths.UserLogin}>
            <BtnText>Log in</BtnText>
          </Link>
        </CheckMailLoginRedirect>
      </LoginRedirectContainer>
    </AuthLayout>
  );
});
