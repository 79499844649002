import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CommonMeetingSelectors,
  ParticipantState,
  PreferDesktopUsageBanner,
  useIsDisconnected,
} from 'modules/common-meeting';
import { useTwilioConnection } from 'modules/chat';

import { WaitingRoom, CallAnnouncement } from 'modules/lobby';
import { ProductSelectors } from 'modules/client';
import { TechCheckMeeting } from 'modules/tech-check';
import { FanMeetingActions } from '../../redux';

import { InACallView } from '../InACallView';
import { PostMeeting } from '../PostMeeting';

export const FanMeeting: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  const loggedInFanBpsId = useSelector(
    CommonMeetingSelectors.getLoggedInParticipantBpsId,
  );
  const fanParticipant = useSelector(
    CommonMeetingSelectors.getLoggedInParticipant,
  );
  const questionnaireAnswers = useSelector(
    ProductSelectors.getQuestionnaireAnswers,
  );

  useIsDisconnected();
  useTwilioConnection();

  useEffect(() => {
    if (loggedInFanBpsId) {
      dispatch(
        FanMeetingActions.insertQuestionnaire({
          BpsId: loggedInFanBpsId,
          WhereFrom: questionnaireAnswers.from,
          FavouriteMovie: questionnaireAnswers.fan,
          TalkAbout: questionnaireAnswers.oneThing,
        }),
      );
    }
  }, [loggedInFanBpsId]);

  const renderFanScreen = () => {
    switch (fanParticipant?.State) {
      case ParticipantState.IN_A_QUEUE:
        return <WaitingRoom />;
      case ParticipantState.IN_A_TECH_CHECK:
        return <TechCheckMeeting />;
      case ParticipantState.IN_ANNOUNCEMENT:
        return <CallAnnouncement isOpen />;
      case ParticipantState.IN_A_CALL:
      case ParticipantState.CALL_PAUSED:
      case ParticipantState.IN_PHOTO_TIMER:
        return <InACallView />;
      case ParticipantState.FINISHED:
        return <PostMeeting />;

      default:
        return <WaitingRoom />;
    }
  };

  return (
    <>
      <PreferDesktopUsageBanner />
      {renderFanScreen()}
    </>
  );
});
