// eslint-disable-next-line no-shadow
export enum Urls {
  AUTH = 'authenticate',
  REGISTER = 'register',
  VERIFY_EMAIL = 'verify-email',
  RESEND_VERIFICATION_EMAIL = 'resend-verification-email',
  UPDATE = 'update',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',
  CREATE_SESSION = 'create-session',
  CREATE_EVENT = 'create-slot',
  SET_ATTENDEE_PREFERENCES = 'set-attendee-preferences',
  GET_NEAREST_MEDIA_REGION = `https://nearest-media-region.l.chime.aws`,
  GET_MESSAGE = 'receive-control-message',
  SEND_MESSAGE = 'send-control-message',
  SEND_CONTROL_MESSAGE = 'send-control-message',
  GET_PRODUCTS = 'get-products',
  GET_NEXT_EVENT = 'get-next-slot',
  GET_EVENTS = 'get-slots',
  GET_NEXT_SESSIONS = 'get-upcoming-bps-for-fan',
  GET_PAST_SESSIONS = 'get-past-bps-for-fan',
  GET_USER_ORDERS = 'get-user-shopify-order-infos',
  GET_ADMIN_MEMBERS = 'get-admins-or-tc-users',
  REMOVE_ADMIN_MEMBER = 'remove-admin-or-tc-user',
  IS_OWNER_ADMIN = 'is-owner-admin',
  INVITE_NEW_ADMIN = 'invite-new-admin',
  VERIFY_ADMIN_AND_SET_PASSWORD = 'verify-admin-or-tc-email-and-set-password',
  RESEND_ADMIN_INVITE = 'send-admin-invitation-again',
  TWILIO_ACCESS_TOKEN = 'chat-access-token',
}
