import styled from 'styled-components';
import { LogoWatermark, TextRegular } from 'modules/ui';
import { device } from 'modules/utils';

export const Placeholder = styled.div`
  width: 100%;
  height: auto;
  max-height: 215px;
  max-width: 386px;

  background-image: url(${LogoWatermark});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1128px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.ga.blueTile};
  padding: 24px 24px;
  margin-bottom: 22px;
  min-height: 263px;

  .placeholder {
  }

  img {
    height: auto;
    max-height: 215px;
    max-width: 386px;
    border-radius: 6px;
  }

  @media ${device.laptop} {
    img {
      width: 100%;
      height: auto;
      max-height: 215px;
      max-width: 386px;
    }
  }

  @media ${device.tablet} {
    flex-direction: column;
    width: 90%;
    padding: 0;
    img {
      max-width: none;
      max-height: none;
    }
    button {
      display: inline-block;
    }
  }

  @media ${device.mobile} {
    width: auto;
  }
`;

export const InfoContainer = styled.div`
  padding: 0 10px 10px 24px;
  width: 100%;
  position: relative;

  @media ${device.tablet} {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 22px 20px;
    button {
      display: inline-block;
    }
  }

  @media ${device.mobile} {
    padding: 0 0 22px 0;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 5px;
    button {
      span {
        font-size: 15px;
      }
    }
  }

  @media ${device.tablet} {
    button {
      padding: 10px 15px;
    }
  }

  @media ${device.mobile} {
    display: inline-block;
    margin-top: 12px;
    button {
      display: none;
    }
    span {
      font-size: 16px;
      line-height: 52px;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > ${TextRegular} {
    margin-right: 10px;
  }

  @media ${device.mobile} {
    padding: 0 22px;
    span {
      padding: 0;
    }
  }
`;

export const MiscInfoText = styled.span`
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  position: absolute;
  bottom: 0;
  left: 24px;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 10px;
  border-top: 1px solid rgba(237, 244, 254, 0.1);

  @media ${device.laptop} {
    font-size: 13px;
    line-height: 16px;
  }

  @media ${device.tablet} {
    margin-top: 10px;
    display: inline-block;
    position: static;
  }

  @media ${device.mobile} {
    padding: 10px 22px 13px 22px;
    display: inline-block;
    margin-top: 10px;
    position: static;
  }
`;

export const EventTitleText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  @media ${device.laptop} {
    font-size: 22px;
    line-height: 21px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 20px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const TextBold = styled.span`
  display: none;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  @media ${device.mobile} {
    display: inline-block;
    padding: 0 22px;
  }
`;

export const ButtonWrapper = styled.div`
  display: none;

  @media ${device.mobile} {
    display: inline-block;
    padding: 0 22px;

    button {
      width: 100%;
    }
  }
`;
