import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthSelectors, logOutUser } from 'modules/auth';
import {
  Header,
  Navigation,
  MobileNavMenu,
  DropdownMenu,
  SettingsIcon,
  SettingsLightIcon,
  DropdownMenuItem,
  TextBold,
  TextRegular,
} from 'modules/ui';
import { WaitingRoomPopup } from 'modules/lobby';
import {
  CommonMeetingSelectors,
  useIsDeviceSupported,
} from 'modules/common-meeting';
import {
  ClientRoutes,
  ClientDropdownRoutes,
  DeviceNotSupportedModal,
} from 'modules/client';
import { Container } from './styled';

type Props = {
  isDark?: boolean;
};

export const ClientLayout: React.FC<Props> = React.memo(
  ({ isDark, children }) => {
    const user = useSelector(AuthSelectors.getUser);
    const isLoggedInFanInQueue = useSelector(
      CommonMeetingSelectors.isLoggedInFanInQueue,
    );
    const isDeviceSupoorted = useIsDeviceSupported();

    const dispatch = useDispatch();
    const handleLogOut = () => {
      dispatch(logOutUser());
    };

    const userName = useMemo(() => {
      return user?.profile?.firstName;
    }, [user]);

    const getMenuItems = () => {
      const items = [
        <DropdownMenuItem key="logout" onClick={handleLogOut}>
          <TextRegular>
            Hi,
            {userName}
          </TextRegular>
          <TextBold className="sign-out">Sign out</TextBold>
        </DropdownMenuItem>,
        ...ClientDropdownRoutes.map((route) => (
          <DropdownMenuItem key={route.path}>
            <Link to={route.path}>
              <TextRegular>{route.label}</TextRegular>
            </Link>
          </DropdownMenuItem>
        )),
      ];

      return items;
    };

    return (
      <>
        <Container>
          <Header isDark={isDark}>
            <Navigation isDark={isDark} routes={ClientRoutes} />
            <DropdownMenu
              icon={isDark ? <SettingsLightIcon /> : <SettingsIcon />}
            >
              {getMenuItems()}
            </DropdownMenu>
          </Header>
          <MobileNavMenu />
          {children}
        </Container>
        {!isDeviceSupoorted && <DeviceNotSupportedModal />}
        {isLoggedInFanInQueue && <WaitingRoomPopup />}
      </>
    );
  },
);
