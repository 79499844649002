import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncApiThunk } from 'modules/adapter';
import { Attendee } from 'modules/utils';
import { getPositionsRequest, getBpsForWaitingRoomRequest } from '../api';

export const removeAttendeeFromQueue = createAction<Attendee>(
  'REMOVE_ATTENDEE_FROM_QUEUE',
);

export const updateAttendeesOrder = createAction<Attendee[]>(
  'UPDATE_ATTENDEE_ORDER',
);

export const getPositions = createAsyncThunk(
  'GET_POSITIONS',
  getPositionsRequest,
);

export const getBpsForWaitingRoom = createAsyncApiThunk(
  'GET_BPS_FOR_WAITING_ROOM',
  getBpsForWaitingRoomRequest,
);
