import { Urls } from 'modules/main';
import { get, create } from 'modules/api';
import { Response } from 'modules/utils';
import {
  UserOrderType,
  MemberType,
  RemoveMemberBodyData,
  RemoveMemberResponseData,
  OwnerAdminResponseData,
  NewAdminData,
} from '../types';

export const getUserOrdersRequest = (): Promise<Response<UserOrderType[]>> => {
  return get(`${Urls.GET_USER_ORDERS}`);
};

export const getAdminMembersRequest = (): Promise<Response<MemberType[]>> => {
  return get(`${Urls.GET_ADMIN_MEMBERS}`);
};

// TODO: implementation for emtpy response -> "result: {}"
export const removeAdminMemberRequest = (
  body: RemoveMemberBodyData,
): Promise<Response<RemoveMemberResponseData>> => {
  return create(`${Urls.REMOVE_ADMIN_MEMBER}`, body);
};

export const getIsOwnerRequest = (
  userId: number,
): Promise<Response<OwnerAdminResponseData>> => {
  return get(`${Urls.IS_OWNER_ADMIN}?userId=${userId}`);
};

export const inviteNewAdminRequest = (
  body: NewAdminData,
): Promise<Response<unknown>> => {
  return create(`${Urls.INVITE_NEW_ADMIN}`, body);
};

export const resendAdminInviteRequest = (
  body: NewAdminData,
): Promise<Response<unknown>> => {
  return create(`${Urls.RESEND_ADMIN_INVITE}`, body);
};

export const updateUserRequest = (
  body: NewAdminData,
): Promise<Response<unknown>> => {
  return create(Urls.UPDATE, body);
};
