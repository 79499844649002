import React from 'react';
import { TextRegular, H3 } from 'modules/ui';
import { Container, Illustration } from './styled';

export const NoVideosPlaceholder: React.FC = () => {
  return (
    <Container>
      <H3>Looks like this screen is empty.</H3>
      <TextRegular>
        Experience live sessions with your favourite celebrities and see the
        video recordings here!
      </TextRegular>
      <Illustration />
    </Container>
  );
};
