import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TextItalic } from 'modules/ui';

const Instructions = styled(TextItalic)`
  font-style: italic !important;
`;

const LinkText = styled.span`
  color: ${({ theme }) => theme.ga.magenta};
  font-style: italic !important;
  cursor: pointer;
`;

const CONTACT_EMAIL = 'info@galaxycon.com';

const EmailInstructionsText = () => {
  const onContactEmailClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      window.open(`mailto:${CONTACT_EMAIL}`, '_blank');
    },
    [],
  );

  return (
    <Instructions>
      Email Address for the account must match the email address used when Video
      Chats were purchased. Contact
      <LinkText onClick={onContactEmailClick}> info@galaxycon.com </LinkText>
      with any questions.
    </Instructions>
  );
};

export default React.memo(EmailInstructionsText);
