import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MessageBrokerActionType } from '../consts';
import { getCredentialsRequest } from '../api';

export const receiveMessage = createAction<unknown>(
  MessageBrokerActionType.RECEIVE_MESSAGE,
);

export const setMessageReceivedTimestamp = createAction<number>(
  MessageBrokerActionType.SET_MESSAGE_RECEIVED_TIMESTAMP,
);

export const getCredentials = createAsyncThunk(
  MessageBrokerActionType.GET_MESSAGE_BROKER_CREDENTIALS,
  getCredentialsRequest,
);

export const initiateConnection = createAction<string>(
  MessageBrokerActionType.CONNECT,
);

export const setIsConnectedToMessageBroker = createAction(
  MessageBrokerActionType.SET_IS_CONNECTED_TO_MESSAGE_BROKER,
);

export const stopConnection = createAction(MessageBrokerActionType.DISCONNECT);
