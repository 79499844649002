import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paths } from 'paths';
import { TalentLobby } from 'modules/talent-meeting';

import {
  UserSignup,
  LoginPage,
  AuthSelectors,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  CheckMail,
  VerifyAdmin,
} from 'modules/auth';

import {
  isAdminRole,
  isFanRole,
  isTalentRole,
  // isTechRole,
} from 'modules/account';

import { AdminRoutes } from './AdminRoutes';
import { FanRoutes } from './FanRoutes';
import { TalentRoutes } from './TalentRoutes';

export const Routes: React.FC = (): JSX.Element => {
  const isUserAuthenticated = useSelector(AuthSelectors.isUserLoggedIn);
  const user = useSelector(AuthSelectors.getUser);

  const isAdmin = isAdminRole(user.role);
  // const isTech = isTechRole(user.role);
  const isFan = isFanRole(user.role);
  const isTalent = isTalentRole(user.role);

  if (!isUserAuthenticated) {
    return (
      <Switch>
        <Route path={Paths.UserLogin} component={LoginPage} />
        <Route path={Paths.UserSignup} component={UserSignup} />
        <Route path={Paths.ForgotPassword} component={ForgotPassword} />
        <Route path={Paths.ResetPassword} component={ResetPassword} />
        <Route path={Paths.VerifyEmail} component={VerifyEmail} />
        <Route path={Paths.CheckMail} component={CheckMail} />
        <Route path={Paths.VerifyAdmin} component={VerifyAdmin} />
        <Route path={Paths.TalentSession}>
          <TalentLobby />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={Paths.ForgotPassword} component={ForgotPassword} />

      {isAdmin && <AdminRoutes />}
      {isFan && <FanRoutes />}
      {isTalent && <TalentRoutes />}
    </Switch>
  );
};
