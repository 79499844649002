import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'modules/main/redux';
import { getAll, getById } from 'modules/adapter';

// import { utcToZonedTime } from 'date-fns-tz';
import { GalaxyEventModel, GalaxyNavigatorEventModel } from '../types';

export const eventsSelector = (
  state: RootState,
): RootState['events']['collection'] => state.events.collection;

export const productsSelector = (
  state: RootState,
): RootState['events']['products'] => state.events.products;

export const oldEventsSelector = (state: RootState): RootState['events'] =>
  state.events;

export const getEvents = getAll(eventsSelector);
export const getEventById = getById(eventsSelector);
export const getProducts = getAll(productsSelector);
export const getEventProducts = getAll(productsSelector);

// export const getEvents = createSelector([getAllEvents], (events) => {
//   return events.map((event) => ({
//     ...event,
//     start: utcToZonedTime(event.start, 'America/New_York').toISOString(),
//     end: utcToZonedTime(event.end, 'America/New_York').toISOString(),
//   }));
// });
export const getCalendarEvents = getEvents;

export const getNextEvent = createSelector(
  [oldEventsSelector],
  (events): GalaxyEventModel => events.nextEvent,
);

export const getOrderInfos = createSelector(
  [oldEventsSelector],
  (events) => events.orderInfo,
);

export const getNavigatorEvents = createSelector(
  [getEvents],
  (events): GalaxyNavigatorEventModel[] =>
    events.map((event: GalaxyEventModel) => {
      const date = new Date(event.start);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        className: 'event',
      };
    }),
);

export const getNumberOfEvents = createSelector(
  [getEvents],
  (events) => events.length,
);
