import { createReducer, combineReducers } from '@reduxjs/toolkit';
import { GalaxyEventModel } from 'modules/galaxy-events';
import { collection } from 'modules/adapter';
import { getGalaxyEventFromEvent } from '../utils';
import * as EventsActions from './actions';
import { UserOrderInfo } from '../types';

export const nextEvent = createReducer({} as GalaxyEventModel, (builder) => {
  builder.addCase(EventsActions.setNextEvent, (_state, action) =>
    getGalaxyEventFromEvent(action.payload),
  );
});

export const orderInfo = createReducer([] as UserOrderInfo[], (builder) => {
  builder.addCase(
    EventsActions.getUserOrderInfos.fulfilled,
    (_state, action) => action.payload,
  );
});

export const eventsReducer = combineReducers({
  nextEvent,
  orderInfo,
  collection: collection(
    EventsActions.getEvents,
    EventsActions.getEventById,
    EventsActions.createEvent,
    EventsActions.updateEvent,
    EventsActions.deleteGalaxyEvent,
  ),
  products: collection(EventsActions.getEventProducts),
});
